<script setup>
import { computed, reactive, onMounted, inject, ref } from 'vue'
import { ChevronLeftIcon } from '@heroicons/vue/solid'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { Form, Field } from "vee-validate"
import * as yup from "yup"
import stytchApi from '@/axios/stytch'
import axios from "@/axios"
import { VueRecaptcha } from 'vue-recaptcha'
import VueCountdown from '@chenfengyuan/vue-countdown'

const swal = inject("$swal")
const store = useStore()
const router = useRouter()
const route = useRoute()
const dataUser = computed(() => store.getters['user/getUser'])
const isLoading = ref(false)
const stepForm = ref(1)
const methodId = ref('')
const captchaShow = ref(false)
const recaptcha = ref('recaptcha')
const params = reactive({
    otpType: 'email',
    identifier: null,
    challenge: 'verification'
})
const codeOtp = reactive([])
const liveTimeOtp = ref(null)
const time = ref(118000)
const reqNewOtp = ref(false)
const validateInput = ['1','2','3','4','5','6','7','8','9','0']

const emailSchema = yup.object({
    email: yup.string().required('Email tidak boleh kosong')
    .email('Email tidak valid')
})

const otpSchema = yup.object({
    code1: yup.string().required().matches(/^[0-9]+$/).length(1),
    code2: yup.string().required().matches(/^[0-9]+$/).length(1),
    code3: yup.string().required().matches(/^[0-9]+$/).length(1),
    code4: yup.string().required().matches(/^[0-9]+$/).length(1),
    code5: yup.string().required().matches(/^[0-9]+$/).length(1),
    code6: yup.string().required().matches(/^[0-9]+$/).length(1),
})

const recaptchaSiteKey = computed(() => {
    return process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY
})

const swalError = swal.mixin({
    customClass: {
        confirmButton: 'bg-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-amalan-white text-sm font-semibold hover:bg-blue-900 hover:border-blue-900',
        cancelButton: 'absolute -top-3 -right-3'
    },
    buttonsStyling: false,
    title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
    width: '340px',
    allowOutsideClick: false,
    reverseButtons: true,
})

const submitEmailOtp = (values) => {
    params.identifier = values.email
    captchaShow.value = true
}

const recaptchaVerify = (response) => {
    if(response){
        setTimeout(() => {
            isLoading.value = true
            axios.get(`/customer/check-duplicate/email?email=${params.identifier}`)
            .then(() =>{
                recaptcha.value.reset()
                captchaShow.value = false
                requestOtp()
            }).catch(() => {
                recaptcha.value.reset()
                captchaShow.value = false
                isLoading.value = false
                swalError.fire({
                    html: '<p class="text-center text-sm mt-2">Maaf, email yang kamu masukkan telah digunakan, pastikan email tersebut adalah email anda atau gunakan email lain.</p>',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                })
            })
        }, 1000)
    }
}

const requestOtp = () => {
    stytchApi.post('request-otp', params)
    .then(resp => {
        isLoading.value = false
        methodId.value = resp.data.data.methodId
        stepForm.value++
        setTimeout(() => {
            document.getElementById('code1').focus()
        }, 2000)
    })
    .catch(err => {
        isLoading.value = false
        return handleErrorMessage(err.response.data.message)
    })
}

const resendOtp = () => {
    isLoading.value = true
    liveTimeOtp.value.end()    
    stytchApi.post('request-otp', params)
    .then(resp => {
        isLoading.value = false;
        methodId.value = resp.data.data.methodId
        time.value += 10
        liveTimeOtp.value.start()
        reqNewOtp.value = true
    })
    .catch(err => {
        isLoading.value = false;
        return handleErrorMessage(err.response.data.message)
    })
}

const submitOtp = (values) => {
    isLoading.value = true
    const payload = {
        methodId: methodId.value,
        otpType: params.otpType,
        identifier: params.identifier,
        code: values.code1 + values.code2 + values.code3 + values.code4 + values.code5 + values.code6,
        challenge: params.challenge
    };

    stytchApi.post('authenticate-otp', payload)
    .then(resp => {
        if(resp.data.status_code == 200){
            axios.put('/customer/verification/email', { email: params.identifier })
            .then(() =>{
                store.dispatch('user/storeUser').then(() => {
                    setTimeout(() => {
                        isLoading.value = false
                        router.push({ name: 'rdp opening - document poa' })
                    }, 1000)
                })
            }).catch(() => {
                isLoading.value = false
                swalError.fire({
                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal melakukan validasi kode verifikasi, silahkan tunggu beberapa saat.<br><br>Atau klik tombol bantuan dibawah</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                }).then((result) => {
                    if (result.isConfirmed){
                        window.location = 'https://wa.me/6285888236207?text=' + 
                        'Mohon bantuan saya tidak dapat verifikasi email di web.amalan.com, saya sudah memasukkan kode verifikasi dengan benar namun tetap gagal.'
                        return;
                    }
                })
            })
        }
    })
    .catch((err) => {
        isLoading.value = false
        return handleErrorMessage(err.response.data.message)
    });
}

const transformSlotProps = (props) => {
    const formattedProps = {}

    Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value)
    })

    return formattedProps
}

const nextInput = (event) => {
    if(event.data && validateInput.includes(event.data)){
        const element = event.srcElement.name
        const el = parseInt(element.replace('code', ''))
        if(el < 6){
            const nextElem = el + 1
            document.getElementById('code' + nextElem).focus()
        }
    }
}

const deleteInput = (event) => {
    if(event.key == 'Backspace'){
        const element = event.srcElement.name
        const el = parseInt(element.replace('code', ''))
        if(el > 1){
            codeOtp[el - 1] = null
            codeOtp[el - 2] = null
            const nextElem = el - 1
            document.getElementById('code' + nextElem).focus()
            if(el === 6){
                codeOtp[5] = null
            }
        }
    }
}

const pasteInput = (event) => {
    const clipboard = event.clipboardData.getData('text').trim()
    if(/\d{6}/.test(clipboard)){
        const data = [...clipboard]
        data.forEach((elem, index) => {
            codeOtp[index] = elem
        })
        document.getElementById('code6').focus()
    }
}

const handleErrorMessage = (message) => {
    if(!message){
        return swalError.fire({
            html: '<p class="text-center text-xs mt-2">Maaf, server gagal mengirim kode OTP, silahkan tunggu beberapa saat.<br><br>Atau klik tombol bantuan dibawah</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        }).then((result) => {
            if (result.isConfirmed){
                window.location = 'https://wa.me/6285888236207?text=' + 
                'Mohon bantuan saya tidak menerima OTP untuk login di web.amalan.com';
                return;
            }
        });
    }

    if(message.includes('Too Many Attempts')){
        captchaShow.value = false
        return swalError.fire({
            html: '<p class="text-center text-xs mt-2">Anda sudah melebihi limit percobaan verifikasi. Mohon coba beberapa saat lagi</p>',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        });
    }

    if(message.includes('Invalid OTP code') || message.includes('OTP not found')){
        return swalError.fire({
            html: '<p class="text-center text-xs mt-2">Maaf, kode OTP yang anda masukan salah. Mohon periksa kembali.</p>',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        });
    }

    if(message.includes('OTP has expired') || message.includes('reached the time limit')){
        return swalError.fire({
            html: '<p class="text-center text-xs mt-2">Maaf, kode OTP yang anda masukan sudah kadaluarsa. Mohon periksa kembali.</p>',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        });
    }

    return swalError.fire({
        html: '<p class="text-center text-xs mt-2">Maaf, server gagal mengirim kode OTP, silahkan tunggu beberapa saat.<br><br>Atau klik tombol bantuan dibawah</p>',
        showCancelButton: true,
        confirmButtonText: 'Bantuan',
        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
    }).then((result) => {
        if (result.isConfirmed){
            window.location = 'https://wa.me/6285888236207?text=' + 
            'Mohon bantuan saya tidak menerima OTP untuk login di web.amalan.com';
            return;
        }
    });
}

onMounted(() => {
    if(dataUser.value.email_verified_at){
        router.push({ name : 'rdp opening - document poa' })
    }

    params.identifier = dataUser.value.email
})

</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="router.push({ name : 'Home' })" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold mt-0 text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full max-w-screen-md px-6">
            <div class="pb-10 pt-6 w-full mx-auto">
                <div class="flex items-center">
                    <div class="flex items-center text-amalan-blue-1 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/signup/data-pinjaman-active.svg" class="w-5 h-5">
                        </div>
                        <div class="absolute -top-2 -ml-10 text-center mt-16 w-32 text-xs font-bold text-amalan-blue-1"><p>Data<br>Pengguna</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-amalan-blue-1"></div>
                    <div class="flex items-center text-amalan-blue-1 relative">
                        <div class="rounded-full h-12 w-12 border-2 bg-amalan-yellow-400 border-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/signup/tanda-tangan-active.svg" class="w-5 h-5">
                        </div>
                        <div class="absolute -top-2 -ml-10 text-center mt-16 w-32 text-xs font-bold text-amalan-blue-1"><p>Tanda<br>Tangan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-amalan-gray-3"></div>
                    <div class="flex items-center text-amalan-gray-3 relative">
                        <div class="rounded-full h-12 w-12 border-2 flex justify-center items-center bg-amalan-gray-5 border-amalan-gray-5">
                            <img src="@/assets/img/verified.svg" class="w-5 h-5">
                        </div>
                        <div class="absolute -top-2 -ml-4 text-center mt-16 w-20 text-xs text-amalan-gray-2"><p>Registrasi<br>Selesai</p></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full px-6 py-6 text-amalan-black">
            <div class="w-full px-10">
                <img src="@/assets/img/verifikasi-email.svg" class="w-full">
            </div>
            <div class="w-full mt-6" v-if="stepForm === 1">
                <p class="text-center text-amalan-blue-1 font-bold text-base">Verifikasi Email Anda</p>
                <p class="text-center text-xs mt-1">Masukkan alamat email Anda untuk mendapatkan kode verifikasi</p>
                <Form
                @submit="submitEmailOtp"
                :validation-schema="emailSchema"
                v-slot="{ errors }"
                >
                    <div class="mt-8">
                        <Field name="email" :validateOnInput="true" type="text" class="block w-full px-4 py-3 text-amalan-black bg-amalan-white border-2 border-gray-200 rounded-xl focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none hover:border-amalan-gray-3" :class="{ 'border-amalan-red': errors.email }" placeholder="Masukan alamat email Anda" v-model="params.identifier" autofocus />
                        <div v-if="errors.email" class="mt-1 text-sm text-amalan-red">*{{errors.email}}</div>
                    </div>
                    <div class="my-4" v-if="captchaShow">
                        <p class="text-center text-xs">Silahkan centang kotak di bawah untuk melanjutkan.</p>
                        <div class="flex justify-center items-center mt-1">
                            <VueRecaptcha
                                ref="recaptcha"
                                :sitekey="recaptchaSiteKey"
                                :language="'id'"
                                @verify="recaptchaVerify"
                            ></VueRecaptcha>
                        </div>
                    </div>
                    <button v-else type="submit" class="my-4 w-full rounded-xl font-bold border-2 border-solid text-center text-base py-3 px-6 text-amalan-white cursor-pointer bg-amalan-blue-1 border-amalan-blue-1 hover:bg-blue-900 hover:border-blue-900">Kirim</button>
                </Form>
            </div>
            <div v-else class="w-full mt-6">
                <p class="text-center text-amalan-blue-1 font-bold text-base">Kode Verifikasi</p>
                <p class="text-center text-xs mt-1">Kode verifikasi akan dikirimkan ke <b>{{ params.identifier }}</b></p>
                <Form
                @submit="submitOtp"
                :validation-schema="otpSchema"
                v-slot="{ errors, meta }"
                >
                    <p class="mt-4 text-xs text-center text-amalan-gray-2 font-semibold">Kode verifikasi akan kadaluarsa dalam</p>
                    <p class="text-amalan-blue-1 text-center text-xs"><vue-countdown ref="liveTimeOtp" :time="time" :transform="transformSlotProps" v-slot="{ minutes, seconds }">{{ minutes }}.{{ seconds }}</vue-countdown></p>
                    <div class="my-4 w-full flex items-center justify-center">
                        <template v-for="i in 6" :key="i">
                            <Field :name="'code' + i" :id="'code' + i" type="tel" maxlength="1" class="bg-transparent mx-2 w-11 p-2 text-lg text-center border-2 rounded-lg outline-none focus:border-amalan-blue-3" :class="[errors['code' + i] ? 'border-amalan-red' : 'border-amalan-gray-3']" :validateOnMount="true" :validateOnInput="true" @input="nextInput" @keydown="deleteInput" @paste="pasteInput" v-model="codeOtp[i - 1]" />
                        </template>
                    </div>
                    <button type="submit" class="my-4 mt-4 w-full rounded-lg font-medium border border-solid text-center text-base py-2 px-6 text-amalan-white" :class="[ !meta.valid ? 'bg-gray-300 border-gray-300 cursor-not-allowed' : 'cursor-pointer bg-amalan-blue-1 border-amalan-blue-1 hover:bg-blue-900 hover:border-blue-900' ]" :disabled="!meta.valid">Verifikasi</button>
                    <p class="my-1 text-xs text-center text-amalan-gray-2 font-semibold">Tidak menerima kode verifikasi?</p>
                    <p v-if="reqNewOtp" class="mt-1 text-sm text-center text-amalan-red font-semibold">Kode verifikasi baru berhasil dikirim.</p>
                    <button v-else type="button" class="block mx-auto text-xs text-amalan-blue-1 font-bold underline hover:text-blue-900" @click="resendOtp">
                        Kirim ulang kode verifikasi
                    </button>
                </Form>
            </div>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>