<script setup>
import { ref, reactive, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import documentApi from "@/axios/docCollection"
import { PlusIcon } from '@heroicons/vue/solid'
import { TrashIcon, ChevronLeftIcon } from '@heroicons/vue/outline'
import { Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { iconBankLender, listLoanType } from '@/constants/LoanConstant'
import useModalPopUp from '@/composables/modalPopUp'
import { BasicImageViewer } from '@/composables/imageViewer'
import PDFViewerModal from '@/components/Modal/PDFViewerModal.vue'

const router = useRouter()
const route = useRoute()
const store = useStore()
const modalPopUp = useModalPopUp()

const billStatements = computed(() => store.getters['docCollection/getbillStatements'])
const dataOpportunities = computed(() => store.getters['docCollection/getOpportunities'])

const FILE_SIZE = 49152 * 1024
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
]
const dataOpportunity = ref(null)
const tempFile = reactive({ file_input: null })
const isLoading = ref(false)
const showFilePreview = ref(false)
const errorMessage = ref(false)
const urlPratinjau = ref(null)
const fileName = ref(null)
const nameFileTypePratinjau = ref(null)
const opportunityIdParam = ref(route.params.opportunityId || null)
const previewFilePDF = ref({
    render: false,
    isVisible: false,
    fileSource: null
})
const fileDownloadBaseUrl = process.env.VUE_APP_SIGNUP_SERVICE_BASE_URL

const formDokumenSchema = yup.object({
    file_input: yup.mixed().required("Pilih file.")
    .test("fileSize", "Ukuran file maksimal 48 MB.", (value) => { if (value && value.length > 0) return value[0].size <= FILE_SIZE }).test("fileFormat", "Mohon pilih file.", (value) => { if (value && value.length > 0) return SUPPORTED_FORMATS.includes(value[0].type) }),
})

const getIcon = (loanName, loanType) => {
    const lenderName = loanName.replace(" " + loanType, "").toLowerCase()
    const lenderData = iconBankLender.filter(elem => elem.name == lenderName)
    if (lenderData[0]) return lenderData[0].icon

    const loanTypeIcon = listLoanType.filter(elem => elem.name == loanType)
    if (loanTypeIcon[0]) return loanTypeIcon[0].icon
}

const getLoanTitle = (loanType) => {
    let loanTitle = listLoanType.filter(elem => elem.name == loanType)
    if (loanTitle[0]) return loanTitle[0].title
}

const getBankName = (loanName, loanType) => loanName.replace(" " + loanType, "")

const clickFileInput = () => document.getElementById("fileInput").click()

const unggahFile = () => {
    if(!urlPratinjau.value) return
    return document.getElementById("formDokumenButton").click()
}

const previewFile = () => {
    urlPratinjau.value = null
    fileName.value = null
    nameFileTypePratinjau.value = null
    if (tempFile.file_input.length > 0 && SUPPORTED_FORMATS.includes(tempFile.file_input[0].type) && tempFile.file_input[0].size <= FILE_SIZE){
        const reader = new window.FileReader()
        reader.onload = (e) => {
            let fileType = tempFile.file_input[0].type
            nameFileTypePratinjau.value = fileType.includes("image") ? "gambar" : fileType.includes("pdf") ? "pdf" : "dokumen"
            fileName.value = tempFile.file_input[0].name
            errorMessage.value = false
            showFilePreview.value = true
            urlPratinjau.value = e.target.result
        }
        reader.readAsDataURL(tempFile.file_input[0])
    } else {
        errorMessage.value = true
        urlPratinjau.value = null
        fileName.value = null
        nameFileTypePratinjau.value = null
        tempFile.file_input = null
    }
}

const checkFileExtension = (fileName) => {
    const fileNameSplit = fileName.split('.')
    if(fileNameSplit.length === 0) return 'default'
    
    const ext = fileNameSplit.length - 1
    if(fileNameSplit[ext] == 'doc' || fileNameSplit[ext] == 'docx') return 'doc'
    if(fileNameSplit[ext] == 'xls' || fileNameSplit[ext] == 'xlsx') return 'xls'
    if(fileNameSplit[ext] == 'pdf') return 'pdf'
    return 'image'
}

const formDokumenSubmit = (values, { resetForm }) => {
    showFilePreview.value = false
    isLoading.value = true
    const formData = new FormData()
    formData.append('file', values.file_input[0])
    formData.append('opportunityId', opportunityIdParam.value)

    documentApi.post('salesforce/opportunities/upload-bill-statement', formData,{
        headers: {
            'Accept': 'multipart/form-data',
            'Content-Type': 'multipart/form-data'
        }
    })
    .then(() => {
        store.dispatch('docCollection/requestOpportunities')
        tempFile.file_input = null
        resetForm();
        store.dispatch('docCollection/requestListBillStatement', opportunityIdParam.value).then(() => {
            isLoading.value = false
        })
    })
    .catch(() => {
        isLoading.value = false
        modalPopUp.swalError.fire({
            html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        }).then((result) => {
            if (result.isConfirmed){
                window.location = 'https://wa.me/6285888236207?text=' + 
                'Mohon bantuan saya gagal terus dalam proses document collection - unggah dokumen lembar tagihan di web.amalan.com'
                return;
            }else{
                showFilePreview.value = true
            }
        })
    })
}

const removeBillStatement = (billStatementId) => {
    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-sm mt-2">Dokumen ini tidak dapat dipulihkan kembali. <br><br>Apakah Anda yakin ingin menghapusnya?</p>',
        showCancelButton: true,
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan',
    }).then((result) => {
        if (result.isConfirmed){
            isLoading.value = true
            documentApi.delete('salesforce/opportunities/' + opportunityIdParam.value + '/bill-statements/' + billStatementId)
            .then(() => {
                store.dispatch('docCollection/requestOpportunities')
                store.dispatch('docCollection/requestListBillStatement', opportunityIdParam.value).then(() => {
                    isLoading.value = false
                })
            })
            .catch(() => {
                isLoading.value = false
                modalPopUp.swalError.fire({
                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                }).then((result) => {
                    if (result.isConfirmed){
                        window.location = 'https://wa.me/6285888236207?text=' + 
                        'Mohon bantuan saya gagal terus untuk menghapus dokumen lembar tagihan - document collection di web.amalan.com'
                        return
                    }
                })
            })
        }
    })
}

const downloadFile = (url) => window.open(url, '_blank')

const findOpportunityFromList = () => dataOpportunity.value = dataOpportunities.value.find((value) => value.Id === opportunityIdParam.value)

const viewImage = (elementImageId) => BasicImageViewer(elementImageId)

const viewPdf = (fileSource) => {
    const splitUrl = fileSource.split('?url=')
    if(splitUrl.length < 2) return

    previewFilePDF.value.fileSource = `${fileDownloadBaseUrl}download-file-aws?url=${splitUrl[1]}`
    previewFilePDF.value.render = true
    previewFilePDF.value.isVisible = true
}

const backButton = () => {
    if(!previewFilePDF.value.isVisible) return router.go(-1)
    return previewFilePDF.value.isVisible = false
}

onMounted(async () => {
    isLoading.value = true
    await store.dispatch('docCollection/requestListBillStatement', opportunityIdParam.value)
    findOpportunityFromList()
    isLoading.value = false
})
</script>

<template>
    <div class="min-h-screen bg-amalan-white relative">
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="backButton" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ route.query.AkunPage ? 'Manajemen Pinjaman' : route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full px-6 py-8 bg-amalan-white" v-if="dataOpportunity">
            <div class="w-full rounded-lg border p-4 flex justify-between items-center border-amalan-gray-4 bg-amalan-white">
                <div class="flex-none w-14 h-14 rounded-lg flex justify-center items-center">
                    <img :src="require('@/assets/img/bank-lender-icon/' + getIcon(dataOpportunity.Name, dataOpportunity.Loan_Type_2__c))" class="w-14">
                </div>
                <div class="flex-grow px-2.5 text-amalan-black">
                    <p class="text-sm text-left font-bold">{{ dataOpportunity.Name ? getBankName(dataOpportunity.Name, dataOpportunity.Loan_Type_2__c) : '-' }}</p>
                    <p class="text-xs mt-0.5">{{ dataOpportunity.Loan_Type_2__c ? getLoanTitle(dataOpportunity.Loan_Type_2__c) : '-' }}</p>
                    <p class="text-xs mt-1">{{ dataOpportunity.Estimated_Outstanding_Amount__c ? $filters.format_number(dataOpportunity.Estimated_Outstanding_Amount__c) : '-' }}</p>
                </div>
            </div>
            <Form
            @submit="formDokumenSubmit"
            :validation-schema="formDokumenSchema"
            v-slot="{ errors }"
            >
                <template v-if="billStatements && billStatements.length > 0">
                    <div v-for="(billStatement, index) in billStatements" :key="index" class="w-full mt-3.5 rounded-lg border border-solid border-amalan-gray-4 overflow-hidden">
                        <div class="w-full border-b border-solid py-2 flex justify-between items-center px-4 text-amalan-black">
                            <span class="text-xs font-bold">Lembar Tagihan {{ index + 1 }}</span>
                            <button type="button" class="h-6 w-6 shadow-amalan-md border border-amalan-blue-1 text-amalan-blue-1 rounded-lg flex justify-center items-center cursor-pointer" @click="removeBillStatement(billStatement.Id)">
                                <TrashIcon class="h-4 w-4" />
                            </button>
                        </div>
                        <div v-if="checkFileExtension(billStatement.Name) == 'image'" class="w-full flex items-center p-3.5 cursor-pointer bg-amalan-white hover:bg-amalan-gray-5" @click="viewImage(`preview-image-${index}`)">
                            <img :src="billStatement.URL__c + '&appkey=' + $amazyncKey" class="w-12" :id="`preview-image-${index}`">
                            <span class="text-sm ml-4">{{ billStatement.Name }}</span>
                        </div>
                        <div v-else-if="checkFileExtension(billStatement.Name) == 'pdf'" class="w-full flex items-center p-3.5 cursor-pointer bg-amalan-white hover:bg-amalan-gray-5" @click="viewPdf(billStatement.URL__c)">
                            <img src="@/assets/img/protect/thumbnail-pdf.png" class="w-12">
                            <span class="text-sm ml-4">{{ billStatement.Name }}</span>
                        </div>
                        <div v-else class="w-full flex items-center p-3.5 cursor-pointer bg-amalan-white hover:bg-amalan-gray-5" @click="downloadFile(billStatement.URL__c + '&appkey=' + $amazyncKey)">
                            <img src="@/assets/img/protect/thumbnail-doc.png" class="w-12">
                            <span class="text-sm ml-4">{{ billStatement.Name }}</span>
                        </div>
                    </div>
                </template>
                <template v-if="billStatements && billStatements.length < 3">
                    <label class="block rounded-lg h-28 py-3.5 mt-3.5 cursor-pointer border border-dashed border-amalan-gray-3 hover:bg-amalan-gray-5 relative group" @click="clickFileInput">
                        <div class="h-11 w-11 rounded-full border border-amalan-blue-1 flex justify-center items-center mx-auto shadow-amalan-md" :class="[ billStatements.length > 0 ? 'bg-amalan-white text-amalan-blue-1 group-hover:bg-amalan-blue-1 group-hover:text-amalan-white' : 'bg-amalan-blue-1 text-amalan-white']">
                            <PlusIcon class="h-9 w-9"/>
                        </div>
                        <p class="text-center mt-4 text-sm">Tambahkan <b>Lembar Tagihan</b> Anda disini!</p>
                    </label>
                    <Field type="file" id="fileInput" name="file_input" class="hidden" v-model="tempFile.file_input" @input="previewFile"/>
                    <button type="submit" id="formDokumenButton" class="hidden"></button>
                    <p class="w-full text-center text-2xs text-amalan-black mt-1">Anda dapat menambahkan Dokumen Lembar Tagihan dengan format JPG, JPEG, PNG, PDF, DOC, DOCX, XLS, atau XLSX</p>
                    <div v-if="errors.file_input" class="mt-1 mb-4 text-xs text-amalan-red">*{{errors.file_input}}</div>
                </template>
            </Form>
        </div>
        <PDFViewerModal v-if="previewFilePDF.render" :visible="previewFilePDF.isVisible" :pdf-file-source="previewFilePDF.fileSource" />
    </div>
    <template v-if="showFilePreview">
        <div class="fixed inset-0 z-50 bg-black bg-opacity-60 grid grid-cols-1 p-5 overflow-y-auto">
            <div class="bg-amalan-white rounded-lg w-full max-w-lg place-self-center">
                <div class="bg-amalan-blue-7 rounded-t-lg py-2"> 
                    <p class="text-center text-base font-bold">Pratinjau</p>
                </div>
                <div v-if="!errorMessage" class="w-full p-4">
                    <img v-if="nameFileTypePratinjau == 'gambar' && urlPratinjau" :src="urlPratinjau" class="w-full mx-auto">
                    <template v-else-if="nameFileTypePratinjau == 'pdf'">
                        <div class="w-40 mx-auto">
                            <img src="@/assets/img/protect/thumbnail-pdf.png" class="w-full">
                            <p class="text-center text-sm px-2 overflow-scroll">{{ fileName }}</p>
                        </div>
                    </template>
                    <template v-else>
                        <div class="w-40 mx-auto">
                            <img src="@/assets/img/protect/thumbnail-doc.png" class="w-full">
                            <p class="text-center text-sm px-2 overflow-scroll">{{ fileName }}</p>
                        </div>
                    </template>
                </div>
                <p v-if="errorMessage" class="text-center my-8 text-xs px-4">Pastikan file yang Anda upload dalam format JPG, JPEG, PNG, PDF, DOC, DOCX, XLS, atau XLSX.</p>
                <div class="w-full grid grid-cols-2 gap-x-4 px-4 pb-4">
                    <button @click="clickFileInput" type="button" class="w-full shadow-amalan-md rounded-amalan-lg font-bold text-center text-sm py-3 text-amalan-blue-1 border border-amalan-blue-1 cursor-pointer bg-amalan-white hover:bg-amalan-gray-5">Pilih Kembali</button>
                    <button @click="unggahFile" type="button" class="w-full shadow-amalan-md rounded-amalan-lg font-bold text-center text-sm py-3 text-amalan-white" :class="[ !errorMessage ? 'cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2' : 'cursor-not-allowed text-amalan-gray-2 bg-amalan-gray-4' ]" :disabled="errorMessage">Unggah</button>
                </div>
            </div>
        </div>
    </template>
    <loading-overlay v-if="isLoading" />
</template>