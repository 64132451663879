import { useStorage } from "@vueuse/core";
import { Crisp } from "crisp-sdk-web";
import { ref } from "vue";
// import { isOutsideWorkingHoursAndMinutes } from "./workingHours";

Crisp.configure(process.env.VUE_APP_CRISP_WEB_ID, {
    autoload: false
})

Crisp.session.onLoaded(() => {
    setCrispLoaded()
})

export const crispInstanceLoaded = ref(false)

export const crispChatBoxClosed = ref(false)

export const sentGreetingMessage = () => {
    const messageSent = useStorage('messageSent', false)
    if(messageSent.value) return Crisp.chat.open()

    Crisp.chat.open()
    Crisp.message.showText("Terima kasih, data Anda sudah kami terima.")
    Crisp.message.showText("Apakah Anda bersedia untuk dihubungkan dengan konsultan kami sekarang ?")
    Crisp.session.setSegments(["routed"]);
    return messageSent.value = true
}

export const sentGreetingPresalesPrompt = () => {
    // Run presales bot 24x7
    //if(!isOutsideWorkingHoursAndMinutes()) return

    const messageSent = useStorage('messageSent', false)
    if(messageSent.value) return Crisp.chat.open()

    Crisp.chat.open()
    Crisp.message.showText("Terima kasih, data Anda sudah kami terima.")
    Crisp.message.showText("Selanjutnya, Anda akan terhubung dengan asisten konsultan kami untuk mengonfirmasi data yang sudah diisikan.")
    Crisp.message.showText(`Mohon balas "Ya" untuk melanjutkan.`)
    Crisp.session.setSegments(["presales-ai"]);
    return messageSent.value = true
}

export const useCrispInstance = () => Crisp

export const setCrispLoaded = () => crispInstanceLoaded.value = true