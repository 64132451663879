<template>
    <div>
        <TermsConditions />
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="backNavigation" class="flex items-center mt-0" :class="{ 'invisible' : termAndConditionStatus }">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold mt-0 text-amalan-black">{{ $route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="mx-4 px-1" :class="{ 'overflow-hidden h-screen' : termAndConditionStatus }">
            <div class="pb-10 pt-6 w-full max-w-screen-md mx-auto">
                <div class="flex items-center">
                    <div class="flex items-center text-amalan-blue-1 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/ktp-active.svg" class="w-7 h-7">
                        </div>
                        <div class="absolute -top-2 -ml-10 text-center mt-16 w-32 text-xs font-bold text-amalan-blue-1"><p>e-KTP &<br>Swafoto</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-amalan-blue-1"></div>
                    <div class="flex items-center text-amalan-blue-1 relative">
                        <div class="rounded-full h-12 w-12 border-2 bg-amalan-yellow-400 border-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/lengkapi-data-active.svg" class="w-5 h-5">
                        </div>
                        <div class="absolute -top-2 -ml-10 text-center mt-16 w-32 text-xs font-bold text-amalan-blue-1"><p>Lengkapi<br>Data</p></div>
                    </div>
                    <div class="flex-auto border-t-2" :class="[ currentStep == 4 ? 'border-amalan-blue-1' : 'border-amalan-gray-3' ]"></div>
                    <div class="flex items-center relative" :class="[ currentStep == 4 ? 'text-amalan-blue-1' : 'text-amalan-gray-3' ]">
                        <div class="rounded-full h-12 w-12 border-2 flex justify-center items-center" :class="[ currentStep == 4 ? 'bg-amalan-yellow-400 border-amalan-yellow-400' : 'bg-amalan-gray-5 border-amalan-gray-5' ]">
                            <img v-if="currentStep == 4" src="@/assets/img/verified-active.svg" class="w-5 h-5">
                            <img v-else src="@/assets/img/verified.svg" class="w-5 h-5">
                        </div>
                        <div class="absolute -top-2 -ml-4 text-center mt-16 w-20 text-xs" :class="[ currentStep == 4 ? 'font-bold text-amalan-blue-1' : 'text-amalan-gray-2' ]"><p>Konfirmasi<br>Data</p></div>
                    </div>
                </div>
            </div>
            <div class="my-6 pb-16 w-full max-w-screen-md mx-auto relative">
                <Form
                    @submit="nextStep"
                    :validation-schema="updateForm"
                    v-slot="{ handleSubmit, errors, meta }"
                    >
                    <template v-if="currentStep === 0">
                        <div class="my-3">
                            <label class="text-amalan-black font-bold text-sm">Gelar</label>
                            <div class="flex relative w-full">
                                <Field name="title" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.title ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.title">
                                    <option v-for="(value, key) in dataTitle" :key="key" :value="value">{{ value }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-3 text-amalan-black">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.title" class="mt-1 text-2xs text-amalan-red">*{{errors.title}}</div>
                        </div>
                        <div class="my-3">
                            <label class="text-amalan-black font-bold text-sm">Nama Lengkap</label>
                            <div class="w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-gray-5 border-2 rounded-xl cursor-not-allowed">{{ (dataUser.first_name || '') + ' ' + (dataUser.last_name || '') }}</div>
                        </div>
                        <div class="my-3">
                            <label class="text-amalan-black font-bold text-sm">NIK</label>
                            <div class="w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-gray-5 border-2 rounded-xl cursor-not-allowed">{{ idCardUser ? idCardUser.number : '-' }}</div>
                        </div>
                        <div class="my-3">
                            <label class="text-amalan-black font-bold text-sm">Email</label>
                            <Field name="email" v-model="formValues.email" :validateOnInput="true" :validateOnMount="true" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.email ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                            <div v-if="errors.email" class="mt-1 text-2xs text-amalan-red">*{{errors.email}}</div>
                        </div>
                    </template>

                    <template v-if="currentStep === 1">
                        <div class="my-3">
                            <label class="text-amalan-black font-bold text-sm">Jenis Kelamin</label>
                            <div class="flex relative w-full">
                                <Field name="gender" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.gender ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.gender">
                                    <option v-for="gender in dataGender" :key="gender.key" :value="gender.code">{{ gender.description }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2 text-amalan-black">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.gender" class="mt-1 text-2xs text-amalan-red">*{{errors.gender}}</div>
                        </div>
                        <div class="my-3 grid grid-cols-2 gap-x-4">
                            <div>
                                <label class="text-amalan-black font-bold text-sm">Tempat Lahir</label>
                                <div class="w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-gray-5 border-2 rounded-xl cursor-not-allowed">{{ dataUser.birthplace }}</div>
                            </div>
                            <div>
                                <label class="text-amalan-black font-bold text-sm">Tanggal Lahir</label>
                                <div class="w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-gray-5 border-2 rounded-xl cursor-not-allowed">{{ $filters.formatDateOnlyDate(dataUser.birthdate) }}</div>
                            </div>
                        </div>
                        <div class="my-3">
                            <label class="text-amalan-black font-bold text-sm">Agama</label>
                            <div class="flex relative w-full">
                                <Field name="religionId" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.religionId ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.religionId">
                                    <option v-for="religion in dataReligion" :key="religion.key" :value="religion.id">{{ religion.name }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2 text-amalan-black">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.religionId" class="mt-1 text-2xs text-amalan-red">*{{errors.religionId}}</div>
                        </div>
                        <div class="my-3">
                            <label class="text-amalan-black font-bold text-sm">Pendidikan</label>
                            <div class="flex relative w-full">
                                <Field name="educationId" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.educationId ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.educationId">
                                    <option v-for="education in dataEducation" :key="education.key" :value="education.id">{{ education.name }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2 text-amalan-black">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.educationId" class="mt-1 text-2xs text-amalan-red">*{{errors.educationId}}</div>
                        </div>
                        <div class="my-3">
                            <label class="text-amalan-black font-bold text-sm">Pekerjaan</label>
                            <div class="flex relative w-full">
                                <Field name="occupationId" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.occupationId ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.occupationId">
                                    <option v-for="occupation in dataOccupation" :key="occupation.key" :value="occupation.id">{{ occupation.name }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2 text-amalan-black">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.occupationId" class="mt-1 text-2xs text-amalan-red">*{{errors.occupationId}}</div>
                        </div>
                        <div class="my-3">
                            <label class="text-amalan-black font-bold text-sm">Status Perkawinan</label>
                            <div class="flex relative w-full">
                                <Field name="maritalId" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.maritalId ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.maritalId">
                                    <option v-for="marital in dataMarital" :key="marital.key" :value="marital.id">{{ marital.name }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2 text-amalan-black">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.maritalId" class="mt-1 text-2xs text-amalan-red">*{{errors.maritalId}}</div>
                        </div>
                    </template>

                    <template v-if="currentStep === 2">
                        <div class="my-3">
                            <label class="text-amalan-black font-bold text-sm">Alamat</label>
                            <Field name="street" v-model="formValues.street" :validateOnInput="true" :validateOnMount="true" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.street ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                            <div v-if="errors.street" class="mt-1 text-2xs text-amalan-red">*{{errors.street}}</div>
                        </div>
                        <div class="my-3 grid grid-cols-2 gap-x-4">
                            <div>
                                <label class="text-amalan-black font-bold text-sm">RT</label>
                                <Field name="rt" v-model="formValues.rt" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.rt ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnInput="true" :validateOnMount="true"/>
                                <div v-if="errors.rt" class="mt-1 text-2xs text-amalan-red">*{{errors.rt}}</div>
                            </div>
                            <div>
                                <label class="text-amalan-black font-bold text-sm">RW</label>
                                <Field name="rw" v-model="formValues.rw" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.rw ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnInput="true" :validateOnMount="true"/>
                                <div v-if="errors.rw" class="mt-1 text-2xs text-amalan-red">*{{errors.rw}}</div>
                            </div>
                        </div>
                        <div class="my-3">
                            <label class="text-amalan-black font-bold text-sm">Kode Pos</label>
                            <Field name="postalCode" v-model="formValues.postalCode" type="tel" maxlength="5" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.postalCode || !postalCodeValid ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnInput="true" :validateOnMount="true"/>
                            <div v-if="errors.postalCode" class="mt-1 text-2xs text-amalan-red">*{{errors.postalCode}}</div>
                            <div class="mt-1 text-2xs" :class="[ postalCodeValid ? 'text-amalan-green' : 'text-amalan-red' ]">{{ postalCodeValid ? 'Kode Pos valid' : '*Kode Pos tidak valid' }}</div>
                        </div>
                        <div class="my-3">
                            <label class="text-amalan-black font-bold text-sm">Provinsi</label>
                            <div class="flex relative w-full">
                                <Field name="provinceId" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.provinceId ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.provinceId" @change="getCity">
                                    <option v-for="province in dataProvince" :key="province.key" :value="province.id">{{ province.name }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2 text-amalan-black">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.provinceId" class="mt-1 text-2xs text-amalan-red">*{{errors.provinceId}}</div>
                        </div>
                        <div class="my-3">
                            <label class="text-amalan-black font-bold text-sm">Kota</label>
                            <div class="flex relative w-full">
                                <Field name="cityId" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.cityId ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.cityId" @change="getDistrict">
                                    <option v-for="city in dataCity" :key="city.key" :value="city.id">{{ city.name }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2 text-amalan-black">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.cityId" class="mt-1 text-2xs text-amalan-red">*{{errors.cityId}}</div>
                        </div>
                        <div class="my-3">
                            <label class="text-amalan-black font-bold text-sm">Kecamatan</label>
                            <div class="flex relative w-full">
                                <Field name="districtId" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.districtId ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.districtId" @change="getVillage">
                                    <option v-for="district in dataDistrict" :key="district.key" :value="district.id">{{ district.name }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2 text-amalan-black">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.districtId" class="mt-1 text-2xs text-amalan-red">*{{errors.districtId}}</div>
                        </div>
                        <div class="my-3">
                            <label class="text-amalan-black font-bold text-sm">Kelurahan</label>
                            <div class="flex relative w-full">
                                <Field name="villageId" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.villageId ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.villageId">
                                    <option v-for="village in dataVillage" :key="village.key" :value="village.id">{{ village.name }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2 text-amalan-black">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.villageId" class="mt-1 text-2xs text-amalan-red">*{{errors.villageId}}</div>
                        </div>
                    </template>

                    <template v-if="currentStep === 3">
                        <div class="text-amalan-black">
                            <div class="px-4 pt-3 pb-6">
                                <p class="text-lg text-center font-bold text-amalan-blue-1">
                                    Konfirmasi Data
                                </p>
                                <p class="mt-4 text-xs text-center">
                                    Cek kembali data Anda sesuai e-KTP dan pastikan semua data yang diinputkan sesuai dengan data yang ada ya!
                                </p>
                            </div>
                            <div class="h-px w-full bg-amalan-gray-4"></div>
                            <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                                <div class="font-bold col-span-4">Nama</div>
                                <div class="col-span-8 flex">
                                    <span class="mr-1">:</span> 
                                    <span>{{ formValues.title }}. {{ dataUser.first_name + ' ' + dataUser.last_name }}</span>
                                </div>
                            </div>
                            <div class="h-px w-full bg-amalan-gray-4"></div>
                            <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                                <div class="font-bold col-span-4">Email</div>
                                <div class="col-span-8 flex">
                                    <span class="mr-1">:</span> 
                                    <span>{{ formValues.email }}</span>
                                </div>
                            </div>
                            <div class="h-px w-full bg-amalan-gray-4"></div>
                            <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                                <div class="font-bold col-span-4">Jenis Kelamin</div>
                                <div class="col-span-8 flex">
                                    <span class="mr-1">:</span> 
                                    <span>{{ genderDescription }}</span>
                                </div>
                            </div>
                            <div class="h-px w-full bg-amalan-gray-4"></div>
                            <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                                <div class="font-bold col-span-4">Tempat/Tgl Lahir</div>
                                <div class="col-span-8 flex">
                                    <span class="mr-1">:</span> 
                                    <span>{{ dataUser.birthplace }}/{{ $filters.formatDateOnlyDate(dataUser.birthdate) }}</span>
                                </div>
                            </div>
                            <div class="h-px w-full bg-amalan-gray-4"></div>
                            <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                                <div class="font-bold col-span-4">Agama</div>
                                <div class="col-span-8 flex">
                                    <span class="mr-1">:</span> 
                                    <span>{{ religionName }}</span>
                                </div>
                            </div>
                            <div class="h-px w-full bg-amalan-gray-4"></div>
                            <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                                <div class="font-bold col-span-4">Pendidikan</div>
                                <div class="col-span-8 flex">
                                    <span class="mr-1">:</span> 
                                    <span>{{ educationName }}</span>
                                </div>
                            </div>
                            <div class="h-px w-full bg-amalan-gray-4"></div>
                            <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                                <div class="font-bold col-span-4">Status</div>
                                <div class="col-span-8 flex">
                                    <span class="mr-1">:</span> 
                                    <span>{{ maritalName }}</span>
                                </div>
                            </div>
                            <div class="h-px w-full bg-amalan-gray-4"></div>
                            <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                                <div class="font-bold col-span-4">Alamat</div>
                                <div class="col-span-8 flex">
                                    <span class="mr-1">:</span> 
                                    <span>{{ formValues.street }}</span>
                                </div>
                            </div>
                            <div class="h-px w-full bg-amalan-gray-4"></div>
                            <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                                <div class="font-bold col-span-4">RT/RW</div>
                                <div class="col-span-8 flex">
                                    <span class="mr-1">:</span> 
                                    <span>{{ formValues.rt }}/{{ formValues.rw }}</span>
                                </div>
                            </div>
                            <div class="h-px w-full bg-amalan-gray-4"></div>
                            <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                                <div class="font-bold col-span-4">Provinsi</div>
                                <div class="col-span-8 flex">
                                    <span class="mr-1">:</span> 
                                    <span>{{ provinceName }}</span>
                                </div>
                            </div>
                            <div class="h-px w-full bg-amalan-gray-4"></div>
                            <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                                <div class="font-bold col-span-4">Kota</div>
                                <div class="col-span-8 flex">
                                    <span class="mr-1">:</span> 
                                    <span>{{ cityName }}</span>
                                </div>
                            </div>
                            <div class="h-px w-full bg-amalan-gray-4"></div>
                            <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                                <div class="font-bold col-span-4">Kecamatan</div>
                                <div class="col-span-8 flex">
                                    <span class="mr-1">:</span> 
                                    <span>{{ districtName }}</span>
                                </div>
                            </div>
                            <div class="h-px w-full bg-amalan-gray-4"></div>
                            <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                                <div class="font-bold col-span-4">Kelurahan</div>
                                <div class="col-span-8 flex">
                                    <span class="mr-1">:</span> 
                                    <span>{{ villageName }}</span>
                                </div>
                            </div>
                            <div class="h-px w-full bg-amalan-gray-4"></div>
                            <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                                <div class="font-bold col-span-4">Kode Pos</div>
                                <div class="col-span-8 flex">
                                    <span class="mr-1">:</span> 
                                    <span>{{ formValues.postalCode }}</span>
                                </div>
                            </div>
                            <div class="h-px w-full bg-amalan-gray-4 mb-4"></div>
                            <div class="px-4 pt-2 flex justify-between items-start">
                                <label class="font-medium text-xs inline-flex" for="personalDataAgreement">
                                    <label class="h-4 w-4 border-2 border-gray-400 rounded mr-2 p-0 cursor-pointer" :class="{ 'border-red-600': errors.personalDataAgreement, 'bg-amalan-yellow-400 border-amalan-yellow-400' : formValues.personalDataAgreement }" @click="personalDataAgreementChecked">
                                        <CheckIcon v-if="formValues.personalDataAgreement" class="w-3 h-3 mx-auto text-amalan-blue-1" />
                                        <div v-else class="w-3 h-3 mx-auto bg-transparent"></div>
                                    </label>
                                    <Field name="personalDataAgreement" v-model="formValues.personalDataAgreement" type="checkbox" class="hidden" :validateOnMount="true" :validateOnInput="true" />
                                </label>
                                <span class="text-xs cursor-pointer" @click="personalDataAgreementChecked">Pengguna dengan ini menyetujui untuk memberikan data pengguna kepada amalan dan amalan akan menjamin kerahasiaan serta keamanan data tersebut.</span>
                            </div>
                            <div v-if="errors.personalDataAgreement" class="text-xs text-red-600 px-4">*{{errors.personalDataAgreement}}</div>
                        </div>
                    </template>

                    <template v-if="currentStep === 4">
                        <div class="px-4 pt-16 pb-6 text-center">
                            <img src="@/assets/img/lead-qualification/qualified-icon.svg" class="mx-auto">
                            <p class="my-8 text-sm text-amalan-black">
                                Verifikasi data Anda telah sukses dilakukan!
                            </p>
                            <router-link :to="{ name: 'rdp opening - document poa' }" class="rounded-lg font-semibold text-sm cursor-pointer text-center py-3 px-4 text-amalan-white bg-amalan-blue-1 hover:bg-blue-900">Lanjut ke Pembukaan RDP</router-link>
                        </div>
                    </template>

                    <template v-if="currentStep !== 4">
                        <div class="w-full mt-24">
                            <button v-if="currentStep !== 0" type="button" class="h-10 rounded-lg font-medium border border-solid cursor-pointer text-center text-base py-1 px-1 text-amalan-blue-1 border-amalan-blue-1 hover:bg-amalan-blue-1 hover:text-amalan-white float-left" @click="prevStep"><ChevronLeftIcon class="h-full" /></button>
                            <button v-if="currentStep !== 3" type="submit" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-1 float-right" :class="[ meta.valid ? currentStep === 2 ? postalCodeValid ? 'text-amalan-white bg-amalan-blue-1 border-amalan-blue-1 hover:bg-blue-900 hover:border-blue-900 cursor-pointer' : 'text-amalan-gray-3 bg-amalan-gray-5 border-amalan-gray-5 cursor-not-allowed' : 'text-amalan-white bg-amalan-blue-1 border-amalan-blue-1 hover:bg-blue-900 hover:border-blue-900 cursor-pointer' : 'text-amalan-gray-3 bg-amalan-gray-5 border-amalan-gray-5 cursor-not-allowed' ]" :disabled="currentStep === 2 && !postalCodeValid" @submit="handleSubmit"><ChevronRightIcon class="h-full" /></button>
                            <button v-if="currentStep === 3" type="submit" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-6 text-amalan-white float-right" :class="[ formValues.personalDataAgreement ? 'cursor-pointer bg-amalan-blue-1 border-amalan-blue-1 hover:bg-blue-900 hover:border-blue-900' : 'bg-gray-300 border-gray-300 cursor-not-allowed' ]" :disabled="!formValues.personalDataAgreement">Kirim</button>
                        </div>
                    </template>
                </Form>
            </div>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<script>
import { ref, reactive, computed, defineComponent, inject, watch } from "vue";
import { useStore } from 'vuex';
import { Form, Field } from "vee-validate";
import * as yup from "yup";
import axios from "@/axios"
import { ChevronLeftIcon, ChevronRightIcon, CheckIcon } from '@heroicons/vue/solid';
import router from "@/router"
import TermsConditions from '@/components/TermsConditions.vue'
import { mapGetters } from 'vuex'
import { useRoute } from 'vue-router'

export default defineComponent ({
    data(){
        return {
            fromAmalanSignup: false
        }
    },
    components: {
        Form,
        Field,
        ChevronRightIcon,
        ChevronLeftIcon,
        CheckIcon,
        TermsConditions
    },
    computed: {
        genderDescription () {
            let gender = this.dataGender.find(({ code }) => code === this.formValues.gender)
            return gender.description
        },
        occupationName () {
            let occupation = this.dataOccupation.find(({ id }) => id === this.formValues.occupationId)
            return occupation.name
        },
        religionName () {
            let religion = this.dataReligion.find(({ id }) => id === this.formValues.religionId)
            return religion.name
        },
        educationName () {
            let education = this.dataEducation.find(({ id }) => id === this.formValues.educationId)
            return education.name
        },
        maritalName () {
            let marital = this.dataMarital.find(({ id }) => id === this.formValues.maritalId)
            return marital.name
        },
        provinceName () {
            let province = this.dataProvince.find(({ id }) => id === this.formValues.provinceId)
            return province.name
        },
        cityName () {
            let city = this.dataCity.find(({ id }) => id === this.formValues.cityId)
            return city.name
        },
        districtName () {
            let district = this.dataDistrict.find(({ id }) => id === this.formValues.districtId)
            return district.name
        },
        villageName () {
            let village = this.dataVillage.find(({ id }) => id === this.formValues.villageId)
            return village.name
        },
        ...mapGetters('globalState',{
            termAndConditionStatus:'getTermAndConditionStatus'
        })
    },
    mounted(){
        if(localStorage.getItem('amalan-signup')){
            this.fromAmalanSignup = true;
        }
        this.$store.dispatch('user/requestIdCard')

        this.formValues.title = this.dataUser.title;
        this.formValues.email = this.dataUser.email;
        this.formValues.gender = this.dataUser.gender;
        this.formValues.street = this.dataUser.address.street;
        this.formValues.rt = this.dataUser.address.rt;
        this.formValues.rw = this.dataUser.address.rw;
        this.formValues.postalCode = this.dataUser.address.postal_code;
        this.formValues.personalDataAgreement = false;
    },
    setup: () => {
        const route = useRoute()
        const store = useStore();
        const currentStep = ref(0);
        const formValues = reactive({
            title: null,
            email: null,
            gender: null,
            street: null,
            rt: null,
            rw: null,
            religionId: null,
            educationId: null,
            occupationId: null,
            maritalId: null,
            provinceId: null,
            cityId: null,
            districtId: null,
            villageId: null,
            postalCode: null,
            personalDataAgreement: null
        });
        let isLoading = ref(false);
        let dataOccupation = ref([]);
        let dataReligion = ref([]);
        let dataEducation = ref([]);
        let dataMarital = ref([]);
        let dataProvince = ref([]);
        let dataCity = ref([]);
        let dataDistrict = ref([]);
        let dataVillage = ref([]);
        let dataTitle = ['Bpk', 'Ibu'];
        let dataGender = [{ code: 'M', description: 'Laki-laki' },{ code: 'F', description: 'Perempuan' }];
        const dataUser = computed(() => store.getters['user/getUser']);
        const idCardUser = computed(() => store.getters['user/getIdCard']);
        const postalCodeValid = ref(false)

        const schemas = [
            yup.object({
                title: yup.string().nullable().required('Silahkan pilih title').oneOf(dataTitle, 'Silahkan pilih title'),
                email: yup.string().nullable().required('Silahkan isi sesuai alamat email yang kamu gunakan ya!')
                .email('Email tidak valid').max(255, "Maksimal 255 karakter"),
            }),
            yup.object({
                gender: yup.string().nullable().required('Silahkan pilih jenis kelamin').oneOf(['M','F'], 'Silahkan pilih jenis kelamin'),
                religionId: yup.string().nullable().required('Silahkan pilih agama'),
                educationId: yup.string().nullable().required('Silahkan pilih pendidikan'),
                occupationId: yup.string().nullable().required('Silahkan pilih pekerjaan'),
                maritalId: yup.string().nullable().required('Silahkan pilih status perkawinan'),
            }),
            yup.object({
                street: yup.string().nullable().required('Alamat tidak boleh kosong').max(255, "Maksimal 255 karakter"),
                rt: yup.string().nullable().required('RT tidak boleh kosong').matches(/^[0-9]+$/, "RT harus berupa angka").max(5, "Maksimal 5 karakter"),
                rw: yup.string().nullable().required('RW tidak boleh kosong').matches(/^[0-9]+$/, "RW harus berupa angka").max(5, "Maksimal 5 karakter"),
                provinceId: yup.string().nullable().required('Silahkan pilih provinsi'),
                cityId: yup.string().nullable().required('Silahkan pilih kota'),
                districtId: yup.string().nullable().required('Silahkan pilih kecamatan'),
                villageId: yup.string().nullable().required('Silahkan pilih kelurahan'),
                postalCode: yup.string().nullable().required('Kode pos tidak boleh kosong')
                .matches(/^[0-9]+$/, "Kode pos harus berupa angka").min(5, "Minimal 5 karakter").max(5, "Maksimal 5 karakter"),
            }),
            yup.object({
                personalDataAgreement: yup.bool().required('Silahkan dicentang').equals([true], 'Silahkan dicentang'),
            }),
        ];

        const swal = inject("$swal");
        const updateForm = computed(() => {
            return schemas[currentStep.value];
        });

        const swalConfirm = swal.mixin({
            customClass: {
                confirmButton: 'bg-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-amalan-white text-sm font-semibold hover:bg-blue-900 hover:border-blue-900',
                cancelButton: 'bg-amalan-white mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-amalan-blue-1 text-sm font-semibold hover:bg-amalan-red hover:border-amalan-red hover:text-amalan-white'
            },
            buttonsStyling: false,
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        const swalError = swal.mixin({
            customClass: {
                confirmButton: 'bg-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-amalan-white text-sm font-semibold hover:bg-blue-900 hover:border-blue-900',
                cancelButton: 'absolute -top-3 -right-3'
            },
            buttonsStyling: false,
            title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        const swalErrorReload = swal.mixin({
            customClass: {
                confirmButton: 'bg-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-amalan-white text-sm font-semibold hover:bg-blue-900 hover:border-blue-900'
            },
            buttonsStyling: false,
            title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
            html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
            showCancelButton: false,
            confirmButtonText: 'Perbaharui',
            width: '340px',
            allowOutsideClick: false,
        })

        function nextStep(values) {
            if (currentStep.value === 3) {
                let additional_data = {
                    name: (dataUser.value.first_name || '') + ' ' + (dataUser.value.last_name || ''),
                    rt_rw: formValues.rt + '_' + formValues.rw,
                    birthdate: dataUser.value.birthdate,
                    birthplace: dataUser.value.birthplace,
                    productStage: null
                }
                Object.assign(formValues,additional_data);            
                
                swalConfirm.fire({
                    html: '<p class="text-center text-sm mt-2">Anda yakin data yang diinput sudah benar?<br>Jika sudah, silahkan untuk dikonfirmasi agar<br>data Anda segera diproses</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Konfirmasi',
                    cancelButtonText: 'Batalkan',
                }).then((result) => {
                    if (result.isConfirmed){
                        isLoading.value = true;
                        axios.put('/customer/update', formValues).then(resp => {
                            if(resp.status == 200){
                                store.dispatch('user/storeUser').then(() => {
                                    setTimeout(() => {
                                        isLoading.value = false
                                        currentStep.value++
                                    }, 1000)
                                })
                            }else{
                                isLoading.value = false;
                                swalError.fire({
                                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                                    showCancelButton: true,
                                    confirmButtonText: 'Bantuan',
                                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                                }).then((result) => {
                                    if (result.isConfirmed){
                                        window.location = 'https://wa.me/6285888236207?text=' + 
                                        'Mohon bantuan saya gagal terus dalam proses KYC - lengkapi data di web.amalan.com';
                                        return;
                                    }
                                });
                            }
                        }).catch((err) => {
                            isLoading.value = false;
                            if(err.response.status == 400 || err.response.status == 422 ){
                                if(err.response.data.message.includes('customers_email_unique')){
                                    swalError.fire({
                                        html: '<p class="text-center text-sm mt-2">Maaf, email yang kamu masukkan telah digunakan, pastikan email tersebut adalah email anda atau gunakan email lain.</p>',
                                        showCancelButton: true,
                                        showConfirmButton: false,
                                        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                                    });
                                } else {
                                    swalError.fire({
                                        html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                                        showCancelButton: true,
                                        confirmButtonText: 'Bantuan',
                                        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                                    }).then((result) => {
                                        if (result.isConfirmed){
                                            window.location = 'https://wa.me/6285888236207?text=' + 
                                            'Mohon bantuan saya gagal terus dalam proses KYC - lengkapi data di web.amalan.com';
                                            return;
                                        }
                                    });
                                }
                            }else{
                                swalError.fire({
                                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                                    showCancelButton: true,
                                    confirmButtonText: 'Bantuan',
                                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                                }).then((result) => {
                                    if (result.isConfirmed){
                                        window.location = 'https://wa.me/6285888236207?text=' + 
                                        'Mohon bantuan saya gagal terus dalam proses KYC - lengkapi data di web.amalan.com';
                                        return;
                                    }
                                });
                            }
                        });
                    }
                });
                return;
            }

            Object.assign(formValues, values);
            currentStep.value++;
        }

        function prevStep() {
            if (currentStep.value <= 0) {
                return;
            }

            currentStep.value--;
        }

        axios.get('/educations').then(resp => {
            dataEducation.value = resp.data.data;
            formValues.educationId = dataUser.value.education.id;
        }).catch(err => {
            if (err.status !== 401){
                swalErrorReload.fire().then((result) => {
                    if (result.isConfirmed){
                        window.location.reload();
                    }
                });
            }
        });

        axios.get('/religions').then(resp => {
            dataReligion.value = resp.data.data;
            formValues.religionId = dataUser.value.religion.id;
        }).catch(err => {
            if (err.status !== 401){
                swalErrorReload.fire().then((result) => {
                    if (result.isConfirmed){
                        window.location.reload();
                    }
                });
            }
        });

        setTimeout(() => {
            axios.get('/occupations').then(resp => {
                dataOccupation.value = resp.data.data;
                formValues.occupationId = dataUser.value.occupation.id;
            }).catch(err => {
                if (err.status !== 401){
                    swalErrorReload.fire().then((result) => {
                        if (result.isConfirmed){
                            window.location.reload();
                        }
                    });
                }
            });

            axios.get('/marital-statuses').then(resp => {
                dataMarital.value = resp.data.data;
                formValues.maritalId = dataUser.value.marital.id;
            }).catch(err => {
                if (err.status !== 401){
                    swalErrorReload.fire().then((result) => {
                        if (result.isConfirmed){
                            window.location.reload();
                        }
                    });
                }
            });
        }, 2000);

        setTimeout(() => {
            axios.get('/regions/provinces').then(resp => {
                dataProvince.value = resp.data.data;
                formValues.provinceId = dataUser.value.address.province.id;
            }).catch(err => {
                if (err.status !== 401){
                    swalErrorReload.fire().then((result) => {
                        if (result.isConfirmed){
                            window.location.reload();
                        }
                    });
                }
            });

            if (dataUser.value.address.province.id !== null){
                axios.get('/regions/cities?province_id=' + dataUser.value.address.province.id)
                .then(resp => {
                    dataCity.value = resp.data.data;
                    formValues.cityId = dataUser.value.address.city.id;
                }).catch(err => {
                    if (err.status !== 401){
                        swalErrorReload.fire().then((result) => {
                            if (result.isConfirmed){
                                window.location.reload();
                            }
                        });
                    }
                });
            }
        }, 4000);
        
        setTimeout(() => {
            if (dataUser.value.address.city.id !== null){
                axios.get('/regions/districts?city_id=' + dataUser.value.address.city.id)
                .then(resp => {
                    dataDistrict.value = resp.data.data;
                    formValues.districtId = dataUser.value.address.district.id;
                }).catch(err => {
                    if (err.status !== 401){
                        swalErrorReload.fire().then((result) => {
                            if (result.isConfirmed){
                                window.location.reload();
                            }
                        });
                    }
                });
            }

            if (dataUser.value.address.district.id !== null){
                axios.get('/regions/villages?district_id=' + dataUser.value.address.district.id)
                .then(resp => {
                    dataVillage.value = resp.data.data;
                    formValues.villageId = dataUser.value.address.village.id;
                }).catch(err => {
                    if (err.status !== 401){
                        swalErrorReload.fire().then((result) => {
                            if (result.isConfirmed){
                                window.location.reload();
                            }
                        });
                    }
                });
            }
        }, 6000);

        function getCity(){
            isLoading.value = true;
            formValues.cityId = null;
            axios.get('/regions/cities?province_id=' + formValues.provinceId).then(resp => {
                dataCity.value = resp.data.data;
                isLoading.value = false;
            }).catch(err => {
                isLoading.value = false;
                if (err.status !== 401){
                    swalErrorReload.fire().then((result) => {
                        if (result.isConfirmed){
                            window.location.reload();
                        }
                    });
                }
            });
        }

        function getDistrict(){
            isLoading.value = true;
            formValues.districtId = null;
            axios.get('/regions/districts?city_id=' + formValues.cityId).then(resp => {
                dataDistrict.value = resp.data.data;
                isLoading.value = false;
            }).catch(err => {
                isLoading.value = false;
                if (err.status !== 401){
                    swalErrorReload.fire().then((result) => {
                        if (result.isConfirmed){
                            window.location.reload();
                        }
                    });
                }
            });
        }

        function getVillage(){
            isLoading.value = true;
            formValues.villageId = null;
            axios.get('/regions/villages?district_id=' + formValues.districtId).then(resp => {
                dataVillage.value = resp.data.data;
                isLoading.value = false;
            }).catch(err => {
                isLoading.value = false;
                if (err.status !== 401){
                    swalErrorReload.fire().then((result) => {
                        if (result.isConfirmed){
                            window.location.reload();
                        }
                    });
                }
            });
        }

        function personalDataAgreementChecked(){
            formValues.personalDataAgreement = !formValues.personalDataAgreement;
        }

        function showTermsAndCondition(){
            store.dispatch('globalState/changeTermAndConditionStatus')
        }

        function backNavigation(){
            if (currentStep.value <= 0 || currentStep.value > 3) {
                if(route.meta.creditScoreKYC){
                    router.push({ name: 'Score' })
                }else{
                    router.push({ name: 'Home' })
                }
            }else{
                currentStep.value--
            }
        }

        const validatePostalCode = () => {
            axios.get('/postal-code/' + formValues.postalCode)
            .then(() => {
                postalCodeValid.value = true
            })
            .catch(() => {
                postalCodeValid.value = false
            });
        }

        watch(() => formValues.postalCode, () => {
            postalCodeValid.value = false
            if(formValues.postalCode) if(formValues.postalCode.length === 5) validatePostalCode()
        })

        return {
            dataUser,
            currentStep,
            updateForm,
            prevStep,
            formValues,
            nextStep,
            getCity,
            dataCity,
            getDistrict,
            dataDistrict,
            getVillage,
            dataVillage,
            dataTitle,
            dataOccupation,
            dataReligion,
            dataEducation,
            dataMarital,
            dataProvince,
            dataGender,
            isLoading,
            showTermsAndCondition,
            backNavigation,
            personalDataAgreementChecked,
            idCardUser,
            postalCodeValid
        };
    }
});
</script>
