<template>
    <div>
        <nav class="bg-blue-100">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="$router.push({ name : 'lead qualification - informasi tambahan' })" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5"/>
                    </button>
                    <span class="text-sm font-bold mt-0">{{ $route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="pt-4 min-h-screen h-auto relative">
            <div class="mt-2 pb-12 w-full px-4">
                <div class="flex items-center">
                    <div class="flex items-center text-blue-900 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/signup/data-pinjaman-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Daftar<br>Pinjaman</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-blue-900"></div>
                    <div class="flex items-center text-blue-900 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/lead-qualification/informasi-tambahan-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Informasi<br>Tambahan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-blue-900"></div>
                    <div class="flex items-center text-blue-900 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/lead-qualification/kemampuan-menabung-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Estimasi<br>Keringanan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-gray-300"></div>
                    <div class="flex items-center text-gray-500 relative">
                        <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                            <img src="@/assets/img/verified.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Registrasi<br>Selesai</p></div>
                    </div>
                </div>
            </div>
            <div class="mt-6 pb-12 w-full px-4">
                <p v-if="withSummaryProjection" class="text-center my-4 text-xs sm:text-sm">Untuk setiap bulannya <b>amalan</b> menyarankan anda untuk menabung sebanyak</p>
                <p v-if="withSummaryProjection" class="text-center text-4xl text-blue-900 font-bold">{{ minimalSaving === 0 ? 'Rp -' : $filters.format_number(minimalSaving)}}</p>
                <div class="w-full my-4">
                    <p class="text-center text-xs sm:text-sm">Kemampuan menabung Anda setiap bulan</p>
                    <div class="flex mt-1 justify-between">
                        <div class="w-full mx-auto border px-4 py-2 rounded-lg flex justify-between" :class="[ !validated ? 'border-red-600' : 'border-gray-200']">
                            <div class="w-5/6">
                                <CurrencyInputNoDecoration
                                v-model="monthlySaving"
                                :setFocus="true"
                                :options="{
                                    currency: 'IDR',
                                    currencyDisplay: 'narrowSymbol',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    hideNegligibleDecimalDigitsOnFocus: false,
                                    autoDecimalDigits: false,
                                    autoSign: false,
                                    useGrouping: true,
                                    accountingSign: true
                                }" />
                            </div>
                            <span class="text-gray-400">/bulan</span>
                        </div>
                        <button v-if="withSummaryProjection" type="button" @click="submit" class="ml-2 rounded-lg font-medium border border-solid text-center text-sm py-2 px-6 text-white cursor-pointer bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700">Submit</button>
                    </div>
                    <div v-if="!validated" class="text-xs sm:text-sm text-red-600">*Mohon diisi dan tidak boleh angka 0 atau angka negatif</div>
                </div>
                <div class="mt-10 w-full" v-if="isSummary">
                    <p class="text-base sm:text-lg font-bold text-blue-900 text-center">Estimasi Keringanan yang Anda Dapatkan</p>
                    <div class="w-full mt-3">
                        <div class="w-full bg-amalan-yellow-200 rounded-t-2xl border-l border-r border-t border-amalan-yellow-200 py-3">
                            <img src="@/assets/img/lead-qualification/wording-1.png" class="mx-auto h-5">
                        </div>
                        <div class="w-full bg-white rounded-b-2xl border-l border-r border-b border-solid py-4 px-4">
                            <div class="w-full grid grid-cols-2 gap-y-1.5 text-xs sm:text-sm">
                                <p>Total Tagihan Akhir</p>
                                <p class="font-bold">= {{ summary.total_to_pay_with_amalan ?? '-' }}</p>
                                <p>Besar Bunga</p>
                                <p class="font-bold">= {{ summary.interest_amount_with_amalan ?? '-' }}</p>
                                <p>Durasi Penyelesaian</p>
                                <p class="font-bold">= {{ summary.months_to_payoff_with_amalan ?? '-' }}</p>
                            </div>
                            <div class="my-4 h-0.5 bg-gray-200"></div>
                            <div class="w-full mb-2">
                                <p class="text-sm sm:text-base text-blue-900 font-bold text-center">Layanan Tambahan yang Didapatkan</p>
                                <div class="mt-2 flex items-center">
                                    <label class="h-4 w-4 rounded-full mr-2 cursor-pointer flex justify-center items-center bg-amalan-yellow-400">
                                        <CheckIcon class="w-3 h-3 text-blue-900"/>
                                    </label>
                                    <span class="cursor-pointer text-xs sm:text-sm">Didampingi oleh tim profesional</span>
                                </div>
                                <div class="mt-2 flex items-center">
                                    <label class="h-4 w-4 rounded-full mr-2 cursor-pointer flex justify-center items-center bg-amalan-yellow-400">
                                        <CheckIcon class="w-3 h-3 text-blue-900"/>
                                    </label>
                                    <span class="cursor-pointer text-xs sm:text-sm">Transparansi biaya yang jelas</span>
                                </div>
                                <div class="mt-2 flex items-center">
                                    <label class="h-4 w-4 rounded-full mr-2 cursor-pointer flex justify-center items-center bg-amalan-yellow-400">
                                        <CheckIcon class="w-3 h-3 text-blue-900"/>
                                    </label>
                                    <span class="cursor-pointer text-xs sm:text-sm">Perlindungan dari debt collector*</span>
                                </div>
                                <div class="mt-2 flex items-center">
                                    <label class="h-4 w-4 rounded-full mr-2 cursor-pointer flex justify-center items-center bg-amalan-yellow-400">
                                        <CheckIcon class="w-3 h-3 text-blue-900"/>
                                    </label>
                                    <span class="cursor-pointer text-xs sm:text-sm">Durasi pelunasan lebih cepat*</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full flex justify-center relative">
                        <div class="absolute -top-2 bg-blue-900 rounded-full h-11 w-11 text-lg font-extrabold text-white flex justify-center items-center">VS</div>
                    </div>
                    <div class="w-full mt-7">
                        <div class="w-full bg-gray-200 rounded-t-2xl border-l border-r border-t border-gray-200 py-3">
                            <img src="@/assets/img/lead-qualification/wording-2.png" class="mx-auto h-5">
                        </div>
                        <div class="w-full bg-white rounded-b-2xl border-l border-r border-b border-solid py-4 px-4">
                            <div class="w-full grid grid-cols-2 gap-y-1.5 text-xs sm:text-sm">
                                <p>Total Tagihan Akhir</p>
                                <p class="font-bold">= {{ summary.total_to_pay_without_amalan ?? '-' }}</p>
                                <p>Besar Bunga</p>
                                <p class="font-bold">= {{ summary.interest_amount_without_amalan ?? '-' }}</p>
                                <p>Durasi Penyelesaian</p>
                                <p class="font-bold">= {{ summary.months_to_payoff_without_amalan ?? '-' }}</p>
                            </div>
                            <div class="my-4 h-0.5 bg-gray-200"></div>
                            <div class="w-full mb-2">
                                <p class="text-sm sm:text-base text-blue-900 font-bold text-center">Layanan Tambahan yang Didapatkan</p>
                                <div class="mt-2 flex items-center">
                                    <label class="h-4 w-4 rounded-full mr-2 cursor-pointer flex justify-center items-center bg-red-500">
                                        <XIcon class="w-3 h-3 text-amalan-yellow-400"/>
                                    </label>
                                    <span class="cursor-pointer text-xs sm:text-sm">Belum tentu didampingi hingga proses selesai</span>
                                </div>
                                <div class="mt-2 flex items-center">
                                    <label class="h-4 w-4 rounded-full mr-2 cursor-pointer flex justify-center items-center bg-red-500">
                                        <XIcon class="w-3 h-3 text-amalan-yellow-400"/>
                                    </label>
                                    <span class="cursor-pointer text-xs sm:text-sm">Tidak ada transparansi biaya</span>
                                </div>
                                <div class="mt-2 flex items-center">
                                    <label class="h-4 w-4 rounded-full mr-2 cursor-pointer flex justify-center items-center bg-red-500">
                                        <XIcon class="w-3 h-3 text-amalan-yellow-400"/>
                                    </label>
                                    <span class="cursor-pointer text-xs sm:text-sm">Tidak ada perlindungan debt collector</span>
                                </div>
                                <div class="mt-2 flex items-center">
                                    <label class="h-4 w-4 rounded-full mr-2 cursor-pointer flex justify-center items-center bg-red-500">
                                        <XIcon class="w-3 h-3 text-amalan-yellow-400"/>
                                    </label>
                                    <span class="cursor-pointer text-xs sm:text-sm">Durasi pelunasan utang lama</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex mt-1">
                        <p class="text-xs sm:text-sm text-gray-400">*</p>
                        <p class="text-xs sm:text-sm text-gray-400 ml-1">Syarat dan ketentuan berlaku sesuai jumlah utang, hasil konsultasi, dan package yang disarankan</p>
                    </div>
                </div>
            </div>
            <div class="w-full px-4 mb-24">
                <router-link :to="{ name : 'lead qualification - informasi tambahan' }" class="h-10 rounded-lg font-medium border border-solid cursor-pointer text-center text-base py-1 px-1 text-blue-900 border-blue-900 hover:bg-blue-900 hover:text-white float-left"><ChevronLeftIcon class="h-full" /></router-link>
                <button v-if="withSummaryProjection" type="button" @click="next" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-1 float-right" :class="[ isSummary ? 'cursor-pointer text-white bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700' : 'text-gray-400 bg-gray-200 border-gray-200 cursor-not-allowed' ]" :disabled="!isSummary"><ChevronRightIcon class="h-full" /></button>
                <button v-else type="button" @click="next" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-1 float-right" :class="[ monthlySaving && monthlySaving > 0 ? 'cursor-pointer text-white bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700' : 'text-gray-400 bg-gray-200 border-gray-200 cursor-not-allowed' ]" :disabled="!monthlySaving || monthlySaving < 1"><ChevronRightIcon class="h-full" /></button>
            </div>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<script>
import { ChevronRightIcon, ChevronLeftIcon, CheckIcon, XIcon } from '@heroicons/vue/solid';
import { defineComponent, inject, computed, ref, watch } from 'vue';
import CurrencyInputNoDecoration from "@/components/CurrencyInputNoDecoration.vue";
import { useStore } from 'vuex'
import router from "@/router"
import amalansignupApi from "@/axios/amalansignup"
import { mixpanelPushEvent, eventName } from '@/composables/mixpanel'

export default defineComponent({
    components:{
        ChevronRightIcon,
        ChevronLeftIcon,
        CurrencyInputNoDecoration,
        CheckIcon,
        XIcon
    },
    mounted(){
        // if(this.dataFormUpdateContact.financialHardship == null || this.dataFormUpdateContact.financialHardshipDetail == null){
        //     this.$router.push({ name: 'lead qualification - informasi tambahan' })
        // }

        if(this.dataFormUpdateContact.financialHardship == null){
            this.$router.push({ name: 'lead qualification - informasi tambahan' })
        }

        mixpanelPushEvent(eventName.SAVING_ABILITY)
    },
    setup(){
        const store = useStore()
        const swal = inject("$swal")

        const dataFormUpdateContact = computed(() => store.getters['leadQualification/getFormUpdateContact'])
        const dataOpportunities = computed(() => store.getters['leadQualification/getOpportunities'])

        let monthlySaving = ref(0)
        let minimalSaving = ref(0)
        let validated = ref(true)
        let isLoading = ref(true)
        let withSummaryProjection = ref(false)
        let isSummary = ref(false)
        let summary = ref({
            interest_amount_with_amalan: null,
            interest_amount_without_amalan: null,
            minimum_saving_with_amalan: null,
            minimum_saving_without_amalan: null,
            months_to_payoff_with_amalan: null,
            months_to_payoff_without_amalan: null,
            saving_amount: null,
            total_outstanding: null,
            total_to_pay_with_amalan: null,
            total_to_pay_without_amalan: null,
        })
        monthlySaving.value = dataFormUpdateContact.value.monthlySaving ?? 0

        const swalConfirm = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600',
                cancelButton: 'bg-white mx-2 py-2 px-5 rounded-lg border border-blue-900 text-blue-900 text-sm font-semibold hover:bg-red-400 hover:border-red-400 hover:text-white'
            },
            buttonsStyling: false,
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        const swalError = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600',
                cancelButton: 'absolute -top-3 -right-3'
            },
            buttonsStyling: false,
            title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        const swalErrorReload = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
            },
            buttonsStyling: false,
            title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
            html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
            showCancelButton: false,
            confirmButtonText: 'Perbaharui',
            width: '340px',
            allowOutsideClick: false,
        })

        watch(() => store.getters['leadQualification/getOpportunities'], function() {
            for(let i in dataOpportunities.value){
                if(dataOpportunities.value[i].Loan_Type_2__c === 'KTA' || dataOpportunities.value[i].Loan_Type_2__c === 'Credit Card'){
                    withSummaryProjection.value = true
                    break
                }
            }

            if(withSummaryProjection.value){
                let data = dataOpportunities.value.map( elem => ({ 
                    bank_name: elem.Name.replace(" " + elem.Loan_Type_2__c, ""), 
                    loan_type: elem.Loan_Type_2__c, 
                    outstanding_amount: elem.Estimated_Outstanding_Amount__c
                }))
                amalansignupApi.post('salesforce/lead-qualification/minimal-saving', { Loans: data })
                .then((resp) => {
                    minimalSaving.value = resp.data.data.minimal_saving
                    isLoading.value = false
                })
                .catch(() => {
                    isLoading.value = false
                    swalErrorReload.fire().then((result) => {
                        if (result.isConfirmed){
                            window.location.reload();
                        }
                    });
                })
            }else{
                isLoading.value = false
            }
        });

        function submit(){
            if(!monthlySaving.value){
                validated.value = false
            }else{
                validated.value = true
                isLoading.value = true
                store.dispatch('leadQualification/setMonthlySaving', { monthlySaving: monthlySaving.value })

                let loanData = dataOpportunities.value.map( elem => ({ 
                    bank_name: elem.Name.replace(" " + elem.Loan_Type_2__c, ""), 
                    loan_type: elem.Loan_Type_2__c, 
                    outstanding_amount: elem.Estimated_Outstanding_Amount__c
                }))

                const data = {
                    Loans: loanData,
                    saving_amount: monthlySaving.value
                }

                amalansignupApi.post('salesforce/lead-qualification/summary', data)
                .then((resp) => {
                    const respData = resp.data.data
                    summary.value.interest_amount_with_amalan = respData.interest_amount_with_amalan ?? null
                    summary.value.interest_amount_without_amalan = respData.interest_amount_without_amalan ?? null
                    summary.value.minimum_saving_with_amalan = respData.minimum_saving_with_amalan ?? null
                    summary.value.minimum_saving_without_amalan = respData.minimum_saving_without_amalan ?? null
                    summary.value.months_to_payoff_with_amalan = respData.months_to_payoff_with_amalan ?? null
                    summary.value.months_to_payoff_without_amalan = respData.months_to_payoff_without_amalan ?? null
                    summary.value.saving_amount = respData.saving_amount ?? null
                    summary.value.total_outstanding = respData.total_outstanding ?? null
                    summary.value.total_to_pay_with_amalan = respData.total_to_pay_with_amalan ?? null
                    summary.value.total_to_pay_without_amalan = respData.total_to_pay_without_amalan ?? null

                    isSummary.value = true
                    isLoading.value = false
                })
                .catch(() => {
                    isLoading.value = false
                    swalErrorReload.fire().then((result) => {
                        if (result.isConfirmed){
                            window.location.reload();
                        }
                    });
                })
            }
        }

        function next(){
            if(!monthlySaving.value){
                validated.value = false
                isSummary.value = false
            }else{
                store.dispatch('leadQualification/setMonthlySaving', { monthlySaving: monthlySaving.value })
                swalConfirm.fire({
                    html: '<p class="text-center text-sm mt-2">Anda yakin data Anda sudah benar?<br>Jika sudah, silahkan untuk dikonfirmasi agar<br>data Anda segera diproses</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Konfirmasi',
                    cancelButtonText: 'Batalkan',
                }).then((result) => {
                    if (result.isConfirmed){
                        isLoading.value = true
                        let newFinancialHardship = ""
                        for (let i in dataFormUpdateContact.value.financialHardship){
                            if(i == 0){
                                newFinancialHardship = dataFormUpdateContact.value.financialHardship[i];
                            }else{
                                newFinancialHardship = newFinancialHardship.concat(";", dataFormUpdateContact.value.financialHardship[i]);
                            }
                        }

                        if(newFinancialHardship.includes('optional')){
                            newFinancialHardship = newFinancialHardship.replace('optional', dataFormUpdateContact.value.optional)
                        }

                        // let newFinancialHardshipDetail = dataFormUpdateContact.value.financialHardshipDetail.replace("\n", " ")
                        let data = {
                            financialHardship : newFinancialHardship,
                            // financialHardshipDetail : newFinancialHardshipDetail,
                            monthlySaving: dataFormUpdateContact.value.monthlySaving
                        }

                        amalansignupApi.put('salesforce/lead-qualification/contacts', data)
                        .then(() => {
                            store.dispatch('contact/requestContact')
                            store.dispatch('leadQualification/prepopulateContactData').then(() => {
                                isLoading.value = false
                                router.push({ name: 'lead qualification - registrasi selesai' })
                            })
                        })
                        .catch(() => {
                            isLoading.value = false
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Mohon bantuan saya gagal terus dalam pengisian kemampuan menabung pada proses registrasi keringanan utang perbankan di web.amalan.com';
                                    return;
                                }
                            });
                        })
                    }
                });
            }
        }

        return {
            submit,
            dataFormUpdateContact,
            monthlySaving,
            minimalSaving,
            validated,
            next,
            isLoading,
            isSummary,
            summary,
            withSummaryProjection
        }
    }
})
</script>