<script setup>
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, ref, onMounted } from 'vue'
import { CheckIcon } from '@heroicons/vue/solid'
import useModalPopUp from '@/composables/modalPopUp'
import amalansignupApi from '@/axios/amalansignup'

const router = useRouter()
const route = useRoute()
const store = useStore()
const modalPopUp = useModalPopUp()

const paymentPageData = computed(() => store.getters['additionalSUF/getPaymentPageData'])
const touchedOpportunities = computed(() => store.getters['additionalSUF/getTouchedOpportunities'])
const lastAdditionalSufData = computed(() => store.getters['additionalSUF/getLastAdditionalSuf'])
const userPricingPackage = computed(() => store.getters['contact/getUserPricingPackage'])

const terms = ref(false)
const isLoading = ref(false)
const isPaymentFrame = ref(false)

const termsChecked = () => terms.value = !terms.value

const storePayment = () => {
    const message = paymentPageData.value.page_type === 'Add' ? 'Untuk melakukan perubahan ini Anda akan dikenakan biaya tambahan dan tanda tangan ulang Dokumen Perjanjian.' : 'Untuk melakukan perubahan ini Anda akan dikenakan biaya tambahan sesuai dengan biaya tambahan yang berlaku di amalan.'
    const btnConfirmText = paymentPageData.value.page_type === 'Add' ? 'Ya, Tambahkan' : 'Ya, Konfirmasi'
    modalPopUp.swalConfirmWithIcon.fire({
        html: `<p class="text-center text-xs mt-2">${message}<br><br>Apakah Anda yakin ingin mengubahnya?</p>`,
        showCancelButton: true,
        confirmButtonText: btnConfirmText,
        cancelButtonText: 'Batal',
    }).then((result) => {
        if(!result.isConfirmed) return
        if(paymentPageData.value.page_type === 'Add') return additionalSufAdd()
        return additionalSufUpdate()
    })
}

const additionalSufUpdate = () => {
    isLoading.value = true
    amalansignupApi.post('loan-management/additional-suf-update/store', { data: touchedOpportunities.value })
    .then((res) => {
        isLoading.value = false
        store.dispatch('additionalSUF/removeTouchedOpportunities')
        createPayment(res.data.data.id_additional_suf, res.data.data.id_pricing_package_payment, 'Update')
    })
    .catch(() => {
        isLoading.value = false
        modalPopUp.swalError.fire({
            html: '<p class="text-center text-xs mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        }).then((result) => {
            if(!result.isConfirmed) return
            return window.location = 'https://wa.me/6285888236207?text=' + 
            'Mohon bantuan saya gagal terus untuk meminta data transaksi additional suf update pada halaman loan management di web.amalan.com'
        })
    })
}

const additionalSufAdd = async () => {
    isLoading.value = true
    await store.dispatch('additionalSUF/storeNewOpportunities')
    store.dispatch('docCollection/requestOpportunities')
    amalansignupApi.post('loan-management/additional-suf-add/store')
    .then((res) => {
        isLoading.value = false
        createPayment(res.data.data.id_additional_suf, res.data.data.id_pricing_package_payment, 'Add')
    })
    .catch(() => {
        isLoading.value = false
        modalPopUp.swalError.fire({
            html: '<p class="text-center text-xs mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        }).then((result) => {
            if(!result.isConfirmed) return
            return window.location = 'https://wa.me/6285888236207?text=' + 
            'Mohon bantuan saya gagal terus untuk meminta data transaksi additional suf add pada halaman loan management di web.amalan.com'
        })
    })
}

const createPayment = (id_additional_suf, id_pricing_package_payment, payment_type) => {
    isLoading.value = true
    const endpoint = payment_type === 'Add' ? 'loan-management/additional-suf-add/payment' : 'loan-management/additional-suf-update/payment'
    amalansignupApi.post(endpoint, {id_additional_suf, id_pricing_package_payment})
    .then((res) => {
        store.dispatch('additionalSUF/setPaymentData', {
            pricing_package_name : userPricingPackage.value,
            payment_purpose : res.data.data.payment_purpose,
            payment_amount : res.data.data.price,
            discount : res.data.data.discount,
            payment_expiration_date : res.data.data.payment_expiration_date,
            payment_link : res.data.data.payment_link,
            total_outstanding : res.data.data.total_outstanding
        })
        isLoading.value = false
    })
    .catch(() => {
        isLoading.value = false
        modalPopUp.swalError.fire({
            html: '<p class="text-center text-xs mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        }).then((result) => {
            if(!result.isConfirmed) return
            return window.location = 'https://wa.me/6285888236207?text=' + 
            `Mohon bantuan saya gagal terus untuk meminta data pembayaran additional suf ${payment_type === 'Add' ? 'add' : 'update'} pada halaman loan management di web.amalan.com`
        })
    })
}

const showPaymentFrame = () => isPaymentFrame.value = true

const checkLastPayment = async () => {
    isPaymentFrame.value = false
    isLoading.value = true
    await store.dispatch('additionalSUF/requestLastAdditionalSuf')
    isLoading.value = false

    if(lastAdditionalSufData.value.status === 201) return createPayment(lastAdditionalSufData.value.data.id_additional_suf, lastAdditionalSufData.value.data.id_pricing_package_payment, lastAdditionalSufData.value.data.type)

    if(lastAdditionalSufData.value.status === 203) return router.push({ name: 'Loan Management - Additional Suf - Sign' })

    if(lastAdditionalSufData.value.status === 202) return store.dispatch('additionalSUF/setPaymentData', {
        page_type : lastAdditionalSufData.value.data.type,
        pricing_package_name : userPricingPackage.value,
        payment_purpose : lastAdditionalSufData.value.data.payment_purpose,
        payment_amount : lastAdditionalSufData.value.data.price,
        discount : lastAdditionalSufData.value.data.discount,
        payment_expiration_date : lastAdditionalSufData.value.data.payment_expiration_date,
        payment_link : lastAdditionalSufData.value.data.payment_link,
        total_outstanding : lastAdditionalSufData.value.data.total_outstanding
    })

    if(lastAdditionalSufData.value.status === 404) return router.push({ name: 'Loan Management - Loan List - Home' })

    if(!lastAdditionalSufData.value.data.status) return router.push({ name: 'Loan Management - Loan List - Home' })

    return router.push({
        name: 'Loan Management - Additional Suf - Finish Page',
        query: {
            update: lastAdditionalSufData.value.data.type === 'Add' ? false : true
        }
    })
}

const goToPrevPage = () => {
    if(paymentPageData.value.page_type === 'Add') return router.push({ name: 'Loan Management - Loan List - Add Loan' })
    return router.push({ name: 'Loan Management - Loan List - Home', query: { editmode: true } })
}

onMounted(() => {
    if(!paymentPageData.value.isTouched) checkLastPayment()
})
</script>

<template>
    <div class="min-h-screen bg-amalan-white">
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <div v-if="paymentPageData.payment_link" class="flex items-center">
                        <div class="w-5 h-8 bg-transparent"></div>
                    </div>
                    <button v-else @click="goToPrevPage" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full py-8 px-6 bg-amalan-white">
            <template v-if="!paymentPageData.payment_link">
                <p class="text-center text-amalan-black text-xs sm:text-sm">Berikut estimasi penambahan pembayaran perubahan Data Pinjaman Anda</p>
                <div class="w-full mt-8 rounded-lg border p-4 border-amalan-gray-4 bg-amalan-white">
                    <div class="w-full flex justify-between items-center pb-3.5 border-b border-amalan-blue-1">
                        <p class="text-amalan-blue-1 text-base font-bold">Estimasi Pembayaran</p>
                        <button @click="goToPrevPage" type="button" class="w-6 h-6 rounded-lg shadow-amalan-md border mr-1 flex justify-center items-center cursor-pointer bg-amalan-white border-amalan-blue-1 hover:bg-amalan-gray-5">
                            <img src="@/assets/img/pencil-primary-icon.svg" class="ml-px">
                        </button>
                    </div>
                    <div class="w-full mt-3.5 grid grid-cols-5 text-amalan-black text-xs gap-y-1.5">
                        <div class="col-span-2">Paket Layanan</div>
                        <div class="col-span-3">: {{ paymentPageData.pricing_package_name }}</div>
                        <div class="col-span-2">Pembayaran</div>
                        <div class="col-span-3">: {{ paymentPageData.payment_purpose }}</div>
                        <div class="col-span-2">Perubahan Pinjaman</div>
                        <div class="col-span-3">: {{ paymentPageData.total_outstanding ? $filters.format_number(paymentPageData.total_outstanding) : '-' }}</div>
                        <div class="col-span-2">Biaya</div>
                        <div class="col-span-3">: {{ paymentPageData.payment_amount ? $filters.format_number(paymentPageData.payment_amount) : '-' }}</div>
                        <div class="col-span-2">Potongan</div>
                        <div class="col-span-3">: {{ paymentPageData.discount ? $filters.format_number(paymentPageData.discount) : '-' }}</div>
                        <div class="col-span-5 my-2.5 border-t border-amalan-gray-3 border-dashed"></div>
                        <div class="col-span-2">Total Biaya</div>
                        <div class="col-span-3">: {{ paymentPageData.payment_amount ? $filters.format_number(paymentPageData.payment_amount - paymentPageData.discount) : '-' }}</div>
                    </div>
                </div>
                <div class="flex justify-between items-start mt-8 relative">
                    <label class="font-medium text-xs inline-flex" for="terms">
                        <label class="h-4 w-4 border-2 mr-2.5 p-0 cursor-pointer" :class="[ terms ? 'bg-amalan-yellow-400 border-amalan-yellow-400' : 'border-amalan-gray-4 bg-amalan-gray-4']" @click="termsChecked">
                            <CheckIcon class="w-4 h-4 -mt-0.5 -ml-0.5" :class="[ terms ? 'text-amalan-blue-1' : 'text-amalan-gray-4' ]" />
                        </label>
                    </label>
                    <p @click="termsChecked" class="text-xs cursor-pointer">Saya dengan sadar melakukan perubahan data pinjaman dan membayar tambahan biaya yang dikenakan</p>
                    <span v-if="!terms" class="text-amalan-red text-4xs absolute -bottom-4 left-0">*centang disini</span>
                </div>
                <button @click="storePayment" type="button" class="w-full mt-22 rounded-amalan-lg font-bold border text-center text-sm p-3" :class="[ terms ? 'bg-amalan-blue-1 hover:bg-amalan-blue-2 text-amalan-white cursor-pointer shadow-amalan-md' : 'cursor-not-allowed bg-amalan-gray-4 text-amalan-gray-2' ]" :disabled="!terms">Konfirmasi</button>
            </template>
            <template v-else>
                <div class="w-full rounded-lg border p-4 border-amalan-gray-4 bg-amalan-white">
                    <div class="w-full pb-3.5 border-b border-amalan-blue-1">
                        <p class="text-amalan-blue-1 text-base font-bold text-center">Ringkasan Pembayaran</p>
                    </div>
                    <div class="w-full bg-amalan-yellow-100 rounded-lg py-2 mt-3.5 text-amalan-black">
                        <p class="font-bold text-xs text-center">-</p>
                    </div>
                    <div class="w-full mt-3.5 grid grid-cols-5 text-amalan-black text-xs gap-y-1.5">
                        <div class="col-span-2">Paket Layanan</div>
                        <div class="col-span-3">: {{ paymentPageData.pricing_package_name }}</div>
                        <div class="col-span-2">Pembayaran</div>
                        <div class="col-span-3">: {{ paymentPageData.payment_purpose }}</div>
                        <div class="col-span-2">Perubahan Pinjaman</div>
                        <div class="col-span-3">: {{ paymentPageData.total_outstanding ? $filters.format_number(paymentPageData.total_outstanding) : '-' }}</div>
                        <div class="col-span-2">Biaya</div>
                        <div class="col-span-3">: {{ paymentPageData.payment_amount ? $filters.format_number(paymentPageData.payment_amount) : '-' }}</div>
                        <div class="col-span-2">Potongan</div>
                        <div class="col-span-3">: {{ paymentPageData.discount ? $filters.format_number(paymentPageData.discount) : '-' }}</div>
                        <div class="col-span-5 my-2.5 border-t border-amalan-blue-1"></div>
                        <div class="col-span-2">Total Biaya</div>
                        <div class="col-span-3">: {{ paymentPageData.payment_amount ? $filters.format_number(paymentPageData.payment_amount - paymentPageData.discount) : '-' }}</div>
                    </div>
                    <button @click="showPaymentFrame" type="button" class="w-full mt-8 rounded-amalan-lg font-bold border text-center text-sm p-3 bg-amalan-blue-1 hover:bg-amalan-blue-2 text-amalan-white cursor-pointer shadow-amalan-md">Pilih Metode Pembayaran</button>
                </div>
            </template>
            <div v-if="paymentPageData.payment_expiration_date" class="w-full bg-amalan-red-light rounded-lg py-1.5 mt-3.5 text-amalan-red">
                <p class="font-bold text-xs text-center">ESTIMASI BATAS PEMBAYARAN<br>{{ $filters.formatDateTimePaymentExpiration(paymentPageData.payment_expiration_date) }} WIB | <span class="text-amalan-black">({{ $filters.diffDateOnDays($filters.dateTimeNow(), paymentPageData.payment_expiration_date) }} HARI)</span></p>
            </div>
        </div>
        <div v-if="isPaymentFrame && paymentPageData.payment_link" class="fixed inset-0 z-50 bg-amalan-blue-7 min-h-screen w-screen flex items-center justify-center">
            <div class="max-w-sceen h-full overflow-visible">
                <iframe class="w-screen h-5/6 border-b-4 bg-amalan-white" :src="paymentPageData.payment_link" frameborder="0" allow="autoplay fullscreen picture-in-picture"></iframe>
                <div class="absolute bottom-0 pb-10 w-full flex justify-center bg-amalan-blue-7 px-4">
                    <button class="border text-amalan-white w-full bg-amalan-blue-1 border-amalan-blue-1 hover:bg-amalan-blue-2 hover:border-amalan-blue-2 rounded-amalan-lg py-3 text-sm font-bold shadow-amalan-md" @click="checkLastPayment">
                        Konfirmasi Pembayaran
                    </button>
                </div>
            </div>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>