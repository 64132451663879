
<script setup>
import { onMounted, ref, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import amalansignupApi from "@/axios/amalansignup"
import { Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import Multiselect from '@vueform/multiselect'
import CurrencyInput from "@/components/CurrencyInputV2.vue"
import { listLoanType, listLoanTypePKU, iconBankLender } from '@/constants/LoanConstant'
import { multiselectClass, dataLamaTunggakan } from '@/constants/Multiselect'
import useModalPopUp from '@/composables/modalPopUp'

const store = useStore()
const router = useRouter()
const route = useRoute()
const modalPopUp = useModalPopUp()

const FORM_STEP_LIST = { listOpportunity: 'list-opportunity', listLoanType: 'list-loan-type', createOpportunity: 'create-opportunity', editOpportunity: 'edit-opportunity' }

const dataOpportunities = computed(() => store.getters['loanConsolidation/getOpportunities'])
const totalHutang = computed(() => store.getters['loanConsolidation/getAmountLoan'])
const dataProductType = computed(() => store.getters['leadQualification/getProductType'])
const dataQualifiedLender = computed(() => store.getters['leadQualification/getQualifiedLender'])
const dataCCType = computed(() => store.getters['leadQualification/getCCType'])
const lastLoanConsolidation = computed(() => store.getters['loanConsolidation/getLastLoanConsolidation'])
const signUpStatus = computed(() => store.getters['contact/getSignUpStatus'])

const formStep = ref(FORM_STEP_LIST.listOpportunity)
const formUtang = reactive({
    bankName: null,
    loanType: null,
    outstandingAmount: null,
    overdue: null,
    ccType: null,
})
const formUpdateUtang = reactive({
    Id: null,
    outstandingAmount: null,
    overdue: null,
    ccType: null,
    bankName: null,
    loanType: null
})
const isLoading = ref(false)
const newLoanStructure = ref(false)
const withCC = ref(false)

const formHutangSchema = yup.object().shape({
    bankName: yup.string().nullable().required('Silahkan pilih nama bank/institusi'),
    outstandingAmount: yup.number('Masukan dalam bentuk angka').nullable().required('Total utang tidak boleh kosong'),
    overdue: yup.string().nullable().required('Silahkan pilih lama tunggakan'),
    ccType: yup.string().nullable(),
})

const formHutangUpdateSchema = yup.object().shape({
    outstandingAmount: yup.number('Masukan dalam bentuk angka').nullable().required('Jumlah pinjaman tidak boleh kosong'),
    overdue: yup.string().nullable().required('Silahkan pilih lama tunggakan'),
    ccType: yup.string().nullable(),
})

const getLoanTitle = (loanType) => {
    const loanTitle = listLoanType.filter(elem => elem.name == loanType)
    if (loanTitle[0]) return loanTitle[0].title
}

const getLoanIcon = (loanName, loanType) => {
    const lenderName = loanName.replace(" " + loanType, "").toLowerCase()
    const lenderData = iconBankLender.filter(elem => elem.name == lenderName)
    if (lenderData[0]) return lenderData[0].icon

    const loanTypeIcon = listLoanType.filter(elem => elem.name == loanType)
    if (loanTypeIcon[0]) return loanTypeIcon[0].icon
}

const getBankName = (loanName, loanType) => {
    return loanName.replace(" " + loanType, "")
}

const formHutangSubmit = (values) => {
    if(signUpStatus.value === 200) return blockClient()

    const data = {
        bankName : values.bankName,
        loanType : formUtang.loanType,
        outstandingAmount : values.outstandingAmount,
        overdue : values.overdue,
        ccType : withCC.value === true ? values.ccType : null,
    }

    isLoading.value = true
    amalansignupApi.post('salesforce/lead-qualification/opportunities', data)
    .then(() => {
        store.dispatch('loanConsolidation/requestOpportunities').then(() => {
            formUtang.bankName= null
            formUtang.loanType= null
            formUtang.outstandingAmount= null
            formUtang.overdue= null
            formUtang.ccType= null
            isLoading.value = false
            formStep.value = FORM_STEP_LIST.listOpportunity
            store.dispatch('leadQualification/resetQualifiedLender')
        })
    })
    .catch(() => {
        isLoading.value = false
        modalPopUp.swalError.fire({
            html: '<p class="text-center text-xs mt-2 text-amalan-black">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        }).then((result) => {
            if (!result.isConfirmed) return 
            return window.location = 'https://wa.me/6285888236207?text=Mohon bantuan saya gagal terus dalam pengisian data pinjaman pada proses Registrasi Kredit Bijak di web.amalan.com'
        })
    })
}

const formUbahHutangSubmit = (values) => {
    if(signUpStatus.value === 200) return blockClient('edit')

    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs mt-2 text-amalan-black">Data pinjaman ini tidak dapat dipulihkan kembali.<br><br>Apakah Anda yakin ingin mengubahnya?</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Ubah',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if(!result.isConfirmed) return

        const data = {
            outstandingAmount : values.outstandingAmount,
            overdue : values.overdue,
            ccType : withCC.value === true ? values.ccType : null,
        }

        isLoading.value = true
        amalansignupApi.put('salesforce/lead-qualification/opportunities/' + formUpdateUtang.Id, data)
        .then(() => {
            store.dispatch('loanConsolidation/requestOpportunities').then(() => {
                formUpdateUtang.Id= null
                formUpdateUtang.outstandingAmount= null
                formUpdateUtang.overdue= null
                formUpdateUtang.ccType= null
                formUpdateUtang.bankName= null
                formUpdateUtang.loanType= null
                isLoading.value = false
                formStep.value = FORM_STEP_LIST.listOpportunity
                store.dispatch('leadQualification/resetQualifiedLender')
            })
        })
        .catch(() => {
            isLoading.value = false
            modalPopUp.swalError.fire({
                html: '<p class="text-center text-xs mt-2 text-amalan-black">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                showCancelButton: true,
                confirmButtonText: 'Bantuan',
                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
            }).then((result) => {
                if (!result.isConfirmed) return 
                return window.location = 'https://wa.me/6285888236207?text=Mohon bantuan saya gagal terus dalam pengisian data pinjaman pada proses Registrasi Kredit Bijak di web.amalan.com'
            })
        })
    })
}

const editOpportunity = (data) => {
    if(signUpStatus.value === 200) return blockClient('edit')

    formUpdateUtang.overdue = data.Month_Overdue_input_by_Lead__c ?? '0'
    formUpdateUtang.Id = data.Id ?? null
    formUpdateUtang.outstandingAmount = data.Estimated_Outstanding_Amount__c ?? 0
    formUpdateUtang.ccType = data.Credit_Card_Type__c ?? null
    formUpdateUtang.bankName = data.Name ? getBankName(data.Name, data.Loan_Type_2__c) : null
    formUpdateUtang.loanType = data.Loan_Type_2__c ? getLoanTitle(data.Loan_Type_2__c) : null
    withCC.value = data.Loan_Type_2__c === 'Credit Card' ? true : false
    newLoanStructure.value = data.new_loan_data_structure__c
    formStep.value = FORM_STEP_LIST.editOpportunity
}

const deleteOpportunity = (Id) => {
    if(signUpStatus.value === 200) return blockClient('edit')

    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs mt-2 text-amalan-black">Data pinjaman ini tidak dapat dipulihkan kembali.<br><br>Apakah Anda yakin ingin menghapusnya?</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if (result.isConfirmed){
            isLoading.value = true
            amalansignupApi.get('salesforce/lead-qualification/opportunities/' + Id + '/delete')
            .then(() => store.dispatch('loanConsolidation/requestOpportunities').then(() => isLoading.value = false))
            .catch(() => {
                isLoading.value = false
                modalPopUp.swalError.fire({
                    html: '<p class="text-center text-xs mt-2 text-amalan-black">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                }).then((result) => {
                    if (!result.isConfirmed) return
                    return window.location = 'https://wa.me/6285888236207?text=Mohon bantuan saya gagal terus dalam pengisian data pinjaman pada proses Registrasi Kredit Bijak di web.amalan.com'
                })
            })
        }
    })
}

const backNavigation = () => {
    if(formStep.value === FORM_STEP_LIST.listOpportunity) return router.push({ name: 'Loan Consolidation - Registrasi - Qualification' })
    if(formStep.value === FORM_STEP_LIST.createOpportunity) return formStep.value = FORM_STEP_LIST.listLoanType
    if(formStep.value === FORM_STEP_LIST.listLoanType || formStep.value === FORM_STEP_LIST.editOpportunity) return formStep.value = FORM_STEP_LIST.listOpportunity
}

const nextPage = async () => {
    isLoading.value = true
    await store.dispatch('loanConsolidation/requestLastLoanConsolidation')
    isLoading.value = false
    return router.push({name:'Loan Consolidation - Registrasi - Extra Questions'})
}

const selectedLoanType = (loanType) => {
    store.dispatch('leadQualification/resetQualifiedLender')
    const allLoanType = dataProductType.value
    const productType = allLoanType.find(elem => elem.Name === loanType) ?? null

    if(productType){
        store.dispatch('leadQualification/requestQualifiedLender', productType.Id)
        formUtang.loanType = loanType
        formUtang.bankName = null
        formUtang.outstandingAmount = null
        formUtang.overdue = null
        formUtang.ccType = null
        withCC.value = loanType === 'Credit Card' ? true : false
        return formStep.value = FORM_STEP_LIST.createOpportunity
    }
    
    modalPopUp.swalErrorReload.fire().then(() => window.location.reload())
}

const goToLoanTypeList = () => {
    if(signUpStatus.value === 200) return blockClient()

    formStep.value = FORM_STEP_LIST.listLoanType
    window.scrollTo(0, 0)
}

const blockClient = (type = 'add') => {
    const htmlText = type === 'add' ? 'Mohon maaf, karena Anda adalah klien amalan, Anda tidak bisa menambahkan data pinjaman baru saat ini.<br><br>Silahkan lanjutkan proses Registrasi Kredit Bijak Karyawan ini terlebih dahulu.' : 'Mohon maaf, karena Anda adalah klien amalan, Anda tidak bisa melakukan perubahan data pinjaman saat ini.<br><br>Silahkan lanjutkan proses Registrasi Kredit Bijak Karyawan ini terlebih dahulu.'

    modalPopUp.swalConfirmBtnFullWithIcon.fire({
        html: `<p class="text-center text-xs mt-2 text-amalan-black">${htmlText}</p>`,
        showCancelButton: false,
        confirmButtonText: 'Kembali'
    })
}

onMounted(() => {
    if(lastLoanConsolidation.value.status === 404) return router.push({ name: 'Loan Consolidation - Registrasi - Informasi Umum'})
    if(lastLoanConsolidation.value.status === 200) return router.push({ name: 'Loan Consolidation - Registrasi - Finish Page'})
    if(lastLoanConsolidation.value.status === 201 || lastLoanConsolidation.value.status === 202) return
    return router.push({ name: 'Home'})
})
</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="backNavigation" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold text-amalan-black text-center">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full min-h-screen bg-amalan-white px-6 pb-24">
            <div v-if="formStep === FORM_STEP_LIST.listOpportunity" class="w-full py-8 flex justify-between items-center">
                <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                    <span class="text-sm text-amalan-blue-1 font-bold">1</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3 mx-1"></div>
                <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400">
                    <span class="text-sm font-bold text-amalan-blue-1">2</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3 mx-1"></div>
                <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400">
                    <span class="text-sm font-bold text-amalan-blue-1">3</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3 mx-1"></div>
                <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400">
                    <span class="text-sm font-bold text-amalan-blue-1">4</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-grow mx-1"></div>
                <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                    <span class="text-sm text-amalan-blue-1 font-bold">5</span>
                </div>
                <p class="flex-none text-amalan-blue-1 text-sm font-bold ml-1">Daftar Pinjaman</p>
            </div>
            <template v-if="formStep === FORM_STEP_LIST.createOpportunity">
                <div class="w-full mt-8">
                    <Form
                    @submit="formHutangSubmit"
                    :validation-schema="formHutangSchema"
                    v-slot="{ errors, meta }"
                    >
                        <div class="mb-3.5">
                            <label class="text-amalan-blue-1 font-bold text-sm">Jenis Pinjaman</label>
                            <div class="w-full pl-4 pr-3 py-2.5 mt-1 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed flex justify-between items-center">
                                <p class="text-amalan-gray-2 text-xs">{{ formUtang.loanType ? getLoanTitle(formUtang.loanType) : '-' }}</p>
                                <span class="bg-multiselect-caret-disabled bg-center bg-no-repeat w-4 h-4"></span>
                            </div>
                        </div>
                        <div class="mb-3.5">
                            <label class="text-amalan-blue-1 font-bold text-sm">Institusi Pinjaman</label>
                            <div class="flex items-center relative w-full mt-1">
                                <div class="w-full border rounded-lg py-1" :class="[ errors.bankName ? 'border-amalan-red' : 'border-amalan-gray-4']">
                                    <Multiselect
                                        ref="multiselect"
                                        v-model="formUtang.bankName"
                                        :noResultsText="'Data tidak ditemukan.'"
                                        :noOptionsText="'Sedang memuat data...'"
                                        :searchable="true"
                                        :options="dataQualifiedLender"
                                        :classes="multiselectClass"
                                    >
                                        <template v-slot:option="{ option }">
                                            <img class="h-10 mr-2" :src="option.icon"> {{ option.label }}
                                        </template>
                                    </Multiselect>
                                </div>
                                <Field name="bankName" type="text" class="hidden" :validateOnMount="true" v-model="formUtang.bankName" />
                            </div>
                            <div v-if="errors.bankName" class="mt-0.5 text-amalan-red text-2xs">{{errors.bankName}}</div>
                        </div>
                        <template v-if="withCC">
                            <div class="mb-3.5">
                                <label class="text-amalan-blue-1 font-bold text-sm">Jenis Kartu</label>
                                <div class="flex items-center relative w-full mt-1">
                                    <div class="w-full border rounded-lg py-1" :class="[ withCC && !formUtang.ccType ? 'border-amalan-red' : 'border-amalan-gray-4']">
                                        <Multiselect
                                        ref="multiselect"
                                        v-model="formUtang.ccType"
                                        :noResultsText="'Data tidak ditemukan.'"
                                        :noOptionsText="'Sedang memuat data...'"
                                        :searchable="true"
                                        :options="dataCCType"
                                        :classes="multiselectClass"
                                        />
                                    </div>
                                    <Field name="ccType" type="text" class="hidden" :validateOnMount="true" v-model="formUtang.ccType" />
                                </div>
                                <div v-if="withCC && !formUtang.ccType" class="mt-0.5 text-amalan-red text-2xs">Silahkan pilih jenis kartu</div>
                            </div>
                        </template>
                        <div class="mb-3.5">
                            <label class="text-amalan-blue-1 font-bold text-sm">Total Utang</label>
                            <div class="flex flex-col relative w-full mt-1">
                                <CurrencyInput 
                                v-model="formUtang.outstandingAmount" 
                                :propsClass="errors.outstandingAmount ? 'border-amalan-red rounded-lg' : 'border-amalan-gray-4 rounded-lg'"
                                :options="{
                                    currency: 'IDR',
                                    currencyDisplay: 'narrowSymbol',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    hideNegligibleDecimalDigitsOnFocus: false,
                                    autoDecimalDigits: false,
                                    autoSign: false,
                                    useGrouping: true,
                                    accountingSign: true
                                }" />
                                <Field name="outstandingAmount" type="text" :validateOnMount="true" :validateOnInput="true" class="hidden" v-model="formUtang.outstandingAmount" />
                                <div v-if="errors.outstandingAmount" class="mt-0.5 text-amalan-red text-2xs">{{errors.outstandingAmount}}</div>
                            </div>
                        </div>
                        <div class="mb-3.5">
                            <label class="text-amalan-blue-1 font-bold text-sm">Lama Tunggakan</label>
                            <div class="flex items-center relative w-full mt-1">
                                <div class="w-full border rounded-lg py-1" :class="[ errors.overdue ? 'border-amalan-red' : 'border-amalan-gray-4']">
                                    <Multiselect
                                    ref="multiselect"
                                    v-model="formUtang.overdue"
                                    :noResultsText="'Data tidak ditemukan.'"
                                    :noOptionsText="'Sedang memuat data...'"
                                    :searchable="true"
                                    :options="dataLamaTunggakan"
                                    :classes="multiselectClass"
                                    />
                                </div>
                                <Field name="overdue" type="text" class="hidden" :validateOnMount="true" v-model="formUtang.overdue" />
                            </div>
                            <div v-if="errors.overdue" class="mt-0.5 text-amalan-red text-2xs">{{errors.overdue}}</div>
                        </div>
                        <button type="submit" class="w-full mt-11 rounded-amalan-lg font-bold text-center text-sm p-3" :class="[ withCC ? meta.valid && formUtang.ccType ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' : meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="withCC ? meta.valid && formUtang.ccType ? false : true : meta.valid ? false : true">Tambahkan</button>
                    </Form>
                </div>
            </template>
            <template v-if="formStep === FORM_STEP_LIST.editOpportunity">
                <div class="w-full mt-8">
                    <Form
                    @submit="formUbahHutangSubmit"
                    :validation-schema="formHutangUpdateSchema"
                    v-slot="{ errors, meta }"
                    >
                        <div class="mb-3.5">
                            <label class="text-amalan-blue-1 font-bold text-sm">Institusi Pinjaman</label>
                            <div class="w-full pl-4 pr-3 py-2.5 mt-1 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed flex justify-between items-center">
                                <p class="text-amalan-gray-2 text-xs">{{ formUpdateUtang.bankName ?? '-' }}</p>
                                <span class="bg-multiselect-caret-disabled bg-center bg-no-repeat w-4 h-4"></span>
                            </div>
                        </div>
                        <div class="mb-3.5">
                            <label class="text-amalan-blue-1 font-bold text-sm">Jenis Pinjaman</label>
                            <div class="w-full pl-4 pr-3 py-2.5 mt-1 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed flex justify-between items-center">
                                <p class="text-amalan-gray-2 text-xs">{{ formUpdateUtang.loanType ?? '-' }}</p>
                                <span class="bg-multiselect-caret-disabled bg-center bg-no-repeat w-4 h-4"></span>
                            </div>
                        </div>
                        <template v-if="withCC">
                            <div class="mb-3.5">
                                <label class="text-amalan-blue-1 font-bold text-sm">Jenis Kartu</label>
                                <div class="flex items-center relative w-full mt-1">
                                    <div class="w-full border rounded-lg py-1" :class="[ withCC && !formUpdateUtang.ccType ? 'border-amalan-red' : 'border-amalan-gray-4']">
                                        <Multiselect
                                        ref="multiselect"
                                        v-model="formUpdateUtang.ccType"
                                        :noResultsText="'Data tidak ditemukan.'"
                                        :noOptionsText="'Sedang memuat data...'"
                                        :searchable="true"
                                        :options="dataCCType"
                                        :classes="multiselectClass"
                                        />
                                    </div>
                                    <Field name="ccType" type="text" class="hidden" :validateOnMount="true" v-model="formUpdateUtang.ccType" />
                                </div>
                                <div v-if="withCC && !formUpdateUtang.ccType" class="mt-0.5 text-amalan-red text-2xs">Silahkan pilih jenis kartu</div>
                            </div>
                        </template>
                        <div class="mb-3.5">
                            <label class="text-amalan-blue-1 font-bold text-sm">Total Utang</label>
                            <div class="flex flex-col relative w-full mt-1">
                                <CurrencyInput 
                                v-model="formUpdateUtang.outstandingAmount" 
                                :propsClass="errors.outstandingAmount ? 'border-amalan-red rounded-lg' : 'border-amalan-gray-4 rounded-lg'"
                                :options="{
                                    currency: 'IDR',
                                    currencyDisplay: 'narrowSymbol',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    hideNegligibleDecimalDigitsOnFocus: false,
                                    autoDecimalDigits: false,
                                    autoSign: false,
                                    useGrouping: true,
                                    accountingSign: true
                                }" />
                                <Field name="outstandingAmount" type="text" :validateOnMount="true" :validateOnInput="true" class="hidden" v-model="formUpdateUtang.outstandingAmount" />
                                <div v-if="errors.outstandingAmount" class="mt-0.5 text-amalan-red text-2xs">{{errors.outstandingAmount}}</div>
                            </div>
                        </div>
                        <div class="mb-3.5">
                            <label class="text-amalan-blue-1 font-bold text-sm">Lama Tunggakan</label>
                            <div class="flex items-center relative w-full mt-1">
                                <div class="w-full border rounded-lg py-1" :class="[ errors.overdue ? 'border-amalan-red' : 'border-amalan-gray-4']">
                                    <Multiselect
                                    ref="multiselect"
                                    v-model="formUpdateUtang.overdue"
                                    :noResultsText="'Data tidak ditemukan.'"
                                    :noOptionsText="'Sedang memuat data...'"
                                    :searchable="true"
                                    :options="dataLamaTunggakan"
                                    :classes="multiselectClass"
                                    />
                                </div>
                                <Field name="overdue" type="text" class="hidden" :validateOnMount="true" v-model="formUpdateUtang.overdue" />
                            </div>
                            <div v-if="errors.overdue" class="mt-0.5 text-amalan-red text-2xs">{{errors.overdue}}</div>
                        </div>
                        <button type="submit" class="w-full mt-11 rounded-amalan-lg font-bold text-center text-sm p-3" :class="[ withCC ? meta.valid && formUpdateUtang.ccType ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' : meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="withCC ? meta.valid && formUpdateUtang.ccType ? false : true : meta.valid ? false : true">Simpan</button>
                    </Form>
                </div>
            </template>
            <template v-if="formStep === FORM_STEP_LIST.listOpportunity">
                <p class="text-center text-xs text-amalan-black">Tambahkan seluruh data pinjaman yang Anda miliki</p>
                <div class="grid grid-cols-1 gap-y-3.5 mt-8">
                    <template v-if="dataOpportunities && dataOpportunities.length > 0">
                        <template v-for="(dataOpp, index) in dataOpportunities" :key="index">
                            <div class="w-full rounded-lg border border-amalan-gray-4 bg-amalan-white p-4 flex justify-between items-center">
                                <div class="flex-none w-14 h-14 rounded-lg flex justify-center items-center">
                                    <img :src="require('@/assets/img/bank-lender-icon/' + getLoanIcon(dataOpp.Name, dataOpp.Loan_Type_2__c))" class="w-14">
                                </div>
                                <div class="flex-grow px-2.5 text-amalan-black">
                                    <p class="text-sm text-left font-bold">{{ dataOpp.Name ? getBankName(dataOpp.Name, dataOpp.Loan_Type_2__c) : '-' }}</p>
                                    <p class="text-xs mt-0.5">{{ dataOpp.Loan_Type_2__c ? getLoanTitle(dataOpp.Loan_Type_2__c) : '-' }}</p>
                                    <p class="text-xs mt-1">{{ dataOpp.Estimated_Outstanding_Amount__c ? $filters.format_number(dataOpp.Estimated_Outstanding_Amount__c) : '-' }}</p>
                                </div>
                                <div class="flex-none w-6 h-14 flex flex-col justify-between items-center">
                                    <div @click="editOpportunity(dataOpp)" class="w-6 h-6 rounded-lg shadow-amalan-md border flex justify-center items-center cursor-pointer bg-amalan-white border-amalan-blue-1 hover:bg-amalan-gray-5">
                                        <img src="@/assets/img/pencil-primary-icon.svg" class="ml-px">
                                    </div>
                                    <div @click="deleteOpportunity(dataOpp.Id)" class="w-6 h-6 rounded-lg shadow-amalan-md border flex justify-center items-center cursor-pointer bg-amalan-white border-amalan-blue-1 hover:bg-amalan-gray-5">
                                        <img src="@/assets/img/signup/delete-icon.svg" class="w-3.5">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="w-full px-1 py-12 text-amalan-black">
                            <p class="text-xs text-center">Belum ada daftar pinjaman yang ditemukan</p>
                        </div>
                    </template>
                </div>
                <div class="w-full mt-3.5 py-3 border-t border-b border-amalan-gray-4 flex justify-between items-center">
                    <span class="font-bold text-sm text-amalan-blue-1">Total Utang</span>
                    <span class="text-sm text-amalan-blue-1">{{ totalHutang ? $filters.format_number(totalHutang) : '-' }}</span>
                </div>
                <div class="mt-16">
                    <button @click="goToLoanTypeList" type="button" class="w-full rounded-amalan-lg font-bold text-center text-sm p-3 cursor-pointer shadow-amalan-md" :class="[ dataOpportunities && dataOpportunities.length > 0 ? 'border text-amalan-blue-1 bg-amalan-white border-amalan-blue-1 hover:bg-amalan-gray-5' : 'text-amalan-white bg-amalan-blue-1 hover:bg-amalan-blue-2']">+ Tambahkan Data Pinjaman</button>
                    <button v-if="dataOpportunities && dataOpportunities.length > 0" @click="nextPage" type="button" class="w-full mt-3.5 rounded-amalan-lg font-bold text-center text-sm p-3 text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md">Selanjutnya</button>
                </div>
            </template>
            <template v-if="formStep === FORM_STEP_LIST.listLoanType">
                <div class="mt-8 w-full">
                    <p class="text-center text-xs">Pilih jenis pinjaman yang ingin Anda daftarkan dan<br>dapatkan solusi sesuai dengan jenis pinjaman Anda!</p>
                    <div class="mt-8">
                        <template v-if="dataProductType.length > 0">
                            <div v-for="(loanType, index) in listLoanTypePKU" :key="index" class="w-full rounded-lg p-4 mb-3.5 shadow-amalan-sm bg-amalan-white cursor-pointer hover:bg-amalan-gray-5" @click="selectedLoanType(loanType.name)">
                                <div class="flex justify-between items-center">
                                    <div class="w-14 h-14 flex-none rounded-full flex justify-center items-center border-2 border-amalan-yellow-400 bg-amalan-white">
                                        <img :src="require('@/assets/img/signup/icon-jenis-pinjaman/' + loanType.icon)">
                                    </div>
                                    <div class="flex-grow px-2.5">
                                        <p class="text-sm font-bold text-amalan-blue-1">{{ loanType.title }}</p>
                                        <p class="text-xs mt-1">{{ loanType.desc }}</p>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <loading-data-without-margin class="mt-20" />
                        </template>
                    </div>
                </div>
            </template>
            <loading-overlay v-if="isLoading || !dataOpportunities" />
        </div>
    </div>
</template>