
<script setup>
import { onMounted, ref, computed, reactive, inject, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import amalansignupApi from "@/axios/amalansignup"
import { Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import Multiselect from '@vueform/multiselect'
import CurrencyInput from "@/components/CurrencyInputV2.vue"
import { listLoanType, listLoanTypePKU, iconBankLender } from '@/constants/LoanConstant'
import { multiselectClass, dataLamaTunggakan } from '@/constants/Multiselect'
import useModalPopUp from '@/composables/modalPopUp'

const store = useStore()
const router = useRouter()
const route = useRoute()
const modalPopUp = useModalPopUp()
const filters = inject('Filters')

const FORM_STEP_LIST = { listOpportunity: 'list-opportunity', listLoanType: 'list-loan-type', createOpportunity: 'create-opportunity', editOpportunity: 'edit-opportunity' }

const dataOpportunities = computed(() => store.getters['docCollection/getOpportunities'])
const totalHutang = computed(() => store.getters['docCollection/getTotalAmountLoan'])
const dataProductType = computed(() => store.getters['leadQualification/getProductType'])
const dataQualifiedLender = computed(() => store.getters['leadQualification/getQualifiedLender'])
const dataCCType = computed(() => store.getters['leadQualification/getCCType'])
const dataContact = computed(() => store.getters['contact/getContact'])
const dataOpportunitiesNew = computed(() => store.getters['additionalSUF/getNewOpportunities'])
const userPricingPackage = computed(() => store.getters['contact/getUserPricingPackage'])

const formStep = ref(FORM_STEP_LIST.listOpportunity)
const formUtang = reactive({
    bankName: null,
    loanType: null,
    outstandingAmount: null,
    overdue: null,
    ccType: null,
    lastPaymentDate: null,
    accountNumber: null
})
const isLoading = ref(false)
const withCC = ref(false)
const userWithPricingPackage = ref(false)
const editIndex = ref(-1)
const totalHutangNewOpportunities = ref(0)

watch(dataOpportunitiesNew, () => {
    let outstandingAmountTotal = 0
    dataOpportunitiesNew.value.forEach((value) => outstandingAmountTotal += value.outstandingAmount)
    totalHutangNewOpportunities.value = outstandingAmountTotal
}, { deep: true })

const formHutangSchema = yup.object().shape({
    bankName: yup.string().nullable().required('Silahkan pilih nama bank/institusi'),
    outstandingAmount: yup.number('Masukan dalam bentuk angka').nullable().required('Total utang tidak boleh kosong'),
    overdue: yup.string().nullable().required('Silahkan pilih lama tunggakan'),
    ccType: yup.string().nullable(),
    accountNumber: yup.string().nullable().max(25, 'Nomor pinjaman tidak boleh melebihi 25 karakter').transform((o, c) => o === "" ? null : c).matches(/^[0-9x]+$/, "Masukan dalam bentuk angka").nullable(),
    lastPaymentDate: yup.date().nullable().required('Silahkan pilih tanggal').max(filters.dateNow(),"Tanggal tidak boleh lebih dari hari ini"),
})

const formHutangSubmit = () => {
    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs mt-2">Anda akan menambahkan data pinjaman baru.<br><br>Apakah Anda yakin ingin menambahkan?</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Tambahkan',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if(!result.isConfirmed) return
        store.dispatch('additionalSUF/setNewOpportunity', {
            index: editIndex.value >= 0 ? editIndex.value : null,
            data: {...formUtang}
        })
        resetFormUtangData()
        return formStep.value = FORM_STEP_LIST.listOpportunity
    })
}

const editOpportunity = (index, data) => {
    editIndex.value = index
    formUtang.loanType = data.loanType
    formUtang.bankName = data.bankName
    formUtang.outstandingAmount = data.outstandingAmount
    formUtang.overdue = data.overdue
    formUtang.ccType = data.ccType
    formUtang.lastPaymentDate = data.lastPaymentDate
    formUtang.accountNumber = data.accountNumber
    return formStep.value = FORM_STEP_LIST.editOpportunity
}

const deleteOpportunity = (index) => store.dispatch('additionalSUF/removeNewOpportunityByIndex', index)

const getLoanTitle = (loanType) => {
    const loanTitle = listLoanType.filter(elem => elem.name == loanType)
    if (loanTitle[0]) return loanTitle[0].title
}

const getLoanIcon = (loanName, loanType) => {
    const lenderName = loanName.replace(" " + loanType, "").toLowerCase()
    const lenderData = iconBankLender.filter(elem => elem.name == lenderName)
    if (lenderData[0]) return lenderData[0].icon

    const loanTypeIcon = listLoanType.filter(elem => elem.name == loanType)
    if (loanTypeIcon[0]) return loanTypeIcon[0].icon
}

const getBankName = (loanName, loanType) => loanName.replace(" " + loanType, "")

const backNavigation = () => {
    if(formStep.value === FORM_STEP_LIST.createOpportunity) return formStep.value = FORM_STEP_LIST.listLoanType
    if(formStep.value === FORM_STEP_LIST.listLoanType || formStep.value === FORM_STEP_LIST.editOpportunity) return formStep.value = FORM_STEP_LIST.listOpportunity
    if(dataOpportunitiesNew.value.length === 0) return router.push({ name: 'Loan Management - Loan List - Home' })

    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs mt-2">Penambahan Data Utang Anda belum disimpan.<br><br>Anda yakin ingin keluar dari halaman ini?</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Konfirmasi',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if(!result.isConfirmed) return
        store.dispatch('additionalSUF/removeNewOpportunities')
        return router.push({ name: 'Loan Management - Loan List - Home' })
    })
}

const nextPage = () => {
    const message = userWithPricingPackage.value ? 'Untuk melakukan perubahan ini Anda akan<br>dikenakan biaya tambahan dan tanda tangan<br>ulang Dokumen Perjanjian.' : 'Untuk melakukan perubahan ini Anda akan<br>tanda tangan ulang Dokumen Perjanjian.'
    modalPopUp.swalConfirmWithIcon.fire({
        html: `<p class="text-center text-xs mt-2">${message}<br><br>Apakah Anda yakin ingin menambahkannya?</p>`,
        showCancelButton: true,
        confirmButtonText: 'Ya, Tambahkan',
        cancelButtonText: 'Batal',
    }).then(async (result) => {
        if(!result.isConfirmed) return
        if(userWithPricingPackage.value) return goToPreviewPaymentPage()

        isLoading.value = true
        await store.dispatch('additionalSUF/storeNewOpportunities')
        await store.dispatch('docCollection/requestOpportunities')
        isLoading.value = false
        return router.push({ name: 'Loan Management - Loan List - Home' })
    })
}

const selectedLoanType = (loanType) => {
    store.dispatch('leadQualification/resetQualifiedLender')
    const allLoanType = dataProductType.value
    const productType = allLoanType.find(elem => elem.Name === loanType) ?? null

    if(productType){
        store.dispatch('leadQualification/requestQualifiedLender', productType.Id)
        resetFormUtangData()
        formUtang.loanType = loanType
        withCC.value = loanType === 'Credit Card' ? true : false
        return formStep.value = FORM_STEP_LIST.createOpportunity
    }

    modalPopUp.swalErrorReload.fire()
    .then((res) => {
        if(!res.isConfirmed) return
        return window.location.reload()
    })
}

const resetFormUtangData = () => {
    editIndex.value = -1
    formUtang.loanType = null
    formUtang.bankName = null
    formUtang.outstandingAmount = null
    formUtang.overdue = null
    formUtang.ccType = null
    formUtang.lastPaymentDate = null
    formUtang.accountNumber = null
}

const goToPreviewPaymentPage = () => {
    isLoading.value = true
    amalansignupApi.post('loan-management/additional-suf-add', { data: dataOpportunitiesNew.value })
    .then(async (res) => {
        if(res.data.data.price){
            store.dispatch('additionalSUF/setPaymentData', {
                page_type : 'Add',
                pricing_package_name : userPricingPackage.value,
                payment_purpose : res.data.data.payment_purpose,
                payment_amount : res.data.data.price,
                discount : res.data.data.discount,
                payment_expiration_date : res.data.data.payment_expiration_date,
                payment_link : null,
                total_outstanding : res.data.data.total_outstanding
            })
            isLoading.value = false
            return router.push({ name: 'Loan Management - Additional Suf - Payment Page' })
        }

        await store.dispatch('additionalSUF/storeNewOpportunities')
        await store.dispatch('docCollection/requestOpportunities')
        isLoading.value = false
        return router.push({ name: 'Loan Management - Loan List - Home' })
    })
    .catch(() => {
        isLoading.value = false
        modalPopUp.swalError.fire({
            html: '<p class="text-center text-xs mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        }).then((result) => {
            if(!result.isConfirmed) return
            return window.location = 'https://wa.me/6285888236207?text=' + 
            'Mohon bantuan saya gagal terus untuk mengirim data pinjaman ke server pada halaman loan management di web.amalan.com'
        })
    })
}

const goToLoanTypeList = () => {
    formStep.value = FORM_STEP_LIST.listLoanType
    window.scrollTo(0, 0)
}

onMounted(() => {
    userWithPricingPackage.value = dataContact.value.Pricing_Package__c && dataContact.value.Pricing_Package__c.toUpperCase() !== 'PAY AS YOU GO' ? true : false
    store.dispatch('additionalSUF/removeTouchedOpportunities')
    store.dispatch('pendingUserActivity/checkPendingAdditionalSuf')
})
</script>

<template>
    <div class="min-h-screen bg-amalan-white">
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="backNavigation" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold text-amalan-black text-center">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div v-if="dataOpportunities && dataOpportunities.length > 0" class="w-full px-6 pt-8 pb-40 bg-amalan-white">
            <template v-if="formStep === FORM_STEP_LIST.createOpportunity || formStep === FORM_STEP_LIST.editOpportunity">
                <div class="w-full">
                    <Form
                    @submit="formHutangSubmit"
                    :validation-schema="formHutangSchema"
                    v-slot="{ errors, meta }"
                    >
                        <div class="mb-3.5">
                            <label class="text-amalan-blue-1 font-bold text-sm">Nama Bank/Institusi</label>
                            <div class="flex items-center relative w-full mt-1">
                                <div class="w-full border rounded-lg py-1" :class="[ errors.bankName ? 'border-amalan-red' : 'border-amalan-gray-4']">
                                    <Multiselect
                                        ref="multiselect"
                                        v-model="formUtang.bankName"
                                        :noResultsText="'Data tidak ditemukan.'"
                                        :noOptionsText="'Sedang memuat data...'"
                                        :searchable="true"
                                        :options="dataQualifiedLender"
                                        :classes="multiselectClass"
                                    >
                                        <template v-slot:option="{ option }">
                                            <img class="h-10 mr-2" :src="option.icon"> {{ option.label }}
                                        </template>
                                    </Multiselect>
                                </div>
                                <Field name="bankName" type="text" class="hidden" :validateOnMount="true" v-model="formUtang.bankName" />
                            </div>
                            <div v-if="errors.bankName" class="mt-0.5 text-amalan-red text-2xs">{{errors.bankName}}</div>
                        </div>
                        <template v-if="withCC">
                            <div class="mb-3.5">
                                <label class="text-amalan-blue-1 font-bold text-sm">Jenis Kartu</label>
                                <div class="flex items-center relative w-full mt-1">
                                    <div class="w-full border rounded-lg py-1" :class="[ withCC && !formUtang.ccType ? 'border-amalan-red' : 'border-amalan-gray-4']">
                                        <Multiselect
                                        ref="multiselect"
                                        v-model="formUtang.ccType"
                                        :noResultsText="'Data tidak ditemukan.'"
                                        :noOptionsText="'Sedang memuat data...'"
                                        :searchable="true"
                                        :options="dataCCType"
                                        :classes="multiselectClass"
                                        />
                                    </div>
                                    <Field name="ccType" type="text" class="hidden" :validateOnMount="true" v-model="formUtang.ccType" />
                                </div>
                                <div v-if="withCC && !formUtang.ccType" class="mt-0.5 text-amalan-red text-2xs">Silahkan pilih jenis kartu</div>
                            </div>
                        </template>
                        <div class="mb-3.5">
                            <label class="text-amalan-blue-1 font-bold text-sm">Total Utang</label>
                            <div class="flex flex-col relative w-full mt-1">
                                <CurrencyInput 
                                v-model="formUtang.outstandingAmount" 
                                :propsClass="errors.outstandingAmount ? 'border-amalan-red rounded-lg' : 'border-amalan-gray-4 rounded-lg'"
                                :options="{
                                    currency: 'IDR',
                                    currencyDisplay: 'narrowSymbol',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    hideNegligibleDecimalDigitsOnFocus: false,
                                    autoDecimalDigits: false,
                                    autoSign: false,
                                    useGrouping: true,
                                    accountingSign: true
                                }" />
                                <Field name="outstandingAmount" type="text" :validateOnMount="true" :validateOnInput="true" class="hidden" v-model="formUtang.outstandingAmount" />
                                <div v-if="errors.outstandingAmount" class="mt-0.5 text-amalan-red text-2xs">{{errors.outstandingAmount}}</div>
                            </div>
                        </div>
                        <div class="mb-3.5 grid grid-cols-2 gap-x-4">
                            <div>
                                <label class="text-amalan-blue-1 font-bold text-sm">Lama Tunggakan</label>
                                <div class="flex items-center relative w-full mt-1">
                                    <div class="w-full border rounded-lg py-1" :class="[ errors.overdue ? 'border-amalan-red' : 'border-amalan-gray-4']">
                                        <Multiselect
                                        ref="multiselect"
                                        v-model="formUtang.overdue"
                                        :noResultsText="'Data tidak ditemukan.'"
                                        :noOptionsText="'Sedang memuat data...'"
                                        :searchable="true"
                                        :options="dataLamaTunggakan"
                                        :classes="multiselectClass"
                                        />
                                    </div>
                                    <Field name="overdue" type="text" class="hidden" :validateOnMount="true" v-model="formUtang.overdue" />
                                </div>
                                <div v-if="errors.overdue" class="mt-0.5 text-amalan-red text-2xs">{{errors.overdue}}</div>
                            </div>
                            <div>
                                <label class="text-amalan-blue-1 font-bold text-sm">Nomor Pinjaman</label>
                                <Field placeholder="Nomor Pinjaman" name="accountNumber" v-model="formUtang.accountNumber" type="text" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none" :class="{ 'border-amalan-red': errors.accountNumber }" :validateOnInput="true" :validateOnMount="true"/>
                                <div v-if="errors.accountNumber" class="mt-0.5 text-amalan-red text-2xs">{{errors.accountNumber}}</div>
                            </div>
                        </div>
                        <div class="mb-3.5">
                            <label class="text-amalan-blue-1 font-bold text-sm">Pembayaran Terakhir</label>
                            <Field name="lastPaymentDate" v-model="formUtang.lastPaymentDate" :validateOnInput="true" :validateOnMount="true" type="date" :max="filters.dateNow()" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none pl-4 pr-3" :class="{ 'border-amalan-red': errors.lastPaymentDate }" />
                            <div v-if="errors.lastPaymentDate" class="mt-1 text-2xs text-amalan-red">{{errors.lastPaymentDate}}</div>
                        </div>
                        <button type="submit" class="w-full mt-11 rounded-amalan-lg font-bold text-center text-sm p-3" :class="[ withCC ? meta.valid && formUtang.ccType ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' : meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="withCC ? meta.valid && formUtang.ccType ? false : true : meta.valid ? false : true">Tambahkan</button>
                    </Form>
                </div>
            </template>
            <template v-if="formStep === FORM_STEP_LIST.listOpportunity">
                <p class="text-center text-amalan-black text-xs sm:text-sm mb-8">Anda dapat mengubah, menghapus, atau menambahkan data pinjaman Anda disini!</p>
                <template v-for="(dataOpp, index) in dataOpportunitiesNew" :key="index">
                    <div class="w-full mb-3.5 rounded-lg border border-amalan-gray-4 bg-amalan-yellow-100 p-4 flex justify-between items-center">
                        <div class="flex-none w-14 h-14 rounded-lg flex justify-center items-center">
                            <img :src="require('@/assets/img/bank-lender-icon/' + getLoanIcon(dataOpp.bankName, dataOpp.loanType))" class="w-14">
                        </div>
                        <div class="flex-grow px-2.5 text-amalan-black">
                            <p class="text-sm text-left font-bold">{{ dataOpp.bankName ? getBankName(dataOpp.bankName, dataOpp.loanType) : '-' }}</p>
                            <p class="text-xs mt-0.5">{{ dataOpp.loanType ? getLoanTitle(dataOpp.loanType) : '-' }}</p>
                            <p class="text-xs mt-1">{{ dataOpp.outstandingAmount ? $filters.format_number(dataOpp.outstandingAmount) : '-' }}</p>
                        </div>
                        <div class="flex-none w-6 h-14 flex flex-col justify-between items-center">
                            <div @click="editOpportunity(index, dataOpp)" class="w-6 h-6 rounded-lg shadow-amalan-md border flex justify-center items-center cursor-pointer bg-amalan-white border-amalan-blue-1 hover:bg-amalan-gray-5">
                                <img src="@/assets/img/pencil-primary-icon.svg" class="ml-px">
                            </div>
                            <div @click="deleteOpportunity(index)" class="w-6 h-6 rounded-lg shadow-amalan-md border flex justify-center items-center cursor-pointer bg-amalan-white border-amalan-blue-1 hover:bg-amalan-gray-5">
                                <img src="@/assets/img/signup/delete-icon.svg" class="w-3.5">
                            </div>
                        </div>
                    </div>
                    <div class="h-px w-full bg-amalan-gray-3 mb-3.5"></div>
                </template>
                <template v-for="(dataOpp, index) in dataOpportunities" :key="index">
                    <div class="w-full mb-3.5 rounded-lg border border-amalan-gray-4 bg-amalan-white p-4 flex justify-between items-center">
                        <div class="flex-none w-14 h-14 rounded-lg flex justify-center items-center">
                            <img :src="require('@/assets/img/bank-lender-icon/' + getLoanIcon(dataOpp.Name, dataOpp.Loan_Type_2__c))" class="w-14">
                        </div>
                        <div class="flex-grow px-2.5 text-amalan-black">
                            <p class="text-sm text-left font-bold">{{ dataOpp.Name ? getBankName(dataOpp.Name, dataOpp.Loan_Type_2__c) : '-' }}</p>
                            <p class="text-xs mt-0.5">{{ dataOpp.Loan_Type_2__c ? getLoanTitle(dataOpp.Loan_Type_2__c) : '-' }}</p>
                            <p class="text-xs mt-1">{{ dataOpp.Estimated_Outstanding_Amount__c ? $filters.format_number(dataOpp.Estimated_Outstanding_Amount__c) : '-' }}</p>
                        </div>
                        <div class="flex-none w-6 h-14 flex flex-col justify-between items-center"></div>
                    </div>
                </template>
                <template v-if="dataOpportunitiesNew.length == 0">
                    <div class="w-full py-3 border-t border-b border-amalan-gray-4 flex justify-between items-center">
                        <span class="font-bold text-sm text-amalan-blue-1">Total Utang</span>
                        <span class="text-sm text-amalan-blue-1">{{ totalHutang ? $filters.format_number(totalHutang + totalHutangNewOpportunities) : '-' }}</span>
                    </div>
                    <div class="mt-16">
                        <p v-if="userWithPricingPackage" class="text-center text-amalan-black text-xs">Penambahan Data Pinjaman akan dikenakan biaya<br>tambahan yang berlaku di amalan.</p>
                        <button v-if="dataOpportunitiesNew.length < 5" @click="goToLoanTypeList" type="button" class="w-full mt-3.5 rounded-amalan-lg font-bold border text-center text-sm p-3 text-amalan-blue-1 cursor-pointer shadow-amalan-md bg-amalan-white border-amalan-blue-1 hover:bg-amalan-gray-5">+ Tambahkan Data Pinjaman</button>
                        <button @click="nextPage" type="button" class="w-full mt-3.5 rounded-amalan-lg font-bold text-center text-sm p-3" :class="[ dataOpportunitiesNew.length > 0 ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="dataOpportunitiesNew.length === 0 ? true : false">Selanjutnya</button>
                    </div>
                </template>
                <div v-else class="fixed bg-amalan-white shadow-amalan-sm-up bottom-0 inset-x-0 mx-auto max-w-lg py-4 px-6">
                    <div class="w-full flex justify-between items-center">
                        <span class="font-bold text-sm text-amalan-blue-1">Total Utang</span>
                        <span class="text-sm text-amalan-blue-1">{{ totalHutang ? $filters.format_number(totalHutang + totalHutangNewOpportunities) : '-' }}</span>
                    </div>
                    <div class="mt-3.5 grid grid-cols-2 gap-x-4">
                        <button v-if="dataOpportunitiesNew.length < 5" @click="goToLoanTypeList" type="button" class="w-full mt-3.5 rounded-amalan-lg font-bold border text-center text-sm p-3 text-amalan-blue-1 cursor-pointer shadow-amalan-md bg-amalan-white border-amalan-blue-1 hover:bg-amalan-gray-5">Tambahkan</button>
                        <button @click="nextPage" type="button" class="w-full mt-3.5 rounded-amalan-lg font-bold text-center text-sm p-3" :class="[ dataOpportunitiesNew.length > 0 ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="dataOpportunitiesNew.length === 0 ? true : false">Selanjutnya</button>
                    </div>
                </div>
            </template>
            <template v-if="formStep === FORM_STEP_LIST.listLoanType">
                <p class="text-center text-amalan-black text-xs sm:text-sm mb-8">Pilih jenis pinjaman yang ingin Anda daftarkan dan<br>dapatkan solusi sesuai dengan jenis pinjaman Anda!</p>
                <div v-if="dataProductType.length > 0" class="mt-8">
                    <div v-for="(loanType, index) in listLoanTypePKU" :key="index" class="w-full rounded-lg p-4 mb-3.5 shadow-amalan-sm bg-amalan-white cursor-pointer hover:bg-amalan-gray-5" @click="selectedLoanType(loanType.name)">
                        <div class="flex justify-between items-center">
                            <div class="w-14 h-14 flex-none rounded-full flex justify-center items-center border-2 border-amalan-yellow-400 bg-amalan-white">
                                <img :src="require('@/assets/img/signup/icon-jenis-pinjaman/' + loanType.icon)">
                            </div>
                            <div class="flex-grow px-2.5">
                                <p class="text-sm font-bold text-amalan-blue-1">{{ loanType.title }}</p>
                                <p class="text-xs mt-1">{{ loanType.desc }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <loading-data-without-margin v-else class="mt-20" />
            </template>
        </div>
        <div v-else class="w-full h-screen px-6 flex flex-col justify-center items-center">
            <img src="@/assets/img/clipboard-v2.svg" class="w-2/6 -mt-36 mx-auto">
            <p class="text-center text-sm mt-6">Anda belum punya daftar pinjaman. Yuk, registrasikan daftar pinjaman Anda dengan layanan <b>Keringanan Pinjaman Full Service</b> dari amalan!</p>
            <router-link to="/sign-up/onboarding" class="w-56 mt-3.5 rounded-amalan-lg font-bold border text-center text-sm p-3 text-amalan-white cursor-pointer shadow-amalan-md bg-amalan-blue-1 border-amalan-blue-1 hover:bg-amalan-blue-3 hover:border-amalan-blue-3">
                Registrasi Sekarang
            </router-link>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>