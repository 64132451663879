<script>
import stytchApi from '../axios/stytch'
import axios from "../axios"
import { ref, computed, reactive, onMounted, defineComponent } from "vue"
import { Form, Field } from "vee-validate"
import * as yup from "yup"
import router from "../router"
import { useStore } from 'vuex'
import VueCountdown from '@chenfengyuan/vue-countdown'
import { VueRecaptcha } from 'vue-recaptcha';
import RecommendationBrowser from '../components/RecommendationBrowser.vue'
import setCookies from '@/composables/setCookies'
import GCLID from '@/composables/GCLID'
import { mixpanelPushEvent, eventName } from '@/composables/mixpanel'
import modalPopUp from '@/composables/modalPopUp'
import { useCrispInstance } from '@/composables/crisp'

export default defineComponent ({
    components: {
        Form,
        Field,
        VueCountdown,
        VueRecaptcha,
        RecommendationBrowser
    },
    watch: {
        stepForm: function(e){
            if(e == 'otp_validate'){
                setTimeout(() => {
                    this.setToFirstInputCode();
                }, 500);
            }
        }
    },
    methods:{
        setToFirstInputCode(){
            this.$refs.code[0].$el.focus();
        },
        nextInput($event){
            if($event.data && this.validateInput.includes($event.data)){
                const element = $event.srcElement.name
                const el = parseInt(element.replace('code', ''))
                if(el < 6){
                    this.$refs.code[el].$el.focus()
                }
            }
        },
        deleteInput($event){
            if($event.key == 'Backspace'){
                const element = $event.srcElement.name
                const el = parseInt(element.replace('code', ''))
                if(el > 1){
                    this.codeOtp[el - 1] = null
                    this.codeOtp[el - 2] = null
                    this.$refs.code[el - 2].$el.focus()
                    if(el === 6){
                        this.codeOtp[5] = null
                    }
                }
            }
        },
        pasteInput($event){
            const clipboard = $event.clipboardData.getData('text').trim()
            if(/\d{6}/.test(clipboard)){
                const data = [...clipboard]
                data.forEach((elem, index) => {
                    this.codeOtp[index] = elem
                })
                this.$refs.code[5].$el.focus()
            }
        }
    },
    setup: () => {
        const store = useStore();
        const crisp = useCrispInstance()
        const swal = modalPopUp()
        const OTP_TYPE = {sms: 'sms', whatsapp: 'whatsapp', email: 'email'}
        const STEP_FORM_LIST = {input_form: 'input_form', otp_validate: 'otp_validate'}

        const recaptchaSiteKey = computed(() => process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY)

        const stepForm = ref(STEP_FORM_LIST.input_form)
        const params = reactive({
            otpType: OTP_TYPE.sms,
            identifier: null,
            challenge: 'login'
        })

        const methodId = ref(null);
        const liveTimeOtp = ref(null);
        const isLoading = ref(false);
        const time = ref(58000);
        const validateInput = ['1','2','3','4','5','6','7','8','9','0'];
        const captchaShow = ref(false)
        const codeOtp = reactive(['','','','','',''])
        const popUpBrowser = ref(true)
        const environmentMode = process.env.VUE_APP_ENVIRONMENT_MODE
        const validateOtpAttemption = ref(0)
        const countdownIsDone = ref(false)

        const emailSchema = yup.object({
            email: yup.string().required('Mohon diisi')
            .email('Email tidak valid')
        });

        const noHpSchema = yup.object({
            noHp: yup.string().required('Mohon diisi').matches(/^[1-9][0-9]+$/, "Harus berupa angka dan tanpa angka 0 di depan").min(9,'Masukan setidaknya 9 digit')
        });

        const otpSchema = yup.object({
            code1: yup.string().required().matches(/^[0-9]+$/).length(1),
            code2: yup.string().required().matches(/^[0-9]+$/).length(1),
            code3: yup.string().required().matches(/^[0-9]+$/).length(1),
            code4: yup.string().required().matches(/^[0-9]+$/).length(1),
            code5: yup.string().required().matches(/^[0-9]+$/).length(1),
            code6: yup.string().required().matches(/^[0-9]+$/).length(1),
        });

        const submitIdentifierOtp = (values) => {
            params.identifier = values.email ?? "+62" + values.noHp
            if(environmentMode === 'locals') return recaptchaVerify(true)
            return captchaShow.value = true
        }

        const recaptchaVerify = async (response) => {
            if(!response) return

            try{
                await checkIsClient()
            }catch{
                isLoading.value = false
                return popUpGeneralError()
            }

            setTimeout(() => {
                isLoading.value = true
                stytchApi.post('request-otp', params)
                .then(resp => {
                    isLoading.value = false
                    methodId.value = resp.data.data?.methodId ?? null
                    mixpanelPushEvent(eventName.OTP)
                    return stepForm.value = STEP_FORM_LIST.otp_validate
                })
                .catch(err => {
                    isLoading.value = false
                    return handleErrorMessage(err.response.data.message)
                })
            }, 1000)
        }

        const checkIsClient = async () => {
            isLoading.value = true
            const response = await axios.post('check-email-client', params);

            params.identifier = response.data.data?.identifier
            params.otpType = response.data.data?.otpType

            isLoading.value = false
            return
        }

        const resendOtp = () => {
            isLoading.value = true
            stytchApi.post('request-otp', params)
            .then(resp => {
                isLoading.value = false
                methodId.value = resp.data.data?.methodId ?? null
                time.value += 10;
                resetOtpField()
                countdownIsDone.value = false
            })
            .catch(err => {
                isLoading.value = false
                return handleErrorMessage(err.response.data.message)
            })
        }

        const validateOtp = (values) => {
            isLoading.value = true

            const payload = {
                methodId: methodId.value,
                otpType: params.otpType,
                identifier: params.identifier,
                code: values.code1 + values.code2 + values.code3 + values.code4 + values.code5 + values.code6,
                challenge: params.challenge
            };

            stytchApi.post('authenticate-otp', payload)
            .then(resp => {
                axios.get('/customer',{
                    headers: {
                        Authorization: 'Bearer ' + resp.data.session_token
                    }
                }).then(() =>{
                    store.dispatch('auth/login', resp.data).then(() => {
                        if(setCookies.checkCookies("utm_source")) setCookies.sendCookies()
                        setCookies.sendQueryParams()
                        const __gtm_campaign_url = setCookies.checkCookies("__gtm_campaign_url")
                        if(__gtm_campaign_url) GCLID.sentGCLID(__gtm_campaign_url)

                        crisp.session.reset()

                        router.push('/home')
                    });
                }).catch((errUser) => {
                    isLoading.value = false
                    if(errUser.response.status == 404){
                        swal.swalError.fire({
                            html: '<p class="text-center text-xs mt-2">Maaf, sepertinya Anda pengguna baru ya. Silahkan login menggunakan No Handphone atau Whatsapp :)</p>',
                            showCancelButton: true,
                            confirmButtonText: 'Login Ulang',
                            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                        }).then((result) => {
                            if (!result.isConfirmed) return
                            return window.location.reload()
                        })
                    }else{
                        swal.swalErrorReload.fire().then((result) => {
                            if (!result.isConfirmed) return
                            return window.location.reload()
                        })
                    }
                });
            })
            .catch((err) => {
                isLoading.value = false
                validateOtpAttemption.value++
                // if(params.otpType === OTP_TYPE.sms && validateOtpAttemption.value === 2) return confirmAnotherOtpMethod('whatsapp')
                // if(params.otpType === OTP_TYPE.whatsapp && validateOtpAttemption.value === 2) return confirmAnotherOtpMethod('email')
                if(validateOtpAttemption.value === 3){
                    validateOtpAttemption.value = 0
                    return popUpGeneralError()
                }
                return handleErrorMessage(err.response.data.message)
            });
        }

        const popUpGeneralError = () => {
            const htmlText = params.otpType ? 'Email' : 'SMS';
            return swal.swalConfirmBtnFullWithIcon.fire({
                html: `<p class="text-center text-xs mt-2">Masuk dengan ${htmlText} gagal dilakukan.<br>Hubungi kami untuk mendapatkan bantuan!</p>`,
                showCancelButton: false,
                confirmButtonText: 'Dapatkan Bantuan',
            }).then((result) => {
                if (result.isConfirmed){
                    window.location = 'https://wa.me/6285888236207?text=' + 
                    'Halo amalan, mohon bantuan saya mengalami kendala saat login di web.amalan.com'
                    return
                }
            })
        }

        const confirmAnotherOtpMethod = (otherMethod) => {
            const confirmButtonText = otherMethod === 'whatsapp' ? 'Masuk dengan WhatsApp' : 'Masuk dengan Email'
            const htmlText = otherMethod === 'whatsapp' ? 'Masuk dengan SMS gagal dilakukan.<br><br>Silahkan coba masuk dengan WhatsApp' : 'Masuk dengan WhatsApp gagal dilakukan.<br><br>Silahkan coba masuk dengan Email'

            swal.swalConfirmBtnFullWithIcon.fire({
                html: `<p class="text-center text-xs mt-2">${htmlText}</p>`,
                confirmButtonText,
                showCancelButton: false
            }).then(() => {
                params.otpType = otherMethod === 'whatsapp' ? OTP_TYPE.whatsapp : OTP_TYPE.email
                stepForm.value = STEP_FORM_LIST.input_form
                validateOtpAttemption.value = 0
                return resetOtpField()
            })
        }

        const resetOtpField = () => {
            codeOtp[0] = ''
            codeOtp[1] = ''
            codeOtp[2] = ''
            codeOtp[3] = ''
            codeOtp[4] = ''
            codeOtp[5] = ''
            captchaShow.value = false
            countdownIsDone.value = false
        }

        const resetOtpForm = () => {
            codeOtp[0] = ''
            codeOtp[1] = ''
            codeOtp[2] = ''
            codeOtp[3] = ''
            codeOtp[4] = ''
            codeOtp[5] = ''
        }

        const recBrowserOnClose = () => {
            setTimeout(() => {
                popUpBrowser.value = false
                document.getElementsByTagName('input')[0].focus()
            }, 500)
        }

        const handleErrorMessage = (message) => {
            if(!message){
                return swal.swalError.fire({
                    html: '<p class="text-center text-xs mt-2">Maaf, server gagal mengirim kode OTP, silahkan tunggu beberapa saat.<br><br>Atau klik tombol bantuan dibawah</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                }).then((result) => {
                    if (result.isConfirmed){
                        window.location = 'https://wa.me/6285888236207?text=' + 
                        'Mohon bantuan saya tidak menerima OTP untuk login di web.amalan.com'
                        return
                    }
                })
            }

            if(message.includes('Too Many Attempts')){
                captchaShow.value = false
                return swal.swalErrorExclamation.fire({
                    html: '<p class="text-center text-xs mt-2">Anda sudah melebihi limit percobaan verifikasi. Mohon coba beberapa saat lagi</p>',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: '<img src="/img/x-icon-primary.svg" class="w-8 h-8 rounded-full">',
                })
            }

            if(message.includes('Invalid OTP code') || message.includes('OTP not found')){
                resetOtpForm()
                countdownIsDone.value = true
                return swal.swalErrorExclamation.fire({
                    html: '<p class="text-center text-xs mt-2">Maaf, kode OTP yang anda masukan salah. Silahkan periksa kembali.</p>',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: '<img src="/img/x-icon-primary.svg" class="w-8 h-8 rounded-full">',
                })
            }

            if(message.includes('OTP has expired') || message.includes('reached the time limit')){
                return swal.swalErrorExclamation.fire({
                    html: '<p class="text-center text-xs mt-2">Maaf, kode OTP yang anda masukan sudah kadaluarsa. Mohon periksa kembali.</p>',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: '<img src="/img/x-icon-primary.svg" class="w-8 h-8 rounded-full">',
                })
            }

            return swal.swalError.fire({
                html: '<p class="text-center text-xs mt-2">Maaf, server gagal mengirim kode OTP, silahkan tunggu beberapa saat.<br><br>Atau klik tombol bantuan dibawah</p>',
                showCancelButton: true,
                confirmButtonText: 'Bantuan',
                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
            }).then((result) => {
                if (result.isConfirmed){
                    window.location = 'https://wa.me/6285888236207?text=' + 
                    'Mohon bantuan saya tidak menerima OTP untuk login di web.amalan.com'
                    return
                }
            })
        }

        const countdownEndEvent = () => countdownIsDone.value = true

        const goToOtherMethod = () => {
            params.otpType = params.otpType == 'sms' ? OTP_TYPE.whatsapp : params.otpType == 'email' ? OTP_TYPE.sms : OTP_TYPE.email
            stepForm.value = STEP_FORM_LIST.input_form
            validateOtpAttemption.value = 0
            return resetOtpField()
        }

        onMounted(() => {
            window.scrollTo(0, 0)
            mixpanelPushEvent(eventName.LOGIN)

            if(localStorage.getItem('ccu-product-destination')){
                mixpanelPushEvent(eventName.CC_LOGIN, {
                    applied_program: localStorage.getItem('ccu-product-destination')
                })
            }
        })

        return {
            emailSchema,
            noHpSchema,
            otpSchema,
            liveTimeOtp,
            time,
            isLoading,
            stepForm,
            validateInput,
            recaptchaSiteKey,
            captchaShow,
            recaptchaVerify,
            codeOtp,
            popUpBrowser,
            recBrowserOnClose,
            params,
            validateOtp,
            countdownEndEvent,
            goToOtherMethod,
            submitIdentifierOtp,
            STEP_FORM_LIST,
            OTP_TYPE,
            countdownIsDone,
            resendOtp,
            confirmAnotherOtpMethod
        }
    }
})
</script>

<template>
    <div class="bg-amalan-blue-7 min-h-screen">
        <div class="justify-center flex pt-16 mb-14">
            <img class="h-16" src="../assets/img/logo-amalan.png">
        </div>
        <div class="rounded-tr-amalan-lg w-full min-h-screen bg-amalan-white pb-10 text-amalan-black">
            <div v-if="stepForm == STEP_FORM_LIST.input_form" class="pt-12 px-6">
                <h1 class="text-2xl font-bold text-amalan-blue-1">Masuk</h1>
                <template v-if="params.otpType == OTP_TYPE.sms">
                    <p class="text-xs mt-2">Masukkan nomor ponsel Anda tanpa angka 0 atau +62 pada form dibawah sesuai dengan contoh.</p>
                    <Form
                        @submit="submitIdentifierOtp"
                        :validation-schema="noHpSchema"
                        v-slot="{ errors, meta }"
                    >
                        <div class="mt-8 w-full flex items-center bg-amalan-white border-2 border-gray-200 rounded-lg" :class="{ 'border-amalan-red': errors.noHp }">
                            <label class="text-amalan-black text-base text-center font-semibold w-14 py-2.5 border-r-2 border-gray-200">+62</label>
                            <Field name="noHp" :validateOnInput="true" type="tel" class="ml-2 text-amalan-black bg-transparent outline-none appearance-none pl-4 pr-8 border-transparent focus:border-transparent focus:ring-0" placeholder="81234567890" />
                        </div>
                        <div v-if="errors.noHp" class="mt-1 text-xs text-amalan-red">*{{errors.noHp}}</div>
                        <div class="my-4" v-if="captchaShow">
                            <p class="text-center text-xs">Silahkan centang kotak di bawah untuk melanjutkan.</p>
                            <div class="flex justify-center items-center mt-1">
                                <VueRecaptcha
                                    ref="recaptcha"
                                    :sitekey="recaptchaSiteKey"
                                    :language="'id'"
                                    @verify="recaptchaVerify"
                                ></VueRecaptcha>
                            </div>
                        </div>
                        <button v-else type="submit" class="mt-4 mb-2.5 w-full rounded-amalan-lg font-bold border-2 border-solid text-center text-sm py-3 px-6" :class="[ meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 border-amalan-blue-1 hover:bg-amalan-blue-2 hover:border-amalan-blue-2' : 'text-amalan-gray-2 bg-amalan-gray-4 border-amalan-gray-4 cursor-not-allowed']" :disabled="!meta.valid">Masuk</button>
                        <p class="text-2xs text-center text-amalan-blue-3 px-3.5">Kami akan mengirimkan kode verifikasi<br>ke pesan SMS atau Email Anda, cek ponsel Anda segera!</p>
                    </Form>
                </template>
                <template v-if="params.otpType == OTP_TYPE.whatsapp">
                    <p class="text-xs mt-2">Masukkan nomor ponsel Anda tanpa angka 0 atau +62 pada form dibawah sesuai dengan contoh.</p>
                    <Form
                        @submit="submitIdentifierOtp"
                        :validation-schema="noHpSchema"
                        v-slot="{ errors, meta }"
                    >
                        <div class="mt-8 w-full flex items-center bg-amalan-white border-2 border-gray-200 rounded-lg" :class="{ 'border-amalan-red': errors.noHp }">
                            <label class="text-amalan-black text-base text-center font-semibold w-14 py-2.5 border-r-2 border-gray-200">+62</label>
                            <Field name="noHp" :validateOnInput="true" type="tel" class="ml-2 text-amalan-black bg-transparent outline-none appearance-none pl-4 pr-8 border-transparent focus:border-transparent focus:ring-0" placeholder="81234567890" />
                        </div>
                        <div v-if="errors.noHp" class="mt-1 text-xs text-amalan-red">*{{errors.noHp}}</div>
                        <div class="my-4" v-if="captchaShow">
                            <p class="text-center text-xs">Silahkan centang kotak di bawah untuk melanjutkan.</p>
                            <div class="flex justify-center items-center mt-1">
                                <VueRecaptcha
                                    ref="recaptcha"
                                    :sitekey="recaptchaSiteKey"
                                    :language="'id'"
                                    @verify="recaptchaVerify"
                                ></VueRecaptcha>
                            </div>
                        </div>
                        <button v-else type="submit" class="mt-4 mb-2.5 w-full rounded-amalan-lg font-bold border-2 border-solid text-center text-sm py-3 px-6" :class="[ meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 border-amalan-blue-1 hover:bg-amalan-blue-2 hover:border-amalan-blue-2' : 'text-amalan-gray-2 bg-amalan-gray-4 border-amalan-gray-4 cursor-not-allowed']" :disabled="!meta.valid">Lanjutkan dengan Whatsapp</button>
                        <p class="text-2xs text-center text-amalan-blue-3 px-3.5">Kami akan mengirimkan kode verifikasi ke pesan WhatsApp Anda, cek ponsel Anda segera!</p>
                    </Form>
                </template>
                <template v-if="params.otpType == OTP_TYPE.email">
                    <p class="text-xs mt-2">Anda dapat mengetikkan alamat email Anda pada form dibawah dan silahkan tunggu kode OTP-nya.</p>
                    <Form
                    @submit="submitIdentifierOtp"
                    :validation-schema="emailSchema"
                    v-slot="{ errors, meta }"
                    >
                        <div class="mt-8">
                            <Field name="email" :validateOnInput="true"  type="text" class="block w-full px-4 py-2.5 text-amalan-black bg-amalan-white border-2 border-gray-200 rounded-lg focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none hover:border-amalan-gray-3" :class="{ 'border-amalan-red': errors.email }" placeholder="nama@email.com" />
                            <div v-if="errors.email" class="mt-1 text-xs text-amalan-red">*{{errors.email}}</div>
                        </div>
                        <div class="my-4" v-if="captchaShow">
                            <p class="text-center text-xs">Silahkan centang kotak di bawah untuk melanjutkan.</p>
                            <div class="flex justify-center items-center mt-1">
                                <VueRecaptcha
                                    ref="recaptcha"
                                    :sitekey="recaptchaSiteKey"
                                    :language="'id'"
                                    @verify="recaptchaVerify"
                                ></VueRecaptcha>
                            </div>
                        </div>
                        <button v-else type="submit" class="mt-4 mb-2.5 w-full rounded-amalan-lg font-bold border-2 border-solid text-center text-sm py-3 px-6" :class="[ meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 border-amalan-blue-1 hover:bg-amalan-blue-2 hover:border-amalan-blue-2' : 'text-amalan-gray-2 bg-amalan-gray-4 border-amalan-gray-4 cursor-not-allowed']" :disabled="!meta.valid">Lanjutkan dengan Email</button>
                        <p class="text-2xs text-center text-amalan-blue-3 px-3.5">Kami akan mengirimkan kode verifikasi ke Email Anda, cek email Anda segera!</p>
                    </Form>
                </template>
            </div>
            <div v-else class="pt-12 px-6">
                <h1 class="text-2xl font-bold text-amalan-blue-1">Verifikasi OTP</h1>
                <p class="text-xs mt-2">Kode OTP dikirim ke {{ params.otpType == 'sms' ? 'SMS' : params.otpType == 'email' ? 'Email' : 'WhatsApp' }} <span class="font-bold">{{ params.identifier }}</span></p>
                <Form
                    @submit="validateOtp"
                    :validation-schema="otpSchema"
                    v-slot="{ errors, meta }"
                >
                    <div class="my-8 px-3.5 sm:px-10 w-full flex items-center justify-between">
                        <template v-for="i in 6" :key="i">
                            <Field ref="code" :name="'code' + i" type="tel" maxlength="1" class="bg-transparent w-10 sm:w-11 py-2 px-2 text-base text-center border-2 rounded-lg outline-none focus:border-amalan-blue-3" :class="[errors['code' + i] ? 'border-amalan-red' : 'border-amalan-gray-3']" :validateOnMount="true" :validateOnInput="true" @input="nextInput" @keydown="deleteInput" @paste="pasteInput" v-model="codeOtp[i - 1]" />
                        </template>
                    </div>
                    <button type="submit" class="w-full rounded-amalan-lg font-bold border border-solid text-center text-sm py-3.5 px-6" :class="[ !meta.valid ? 'bg-amalan-gray-4 border-amalan-gray-4 cursor-not-allowed text-amalan-gray-2' : 'cursor-pointer bg-amalan-blue-1 border-amalan-blue-1 hover:bg-amalan-blue-2 hover:border-amalan-blue-2 text-amalan-white' ]" :disabled="!meta.valid">Verifikasi</button>
                    <p v-if="!countdownIsDone" class="mt-4 text-2xs text-center text-amalan-gray-2">
                        Mohon tunggu
                        <VueCountdown ref="liveTimeOtp" :time="time" v-slot="{ totalSeconds }" @end="countdownEndEvent" class="text-xs text-amalan-blue-3 font-bold">
                            {{ totalSeconds }} detik
                        </VueCountdown>
                        untuk mengirim ulang
                    </p>
                    <p v-else class="mt-4 text-2xs text-center text-amalan-gray-2 font-semibold">
                        Tidak menerima kode OTP? <span @click="resendOtp" class="text-xs text-amalan-blue-1 font-bold cursor-pointer">Kirim ulang kode OTP</span><br>
                    </p>
                    <!-- <p v-else class="mt-4 text-2xs text-center text-amalan-gray-2 font-semibold">
                        Tidak menerima kode OTP? <span @click="resendOtp" class="text-xs text-amalan-blue-1 font-bold cursor-pointer">Kirim ulang kode OTP</span><br>
                        atau coba <span @click="goToOtherMethod" class="text-xs text-amalan-blue-1 font-bold cursor-pointer">masuk dengan {{ params.otpType == 'sms' ? 'WhatsApp' : params.otpType == 'email' ? 'SMS' : 'Email' }}</span><br>
                    </p> -->
                </Form>
            </div>
        </div>
        <loading-overlay v-if="isLoading" />
        <RecommendationBrowser v-if="popUpBrowser" :visible="true" @on-close="recBrowserOnClose" />
    </div>
</template>