<template>
    <div class="pt-4 min-h-screen">
        <div v-if="dataContact" class="mx-4">
            <div class="mt-2 pb-12 w-full">
                <div class="flex items-center">
                    <div class="flex items-center text-blue-900 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/signup/data-pinjaman-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Data<br>Pinjaman</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-blue-900"></div>
                    <div class="flex items-center text-blue-900 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/signup/informasi-rekening-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Informasi<br>Rekening</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-gray-300"></div>
                    <div class="flex items-center text-gray-500 relative">
                        <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                            <img src="@/assets/img/signup/tanda-tangan.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Tanda<br>Tangan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-gray-300"></div>
                    <div class="flex items-center text-gray-500 relative">
                        <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                            <img src="@/assets/img/signup/pembayaran.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Pembayaran<br>Registrasi</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-gray-300"></div>
                    <div class="flex items-center text-gray-500 relative">
                        <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                            <img src="@/assets/img/verified.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Registrasi<br>Selesai</p></div>
                    </div>
                </div>
            </div>
            <div class="mt-4 pb-14 w-full">
                <Form
                    @submit="nextStep"
                    :validation-schema="updateForm"
                    v-slot="{ handleSubmit, errors }"
                    >
                    <template v-if="currentStep === 0">
                        <p class="text-xs sm:text-sm text-center pt-2 pb-1">
                            Informasi rekening bank ini berguna untuk pengembalian dana setelah proses mediasi selesai dilakukan, jika masih terdapat sisa dana di RDP.
                        </p>
                        <div class="my-2">
                            <label class="text-gray-700 font-bold text-sm">Nama Bank</label>
                            <Field name="ref_bank_name" v-model="formValues.ref_bank_name" type="text" class="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none pl-4 pr-8 hover:border-gray-400" :class="{ 'border-red-600': errors.ref_bank_name }" />
                            <div v-if="errors.ref_bank_name" class="mt-1 text-sm text-red-600">*{{errors.ref_bank_name}}</div>
                        </div>
                        <div class="my-2">
                            <label class="text-gray-700 font-bold text-sm">Nomor Rekening</label>
                            <Field name="ref_bank_number" v-model="formValues.ref_bank_number" :validateOnInput="true" type="text" class="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none pl-4 pr-8 hover:border-gray-400" :class="{ 'border-red-600': errors.ref_bank_number }" />
                            <div v-if="errors.ref_bank_number" class="mt-1 text-sm text-red-600">*{{errors.ref_bank_number}}</div>
                        </div>
                        <div class="my-2">
                            <label class="text-gray-700 font-bold text-sm">Nama Pemilik</label>
                            <Field name="ref_account_holder" v-model="formValues.ref_account_holder" type="text" class="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none pl-4 pr-8 hover:border-gray-400" :class="{ 'border-red-600': errors.ref_account_holder }" />
                            <div v-if="errors.ref_account_holder" class="mt-1 text-sm text-red-600">*{{errors.ref_account_holder}}</div>
                        </div>
                        <div class="my-2">
                            <label class="text-gray-700 font-bold text-sm">Kantor Cabang</label>
                            <Field name="ref_bank_branch" v-model="formValues.ref_bank_branch" type="text" class="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none pl-4 pr-8 hover:border-gray-400" :class="{ 'border-red-600': errors.ref_bank_branch }" />
                            <div v-if="errors.ref_bank_branch" class="mt-1 text-sm text-red-600">*{{errors.ref_bank_branch}}</div>
                        </div>
                    </template>
                    <template v-if="currentStep === 1">
                        <div>
                            <div class="px-4 pb-6 sm:px-6">
                                <p class="text-lg sm:text-xl text-center font-bold text-blue-900">
                                    Konfirmasi Data
                                </p>
                                <p class="mt-1 text-sm sm:text-md">
                                    Cek kembali data Anda dan pastikan semua data yang diinputkan sesuai dengan data yang ada ya!
                                </p>
                            </div>
                            <div>
                                <dl>
                                    <div class="px-4 py-2 border-b border-t sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt class="text-sm font-bold">
                                            Nama Bank
                                        </dt>
                                        <dd class="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                            {{ formValues.ref_bank_name }}
                                        </dd>
                                    </div>
                                </dl>
                                <dl>
                                    <div class="px-4 py-2 border-b sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt class="text-sm font-bold">
                                            Nomor Rekening
                                        </dt>
                                        <dd class="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                            {{ formValues.ref_bank_number }}
                                        </dd>
                                    </div>
                                </dl>
                                <dl>
                                    <div class="px-4 py-2 border-b sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt class="text-sm font-bold">
                                            Nama Pemilik
                                        </dt>
                                        <dd class="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                            {{ formValues.ref_account_holder }}
                                        </dd>
                                    </div>
                                </dl>
                                <dl>
                                    <div class="px-4 py-2 border-b sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt class="text-sm font-bold">
                                            Kantor Cabang
                                        </dt>
                                        <dd class="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                            {{ formValues.ref_bank_branch }}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                            <div class="px-4 pt-3 pb-2 sm:px-6 hidden">
                                <label class="text-gray-700 font-medium text-xs inline-flex" for="terms">
                                    <label class="h-4 w-4 border-2 border-gray-400 rounded mr-2 p-0 cursor-pointer" :class="{ 'border-red-600': errors.terms, 'bg-amalan-yellow-400 border-amalan-yellow-400' : formValues.terms }" @click="termsChecked">
                                        <CheckIcon v-if="formValues.terms" class="w-3 h-3 mx-auto text-blue-900" />
                                        <div v-else class="w-3 h-3 mx-auto bg-transparent"></div>
                                    </label>
                                    <Field name="terms" v-model="formValues.terms" type="checkbox" class="hidden" :validateOnMount="true" :validateOnInput="true" />
                                    <span @click="termsChecked" class="self-center cursor-pointer text-sm" :class="{ 'text-red-600': errors.terms }">Saya menyetujui seluruh <span class="underline" :class="[ errors.terms ? 'text-red-600' : 'text-blue-900' ]">syarat dan ketentuan</span> yang berlaku di <b>amalan international.</b></span>
                                </label>
                                <div v-if="errors.terms" class="text-sm text-red-600">*{{errors.terms}}</div>
                            </div>
                        </div>
                    </template>

                    <div class="my-6">
                        <button v-if="currentStep !== 0" type="button" class="h-10 rounded-lg font-medium border border-solid cursor-pointer text-center text-base py-1 px-1 text-blue-900 border-blue-900 hover:bg-blue-900 hover:text-white float-left" @click="prevStep"><ChevronLeftIcon class="h-full" /></button>
                        <button v-if="currentStep !== 1" type="submit" class="h-10 rounded-lg font-medium border border-solid cursor-pointer text-center text-base py-1 px-1 text-white bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700 float-right" @submit="handleSubmit"><ChevronRightIcon class="h-full" /></button>
                        <button v-if="currentStep === 1" type="submit" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-6 text-white float-right" :class="[ formValues.terms ? 'cursor-pointer bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700' : 'bg-gray-300 border-gray-300 cursor-not-allowed' ]" :disabled="!formValues.terms">Kirim</button>
                    </div>
                </Form>
            </div>
        </div>
        <loading-overlay v-else />
        <loading-overlay v-if="isLoading" />
        <div style="height:100px"></div>
    </div>
</template>

<script>
import { defineComponent, ref, inject, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import amalansignupApi from "@/axios/amalansignup";
import router from "@/router";
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import { ChevronLeftIcon, ChevronRightIcon, CheckIcon } from '@heroicons/vue/solid';
import { mapGetters } from 'vuex'

export default defineComponent({
    components:{
        Form,
        Field,
        ChevronRightIcon,
        ChevronLeftIcon,
        CheckIcon
    },
    mounted(){
        this.formValues.ref_bank_number = this.dataContact.Account_Number__c;
        this.formValues.ref_bank_name = this.dataContact.Bank_Name__c;
        this.formValues.ref_bank_branch = this.dataContact.Branch_Name__c;
        this.formValues.ref_account_holder = this.dataContact.Reference_Full_Name__c;
    },
    computed: {
        ...mapGetters('contact',{
            signUpStatus:'getSignUpStatus'
        }),
        ...mapGetters('opportunities',{
            amountLoan:'getAmountLoan'
        })
    },
    created(){
        this.$router.push({ name: 'Home'});
        
        if(this.signUpStatus == 200){
            this.$router.push({ name: 'Home'});
        }else if(this.signUpStatus == 201){
            this.$router.push('/sign-up/tanda-tangan');
        }else if(this.signUpStatus == 202){
            this.$router.push('/sign-up/pembayaran');
        }

        if(this.amountLoan == null){
            this.$router.push('/sign-up/data-utang');
        }

        this.formValues.terms = true;
    },
    setup() {
        const store = useStore();
        const currentStep = ref(0);
        const formValues = reactive({});
        const dataContact = computed(() => store.getters['contact/getContact']);
        const dataUser = computed(() => store.getters['user/getUser']);
        const swal = inject("$swal");
        let isLoading = ref(false);

        const dataBankSchema = [
            yup.object({
                ref_bank_name: yup.string().nullable().required('Nama bank tidak boleh kosong').max(100, "Maksimal 100 karakter"),
                ref_bank_number: yup.string().nullable().required('Nomor rekening tidak boleh kosong').matches(/^[0-9]+$/, "Nomor rekening harus berupa angka").max(30, "Maksimal 30 karakter"),
                ref_account_holder: yup.string().nullable().required('Nama pemilik tidak boleh kosong').max(100, "Maksimal 100 karakter"),
                ref_bank_branch: yup.string().nullable().required('Kantor cabang tidak boleh kosong').max(100, "Maksimal 100 karakter"),
            }),
            yup.object({
                terms: yup.bool().required('Silahkan dicentang').equals([true], 'Silahkan dicentang'),
            }),
        ];

        const updateForm = computed(() => {
            return dataBankSchema[currentStep.value];
        });

        const swalConfirmIcon = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600',
                cancelButton: 'bg-white mx-2 py-2 px-5 rounded-lg border border-blue-900 text-blue-900 text-sm font-semibold hover:bg-red-400 hover:border-red-400 hover:text-white'
            },
            buttonsStyling: false,
            title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        const swalError = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600',
                cancelButton: 'absolute -top-3 -right-3'
            },
            buttonsStyling: false,
            title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        function nextStep(values) {
            if (currentStep.value === 1) {
                let additional_data = {
                    phone: dataUser.value.phone_number,
                }
                Object.assign(formValues,additional_data);            
                
                swalConfirmIcon.fire({
                    html: '<p class="text-center text-sm mt-2">Pastikan data yang Anda masukkan sudah benar ya! <br>Setalah ini <b>amalan</b> akan segera memproses dokumen Perjanjian Kerjasama</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Konfirmasi',
                    cancelButtonText: 'Batalkan',
                }).then((result) => {
                    if (result.isConfirmed){
                        isLoading.value = true;
                        amalansignupApi.post('salesforce/contact/bank_refunds', formValues)
                        .then(() => {
                            amalansignupApi.post('salesforce/contact/hellosign')
                            .then(() => {
                                store.dispatch('contact/requestSignUpStatus').then(() => {
                                    isLoading.value = false;
                                    router.push('/sign-up/tanda-tangan');
                                });
                            })
                            .catch(() => {
                                isLoading = false;
                                swalError.fire({
                                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                                    showCancelButton: true,
                                    confirmButtonText: 'Bantuan',
                                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                                }).then((result) => {
                                    if (result.isConfirmed){
                                        window.location = 'https://wa.me/6285888236207?text=' + 
                                        'Mohon bantuan saya tidak menerima dokumen perjanjian pada proses signup di web.amalan.com';
                                        return;
                                    }
                                });
                            })
                        })
                        .catch( err => {
                            isLoading.value = false;
                            if (err.status !== 401){
                                swalError.fire({
                                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                                    showCancelButton: true,
                                    confirmButtonText: 'Bantuan',
                                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                                }).then((result) => {
                                    if (result.isConfirmed){
                                        window.location = 'https://wa.me/6285888236207?text=' + 
                                        'Mohon bantuan saya gagal terus dalam pengisian rekening penarikan pada proses signup di web.amalan.com';
                                        return;
                                    }
                                });
                            }
                        })
                    }
                });
                return;
            }

            Object.assign(formValues, values);
            currentStep.value++;
        }

        function prevStep() {
            if (currentStep.value <= 0) {
                return;
            }

            currentStep.value--;
        }

        function termsChecked(){
            formValues.terms = !formValues.terms;
        }

        return {
            formValues,
            isLoading,
            dataContact,
            updateForm,
            prevStep,
            nextStep,
            termsChecked,
            currentStep
        };
    },
})
</script>