<script setup>
import { inject, computed, ref } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import apiRdpManagement from "@/axios/rdpManagement"

const swal = inject('$swal')
const store = useStore()

const paymentData = computed(() => store.getters['RDPManagement/paymentData'])
const paymentFetchStatus = computed(() => store.getters['RDPManagement/paymentFetchStatus'])
const isLoading = ref(false)

const PAYMENT_FETCH_STATUSES = { FETCH: 'FETCH', DONE: 'DONE' }
const PAYMENT_STATUSES = { pending: 'PENDING', onprogress: 'ONPROGRESS', done: 'DONE', cancelled: 'CANCELLED', failed: 'FAILED', waiting: 'WAITING', expired: 'EXPIRED' }

const swalConfirm = swal.mixin({
    customClass: {
        confirmButton: 'mx-2 py-3 w-34 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-blue-1 hover:bg-amalan-blue-2 text-xs font-bold text-amalan-white',
        cancelButton: 'mx-2 py-3 w-34 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-white hover:bg-amalan-gray-5 text-xs font-bold text-amalan-blue-1'
    },
    title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
    buttonsStyling: false,
    width: '340px',
    allowOutsideClick: false,
    reverseButtons: true,
})

const swalError = swal.mixin({
    customClass: {
        confirmButton: 'mx-2 py-3 w-34 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-blue-1 hover:bg-amalan-blue-2 text-xs font-bold text-amalan-white',
        cancelButton: 'absolute -top-3 -right-3'
    },
    buttonsStyling: false,
    title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
    width: '340px',
    allowOutsideClick: false,
    reverseButtons: true,
})

const agreeBtn = (token) => {
    swalConfirm.fire({
        html: '<p class="text-center text-xs mt-2 text-amalan-black">Anda ingin melakukan konfirmasi transaksi dana dari Rekening Dana Pelunasan Anda oleh amalan?</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Konfirmasi',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if (result.isConfirmed){
            sendApproval(token, true)
            return
        }

        return
    })
}

const disagreeBtn = (token) => {
    swalConfirm.fire({
        html: '<p class="text-center text-xs mt-2 text-amalan-black">Anda yakin tidak menyetujui transaksi dana dari Rekening Dana Pelunasan Anda oleh amalan?</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Konfirmasi',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if (result.isConfirmed){
            sendApproval(token, false)
            return
        }

        return
    })
}

const formatDateTime = (dateTime) => moment(dateTime).subtract(7, 'hours').format('DD MMMM YYYY | HH.mm')

const sendApproval = (token, approval) => {
    isLoading.value = true
    apiRdpManagement.post('/payment/approval/' + token, { approval })
    .then(() => {
        isLoading.value = false
        store.dispatch('RDPManagement/requestLatestPaymentData')
    })
    .catch(() => {
        isLoading.value = false
        swalError.fire({
            html: '<p class="text-center text-xs mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        }).then((result) => {
            if (result.isConfirmed){
                window.location = 'https://wa.me/6285888236207?text=' + 
                'Mohon bantuan saya server gagal memproses RDP payment approval di web.amalan.com'
                return
            }

            return
        })
    })
}

</script>

<template>
    <div class="w-full h-100 mt-3 px-4 border border-solid border-amalan-gray-4 rounded-lg box-border bg-amalan-white">
        <div class="w-full h-full pt-4 overflow-y-scroll scrollbar-hide">
            <loading-data-without-margin v-if="(paymentFetchStatus === PAYMENT_FETCH_STATUSES.FETCH)" class="pt-40" />
            <template v-else>
                <div v-if="(paymentData.length === 0)" class="w-full h-full flex justify-center items-center -mt-2">
                    <p class="text-xs text-amalan-black text-center">Maaf, Anda belum memiliki notifikasi</p>
                </div>
                <template v-else>
                    <div v-for="(payment, indexPayment) in paymentData" class="w-full mb-5" :key="indexPayment">
                        <p class="text-xs font-bold text-amalan-black">{{ payment.group }}</p>
                        <template v-for="(data, indexData) in payment.data" :key="indexData">
                            <div v-if="(data.status === PAYMENT_STATUSES.pending && !data.approver_2)" class="w-full p-3 border border-solid border-amalan-blue-6 rounded-lg box-border bg-amalan-blue-7 bg-opacity-30 mt-2">
                                <p class="text-xs">amalan ingin melakukan transaksi dana dari Rekening Dana Pelunasan Anda sebesar <b>{{ $filters.format_number(parseFloat(data.amount)) }}</b> untuk {{ data.remark }}. Silahkan tekan tombol konfirmasi dibawah jika Anda menyetujuinya.</p>
                                <div class="w-full grid grid-cols-2 gap-x-4 mt-4">
                                    <div @click="disagreeBtn(data.approval_token)" class="w-full py-1 text-xs font-bold text-center text-amalan-blue-1 border border-amalan-blue-1 rounded-amalan-lg bg-amalan-white hover:bg-amalan-gray-5 cursor-pointer">Tidak Setuju</div>
                                    <div @click="agreeBtn(data.approval_token)" class="w-full py-1 text-xs font-bold text-center text-amalan-white border border-amalan-blue-1 rounded-amalan-lg bg-amalan-blue-1 hover:bg-amalan-blue-2 cursor-pointer">Ya, Setuju</div>
                                </div>
                            </div>
                            <div v-else-if="(data.status === PAYMENT_STATUSES.cancelled && data.approver_2)" class="w-full p-3 border border-solid border-amalan-gray-4 rounded-lg box-border bg-amalan-white mt-2">
                                <p class="text-xs">Anda tidak menyetujui transaksi dana dari Rekening Dana Pelunasan Anda sebesar <b>{{ $filters.format_number(parseFloat(data.amount)) }}</b> untuk {{ data.remark }}.</p>
                                <p class="text-2xs text-amalan-gray-2 mt-2 text-right">{{ formatDateTime(data.created_at) }}</p>
                            </div>
                            <div v-else class="w-full p-3 border border-solid border-amalan-gray-4 rounded-lg box-border bg-amalan-white mt-2">
                                <p class="text-xs">Anda telah menyetujui transaksi dana dari Rekening Dana Pelunasan Anda sebesar <b>{{ $filters.format_number(parseFloat(data.amount)) }}</b> untuk {{ data.remark }}.</p>
                                <p class="text-2xs text-amalan-gray-2 mt-2 text-right">{{ formatDateTime(data.created_at) }}</p>
                            </div>
                        </template>
                    </div>
                </template>
            </template>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<style scoped>
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
</style>