<script setup>
import { ref, inject, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import axios from "../../axios";
import { useRouter, useRoute} from "vue-router";
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import { PlusIcon } from '@heroicons/vue/solid';
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import SampleKtp from './SampleImageKtpV2.vue'
import SampleSelfie from './SampleImageSelfieV2.vue'

const router = useRouter()
const route = useRoute()
const urlKtpPratinjau = ref(null)
const urlSelfiePratinjau = ref(null)
const showKtpPreview = ref(false)
const showSelfiePreview = ref(false)
const errorMessage = ref(false)
const sampleKtp = ref(false)
const sampleSelfie = ref(false)
const formValues = reactive({});
const currentStep = ref(0);
const FILE_SIZE = 49152 * 1024;
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png"
];
const formUrl = reactive({
    urlKtp: null,
    urlSelfie: null
})

const schemas = [
    yup.object({
        ktp: yup.mixed().required("Pilih file gambar.")
        .test(
            "fileSize",
            "Ukuran file maksimal 48 MB.",
            (value) => {
                if (value && value.length > 0) return value[0].size <= FILE_SIZE
            }
        )
        .test(
            "fileFormat",
            "Mohon pilih file gambar.",
            (value) => {
                if (value && value.length > 0) return SUPPORTED_FORMATS.includes(value[0].type)
            }
        ),
    }),
    yup.object({
        selfie: yup.mixed().required("Pilih file gambar.")
        .test(
            "fileSize",
            "Ukuran file maksimal 48 MB.",
            (value) => {
                if (value && value.length > 0) return value[0].size <= FILE_SIZE
            }
        )
        .test(
            "fileFormat",
            "Mohon pilih file gambar.",
            (value) => {
                if (value && value.length > 0) return SUPPORTED_FORMATS.includes(value[0].type)
            }
        )
    }),
];

const updateForm = computed(() => {
    return schemas[currentStep.value];
});
const swal = inject("$swal");
const store = useStore();
let isLoading = ref(false);

const swalError = swal.mixin({
    customClass: {
        confirmButton: 'mx-2 py-3 w-34 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-blue-1 hover:bg-amalan-blue-2 text-xs font-bold text-amalan-white',
        cancelButton: 'absolute -top-3 -right-3'
    },
    buttonsStyling: false,
    title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
    width: '340px',
    allowOutsideClick: false,
    reverseButtons: true,
})

const swalConfirmIcon = swal.mixin({
    customClass: {
        confirmButton: 'mx-2 py-3 w-34 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-blue-1 hover:bg-amalan-blue-2 text-xs font-bold text-amalan-white',
        cancelButton: 'mx-2 py-3 w-34 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-white hover:bg-amalan-gray-5 text-xs font-bold text-amalan-blue-1'
    },
    title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
    buttonsStyling: false,
    width: '340px',
    allowOutsideClick: false,
    reverseButtons: true,
})

const nextStep = (values) => {
    if (currentStep.value === 1) {
        isLoading.value = true;
        Object.assign(formValues, values);
        let data = new FormData();
        data.append('img_ktp', formValues.ktp[0]);
        data.append('img_selfie', formValues.selfie[0]);
        data.append('type', 'general');
        axios.post('/customer/store', data,{
            headers: {
                'Accept': 'multipart/form-data',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(() => {
            store.dispatch('user/storeUser').then(() => {
                router.push('/onboarding-2');
            });
        })
        .catch((err) => {
            formUrl.urlKtp = null;
            formUrl.urlSelfie = null;
            currentStep.value = 0;
            isLoading.value = false;
            if(err.response.status == 400 || err.response.status == 422 ){
                if(err.response.data.message.includes('Face not detected')){
                    // swalError.fire({
                    //     html: '<p class="text-center text-sm mt-2">Maaf, Pastikan anda mengunggah foto yang mengandung wajah.</p>',
                    //     showCancelButton: true,
                    //     showConfirmButton: false,
                    //     cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                    // });
                    Bypassconfirmation(data)
                } else if(err.response.data.message.includes('KTP not detected')){
                    // swalError.fire({
                    //     html: '<p class="text-center text-sm mt-2">Maaf, Pastikan anda mengunggah foto e-KTP pada tempat yang sudah disediakan.</p>',
                    //     showCancelButton: true,
                    //     showConfirmButton: false,
                    //     cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                    // });
                    Bypassconfirmation(data)
                } else if(err.response.data.message.includes('Your selfie and ktp image similarity')){
                    // swalError.fire({
                    //     html: '<p class="text-center text-sm mt-2">Maaf, Sepertinya skor kecocokan pada foto e-KTP dan Swafoto anda tidak memenuhi persyaratan.</p>',
                    //     showCancelButton: true,
                    //     confirmButtonText: 'Bantuan',
                    //     cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                    // }).then((result) => {
                    //     if (result.isConfirmed){
                    //         window.location = 'https://wa.me/6285888236207?text=' + 
                    //         'Mohon bantuan skor kecocokan foto e-KTP dan Swafoto saya tidak memenuhi persyaratan di web.amalan.com, saya sudah gunakan foto e-KTP dan Swafoto terbaru.';
                    //         return;
                    //     }
                    // });
                    Bypassconfirmation(data)
                } else if(err.response.data.message.includes('Duplicate entry')){
                    swalError.fire({
                        html: '<p class="text-center text-sm mt-2">Maaf, Data KTP Anda terdeteksi sudah terdaftar sebelumnya. Silahkan tekan tombol bantuan dibawah ini atau hubungi konsultan Anda.</p>',
                        showCancelButton: true,
                        confirmButtonText: 'Bantuan',
                        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                    }).then((result) => {
                        if (result.isConfirmed){
                            window.location = 'https://wa.me/6285888236207?text=' + 
                            'Mohon bantuan saya tidak dapat melanjutkan proses karena KTP saya sudah terdaftar di akun lain.';
                            return;
                        }
                    });
                } else {
                    swalError.fire({
                        html: '<p class="text-center text-sm mt-2">Maaf, Pastikan anda mengunggah foto e-KTP dan Swafoto sesuai contoh dan pastikan tidak mengunggah di tempat yang tertukar.</p>',
                        showCancelButton: true,
                        confirmButtonText: 'Bantuan',
                        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                    }).then((result) => {
                        if (result.isConfirmed){
                            window.location = 'https://wa.me/6285888236207?text=' + 
                            'Mohon bantuan saya gagal terus dalam proses KYC - unggah e-KTP dan Swafoto di web.amalan.com';
                            return;
                        }
                    });
                }
            }else{
                swalError.fire({
                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                }).then((result) => {
                    if (result.isConfirmed){
                        window.location = 'https://wa.me/6285888236207?text=' + 
                        'Mohon bantuan saya gagal terus dalam proses KYC - unggah e-KTP dan Swafoto di web.amalan.com';
                        return;
                    }
                });
            }
        })
        return;
    }

    Object.assign(formValues, values);
    currentStep.value++;
}

const Bypassconfirmation = (data) => {
    swalConfirmIcon.fire({
        html: '<p class="text-center text-sm mt-2">Proses e-KYC Anda gagal dilakukan karena e-KTP atau Swafoto Anda tidak sesuai dengan ketentuan.<br><br>Anda dapat mengulangi proses ini atau melewatinya.</p>',
        showCancelButton: true,
        confirmButtonText: 'Lewati Proses',
        cancelButtonText: 'Ulangi Proses',
    }).then((result) => {
        if (result.isConfirmed){
            isLoading.value = true;
            axios.post('/customer/bypass-ocr', data,{
                headers: {
                    'Accept': 'multipart/form-data',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(() => {
                store.dispatch('user/storeUser').then(() => {
                    router.push('/onboarding-2');
                });
            })
            .catch(() => {
                isLoading.value = false;
                swalError.fire({
                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                }).then((result) => {
                    if (result.isConfirmed){
                        window.location = 'https://wa.me/6285888236207?text=' + 
                        'Mohon bantuan saya gagal terus dalam proses KYC - Bypass KYC status di web.amalan.com';
                        return;
                    }
                });
            })
        }
    });
}

const previewKtp = () => {
    if (formValues.img_ktp.length > 0 && SUPPORTED_FORMATS.includes(formValues.img_ktp[0].type) && formValues.img_ktp[0].size <= FILE_SIZE){
        var reader = new FileReader();
        reader.onload = (e) => {
            urlKtpPratinjau.value = e.target.result;
        }
        reader.readAsDataURL(formValues.img_ktp[0]);

        errorMessage.value = false;
        showKtpPreview.value = true;
    } else {
        errorMessage.value = true;
        urlKtpPratinjau.value = null;
        formUrl.urlKtp = null;
    }
}

const previewSelfie = () => {
    if (formValues.img_selfie.length > 0 && SUPPORTED_FORMATS.includes(formValues.img_selfie[0].type) && formValues.img_selfie[0].size <= FILE_SIZE){
        var reader = new FileReader();
        reader.onload = (e) => {
            urlSelfiePratinjau.value = e.target.result;
        }
        reader.readAsDataURL(formValues.img_selfie[0]);

        errorMessage.value = false;
        showSelfiePreview.value = true;
    } else {
        errorMessage.value = true;
        urlSelfiePratinjau.value = null;
        formUrl.urlSelfie = null;
    }
}

const pilihKembaliKtp = () => document.getElementById("ktpInput").click()
const pilihKembaliSelfie = () => document.getElementById("selfieInput").click()

const unggahKtp = () => {
    if(urlKtpPratinjau.value){
        showKtpPreview.value = false; 
        formUrl.urlKtp = urlKtpPratinjau;
    }
}

const unggahSelfie = () => {
    if(urlSelfiePratinjau.value){
        showSelfiePreview.value = false; 
        formUrl.urlSelfie = urlSelfiePratinjau;
    }
}

const ktpInputClick = () => {
    if(formUrl.urlKtp) return

    sampleKtp.value = true
}

const selfieInputClick = () => {
    if(formUrl.urlSelfie) return

    sampleSelfie.value = true
}

const sampleKtpBack = () => sampleKtp.value = false

const sampleKtpClose = () => {
    sampleKtp.value = false
    pilihKembaliKtp()
}

const sampleSelfieBack = () => sampleSelfie.value = false

const sampleSelfieClose = () => {
    sampleSelfie.value = false
    pilihKembaliSelfie()
}

const backNavigation = () => {
    if(currentStep.value === 1) return currentStep.value--

    localStorage.removeItem('from-onboarding-go-to')
    return router.push({ name: 'Home'})
}

</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="backNavigation" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="relative bg-amalan-white min-h-screen">
            <SampleKtp :changeVisibility="sampleKtp" @on-close="sampleKtpClose" @on-back="sampleKtpBack" />
            <SampleSelfie :changeVisibility="sampleSelfie" @on-close="sampleSelfieClose" @on-back="sampleSelfieBack" />
            <div class="mx-5 bg-amalan-white">
                <div class="w-full py-8 flex justify-between items-center">
                    <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                        <span class="text-sm text-amalan-blue-1 font-bold">1</span>
                    </div>
                    <p v-if="currentStep === 0" class="flex-none text-amalan-blue-1 text-sm font-bold mx-1">Foto e-KTP</p>
                    <div class="bg-amalan-blue-1 h-px" :class="[currentStep === 0 ? 'flex-grow' : 'flex-none w-3 mx-1']"></div>
                    <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none" :class="[currentStep === 0 ? 'bg-amalan-gray-4 mx-1' : 'bg-amalan-yellow-400']">
                        <span class="text-sm font-bold" :class="[currentStep === 0 ? 'text-amalan-gray-2' : 'text-amalan-blue-1']">2</span>
                    </div>
                    <p v-if="currentStep === 1" class="flex-none text-amalan-blue-1 text-sm font-bold mx-1">Foto Swafoto</p>
                    <div class="bg-amalan-blue-1 h-px" :class="[currentStep === 1 ? 'flex-grow' : 'flex-none w-3']"></div>
                    <div class="w-6 h-6 rounded-full bg-amalan-gray-4 flex justify-center items-center flex-none ml-1">
                        <span class="text-sm text-amalan-gray-2 font-bold">3</span>
                    </div>
                </div>
                <div class="w-full pb-12">
                    <Form
                        @submit="nextStep"
                        :validation-schema="updateForm"
                        v-slot="{ handleSubmit, errors }"
                        >
                        <template v-if="currentStep === 0">
                            <div class="w-full text-amalan-black">
                                <p class="text-xs text-center">
                                    Ambil dan unggah foto <b>e-KTP</b> Anda dalam format<br>PNG/jpg/jpeg untuk verifikasi data Anda.
                                </p>
                            </div>
                            <div class="w-full mt-3.5">
                                <label class="block rounded-lg h-56 py-14 cursor-pointer border-2 border-dashed border-amalan-gray-3 hover:bg-amalan-gray-5 relative" @click="ktpInputClick">
                                    <div v-if="formUrl.urlKtp" class="absolute bg-amalan-white w-full h-full top-0 rounded-lg p-4 flex justify-center items-center">
                                        <img :src="formUrl.urlKtp" class="max-h-full">
                                    </div>
                                    <div class="h-14 w-14 rounded-full bg-amalan-blue-1 flex justify-center items-center mx-auto">
                                        <PlusIcon class="text-amalan-white h-10 w-10"/>
                                    </div>
                                    <p class="text-center mt-7 text-sm text-amalan-black">Unggah atau ambil foto <b>e-KTP</b> Anda disini!</p>
                                </label>
                                <Field type="file" id="ktpInput" name="ktp" class="hidden" v-model="formValues.img_ktp" @change="previewKtp" />
                                <div v-if="errors.ktp" class="mt-1 mb-4 text-xs text-red-600">*{{errors.ktp}}</div>
                            </div>
                        </template>

                        <template v-if="currentStep === 1">
                            <div class="w-full text-amalan-black">
                                <p class="text-xs text-center">
                                    Ambil dan unggah foto <b>Swafoto</b> Anda dalam format<br>PNG/jpg/jpeg verifikasi data Anda.
                                </p>
                            </div>
                            <div class="w-full mt-3.5">
                                <label class="block rounded-lg h-80 py-25 cursor-pointer border-2 border-dashed border-amalan-gray-3 hover:bg-amalan-gray-5 relative" @click="selfieInputClick">
                                    <div v-if="formUrl.urlSelfie" class="absolute bg-amalan-white w-full h-full top-0 rounded-xl p-4 flex justify-center items-center">
                                        <img :src="formUrl.urlSelfie" class="max-h-full">
                                    </div>
                                    <div class="h-14 w-14 rounded-full bg-amalan-blue-1 flex justify-center items-center mx-auto">
                                        <PlusIcon class="text-amalan-white h-10 w-10"/>
                                    </div>
                                    <p class="text-center mt-7 text-sm text-amalan-black">Unggah atau ambil foto <b>Swafoto</b> Anda disini!</p>
                                </label>
                                <Field type="file" id="selfieInput" name="selfie" class="hidden" v-model="formValues.img_selfie" @change="previewSelfie" />
                                <div v-if="errors.selfie" class="mt-1 mb-4 text-xs text-red-600">*{{errors.selfie}}</div>
                            </div>
                        </template>

                        <div class="mt-16">
                            <button v-if="currentStep === 0" @submit="handleSubmit" type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ formUrl.urlKtp ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!formUrl.urlKtp">Selanjutnya</button>
                            <button v-if="currentStep === 1" type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ formUrl.urlSelfie ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!formUrl.urlSelfie">Selanjutnya</button>
                        </div>
                    </Form>
                </div>
            </div>
            <loading-overlay v-if="isLoading" />
            <template v-if="showKtpPreview">
                <div class="fixed inset-0 z-50 bg-black bg-opacity-60 grid grid-cols-1 p-5 sm:p-10 overflow-y-auto">
                    <div class="bg-amalan-white rounded-lg w-full sm:w-8/12 max-w-lg place-self-center">
                        <div class="bg-amalan-blue-7 rounded-t-lg py-3"> 
                            <p class="text-center text-base font-bold text-amalan-black">Pratinjau</p>
                        </div>
                        <div class="w-full p-4">
                            <img :src="urlKtpPratinjau" class="w-full sm:w-8/12 sm:mx-auto">
                        </div>
                        <p v-if="errorMessage" class="text-center mb-8 text-sm text-amalan-black px-4">Pastikan file yang Anda upload dalam format png/jpg/jpeg.</p>
                        <div class="w-full grid grid-cols-2 gap-x-4 px-4 pb-4">
                            <button @click="pilihKembaliKtp" type="button" class="w-full shadow-amalan-md rounded-amalan-lg font-bold text-center text-sm py-3 text-amalan-blue-1 border border-amalan-blue-1 cursor-pointer bg-amalan-white hover:bg-amalan-gray-5">Pilih Kembali</button>
                            <button @click="unggahKtp" type="button" class="w-full shadow-amalan-md rounded-amalan-lg font-bold text-center text-sm py-3 text-amalan-white" :class="[ !errorMessage ? 'cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2' : 'cursor-not-allowed text-amalan-gray-2 bg-amalan-gray-4' ]" :disabled="errorMessage">Unggah</button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="showSelfiePreview">
                <div class="fixed inset-0 z-50 bg-black bg-opacity-60 grid grid-cols-1 p-5 sm:p-10 overflow-y-auto">
                    <div class="bg-amalan-white rounded-lg w-full sm:w-8/12 max-w-lg place-self-center">
                        <div class="bg-amalan-blue-7 rounded-t-lg py-3"> 
                            <p class="text-center text-base font-bold text-amalan-black">Pratinjau</p>
                        </div>
                        <div class="w-full p-4">
                            <img :src="urlSelfiePratinjau" class="w-10/12 sm:w-8/12 mx-auto">
                        </div>
                        <p v-if="errorMessage" class="text-center mb-8 text-sm text-amalan-black px-4">Pastikan file yang Anda upload dalam format png/jpg/jpeg.</p>
                        <div class="w-full grid grid-cols-2 gap-x-4 px-4 pb-4">
                            <button @click="pilihKembaliSelfie" type="button" class="w-full shadow-amalan-md rounded-amalan-lg font-bold text-center text-sm py-3 text-amalan-blue-1 border border-amalan-blue-1 cursor-pointer bg-amalan-white hover:bg-amalan-gray-5">Pilih Kembali</button>
                            <button @click="unggahSelfie" type="button" class="w-full shadow-amalan-md rounded-amalan-lg font-bold text-center text-sm py-3 text-amalan-white" :class="[ !errorMessage ? 'cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2' : 'cursor-not-allowed text-amalan-gray-2 bg-amalan-gray-4' ]" :disabled="errorMessage">Unggah</button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
