<script setup>
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { onMounted, computed, ref } from 'vue'
import amalansignupApi from "@/axios/amalansignup"
import emblaCarouselVue from 'embla-carousel-vue'
import { QuestionMarkCircleIcon } from '@heroicons/vue/outline'
import { feeDescription, benefitConfig } from '@/constants/PricingPackage'
import LoadingDataWithoutMargin from '@/components/LoadingDataWithoutMargin.vue'
import BasicModal from '@/components/Modal/BasicModal.vue'
import useModalPopUp from '@/composables/modalPopUp'

const router = useRouter()
const route = useRoute()
const store = useStore()
const modalPopUp = useModalPopUp()

const pricingPackageState = computed(() => store.getters['upgradePricingPackage/getPackageData'])
const paymentPageData = computed(() => store.getters['upgradePricingPackage/getPaymentPageData'])
const userPricingPackage = computed(() => store.getters['contact/getUserPricingPackage'])
const signUpFeeAmountPaid = computed(() => store.getters['upgradePricingPackage/getSignUpFeeAmountPaid'])
const totalHutang = computed(() => store.getters['docCollection/getAmountLoan'])

const isLoading = ref(false)
const selectedCarouselIndex = ref(0)
const modalComponent = ref({
    visible: false,
    title: '',
    description: ''
})
const showBasicPackagePrevent = ref(false)

const PRICING_TYPE = {
    BASIC: {
        text: 'Basic',
        icon: 'basic.svg',
        name: 'pay_as_you_go'
    },
    SILVER: {
        text: 'Silver',
        icon: 'silver.svg',
        name: 'silver_package'
    },
    GOLD: {
        text: 'Gold',
        icon: 'gold.svg',
        name: 'gold_package'
    },
    PLATINUM: {
        text: 'Platinum',
        icon: 'platinum.svg',
        name: 'platinum_package'
    },
    DIAMOND: {
        text: 'Diamond',
        icon: 'diamond.svg',
        name: 'diamond_package'
    }
}

const carouselDotsClass = {
    default: 'w-1.5 bg-amalan-gray-3',
    active: 'w-4 bg-amalan-blue-1'
}

const [carouselRoot, emblaApi] = emblaCarouselVue({
    align: 'center'
})

const reinitializeCarousel = () => {
    emblaApi.value.reInit({align: 'center'})
    emblaApi.value.on('select', () => selectedCarouselIndex.value = emblaApi.value.selectedScrollSnap())
}

const removeItalicTag = (value) => value.replace('<i>', '').replace('</i>', '')

const showModal = (title, description) => {
    modalComponent.value.title = title
    modalComponent.value.description = description
    modalComponent.value.visible = true
}

const hideModal = () => modalComponent.value.visible = false

const backButton = () => {
    if(!paymentPageData.value.isTouched) return router.push({ name: 'Loan Management - Home' })
    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs mt-2 text-amalan-black">Perubahan Paket Layanan Anda belum disimpan.<br><br>Anda yakin ingin keluar dari halaman ini?</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Konfirmasi',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if(!result.isConfirmed) return
        store.dispatch('upgradePricingPackage/resetPaymentPageData')
        return router.push({ name: 'Loan Management - Home' })
    })
}

const selectPackage = (package_name) => {
    if(userPricingPackage.value === 'BASIC') return showBasicPackagePrevent.value = true
    if(package_name.includes('diamond') && totalHutang.value < 20000000) return preventDiamondAndPlatinum('diamond')
    if(package_name.includes('platinum') && totalHutang.value < 50000000) return preventDiamondAndPlatinum('platinum')

    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs mt-2 text-amalan-black">Anda melalukan peningkatan paket layanan.<br><br>Peningkatan Paket Layanan ini akan dikenakan biaya tambahan sesuai dengan biaya paket yang ada di amalan.<br><br>Apakah Anda yakin ingin melakukannya?</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Konfirmasi',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if(!result.isConfirmed) return
        
        isLoading.value = true
        amalansignupApi.get(`loan-management/upgrade-package?package=${package_name.replace('_package', '')}`)
        .then((res) => {
            store.dispatch('upgradePricingPackage/setPaymentPageData', {
                current_package: res.data.data.old_package,
                new_package: res.data.data.new_package,
                payment_amount: res.data.data.price,
                payment_expiration_date: res.data.data.payment_expiration_date,
                payment_link: null
            })
            isLoading.value = false
            return router.push({ name: 'Loan Management - Upgrade Package - Payment Page' })
        })
        .catch(() => {
            isLoading.value = false
            modalPopUp.swalError.fire({
                html: '<p class="text-center text-xs mt-2 text-amalan-black">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                showCancelButton: true,
                confirmButtonText: 'Bantuan',
                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
            }).then((result) => {
                if(!result.isConfirmed) return
                return window.location = 'https://wa.me/6285888236207?text=' + 
                'Mohon bantuan saya gagal terus untuk memilih paket layanan pada halaman upgrade package loan management di web.amalan.com'
            })
        })
    })
}

const preventDiamondAndPlatinum = (packageName) => {
    const text = packageName === 'platinum' ? 'Mohon maaf, saat ini Anda belum memenuhi syarat untuk memilih paket ini.<br><br>Paket <b>Platinum</b> hanya dapat dipilih jika Anda memiliki total utang senilai <b>≥ Rp 50,000,000</b>.<br><br>Apakah Anda ingin menambahkan data pinjaman lainnya?' : 'Mohon maaf, saat ini Anda belum memenuhi syarat untuk memilih paket ini.<br><br>Paket <b>Diamond</b> hanya dapat dipilih jika Anda memiliki total utang senilai <b>≥ Rp 20,000,000</b>.<br><br>Apakah Anda ingin menambahkan data pinjaman lainnya?'

    modalPopUp.swalConfirmWithIcon.fire({
        html: `<p class="text-center text-xs mt-2 text-amalan-black">${text}</p>`,
        showCancelButton: true,
        confirmButtonText: 'Ya, Tambahkan',
        cancelButtonText: 'Tidak',
    }).then((result) => {
        if(!result.isConfirmed) return
        return router.push({ name: 'Loan Management - Loan List - Home' })
    })
}

const initializePricingPackageData = () => {
    amalansignupApi.get('loan-management/calculate-pricing-package')
    .then((res) => {
        store.dispatch('upgradePricingPackage/setPackageData', {
            userPackage: PRICING_TYPE[userPricingPackage.value].name,
            totalOutstandingAmount: totalHutang.value ?? 0,
            data: res.data.data
        })

        if(userPricingPackage.value === 'DIAMOND') return
        return reinitializeCarousel()
    })
    .catch(async () => {
        const result = await modalPopUp.swalErrorReload.fire()
        if(!result.isConfirmed) return
        return window.location.reload()
    })
}

const goToSupport = () => window.open('https://wa.me/6285888236207?text=Halo tim amalan, saya ingin meningkatkan paket layanan dari Basic ke paket layanan lainnya. Mohon bantuannya.', '_blank')

onMounted(() => {
    store.dispatch('pendingUserActivity/checkPendingAdditionalSuf')
    if(pricingPackageState.value) reinitializeCarousel()
    if(!pricingPackageState.value) initializePricingPackageData()
})

</script>

<template>
    <div class="min-h-screen bg-amalan-white relative">
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="backButton" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full py-8 bg-amalan-white">
            <p class="px-6 text-center text-amalan-black text-xs sm:text-sm">Anda dapat meningkatkan paket layanan Anda<br>dengan memilih paket layanan yang tersedia.</p>
            <div class="w-full flex justify-center items-center mt-8">
                <div class="w-4/5">
                    <div class="w-full py-1.5 bg-amalan-blue-7 rounded-md flex items-center justify-center">
                        <p class="text-amalan-black text-xs">PAKET SAAT INI :</p>
                        <div class="flex items-center ml-1 bg-amalan-blue-1 rounded-md px-1">
                            <img :src="require('@/assets/img/rdp/kartu/' + PRICING_TYPE[userPricingPackage].icon)" class="h-2.5">
                            <span class="text-xs text-amalan-white ml-1.5 font-mono">{{ PRICING_TYPE[userPricingPackage].text }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full m-0 pb-10 px-0 pt-0">
            <div v-if="userPricingPackage !== 'DIAMOND' && !showBasicPackagePrevent" class="overflow-hidden" ref="carouselRoot">
                <div class="flex gap-x-6">
                    <template v-if="pricingPackageState">
                        <div v-for="(data, index) in pricingPackageState" :key="index" class="p-0 text-amalan-black flex flex-col" style="flex: 0 0 80%;">
                            <div class="w-full flex-none flex flex-col justify-center items-center border-t border-r border-l rounded-t-lg py-5 relative" :class="[ data.package_name === 'gold_package' || data.package_name === 'diamond_package' ? 'border-amalan-blue-1 bg-amalan-blue-1' : 'border-amalan-blue-7 bg-amalan-blue-7']">
                                <div v-if="data.ribbon" class="absolute right-0 top-0 overflow-hidden w-20 h-20"><span class="py-1 text-xs font-bold text-amalan-blue-1 bg-amalan-yellow-400 text-center transform rotate-45 block absolute top-5 -right-8" style="width: 150%;">{{ data.ribbon }}</span></div>
                                <p class="text-lg" :class="[ data.package_name === 'gold_package' || data.package_name === 'diamond_package' ? 'text-amalan-white' : 'text-amalan-blue-1']">{{ data.package_name.charAt(0).toUpperCase() + data.package_name.replace('_package', '').slice(1) }}</p>
                                <p class="text-xl font-bold mt-2" :class="[ data.package_name === 'gold_package' || data.package_name === 'diamond_package' ? 'text-amalan-white' : 'text-amalan-blue-1']">{{ $filters.format_number(data.signup_fee - signUpFeeAmountPaid) }}</p>
                                <div class="flex justify-center items-center gap-x-1 cursor-pointer" @click="showModal('Biaya Pendaftaran', feeDescription.registrationFee)">
                                    <p class="text-2xs" :class="[ data.package_name === 'gold_package' || data.package_name === 'diamond_package' ? 'text-amalan-gray-5' : 'text-amalan-blue-3']">Biaya Pendaftaran</p>
                                    <QuestionMarkCircleIcon class="w-3.5" :class="[ data.package_name === 'gold_package' || data.package_name === 'diamond_package' ? 'text-amalan-gray-5' : 'text-amalan-blue-4']" />
                                </div>
                                <div class="mt-4 flex gap-x-1 justify-center items-center">
                                    <p class="text-xs line-through" :class="[ data.package_name === 'gold_package' || data.package_name === 'diamond_package' ? 'text-amalan-white' : 'text-amalan-black']">{{ $filters.format_number(data.discount_on_success_fee + data.success_fee) }}</p>
                                    <div class="px-1.5 py-0.5 text-amalan-white text-xs font-bold bg-amalan-green-light rounded-md border-none">Diskon {{ data.discount_CCKTA_success_fee_percentage }}%</div>
                                </div>
                                <p class="text-base font-bold mt-1" :class="[ data.package_name === 'gold_package' || data.package_name === 'diamond_package' ? 'text-amalan-white' : 'text-amalan-blue-1']">{{ $filters.format_number(data.success_fee) }}</p>
                                <div class="flex justify-center items-center gap-x-1 cursor-pointer" @click="showModal('Biaya Keberhasilan', feeDescription.successFee)">
                                    <p class="text-2xs" :class="[ data.package_name === 'gold_package' || data.package_name === 'diamond_package' ? 'text-amalan-gray-5' : 'text-amalan-blue-3']">Biaya Keberhasilan</p>
                                    <QuestionMarkCircleIcon class="w-3.5" :class="[ data.package_name === 'gold_package' || data.package_name === 'diamond_package' ? 'text-amalan-gray-5' : 'text-amalan-blue-4']" />
                                </div>
                            </div>
                            <div class="w-full flex-grow border-r border-l bg-amalan-white py-5 px-4">
                                <div class="grid grid-cols-1 gap-y-2">
                                    <div class="flex gap-x-1.5 justify-start items-center">
                                        <div class="w-10 text-center py-0.5 text-amalan-white text-4xs font-bold bg-amalan-blue-8 rounded-md border-none">Standar</div>
                                        <p class="text-xs text-amalan-black">Keringanan Pelunasan</p>
                                    </div>
                                    <template v-for="(service, index) in data.services">
                                        <div v-if="service.addOn || service.included" :key="index" class="flex gap-x-1.5 justify-start items-center">
                                            <div class="w-10 text-center py-0.5 text-4xs font-bold rounded-md border-none" :class="[ service.included ? 'bg-amalan-green text-amalan-white' : 'bg-amalan-yellow-800 text-amalan-black' ]">{{ service.included ? 'Gratis' : 'Add-On' }}</div>
                                            <div class="flex justify-center items-center gap-x-1 cursor-pointer" @click="showModal(benefitConfig[service.service_name].name_text, benefitConfig[service.service_name].description)">
                                                <p class="text-xs text-amalan-blue-3">{{ removeItalicTag(benefitConfig[service.service_name].name_text) }}</p>
                                                <QuestionMarkCircleIcon class="w-3.5 text-amalan-blue-4" /> 
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="w-full flex-none flex justify-center items-center border-b border-r border-l rounded-b-lg bg-amalan-gray-200 py-5 px-4">
                                <button @click="selectPackage(data.package_name)" class="w-full py-2 text-center border border-amalan-blue-1 rounded-amalan-lg text-amalan-blue-1 text-sm font-bold hover:bg-amalan-gray-5">Pilih Paket Layanan</button>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <LoadingDataWithoutMargin class="mt-32" />
                    </template>
                </div>
                <div v-if="pricingPackageState" class="w-full flex justify-center items-center gap-x-2 mt-4">
                    <div v-for="(data, index) in pricingPackageState" :key="index" class="h-1.5 rounded-full cursor-pointer transition-all duration-500 ease-in-out" :class="[ selectedCarouselIndex === index ? carouselDotsClass.active : carouselDotsClass.default ]" @click="emblaApi.scrollTo(index)"></div>
                </div>
            </div>
            <div v-if="userPricingPackage === 'DIAMOND'" class="w-full px-6 flex flex-col justify-center items-center pt-30">
                <img src="@/assets/img/clipboard-v2.svg" class="w-2/6 mx-auto">
                <p class="text-center text-xs mt-4">Mohon maaf, Anda saat ini sudah berada pada<br>paket layanan <b>Diamond</b> dan belum tersedia paket<br>layanan lain untuk melakukan peningkatan.</p>
            </div>
            <div v-if="showBasicPackagePrevent" class="w-full px-6 flex flex-col justify-center items-center pt-30">
                <img src="@/assets/img/clipboard-v2.svg" class="w-2/6 mx-auto">
                <p class="text-center text-xs mt-4">Mohon maaf, saat ini Anda berada pada<br>paket layanan <b>Basic</b>.<br><br>Untuk melakukan peningkatan paket layanan, mohon menghubungi konsultan kami terlebih dahulu. </p>
                <button @click="goToSupport" type="button" class="w-full text-center py-3 px-2 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-8 text-amalan-white text-sm font-bold">Hubungi Konsultan</button>
            </div>
        </div>
        <BasicModal :visible="modalComponent.visible" :title="modalComponent.title" :content="modalComponent.description" @on-close="hideModal" />
        <loading-overlay v-if="isLoading" />
    </div>
</template>