<script setup>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import moment from 'moment'

const store = useStore()
const router = useRouter()

const userStage = computed(() => store.getters['user/getUserStage'])
const signUpStatus = computed(() => store.getters['contact/getSignUpStatus'])
const billCompleted = computed(() => store.getters['docCollection/getbillStatus'])
const docCompleted = computed(() => store.getters['docCollection/getDocStatus'])
const poaDocument = computed(() => store.getters['user/getDocumentPoa'])
const amalanProtectPurchaseList = computed(() => store.getters['formAmalanProtect/getPurchaseList'])
const servicePurchaseList = computed(() => store.getters['services/getServices'])
const additionalSUFPurchaseList = computed(() => store.getters['additionalSUF/getHistoryAdditionalSuf'])
const upgradePricingPackagePurchaseList = computed(() => store.getters['upgradePricingPackage/getHistoryUpgradePackage'])
const negotiationResultHistories = computed(() => store.getters['negotiationResult/getNegotiationResultHistory'])

const formatDateTime = (dateTime) => moment(dateTime).format('DD MMMM YYYY | HH.mm')

const goToSupport = (productName, purchase) => {
    window.open(`https://wa.me/6285888236207?text=Saya sudah ${purchase ? 'melakukan pembelian' : 'menyelesaikan proses'} ${productName} di web.amalan.com`, '_blank')
}

const goToAmalanProtectDetail = (id) => {
    router.push(`/amalan-protect/${id}/detail`)
}

onMounted(() => {
    store.dispatch('additionalSUF/requestPurchaseHistory')
    store.dispatch('upgradePricingPackage/requestPurchaseHistory')
    store.dispatch('formAmalanProtect/requestPurchaseList')
    store.dispatch('services/requestServices')
})

</script>

<template>
    <div class="w-full h-screen-80 px-2 pb-2 mt-4 overflow-y-scroll scrollbar-hide">
        <div v-if="userStage === 'lq-profile'" class="w-full p-4 mt-4 shadow-amalan-sm bg-amalan-white hover:bg-amalan-gray-5 rounded-lg cursor-pointer">
            <p class="text-xs text-amalan-black">Anda telah berhasil melengkapi proses <span class="font-bold">Registrasi Program Keringanan Utang</span>. Jika Anda membutuhkan bantuan silahkan klik <a href="#" @click="goToSupport('Registrasi Program Keringanan Utang', false)" class="text-amalan-blue-3 font-semibold underline">DISINI</a></p>
        </div>
        <div v-if="signUpStatus === 200" class="w-full p-4 mt-4 shadow-amalan-sm bg-amalan-white hover:bg-amalan-gray-5 rounded-lg cursor-pointer">
            <p class="text-xs text-amalan-black">Anda telah berhasil melengkapi proses <span class="font-bold">Sign Up Program Keringanan Utang</span>. Jika Anda membutuhkan bantuan silahkan klik <a href="#" @click="goToSupport('Sign Up Program Keringanan Utang', false)" class="text-amalan-blue-3 font-semibold underline">DISINI</a></p>
        </div>
        <div v-if="(billCompleted && docCompleted)" class="w-full p-4 mt-4 shadow-amalan-sm bg-amalan-white hover:bg-amalan-gray-5 rounded-lg cursor-pointer">
            <p class="text-xs text-amalan-black">Anda telah berhasil melengkapi dokumen pada <span class="font-bold">Daftar Dokumen</span>. Jika Anda membutuhkan bantuan silahkan klik <a href="#" @click="goToSupport('Daftar Dokumen', false)" class="text-amalan-blue-3 font-semibold underline">DISINI</a></p>
        </div>
        <div v-if="(poaDocument && poaDocument.status)" class="w-full p-4 mt-4 shadow-amalan-sm bg-amalan-white hover:bg-amalan-gray-5 rounded-lg cursor-pointer">
            <p class="text-xs text-amalan-black">Anda telah berhasil melakukan <span class="font-bold">Pembukaan Rekening Dana Pelunasan (RDP)</span>. Surat Kuasa (POA) sudah dikirimkan ke email Anda. Jika Anda membutuhkan bantuan silahkan klik <a href="#" @click="goToSupport('Pembukaan Rekening Dana Pelunasan (RDP)', false)" class="text-amalan-blue-3 font-semibold underline">DISINI</a></p>
            <p v-if="poaDocument.created_at" class="text-2xs text-amalan-gray-2 mt-4 text-right">{{ formatDateTime(poaDocument.created_at) }}</p>
        </div>
        <template v-if="(upgradePricingPackagePurchaseList.done.length > 0)">
            <div v-for="(data, index) in upgradePricingPackagePurchaseList.done" class="w-full p-4 mt-4 shadow-amalan-sm bg-amalan-white hover:bg-amalan-gray-5 rounded-lg cursor-pointer" :key="index">
                <p v-if="data.Pricing_Package_Payment__r.records[0].Payment_Status__c === 'Done'" class="text-xs text-amalan-black">Anda telah berhasil melakukan pembayaran <span class="font-bold">Peningkatan Paket Layanan</span>. Jika Anda membutuhkan bantuan silahkan klik <a href="#" @click="goToSupport('peningkatan paket layanan', true)" class="text-amalan-blue-3 font-semibold underline">DISINI</a></p>
                <p v-else class="text-xs text-amalan-black">Pembayaran Anda untuk <span class="font-bold">Peningkatan Paket Layanan</span>, gagal/kadaluarsa.</p>
                <p v-if="data.CreatedDate" class="text-2xs text-amalan-gray-2 mt-4 text-right">{{ formatDateTime(data.CreatedDate) }}</p>
            </div>
        </template>
        <template v-if="(additionalSUFPurchaseList.done.length > 0)">
            <div v-for="(data, index) in additionalSUFPurchaseList.done" class="w-full p-4 mt-4 shadow-amalan-sm bg-amalan-white hover:bg-amalan-gray-5 rounded-lg cursor-pointer" :key="index">
                <p v-if="data.Payment_Status__c === 'Paid'" class="text-xs text-amalan-black">Anda telah berhasil melakukan pembayaran <span class="font-bold">{{ data.Opportunities__r ? 'Penambahan Data Pinjaman Baru' : 'Perubahan Data Pinjaman' }}</span>. Jika Anda membutuhkan bantuan silahkan klik <a href="#" @click="goToSupport('additional SUF', true)" class="text-amalan-blue-3 font-semibold underline">DISINI</a></p>
                <p v-else class="text-xs text-amalan-black">Pembayaran Anda untuk <span class="font-bold">{{ data.Opportunities__r ? 'Penambahan Data Pinjaman Baru' : 'Perubahan Data Pinjaman' }}</span>, gagal/kadaluarsa.</p>
                <p v-if="data.CreatedDate" class="text-2xs text-amalan-gray-2 mt-4 text-right">{{ formatDateTime(data.CreatedDate) }}</p>
            </div>
        </template>
        <template v-if="(amalanProtectPurchaseList.done.length > 0)">
            <div v-for="(data, index) in amalanProtectPurchaseList.done" class="w-full p-4 mt-4 shadow-amalan-sm bg-amalan-white hover:bg-amalan-gray-5 rounded-lg cursor-pointer" :key="index" @click="goToAmalanProtectDetail(data.Id)">
                <p class="text-xs text-amalan-black">Anda telah berhasil melakukan pembelian produk <span class="font-bold">amalanPROTECT</span>. Jika Anda membutuhkan bantuan silahkan klik <a href="#" @click="goToSupport('amalanPROTECT', true)" class="text-amalan-blue-3 font-semibold underline">DISINI</a></p>
                <p v-if="data.CreatedDate" class="text-2xs text-amalan-gray-2 mt-4 text-right">{{ formatDateTime(data.CreatedDate) }}</p>
            </div>
        </template>
        <template v-if="(servicePurchaseList.done.length > 0)">
            <div v-for="(data, index) in servicePurchaseList.done" class="w-full p-4 mt-4 shadow-amalan-sm bg-amalan-white hover:bg-amalan-gray-5 rounded-lg cursor-pointer" :key="index">
                <p class="text-xs text-amalan-black">Anda telah berhasil melakukan pembelian produk <span class="font-bold">{{ data.service_catalog.name }}</span>. Dokumen {{ data.service_catalog.name }} sudah dikirim melalui Email Anda. Jika Anda membutuhkan bantuan silahkan klik <a href="#" @click="goToSupport(data.service_catalog.name, true)" class="text-amalan-blue-3 font-semibold underline">DISINI</a></p>
                <p v-if="data.created_at" class="text-2xs text-amalan-gray-2 mt-4 text-right">{{ formatDateTime(data.created_at) }}</p>
            </div>
        </template>
        <template v-if="(negotiationResultHistories.data.length > 0)">
            <div v-for="(data, index) in negotiationResultHistories.data" class="w-full p-4 mt-4 shadow-amalan-sm bg-amalan-white hover:bg-amalan-gray-5 rounded-lg cursor-pointer" :key="index">
                <p class="text-xs text-amalan-black">Anda telah berhasil melakukan Persetujuan Hasil Negosiasi Pinjaman Anda. Jika Anda membutuhkan bantuan silahkan klik <a href="#" @click="goToSupport('Persetujuan Hasil Negosiasi Pinjaman', false)" class="text-amalan-blue-3 font-semibold underline">DISINI</a></p>
                <p v-if="data.Opportunity_Approval__r" class="text-2xs text-amalan-gray-2 mt-4 text-right">{{ data.Opportunity_Approval__r.records[0].Customer_Feedback_Timestamp__c ? formatDateTime(data.Opportunity_Approval__r.records[0].Customer_Feedback_Timestamp__c) : '-' }}</p>
            </div>
        </template>
        <div v-if="userStage !== 'lq-profile' && signUpStatus !== 200 && !billCompleted && !docCompleted && (!poaDocument || !poaDocument.status) && amalanProtectPurchaseList.done.length === 0 && servicePurchaseList.done.length === 0" class="w-full h-full flex justify-center items-center">
            <p class="text-xs text-amalan-black text-center">Anda belum memiliki<br>riwayat notifikasi untuk saat ini</p>
        </div>
    </div>
</template>

<style scoped>
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
</style>