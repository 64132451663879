<template>
    <div class="relative">
        <nav class="bg-amalan-blue-7 mb-4">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="backButton" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold mt-0 text-amalan-black">{{ $route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <SampleKtp :changeVisibility="sampleKtp" @upload="showKTPCamera" />
        <SampleSelfie :changeVisibility="sampleSelfie" @upload="showSelfieCamera" />
        <div class="mx-4">
            <div class="pb-10 pt-3 w-full max-w-screen-md mx-auto">
                <div class="flex items-center">
                    <div class="flex items-center text-amalan-blue-1 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/ktp-active.svg" class="w-7 h-7">
                        </div>
                        <div class="absolute -top-2 -ml-10 text-center mt-16 w-32 text-xs font-bold text-amalan-blue-1"><p>e-KTP &<br>Swafoto</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-amalan-gray-3"></div>
                    <div class="flex items-center text-amalan-gray-3 relative">
                        <div class="rounded-full h-12 w-12 border-2 bg-amalan-gray-5 border-amalan-gray-5 flex justify-center items-center">
                            <img src="@/assets/img/lengkapi-data.svg" class="w-5 h-5">
                        </div>
                        <div class="absolute -top-2 -ml-10 text-center mt-16 w-32 text-xs text-amalan-gray-2"><p>Lengkapi<br>Data</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-amalan-gray-3"></div>
                    <div class="flex items-center text-amalan-gray-3 relative">
                        <div class="rounded-full h-12 w-12 border-2 bg-amalan-gray-5 border-amalan-gray-5 flex justify-center items-center">
                            <img src="@/assets/img/verified.svg" class="w-5 h-5">
                        </div>
                        <div class="absolute -top-2 -ml-4 text-center mt-16 w-20 text-xs text-amalan-gray-2"><p>Konfirmasi<br>Data</p></div>
                    </div>
                </div>
            </div>
            <div class="my-4 pb-12 w-full max-w-screen-md mx-auto relative">
                <Form
                    @submit="nextStep"
                    :validation-schema="updateForm"
                    v-slot="{ handleSubmit, errors, meta }"
                    >
                    <template v-if="currentStep === 0">
                        <div class="pt-2 text-amalan-black">
                            <p v-if="pictureTaken.fileKTP.objectURL" class="mt-1 text-xs sm:text-sm text-center">Foto <b>e-KTP</b> Anda sudah berhasil di unggah!</p>
                            <p v-else class="mt-1 text-xs sm:text-sm text-center">
                                Ambil foto <b>e-KTP</b> Anda untuk verifikasi data
                            </p>
                        </div>
                        <div class="my-4 overflow-hidden rounded-lg isolate" v-if="takePictureKTP && !cameraErrorMessage">
                            <camera
                                :autoplay="false"
                                :resolution="resolutionKtpCamera()"
                                ref="cameraForKtp"
                                @error="errorCallbackCamera"
                                @loading="loadingCamera"
                                @started="startedCamera"
                            >
                                <loading-data-without-margin v-if="cameraLoading" class="mt-20" />
                                <template v-else>
                                    <KtpGuideMask v-if="isMobile" :width="GuideMasking.ktp.width" :height="GuideMasking.ktp.height" />
                                    <div class="bg-amalan-black bg-opacity-70 w-full h-16 flex justify-center items-center bottom-0 absolute">
                                        <div class="w-14 h-14 bg-amalan-gray-3 border-4 border-amalan-white rounded-full flex justify-center items-center cursor-pointer" @click="snapshotKTP"></div>
                                    </div>
                                </template>
                            </camera>
                        </div>
                        <div class="my-4" v-else>
                            <label class="block rounded-xl h-56 py-16 border-2 border-dashed border-amalan-gray-3 hover:bg-amalan-gray-5 relative" :class="[ formValues.img_ktp ? 'cursor-default' : 'cursor-pointer' ]" @click="ktpInputClick">
                                <div v-if="pictureTaken.fileKTP.objectURL" class="absolute bg-amalan-white w-full h-full top-0 rounded-xl p-2 flex justify-center items-center">
                                    <img :src="pictureTaken.fileKTP.objectURL" class="max-h-full">
                                </div>
                                <div class="h-14 w-14 rounded-full bg-amalan-blue-1 flex justify-center items-center mx-auto">
                                    <PlusIcon class="text-amalan-white h-10 w-10"/>
                                </div>
                                <p class="text-center mt-4 text-sm text-amalan-black">Ambil foto <b>e-KTP</b> Anda disini!</p>
                            </label>
                            <Field type="file" id="ktpInput" name="ktp" class="hidden" v-model="formValues.img_ktp" />
                            <div v-if="errors.ktp" class="mt-1 mb-4 text-sm text-red-600">*{{errors.ktp}}</div>
                        </div>
                    </template>

                    <template v-if="currentStep === 1">
                        <div class="pt-2 text-amalan-black">
                            <p v-if="pictureTaken.fileSelfie.objectURL" class="mt-1 text-xs sm:text-sm text-center">Foto <b>Swafoto</b> Anda sudah berhasil di unggah!</p>
                            <p v-else class="mt-1 text-xs sm:text-sm text-center">
                                Ambil foto <b>Swafoto</b> Anda untuk verifikasi data
                            </p>
                        </div>
                        <div class="my-4 overflow-hidden rounded-lg isolate" v-if="takePictureSelfie && !cameraErrorMessage">
                            <camera
                                :autoplay="false"
                                :constraints="constraintsSelfieCamera()"
                                :facingMode="'user'"
                                ref="cameraForSelfie"
                                @error="errorCallbackCamera"
                                @loading="loadingCamera"
                                @started="startedCamera"
                            >
                                <loading-data-without-margin v-if="cameraLoading" class="mt-20" />
                                <template v-else>
                                    <SelfieGuideMask v-if="isMobile" :width="GuideMasking.selfie.width" :height="GuideMasking.selfie.height" />
                                    <div class="bg-amalan-black bg-opacity-70 w-full h-16 flex justify-center items-center bottom-0 absolute">
                                        <div class="w-14 h-14 bg-amalan-gray-3 border-4 border-amalan-white rounded-full flex justify-center items-center cursor-pointer" @click="snapshotSelfie"></div>
                                    </div>
                                </template>
                            </camera>
                        </div>
                        <div class="my-4" v-else>
                            <label class="block rounded-xl h-56 py-16 border-2 border-dashed border-amalan-gray-3 hover:bg-amalan-gray-5 relative" :class="[ formValues.img_selfie ? 'cursor-default' : 'cursor-pointer' ]" @click="selfieInputClick">
                                <div v-if="pictureTaken.fileSelfie.objectURL" class="absolute bg-amalan-white w-full h-full top-0 rounded-xl p-2 flex justify-center items-center">
                                    <img :src="pictureTaken.fileSelfie.objectURL" class="max-h-full">
                                </div>
                                <div class="h-14 w-14 rounded-full bg-amalan-blue-1 flex justify-center items-center mx-auto">
                                    <PlusIcon class="text-amalan-white h-10 w-10"/>
                                </div>
                                <p class="text-center mt-4 text-sm text-amalan-black">Ambil <b>Swafoto</b> Anda disini!</p>
                            </label>
                            <Field type="file" id="selfieInput" name="selfie" class="hidden" v-model="formValues.img_selfie" />
                            <div v-if="errors.selfie" class="mt-1 mb-4 text-sm text-red-600">*{{errors.selfie}}</div>
                        </div>
                    </template>

                    <template v-if="currentStep === 2">
                        <div class="pt-2">
                            <div class="my-3">
                                <label class="text-amalan-black font-bold text-sm">Gelar</label>
                                <div class="flex relative w-full">
                                    <Field name="title" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.title ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.title">
                                        <option v-for="(value, key) in dataTitle" :key="key" :value="value">{{ value }}</option>
                                    </Field>
                                    <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-3 text-amalan-black">
                                        <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div v-if="errors.title" class="mt-1 text-2xs text-amalan-red">*{{errors.title}}</div>
                            </div>
                            <div class="my-3">
                                <label class="text-amalan-black font-bold text-sm">Nama Lengkap</label>
                                <Field name="name" v-model="formValues.name" :validateOnInput="true" :validateOnMount="true" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none uppercase" :class="[ errors.name ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                                <div v-if="errors.name" class="mt-1 text-2xs text-amalan-red">*{{errors.name}}</div>
                            </div>
                            <div class="my-3">
                                <label class="text-amalan-black font-bold text-sm">NIK</label>
                                <Field name="nik" v-model="formValues.nik" :validateOnInput="true" :validateOnMount="true" type="tel" maxlength="16" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.nik ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                                <div v-if="errors.nik" class="mt-1 text-2xs text-amalan-red">*{{errors.nik}}</div>
                            </div>
                            <div class="my-3 grid grid-cols-2 gap-x-4">
                                <div>
                                    <label class="text-amalan-black font-bold text-sm">Tempat Lahir</label>
                                    <Field name="birthplace" v-model="formValues.birthplace" :validateOnInput="true" :validateOnMount="true" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none uppercase" :class="[ errors.birthplace ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                                    <div v-if="errors.birthplace" class="mt-1 text-2xs text-amalan-red">*{{errors.birthplace}}</div>
                                </div>
                                <div>
                                    <label class="text-amalan-black font-bold text-sm">Tanggal Lahir</label>
                                    <Field name="birthdate" v-model="formValues.birthdate" :validateOnInput="true" :validateOnMount="true" type="date" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.birthdate ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                                    <div v-if="errors.birthdate" class="mt-1 text-2xs text-amalan-red">*{{errors.birthdate}}</div>
                                </div>
                            </div>
                            <div class="my-3">
                                <label class="text-amalan-black font-bold text-sm">Email</label>
                                <Field name="email" v-model="formValues.email" :validateOnInput="true" :validateOnMount="true" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.email ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                                <div v-if="errors.email" class="mt-1 text-2xs text-amalan-red">*{{errors.email}}</div>
                            </div>
                        </div>
                    </template>

                    <div class="w-full mt-24 pb-20">
                        <button v-if="currentStep !== 0" type="button" class="h-10 rounded-lg font-medium border border-solid cursor-pointer text-center text-base py-1 px-1 text-amalan-blue-1 border-amalan-blue-1 hover:bg-amalan-blue-1 hover:text-amalan-white float-left" @click="prevStep"><ChevronLeftIcon class="h-full" /></button>
                        <button v-if="currentStep === 0" type="submit" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-1 float-right" :class="[ formValues.img_ktp ? 'cursor-pointer text-amalan-white bg-amalan-blue-1 border-amalan-blue-1 hover:bg-blue-900 hover:border-blue-900' : 'text-amalan-gray-3 bg-amalan-gray-5 border-amalan-gray-5 cursor-not-allowed' ]" :disabled="!formValues.img_ktp" @submit="handleSubmit"><ChevronRightIcon class="h-full" /></button>
                        <button v-if="currentStep === 1" type="submit" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-1 float-right" :class="[ formValues.img_selfie ? 'cursor-pointer text-amalan-white bg-amalan-blue-1 border-amalan-blue-1 hover:bg-blue-900 hover:border-blue-900' : 'text-amalan-gray-3 bg-amalan-gray-5 border-amalan-gray-5 cursor-not-allowed' ]" :disabled="!formValues.img_selfie" @submit="handleSubmit"><ChevronRightIcon class="h-full" /></button>
                        <button v-if="currentStep === 2" type="submit" class="h-10 rounded-lg font-medium border border-solid text-center text-sm py-1 px-8 float-right" :class="[ meta.valid ? 'cursor-pointer text-amalan-white bg-amalan-blue-1 border-amalan-blue-1 hover:bg-blue-900 hover:border-blue-900' : 'text-amalan-gray-3 bg-amalan-gray-5 border-amalan-gray-5 cursor-not-allowed' ]" :disabled="!meta.valid">Kirim</button>
                    </div>
                </Form>
            </div>
        </div>
        <loading-overlay-check-id v-if="isLoading" />
        <template v-if="showKtpPreview">
            <div class="fixed inset-0 z-50 bg-gray-900 bg-opacity-75 grid grid-cols-1 p-3 sm:p-10 overflow-y-auto">
                <div class="bg-amalan-white rounded-lg w-full sm:w-8/12 place-self-center">
                    <div class="bg-amalan-blue-7 rounded-t-lg py-2"> 
                        <p class="text-center text-base font-bold text-amalan-black">Pratinjau</p>
                    </div>
                    <div class="w-full p-4">
                        <img :src="pictureTaken.fileKTP.objectURL" class="w-full sm:w-8/12 sm:mx-auto">
                    </div>
                    <div class="flex justify-between">
                        <button type="button" class="w-6/12 rounded-bl-lg py-2 text-sm font-bold text-amalan-blue-1 bg-amalan-white border border-amalan-blue-1 hover:text-amalan-white hover:bg-amalan-blue-1" @click="showKtpPreview = !showKtpPreview">Pilih Kembali</button>
                        <button type="button" class="w-6/12 rounded-br-lg py-2 text-sm font-bold text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-blue-900" @click="unggahKtp">Unggah</button>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="showSelfiePreview">
            <div class="fixed inset-0 z-50 bg-gray-900 bg-opacity-75 grid grid-cols-1 p-3 sm:p-10 overflow-y-auto">
                <div class="bg-amalan-white rounded-lg w-full sm:w-8/12 place-self-center">
                    <div class="bg-amalan-blue-7 rounded-t-lg py-2"> 
                        <p class="text-center text-base font-bold text-amalan-black">Pratinjau</p>
                    </div>
                    <div class="w-full p-4">
                        <img :src="pictureTaken.fileSelfie.objectURL" class="w-full sm:w-8/12 sm:mx-auto">
                    </div>
                    <div class="flex justify-between">
                        <button type="button" class="w-6/12 rounded-bl-lg py-2 text-sm font-bold text-amalan-blue-1 bg-amalan-white border border-amalan-blue-1 hover:text-amalan-white hover:bg-amalan-blue-1" @click="showSelfiePreview = !showSelfiePreview">Pilih Kembali</button>
                        <button type="button" class="w-6/12 rounded-br-lg py-2 text-sm font-bold text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-blue-900" @click="unggahSelfie">Unggah</button>
                    </div>
                </div>
            </div>
        </template>
        <loading-overlay v-if="getDataLoading" />
    </div>
</template>

<script>
import { defineComponent, ref, inject, reactive, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import axios from "@/axios"
import router from "@/router"
import { Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { ChevronLeftIcon, ChevronRightIcon, PlusIcon } from '@heroicons/vue/solid'
import SampleKtp from '@/views/Akun/CheckId/SampleImageKtp.vue'
import SampleSelfie from '@/views/Akun/CheckId/SampleImageSelfie.vue'
import moment from 'moment'
import Camera from "simple-vue-camera"
import getBrowser from "@/composables/getBrowserInfo"
import localStorageExpiry from "@/composables/localStorageExpiry"
import SelfieGuideMask from '@/components/icon/SelfieGuideMask.vue'
import KtpGuideMask from '@/components/icon/KtpGuideMask.vue'
import amalansignupApi from "@/axios/amalansignup"

export default defineComponent({
    components:{
        Form,
        Field,
        PlusIcon,
        ChevronLeftIcon, 
        ChevronRightIcon,
        SampleKtp,
        SampleSelfie,
        Camera,
        SelfieGuideMask,
        KtpGuideMask
    },
    setup() {
        const store = useStore()
        const swal = inject("$swal")
        const formValues = reactive({
            img_ktp: null,
            img_selfie: null,
            birthdate: null,
            birthplace: null,
            name: null,
            nik: null,
            title: null,
            email: null
        });
        const currentStep = ref(0)
        const FILE_SIZE = 49152 * 1024
        const SUPPORTED_FORMATS = [
            "image/jpg",
            "image/jpeg",
            "image/png"
        ]
        const dataTitle = ['Bpk', 'Ibu']
        const minDate = moment().subtract(100, 'years').format('YYYY-MM-DD')
        const maxDate = moment().subtract(17, 'years').format('YYYY-MM-DD')
        const dataUser = computed(() => store.getters['user/getUser'])
        const idCardUser = computed(() => store.getters['user/getIdCard'])
        const filters = inject("Filters")
        const isLoading = ref(false)
        const getDataLoading = ref(false)
        const sampleKtp = ref(false)
        const sampleSelfie = ref(false)
        const showKtpPreview = ref(false)
        const showSelfiePreview = ref(false)
        const isMobile = ref(false)
        const cameraForKtp = ref()
        const cameraForSelfie = ref()
        const snapshotURLKTP = ref(null)
        const cameraErrorMessage = ref(null)
        const cameraLoading = ref(false)
        const takePictureKTP = ref(false)
        const takePictureSelfie = ref(false)
        const browserName = ref(null)
        const GuideMasking = ref({
            selfie: {
                width: 318,
                height: 435,
                multiplyWith: 1.368
            },
            ktp: {
                width: 318,
                height: 260,
                multiplyWith: 0.818
            }
        })
        const constraintsSelfieCamera = () => {
            return {
                video: {
                    width: isMobile.value ? 1472 : 1080,
                    height: isMobile.value ? 1080 : 1430
                }
            }
        }
        const resolutionKtpCamera = () => {
            return { 
                width: isMobile.value ? 1080 : 1080,
                height: isMobile.value ? 1472 : 1430
            }
        }
        const pictureTaken = ref({
            fileKTP: {
                blob: null,
                objectURL: null
            },
            fileSelfie: {
                blob: null,
                objectURL: null
            }
        })

        watch(() => idCardUser.value, () => {
            formValues.nik = idCardUser.value ? idCardUser.value.number : null
        })

        const schemas = [
            yup.object({
                ktp: yup.mixed().required("Pilih file gambar.")
                .test(
                    "fileSize",
                    "Ukuran file maksimal 48 MB.",
                    (value) => {
                        if (value) return value.size <= FILE_SIZE
                    }
                )
                .test(
                    "fileFormat",
                    "Mohon pilih file gambar.",
                    (value) => {
                        if (value) return SUPPORTED_FORMATS.includes(value.type)
                    }
                ),
            }),
            yup.object({
                selfie: yup.mixed().required("Pilih file gambar.")
                .test(
                    "fileSize",
                    "Ukuran file maksimal 48 MB.",
                    (value) => {
                        if (value) return value.size <= FILE_SIZE
                    }
                )
                .test(
                    "fileFormat",
                    "Mohon pilih file gambar.",
                    (value) => {
                        if (value) return SUPPORTED_FORMATS.includes(value.type)
                    }
                )
            }),
            yup.object({
                title: yup.string().nullable().required('Silahkan pilih title').oneOf(dataTitle, 'Silahkan pilih title'),
                nik: yup.string().nullable().required('NIK tidak boleh kosong').matches(/^[0-9]+$/, "NIK harus berupa angka").length(16, "NIK harus 16 digit"),
                name: yup.string().nullable().required('Nama lengkap tidak boleh kosong').max(50, "Maksimal 50 karakter"),
                email: yup.string().nullable().required('Silahkan isi sesuai alamat email yang kamu gunakan ya!').email('Email tidak valid').max(255, "Maksimal 255 karakter"),
                birthplace: yup.string().nullable().required('Tempat lahir tidak boleh kosong').max(30, "Maksimal 30 karakter"),
                birthdate: yup.date().nullable().required('Silahkan pilih tanggal lahir').min(minDate,"Pengguna maksimal berumur 100 tahun").max(maxDate,"Pastikan tanggal lahir sesuai KTP"),
            })
        ];

        const updateForm = computed(() => {
            return schemas[currentStep.value]
        });

        const swalError = swal.mixin({
            customClass: {
                confirmButton: 'bg-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-amalan-white text-sm font-semibold hover:bg-blue-900 hover:border-blue-900',
                cancelButton: 'absolute -top-3 -right-3'
            },
            buttonsStyling: false,
            title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        const swalCameraError = swal.mixin({
            customClass: {
                confirmButton: 'bg-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-amalan-white text-sm font-semibold hover:bg-blue-900 hover:border-blue-900',
                cancelButton: 'bg-amalan-white mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-amalan-blue-1 text-sm font-semibold hover:bg-amalan-red hover:border-amalan-red hover:text-amalan-white'
            },
            buttonsStyling: false,
            title: '<img src="/img/camera-error-icon.svg" class="w-12 h-12 mx-auto">',
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        const nextStep = (values) => {
            if (currentStep.value === 2) {
                if(localStorageExpiry.validateData('fr-limit', moment().format('YYYY-MM-DD HH:mm:ss'))){
                    swalError.fire({
                        html: '<p class="text-center text-sm mt-2">Mohon maaf Pembukaan Rekening Dana Pelunasan untuk hari ini telah mencapai batas permintaan. Silakan coba lagi besok!<br><br>Atau tekan tombol bantuan dibawah untuk info lebih lanjut!</p>',
                        showCancelButton: true,
                        confirmButtonText: 'Bantuan',
                        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                    }).then((result) => {
                        if (result.isConfirmed){
                            window.location = 'https://wa.me/6285888236207?text=' + 
                            'Halo. Proses Pembukaan Rekening Dana Pelunasan (RDP) Saya terhenti karena mencapai batas permintaan. Mohon bantuannya.';
                            return;
                        }
                    });
                    return;
                }

                isLoading.value = true;
                Object.assign(formValues, values);
                let data = new FormData();
                data.append('img_ktp', formValues.img_ktp);
                data.append('img_selfie', formValues.img_selfie);
                data.append('title', formValues.title);
                data.append('name', formValues.name.toUpperCase());
                data.append('nik', formValues.nik);
                data.append('email', formValues.email);
                data.append('birthplace', formValues.birthplace.toUpperCase());
                data.append('birthdate', filters.dateFormatDayFirst(formValues.birthdate));
                axios.post('/bni/kyc', data,{
                    headers: {
                        'Accept': 'multipart/form-data',
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(() => {
                    store.dispatch('user/storeUser').then(() => {
                        router.push({ name: 'rdp opening - onboarding 2' });
                    });
                })
                .catch((err) => {
                    isLoading.value = false;
                    if(err.response.status == 400 || err.response.status == 422 ){
                        if(err.response.data.message.includes('Face not detected')){
                            pictureTaken.value.fileKTP.objectURL = null;
                            pictureTaken.value.fileSelfie.objectURL = null;
                            currentStep.value = 0;
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Maaf, Pastikan anda mengunggah foto yang mengandung wajah.</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Pastikan anda mengunggah foto yang mengandung wajah.';
                                    return;
                                }
                            });
                        } else if(err.response.data.message.includes('KTP not detected')){
                            pictureTaken.value.fileKTP.objectURL = null;
                            pictureTaken.value.fileSelfie.objectURL = null;
                            currentStep.value = 0;
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Maaf, Pastikan anda mengunggah foto e-KTP pada tempat yang sudah disediakan.</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Pastikan anda mengunggah foto e-KTP pada tempat yang sudah disediakan.';
                                    return;
                                }
                            });
                        } else if(err.response.data.message.includes('threshold')){
                            pictureTaken.value.fileKTP.objectURL = null;
                            pictureTaken.value.fileSelfie.objectURL = null;
                            currentStep.value = 0;
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Maaf, Sepertinya skor kecocokan pada foto e-KTP dan Swafoto anda tidak memenuhi persyaratan.</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Sepertinya skor kecocokan pada foto e-KTP dan Swafoto anda tidak memenuhi persyaratan.';
                                    return;
                                }
                            });
                        } else if(err.response.data.message.includes('id_cards_number_unique')){
                            pictureTaken.value.fileKTP.objectURL = null;
                            pictureTaken.value.fileSelfie.objectURL = null;
                            currentStep.value = 0;
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Maaf, Data KTP Anda terdeteksi sudah terdaftar sebelumnya. Silahkan tekan tombol bantuan dibawah ini atau hubungi konsultan Anda.</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Mohon bantuan saya tidak dapat melanjutkan proses karena KTP saya sudah terdaftar di akun lain.';
                                    return;
                                }
                            });
                        } else if(err.response.data.message.includes('Face liveness is not passed')){
                            pictureTaken.value.fileKTP.objectURL = null;
                            pictureTaken.value.fileSelfie.objectURL = null;
                            currentStep.value = 0;
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Maaf, Pastikan anda mengunggah foto yang mengandung wajah asli.</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Pastikan anda mengunggah foto yang mengandung wajah asli.';
                                    return;
                                }
                            });
                        } else if(err.response.data.message.includes('Id check is not passed caused by NIK')){
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Maaf, Pastikan NIK yang anda masukan sesuai dengan KTP anda.</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Pastikan NIK yang anda masukan sesuai dengan KTP anda.';
                                    return;
                                }
                            });
                        } else if(err.response.data.message.includes('Id check is not passed caused by NAME')){
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Maaf, Pastikan nama lengkap yang anda masukan sesuai dengan KTP anda.</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Pastikan nama lengkap yang anda masukan sesuai dengan KTP anda.';
                                    return;
                                }
                            });
                        } else if(err.response.data.message.includes('Id check is not passed caused by BIRTH_DATE')){
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Maaf, Pastikan tanggal lahir yang anda masukan sesuai dengan KTP anda.</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Pastikan tanggal lahir yang anda masukan sesuai dengan KTP anda.';
                                    return;
                                }
                            });
                        } else if(err.response.data.message.includes('Id check is not passed caused by BIRTH_PLACE')){
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Maaf, Pastikan tempat lahir yang anda masukan sesuai dengan KTP anda.</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Pastikan tempat lahir yang anda masukan sesuai dengan KTP anda.';
                                    return;
                                }
                            });
                        } else if(err.response.data.message.includes('registered cif cannot continue the process')){
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Maaf, pembukaan RDP Anda gagal karena NIK sudah pernah terdaftar sebelumnya di BNI. Klik tombol di bawah untuk menghubungi konsultan kami.</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Halo amalan, Mohon bantuan saya gagal membuka RDP dengan keterangan NIK sudah terdaftar di BNI. Terima kasih.';
                                    return;
                                }
                            });
                        } else if(err.response.data.message.includes('customers_email_unique')){
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Maaf, email yang kamu masukkan telah digunakan, pastikan email tersebut adalah email anda atau gunakan email lain.</p>',
                                showCancelButton: true,
                                showConfirmButton: false,
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            });
                        } else if(err.response.data.message.includes('Email is used by another NIK')){
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Alamat Email yang Anda masukan sudah digunakan di NIK yang lain, silahkan masukan Email yang berbeda!</p>',
                                showCancelButton: true,
                                showConfirmButton: false,
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            });
                        } else if(err.response.data.message.includes('Phone is used by another NIK')){
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Nomor HP yang Anda masukan sudah digunakan di NIK yang lain, silahkan tekan tombol bantuan dibawah untuk mendapatkan bantuan dari kami!</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Nomor HP yang Anda masukan sudah digunakan di NIK yang lain';
                                    return;
                                }
                            });
                        } else if(err.response.data.message.includes('Phone and email is used by another NIK')){
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Nomor HP dan Email yang Anda masukan sudah digunakan di NIK yang lain, silahkan tekan tombol bantuan dibawah untuk mendapatkan bantuan dari kami!</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Nomor HP dan Email yang Anda masukan sudah digunakan di NIK yang lain';
                                    return;
                                }
                            });
                        } else if(err.response.data.message.includes('FR lmit reached, please try again next day')){
                            const payload = {
                                value: 'FR lmit reached, please try again next day',
                                expiry: moment().format('YYYY-MM-DD') + ' 23:59:59'
                            }
                            localStorageExpiry.setData('fr-limit', payload)
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Mohon maaf Pembukaan Rekening Dana Pelunasan untuk hari ini telah mencapai batas permintaan. Silakan coba lagi besok!<br><br>Atau tekan tombol bantuan dibawah untuk info lebih lanjut!</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Halo. Proses Pembukaan Rekening Dana Pelunasan (RDP) Saya terhenti karena mencapai batas permintaan. Mohon bantuannya.';
                                    return;
                                }
                            });
                        } else if(err.response.data.message.includes('Maximum number of KYC attempts reached')){
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Mohon maaf sepertinya Anda mengalami masalah dalam pengecekan identitas. Anda tidak perlu khawatir, tim kami sudah mendapatkan laporan masalah ini.<br><br>Untuk memantau progress perbaikan, Anda dapat menanyakan pada tim kami melalui menu “Pesan” di bawah ini.</p>',
                                showCancelButton: false,
                                confirmButtonText: 'Hubungi Konsultan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (!result.isConfirmed) return
                                return router.push({ name: 'Pesan' })
                            });
                        } else {
                            pictureTaken.value.fileKTP.objectURL = null;
                            pictureTaken.value.fileSelfie.objectURL = null;
                            currentStep.value = 0;
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Maaf, Pastikan anda mengunggah foto e-KTP dan Swafoto sesuai contoh dan pastikan tidak mengunggah di tempat yang tertukar serta pastikan Nama, NIK, Tanggal Lahir, Tempat Lahir sesuai dengan KTP anda.</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Pastikan anda mengunggah foto e-KTP dan Swafoto sesuai contoh dan pastikan tidak mengunggah di tempat yang tertukar serta pastikan Nama, NIK, Tanggal Lahir, Tempat Lahir sesuai dengan KTP anda.';
                                    return;
                                }
                            });
                        }
                    }else{
                        pictureTaken.value.fileKTP.objectURL = null;
                        pictureTaken.value.fileSelfie.objectURL = null;
                        currentStep.value = 0;
                        swalError.fire({
                            html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                            showCancelButton: true,
                            confirmButtonText: 'Bantuan',
                            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                        }).then((result) => {
                            if (result.isConfirmed){
                                window.location = 'https://wa.me/6285888236207?text=' + 
                                'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya';
                                return;
                            }
                        });
                    }
                })
                return;
            }

            Object.assign(formValues, values);
            currentStep.value++;
        }

        const snapshotKTP = async () => {
            const cameraContainer = document.getElementById('camera-container')
            const customWidth = cameraContainer.offsetWidth
            const customHeight = cameraContainer.offsetHeight

            const blob = await cameraForKtp.value.snapshot(
                { 
                    width: isMobile.value ? browserName.value == 'firefox' ? customWidth * 2 : 1472 : 1430,
                    height: isMobile.value ? browserName.value == 'firefox' ? customHeight * 2 : 1080 : 1080
                },
                "image/png",
                0.5
            )

            pictureTaken.value.fileKTP.blob = blob
            pictureTaken.value.fileKTP.objectURL = URL.createObjectURL(blob)
            showKtpPreview.value = true
        }

        const snapshotSelfie = async () => {
            const cameraContainer = document.getElementById('camera-container')
            const customWidth = cameraContainer.offsetWidth
            const customHeight = cameraContainer.offsetHeight

            const blob = await cameraForSelfie.value.snapshot(
                { 
                    width: isMobile.value ? browserName.value == 'firefox' ? customWidth * 2 : 1080 : 1430, 
                    height: isMobile.value ? browserName.value == 'firefox' ? customHeight * 2 : 1472 : 1080
                },
                "image/png",
                0.5
            )

            pictureTaken.value.fileSelfie.blob = blob
            pictureTaken.value.fileSelfie.objectURL = URL.createObjectURL(blob)
            showSelfiePreview.value = true
        }

        const errorCallbackCamera = (error) => {
            cameraLoading.value = false
            callErrorPopUp(error)
        }

        const loadingCamera = () => {
            cameraLoading.value = true
        }
        
        const startedCamera = () => {
            const cameraContainer = document.getElementById('camera-container')
            GuideMasking.value.selfie.width = cameraContainer.offsetWidth
            GuideMasking.value.selfie.height = Math.round(cameraContainer.offsetWidth * GuideMasking.value.selfie.multiplyWith)
            GuideMasking.value.ktp.width = cameraContainer.offsetWidth
            GuideMasking.value.ktp.height = Math.round(cameraContainer.offsetWidth * GuideMasking.value.ktp.multiplyWith)
            cameraLoading.value = false
        }

        const showKTPCamera = () => {
            takePictureKTP.value = true
            cameraLoading.value = true
            setTimeout(() => {
                cameraForKtp.value.start()
            }, 1000);
        }

        const showSelfieCamera = () => {
            takePictureSelfie.value = true
            cameraLoading.value = true
            setTimeout(() => {
                cameraForSelfie.value.start()
            }, 1000);
        }

        const blobToFIle = (blobFile, fileName) => {
            blobFile.lastModifiedDate = new Date();
            blobFile.name = fileName
            return blobFile
        }

        const unggahKtp = () => {
            takePictureKTP.value = false
            showKtpPreview.value = false
            formValues.img_ktp = blobToFIle(pictureTaken.value.fileKTP.blob, 'ktp.png')
        }

        const unggahSelfie = () => {
            takePictureSelfie.value = false
            showSelfiePreview.value = false
            formValues.img_selfie = blobToFIle(pictureTaken.value.fileSelfie.blob, 'selfie.png')
        }

        const ktpInputClick = (e) => { 
            if(formValues.img_ktp){
                e.preventDefault()
            }else{
                if(sampleKtp.value == false){
                sampleKtp.value = true
                }else{
                    setTimeout(() => {
                        sampleKtp.value = true
                    }, 500)
                    sampleKtp.value = false
                }
            } 
        }

        const selfieInputClick = (e) => { 
            if(formValues.img_selfie){
                e.preventDefault()
            }else{
                if(sampleSelfie.value == false){
                    sampleSelfie.value = true
                }else{
                    setTimeout(()=>{
                        sampleSelfie.value = true
                    }, 500)
                    sampleSelfie.value = false
                }
            } 
        }

        const backButton = () => {
            if(sampleKtp.value){
                sampleKtp.value = false
            }else if(sampleSelfie.value){
                sampleSelfie.value = false
            }else{
                localStorage.removeItem('from-onboarding-go-to')
                router.push({ name: 'Home' })
            }
        }

        const prevStep = () => {
            if (currentStep.value <= 0) {
                return;
            }

            currentStep.value--
            takePictureSelfie.value = false
        }

        const callErrorPopUp = (msg) => {
            cameraErrorMessage.value = msg
            if(msg == 'camera-not-supported'){
                swalCameraError.fire({
                    html: '<p class="text-center text-sm mt-2"><span class="font-bold text-base text-amalan-blue-1">Tidak bisa menggunakan kamera</span><br><br>Browser Anda mungkin tidak memiliki akses ke kamera. Untuk mengatasi masalah ini, buka Pengaturan sistem</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: 'Muat Ulang',
                }).then((result) => {
                    if (result.isConfirmed){
                        window.location = 'https://wa.me/6285888236207?text=' + 
                        'Mohon bantuan Browser Saya mungkin tidak memiliki akses ke kamera'
                        return
                    }else{
                        window.location.reload()
                        return
                    }
                })
            }else{
                swalCameraError.fire({
                    html: '<p class="text-center text-sm mt-2"><span class="font-bold text-base text-amalan-blue-1">Izinkan aplikasi menggunakan kamera</span><br><br>Aplikasi memerlukan akses ke kamera Anda agar dapat mengambil foto</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: 'Muat Ulang',
                }).then((result) => {
                    if (result.isConfirmed){
                        window.location = 'https://wa.me/6285888236207?text=' + 
                        'Mohon bantuan Aplikasi mungkin tidak memiliki akses ke kamera'
                        return
                    }else{
                        window.location.reload()
                        return
                    }
                })
            }
        }

        const requestDocumentPoa = () => {
            getDataLoading.value = true
            amalansignupApi.get('customers/document-poa')
            .then(() => {
                getDataLoading.value = false
            })
            .catch((err) => {
                getDataLoading.value = false
                if(err.response.status === 404){
                    if(localStorage.getItem('rdp-opening')) localStorage.removeItem('rdp-opening')
                    router.push({ name : 'Home' })
                }
            })
        }

        onMounted(() => {
            store.dispatch('user/requestIdCard')
            requestDocumentPoa()
            formValues.title = dataUser.value.title
            formValues.name = ((dataUser.value.first_name || '') + ' ' + (dataUser.value.last_name || '')).trim()
            formValues.birthplace = dataUser.value.birthplace
            formValues.birthdate = dataUser.value.birthdate
            formValues.email = dataUser.value.email

            if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
                isMobile.value = window.matchMedia("only screen and (max-width: 480px)").matches
                if('userAgent' in navigator){
                    browserName.value = getBrowser()
                }
            }else{
                callErrorPopUp('camera-not-supported')
            }
        })

        return {
            formValues,
            updateForm,
            nextStep,
            prevStep,
            currentStep,
            SUPPORTED_FORMATS,
            FILE_SIZE,
            isLoading,
            dataTitle,
            dataUser,
            cameraForKtp,
            snapshotKTP,
            snapshotURLKTP,
            errorCallbackCamera,
            loadingCamera,
            startedCamera,
            constraintsSelfieCamera,
            showKTPCamera,
            cameraLoading,
            takePictureKTP,
            takePictureSelfie,
            blobToFIle,
            showKtpPreview,
            pictureTaken,
            unggahKtp,
            sampleKtp,
            ktpInputClick,
            backButton,
            selfieInputClick,
            cameraForSelfie,
            showSelfiePreview,
            snapshotSelfie,
            showSelfieCamera,
            sampleSelfie,
            unggahSelfie,
            resolutionKtpCamera,
            cameraErrorMessage,
            browserName,
            GuideMasking,
            isMobile,
            getDataLoading
        }
    },
})
</script>
