import axios from "../../axios";
import vm from "../../main";
import amalansignupApi from "../../axios/amalansignup"
import { getPartnerId, removePartnerId } from "@/composables/partnero";
import { useCrispInstance } from "@/composables/crisp";

const crisp = useCrispInstance()

const state = () => ({
    user: {
        user_id: null,
        title: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        is_whatsapp_phone_number: null,
        email: '',
        id_number : '',
        gender: '',
        birthplace: '',
        birthdate: '',
        sf_account_id: null,
        email_verified_at: null,
        ocr_app: null,
        compare_image: null,
        kyc_bypass: null,
        check_id: null,
        address: {
            id: null,
            street: '',
            rt: '',
            rw: '',
            perum: '',
            postal_code: '',
            province: {
                id: null,
                name: '',
            },
            city: {
                id: null,
                name: '',
            },
            district: {
                id: null,
                name: '',
            },
            village: {
                id: null,
                name: '',
            },
        },
        education: {
            id: null,
            name: '',
        },
        marital: {
            id: null,
            name: '',
        },
        occupation: {
            id: null,
            name: '',
        },
        religion: {
            id: null,
            name: '',
        },
        customer_stage: null
    },
    idCard: null,
    mother_maiden: null,
    documentPoa: null,
    profilePicture: null
});

const getters = {
    getUser: (state) => {
        return state.user;
    },
    getUserId(state) {
        return state.user.user_id;
    },
    getOCRStatus(state) {
        return state.user.ocr_app;
    },
    getCompareStatus(state) {
        return state.user.compare_image;
    },
    getKYCBypassStatus(state) {
        return state.user.kyc_bypass;
    },
    getIdCard(state) {
        return state.idCard;
    },
    getMotherMaiden(state) {
        return state.mother_maiden;
    },
    getDocumentPoa(state) {
        return state.documentPoa;
    },
    getCheckIdStatus(state) {
        return state.user.check_id;
    },
    getProfilePicture(state){
        return state.profilePicture
    },
    getUserStage(state){
        return state.user.customer_stage
    },
    getUserIdentifier(state){
        if(state.user.phone_number && state.user.phone_number !== '') return state.user.phone_number
        if(state.user.email && state.user.email !== '') return state.user.email
        return null
    }
};

const actions = {
    storeUser({ commit }) {
        const {partnerId, endpoint} = createCustomerEndpointParams()
        return axios.get(endpoint, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('accessToken')
            }
        }).then(resp =>{
            commit("storeUser", resp.data.data);
            if(partnerId) removePartnerId()
            return 200;
        }).catch(err => {
            if(err.response.status !== 401){
                const swalErrorReload = vm.$swal.mixin({
                    customClass: {
                        confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                    },
                    buttonsStyling: false,
                    title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                    showCancelButton: false,
                    confirmButtonText: 'Perbaharui',
                    width: '340px',
                    allowOutsideClick: false,
                })

                swalErrorReload.fire().then((result) => {
                    if (result.isConfirmed){
                        window.location.reload();
                    }
                });

                return 500;
            }else{
                return 401;
            }
        });
    },
    requestIdCard({commit}){
        return axios.get('/customer/id-card')
        .then(resp =>{
            commit("requestIdCard", resp.data.data);
        })
    },
    requestMotherMaiden({commit}){
        axios.get('/customer/mother-maiden')
        .then(resp =>{
            commit("requestMotherMaiden", resp.data.data);
        })
    },
    fetchDocumentPoa({ dispatch, commit, rootGetters }){
        amalansignupApi.get('customers/document-poa')
        .then((resp) => {
            commit("requestDocumentPoa", resp.data.data)
            dispatch('pendingUserActivity/setPendingActivityData', {
                userStage: rootGetters['user/getUserStage'],
                signUpStatus: rootGetters['contact/getSignUpStatus'],
                lastAmalanProtect: rootGetters['formAmalanProtect/getLastProtect'],
                latestService: rootGetters['services/getLatestService'],
                timeToDocCollection: rootGetters['docCollection/getTimeToDocCollection'],
                billCompleted: rootGetters['docCollection/getbillStatus'],
                docCompleted: rootGetters['docCollection/getDocStatus'],
                poaDocument: rootGetters['user/getDocumentPoa'],
                lastAdditionalSuf: rootGetters['additionalSUF/getLastAdditionalSuf'],
                lastUpgradePackage: rootGetters['upgradePricingPackage/getLastUpgradePackage'],
                negotiationResultApprovalFlag: rootGetters['negotiationResult/getNegotiationResultApprovalFlag']
            }, {
                root: true 
            })
        })
    },
    requestDocumentPoa({commit}, payload){
        commit("requestDocumentPoa", payload)
    },
    setProfilePicture({ commit }){
        axios.get('/customer/profile/picture')
        .then((res) =>{
            commit("setProfilePicture", res.data.data)
        }).catch(() => {
            commit("setProfilePicture", null)
        });
    }
};

const mutations = {
    storeUser(state, payload) {
        const check_ocr = JSON.parse(payload.customer.ocr_app);
        const check_compare = JSON.parse(payload.customer.additional_data);
        state.user.ocr_app = check_ocr ? check_ocr.includes("credit-checkup") : false;
        if(check_compare){
            state.user.compare_image = check_compare["image_compare"] ? true : false;
            state.user.kyc_bypass = check_compare["image_compare"] ? check_compare["image_compare"]['similarity'] == 'Bypass' ? true : false : true;
            state.user.id_number = check_compare['ocr'] ? check_compare['ocr']['nik'] : '';
            state.user.check_id = check_compare['image_compare'] ? check_compare['image_compare']['nik'] ? true : false : false;
        }else{
            state.user.compare_image = false;
            state.user.id_number = '';
        }
        state.user.user_id = payload.customer.id;
        state.user.title = payload.customer.title;
        state.user.first_name = payload.customer.first_name;
        state.user.last_name = payload.customer.last_name;
        state.user.phone_number = payload.phone_number;
        state.user.is_whatsapp_phone_number = payload.is_whatsapp ? true : false
        state.user.email = payload.customer.email;
        state.user.gender = payload.customer.gender;
        state.user.birthplace = payload.customer.birthplace;
        state.user.birthdate = payload.customer.birthdate;
        state.user.sf_account_id = payload.customer.sf_account_id;
        state.user.email_verified_at = payload.customer.email_verified_at;

        crisp.user.setEmail(payload.customer.email);
        crisp.user.setPhone(payload.phone_number);
        crisp.user.setNickname((payload.customer.first_name || '') + ' ' + (payload.customer.last_name || ''));
        crisp.session.setData({ sfContactId: payload.customer.sf_account_id });

        if(payload.customer.latest_address){
            let rt_rw_perum = payload.customer.latest_address.rt_rw_perum;
            const rtrwperum = rt_rw_perum ? rt_rw_perum.split("_") : [];
            state.user.address.id = payload.customer.latest_address.id;
            state.user.address.street = payload.customer.latest_address.street;
            state.user.address.rt = rtrwperum[0] ?? '';
            state.user.address.rw = rtrwperum[1] ?? '';
            state.user.address.perum = rtrwperum[2] ?? '';
            state.user.address.postal_code = payload.customer.latest_address.postal_code;
            if(payload.customer.latest_address.prov){
                state.user.address.province.id = payload.customer.latest_address.prov.id;
                state.user.address.province.name = payload.customer.latest_address.prov.name;
            }
            if(payload.customer.latest_address.kab){
                state.user.address.city.id = payload.customer.latest_address.kab.id;
                state.user.address.city.name = payload.customer.latest_address.kab.name;
            }
            if(payload.customer.latest_address.district){
                state.user.address.district.id = payload.customer.latest_address.district.id;
                state.user.address.district.name = payload.customer.latest_address.district.name;
            }
            if(payload.customer.latest_address.village){
                state.user.address.village.id = payload.customer.latest_address.village.id;
                state.user.address.village.name = payload.customer.latest_address.village.name;
            }
        }

        if(payload.customer.education_id || payload.customer.education){
            state.user.education.id = payload.customer.education.id;
            state.user.education.name = payload.customer.education.name;
        }
        if(payload.customer.marital_id || payload.customer.marital_status){
            state.user.marital.id = payload.customer.marital_status.id;
            state.user.marital.name = payload.customer.marital_status.name;
        }
        if(payload.customer.occupation_id || payload.customer.occupation){
            state.user.occupation.id = payload.customer.occupation.id;
            state.user.occupation.name = payload.customer.occupation.name;
        }
        if(payload.customer.religion_id || payload.customer.religion){
            state.user.religion.id = payload.customer.religion.id;
            state.user.religion.name = payload.customer.religion.name;
        }
        if(payload.customer.customer_stage_id || payload.customer.customer_stage){
            state.user.customer_stage = payload.customer.customer_stage.name;
        }
    },
    requestIdCard(state, payload){
        state.idCard = payload
    },
    requestMotherMaiden(state, payload){
        state.mother_maiden = payload.mother_maiden
    },
    requestDocumentPoa(state, payload){
        state.documentPoa = payload
    },
    setProfilePicture(state, payload){
        state.profilePicture = payload
    }
};

const createCustomerEndpointParams = () => {
    const fromOnboarding = localStorage.getItem('from-onboarding-go-to')
    const partnerId = getPartnerId()

    const endpoint = partnerId && fromOnboarding ? `/customer?partnero_partner_id=${partnerId}&from_onboarding=${fromOnboarding}` : '/customer'

    return {partnerId, endpoint}
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
