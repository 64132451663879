<script setup>
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'
import NavbarWithBackV2 from '@/components/NavbarWithBackV2.vue'

const router = useRouter()
const route = useRoute()
const store = useStore()

const signUpStatus = computed(() => store.getters['contact/getSignUpStatus'])
const negotiationResultApprovalFlag = computed(() => store.getters['negotiationResult/getNegotiationResultApprovalFlag'])

const MenuConfig = [
    {
        routeName: 'Negotiation Process - Target Pelunasan - Home',
        title: 'Target Pelunasan',
        text: 'Anda dapat mengubah target pelunasan Anda',
        mustSignup: true
    },
    {
        routeName: 'Negotiation Process - Result - Home',
        title: 'Persetujuan Hasil Negosiasi',
        text: 'Konfirmasi hasil negosiasi yang telah didapatkan',
        mustSignup: true
    },
    {
        routeName: 'Negotiation Process - Result - History',
        title: 'Riwayat Persetujuan Hasil Negosiasi',
        text: 'Riwayat persetujuan negosiasi Anda ada disini',
        mustSignup: true
    },
    {
        routeName: 'Negotiation Process - FAQ',
        title: 'Pertanyaan terkait Proses Negosiasi',
        text: 'Dapatkan informasi yang Anda butuhkan',
        mustSignup: false
    }
]

const goToHelp = () => window.open('https://wa.me/6285888236207?text=Halo, saya ingin konsultasi perihal proses negosiasi. Mohon bantuannya.', '_blank')

onMounted(() => {
    store.dispatch('faq/setNegotiationProcess')
})

</script>

<template>
    <div class="min-h-screen bg-amalan-white relative">
        <NavbarWithBackV2 :title="route.meta.title" @back-button="router.push({ name: 'Akun' })" />
        <div class="w-full pt-8 pb-20 px-6 bg-amalan-white">
            <p class="text-center text-amalan-black text-xs sm:text-sm">Kelola dan dapatkan informasi terkait<br>proses negosiasi pinjaman Anda pada halaman ini.</p>
            <div class="w-full mt-8 grid grid-cols-1 gap-4">
                <router-link v-for="(menu, index) in MenuConfig" :key="index" :to="{ name: menu.routeName }" class="w-full rounded-lg text-sm relative" :class="[ menu.mustSignup && signUpStatus !== 200 ? 'cursor-not-allowed bg-amalan-gray-4 text-amalan-gray-2' : 'shadow-amalan-sm bg-amalan-blue-1 hover:bg-amalan-blue-2 text-amalan-white cursor-pointer' ]">
                    <div class="w-full relative px-4 py-6 rounded-lg overflow-hidden">
                        <p class="text-amalan-yellow-400 font-bold" v-html="menu.title"></p>
                        <p class="mt-1 text-2xs" v-html="menu.text"></p>
                        <div class="absolute -bottom-9 -right-9 w-22 h-22 rounded-full bg-transparent border border-amalan-yellow-400 opacity-30 flex justify-center items-center">
                            <div class="w-20 h-20 rounded-full bg-amalan-white bg-opacity-50"></div>
                        </div>
                    </div>
                    <div v-if="index === 1 && negotiationResultApprovalFlag" class="w-4 h-4 bg-amalan-red rounded-full absolute -top-2 -right-2"></div>
                </router-link>
            </div>
        </div>
        <div class="bg-amalan-white absolute bottom-0 h-16 pt-8 inset-x-0">
            <p class="text-xs text-amalan-black text-center">Butuh bantuan konsultan? Klik <span @click="goToHelp" class="text-amalan-blue-3 cursor-pointer font-bold">DISINI</span></p>
        </div>
    </div>
</template>