<template>
    <div class="w-full absolute bg-amalan-white shadow-xl z-50" :class="[ this.$route.meta.excludeKYC ? 'top-0' : '-top-24']" v-if="ishidden">
        <div class="bg-amalan-blue-7">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="() => { ishidden = false }" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold mt-0 text-amalan-black">{{ $route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-12 w-full mx-auto px-4 mt-4 text-amalan-black">
            <p class="text-center text-base font-bold">Contoh foto Swafoto yang dibenarkan</p>
            <div class="h-12 w-12 rounded-full border border-amalan-green flex justify-center items-center mx-auto my-4">
                <CheckIcon class="w-10 h-10 text-amalan-green" />
            </div>
            <div class="w-full mt-2 mb-6">
                <p class="text-center text-xs font-semibold">
                    Foto diambil tidak terlalu dekat atau jauh, menghadap ke kamera, wajah tampak jelas, tidak buram, tidak terpotong, dan tidak menggunakan aksesoris.
                </p>
                <img src="@/assets/img/sample-selfie1.png" class="w-3/6 mx-auto my-2">
            </div>
            <div class="h-0.5 w-full bg-amalan-gray-3 my-6"></div>
            <p class="text-center text-base font-bold">Contoh foto Swafoto yang salah</p>
            <div class="h-12 w-12 rounded-full border border-amalan-red flex justify-center items-center mx-auto my-4">
                <XIcon class="w-10 h-10 text-amalan-red" />
            </div>
            <div class="w-full mt-2">
                <p class="text-center text-xs font-semibold">
                    Foto yang diambil menggunakan aksesoris, <br> foto yang diambil buram dan wajah terlihat tidak jelas, <br> dan foto yang diambil tidak menghadap ke kamera.
                </p>
            </div>
            <div class="w-full grid grid-cols-3 gap-x-2 mt-2">
                <img src="@/assets/img/sample-selfie2.png" class="w-full">
                <img src="@/assets/img/sample-selfie3.png" class="w-full">
                <img src="@/assets/img/sample-selfie4.png" class="w-full">
            </div>
            <button type="button" class="w-full mt-20 py-3 text-sm font-bold bg-amalan-blue-1 border border-amalan-blue-1 text-amalan-white rounded-lg hover:bg-blue-900 hover:border-blue-900" @click="close">Unggah Sekarang</button>
        </div>
    </div>
</template>

<script>
import { CheckIcon, XIcon, ChevronLeftIcon } from '@heroicons/vue/solid';

export default {
    components: {
        CheckIcon, 
        XIcon,
        ChevronLeftIcon
    },
    data() {
        return {
            ishidden: this.changeVisibility
        }
    },
    props: {
        changeVisibility: Boolean
    },
    watch: {
        changeVisibility(){
            this.ishidden = this.changeVisibility;
        }
    },
    methods: {
        close() {
            this.ishidden = false;
            this.$emit('upload', true)
        }
    }
}
</script>