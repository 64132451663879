<template>
    <div>
        <AmalanTiktokAds />
        <CreditScoreBanner />
        <nav class="bg-blue-100">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="$router.push({ name: 'Home' })" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5"/>
                    </button>
                    <span class="text-sm font-bold mt-0">{{ $route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="pt-4 min-h-screen h-auto relative bg-amalan-white">
            <div class="mt-2 pb-12 w-full px-4">
                <div class="flex items-center">
                    <div class="flex items-center text-blue-900 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/signup/data-pinjaman-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Daftar<br>Pinjaman</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-blue-900"></div>
                    <div class="flex items-center text-blue-900 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/lead-qualification/informasi-tambahan-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Informasi<br>Tambahan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-blue-900"></div>
                    <div class="flex items-center text-blue-900 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/lead-qualification/kemampuan-menabung-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Estimasi<br>Keringanan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-blue-900"></div>
                    <div class="flex items-center text-blue-900 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/verified-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Registrasi<br>Selesai</p></div>
                    </div>
                </div>
            </div>
            <div class="mt-4 pb-14 w-full px-4 text-amalan-black">
                <div class="mt-12 sm:px-6 text-center flex flex-col">
                    <template v-if="contactData.Status_of_Lead__c && contactData.Status_of_Lead__c === 'Qualified'">
                        <img src="@/assets/img/lead-qualification/qualified-icon.svg" class="mx-auto">
                        <p class="my-6 text-xs sm:text-sm">
                            Proses Registrasi Anda telah selesai dilakukan. <br>Selanjutnya konsultan kami akan segera menghubungi Anda untuk proses berikutnya.
                        </p>
                    </template>
                    <template v-else>
                        <img src="@/assets/img/lead-qualification/unqualified-icon.svg" class="mx-auto">
                        <p class="my-6 text-xs sm:text-sm">
                            Proses Registrasi Anda telah selesai dilakukan. <br> Namun, mohon maaf amalan belum dapat membantu Anda untuk saat ini. <br><br> Terima kasih sudah mempercayai <br> PT. amalan international Indonesia
                        </p>
                    </template>
                    <router-link to='/home' class="mx-auto rounded-lg font-bold border border-solid cursor-pointer text-center text-sm py-3 w-52 text-amalan-blue-1 bg-amalan-white border-amalan-blue-1 hover:bg-amalan-blue-1 hover:text-amalan-white">Kembali ke Beranda</router-link>
                </div>
                <div class="w-full flex justify-center items-center text-sm bg-amalan-gray-3 text-amalan-gray-3 my-10" style="height: 1px">
                    <div class="bg-amalan-white px-2">ATAU</div>
                </div>
                <div class="w-full">
                    <p class="text-center text-amalan-blue-1 font-bold text-base sm:text-lg my-2">Apakah Anda ingin mengetahui Credit Score yang Anda miliki?</p>
                    <p class="text-center text-xs sm:text-sm my-2">Credit score adalah profil ekonomi berdasarkan kelayakan kredit untuk memudahkan Anda melihat analisis finansial Anda dan peluang mendapatkan pinjaman baru.</p>
                    <button @click="goToCreditScore" type="button" class="mx-auto rounded-lg font-bold border border-solid cursor-pointer text-center text-sm mt-8 mb-6 py-3 w-52 block text-amalan-white bg-amalan-blue-1 border-amalan-blue-1 hover:bg-blue-900 hover:border-blue-900">Dapatkan Credit Score</button>
                    <p class="text-center text-amalan-blue-1 font-bold text-base sm:text-lg">Kenapa Perlu Credit Score?</p>
                    <div class="w-full mt-4 px-6">
                        <div class="w-full flex items-center my-4">
                            <img class="w-12 mr-3" src="@/assets/img/lead-qualification/self-service-4.svg">
                            <div class="w-full py-2 px-2 border-2 border-solid rounded-lg">
                                <p class="text-xs">Sebagai bahan pertimbangan besar kecilnya suku bunga pinjaman</p>
                            </div>
                        </div>
                        <div class="w-full flex items-center my-4">
                            <img class="w-12 mr-3" src="@/assets/img/lead-qualification/self-service-2.svg">
                            <div class="w-full py-2 px-2 border-2 border-solid rounded-lg">
                                <p class="text-xs">Sebagai gambaran kemampuan membayar tagihan Anda kedepannya</p>
                            </div>
                        </div>
                        <div class="w-full flex items-center my-4">
                            <img class="w-12 mr-3" src="@/assets/img/lead-qualification/self-service-5.svg">
                            <div class="w-full py-2 px-2 border-2 border-solid rounded-lg">
                                <p class="text-xs">Sebagai cerminan perilaku kredit Anda sebagai acuan persetujuan pinjaman</p>
                            </div>
                        </div>
                        <div class="w-full flex items-center my-4">
                            <img class="w-12 mr-3" src="@/assets/img/lead-qualification/self-service-6.svg">
                            <div class="w-full py-2 px-2 border-2 border-solid rounded-lg">
                                <p class="text-xs">Penentu besarnya pinjaman yang akan diperoleh & jangka waktu yang didapat</p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="w-full grid grid-cols-2 gap-y-10 gap-x-3 mt-10">
                        <div class="relative flex justify-center">
                            <div class="absolute w-14 h-14 bg-amalan-blue-7 rounded-full flex justify-center items-center -top-7">
                                <img src="@/assets/img/lead-qualification/diagnosa-dini.svg">
                            </div>
                            <div class="border border-solid rounded-lg py-10 px-3">
                                <p class="text-amalan-blue-1 text-sm sm:text-base font-bold text-center mb-2">Diagnosa Dini</p>
                                <p class="text-xs sm:text-sm text-center">Mendiagnosa masalah pinjaman lebih awal untuk meningkatkan kesehatan pinjaman dan finansial Anda.</p>
                            </div>
                        </div>
                        <div class="relative flex justify-center">
                            <div class="absolute w-14 h-14 bg-amalan-blue-7 rounded-full flex justify-center items-center -top-7">
                                <img src="@/assets/img/lead-qualification/analisa-akurat.svg">
                            </div>
                            <div class="border border-solid rounded-lg py-10 px-3">
                                <p class="text-amalan-blue-1 text-sm sm:text-base font-bold text-center mb-2">Analisa Akurat</p>
                                <p class="text-xs sm:text-sm text-center">Dianalisis oleh Credit Advisor yang bersertifikat CFP dengan dukungan data akurat dari biro kredit terdaftar dan diawasi oleh OJK (CLIK).</p>
                            </div>
                        </div>
                        <div class="relative flex justify-center">
                            <div class="absolute w-14 h-14 bg-amalan-blue-7 rounded-full flex justify-center items-center -top-7">
                                <img src="@/assets/img/lead-qualification/memperbaiki-skor-kredit.svg">
                            </div>
                            <div class="border border-solid rounded-lg py-10 px-3">
                                <p class="text-amalan-blue-1 text-sm sm:text-base font-bold text-center mb-2">Memperbaiki Skor Kredit</p>
                                <p class="text-xs sm:text-sm text-center">Mencari solusi untuk memperbaiki skor kredit Anda.</p>
                            </div>
                        </div>
                        <div class="relative flex justify-center">
                            <div class="absolute w-14 h-14 bg-amalan-blue-7 rounded-full flex justify-center items-center -top-7">
                                <img src="@/assets/img/lead-qualification/mempercepat-pengajuan-kredit.svg">
                            </div>
                            <div class="border border-solid rounded-lg py-10 px-3">
                                <p class="text-amalan-blue-1 text-sm sm:text-base font-bold text-center mb-2">Mempercepat Pengajuan Kredit</p>
                                <p class="text-xs sm:text-sm text-center">Skor kredit yang sehat dapat mempercepat proses pengajuan pinjaman Anda.</p>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- <div class="mt-4 pb-14 w-full px-4" v-else>
                <div class="mt-16 sm:px-6 text-center flex flex-col">
                    <img src="@/assets/img/lead-qualification/unqualified-icon.svg" class="mx-auto">
                    <p class="my-6 text-xs sm:text-sm">
                        Proses Registrasi Anda telah selesai dilakukan. <br> Namun, mohon maaf amalan belum dapat membantu Anda untuk saat ini. <br><br> Terima kasih sudah mempercayai <br> PT. amalan international Indonesia
                    </p>
                    <router-link to='/home' class="mx-auto rounded-lg font-bold border border-solid cursor-pointer text-center text-sm py-3 w-52 text-blue-900 bg-amalan-white border-blue-900 hover:bg-blue-900 hover:text-white">Kembali ke Beranda</router-link>
                </div>
                <div class="w-full flex justify-center items-center text-sm bg-gray-300 text-gray-300 my-10" style="height: 1px">
                    <div class="bg-amalan-white px-2">ATAU</div>
                </div>
                <div class="w-full">
                    <p class="text-center text-blue-900 font-bold text-base sm:text-lg my-2">Apakah Anda ingin mendapatkan Keringanan Pinjaman Online Mandiri?</p>
                    <p class="text-center text-xs sm:text-sm my-2">Tidak perlu khawatir, Anda hanya perlu mencari partner pengajuan keringanan yang sudah berpengalaman. Dengan bantuan amalan, peluang Anda mendapatkan persetujuan keringanan akan lebih besar.</p>
                    <a href="https://lp.amalan.com/id-keringanan-utang-pinjaman-online/" class="mx-auto rounded-lg font-bold border border-solid cursor-pointer text-center text-sm mt-8 mb-6 py-3 w-52 block text-white bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700">Ajukan Keringanan</a>
                    <p class="text-center text-blue-900 font-bold text-base sm:text-lg">Bagaimana caranya mengajukan Keringanan Pinjaman Online?</p>
                    <div class="w-full grid grid-cols-12 mt-4 px-6 gap-x-2 sm:gap-x-1">
                        <div class="col-span-3 sm:col-span-2 flex flex-col items-center">
                            <img class="w-12" src="@/assets/img/lead-qualification/self-service-1.svg">
                            <div class="h-6 w-0.5 bg-blue-900"></div>
                            <img class="w-12" src="@/assets/img/lead-qualification/self-service-2.svg">
                            <div class="h-6 w-0.5 bg-blue-900"></div>
                            <img class="w-12" src="@/assets/img/lead-qualification/self-service-3.svg">
                        </div>
                        <div class="col-span-9 sm:col-span-10 grid grid-cols-1 gap-y-6">
                            <div class="w-full h-12 px-2 border-2 border-solid rounded-lg flex items-center justify-center">
                                <p class="text-center text-xs">Daftar Melalui Website</p>
                            </div>
                            <div class="w-full h-12 px-2 border-2 border-solid rounded-lg flex items-center justify-center">
                                <p class="text-center text-xs">Tanda tangani dokumen pengajuan keringanan</p>
                            </div>
                            <div class="w-full h-12 px-2 border-2 border-solid rounded-lg flex items-center justify-center">
                                <p class="text-center text-xs">Tindak lanjuti pengajuan Anda ke pemberi pinjaman secara <b>MANDIRI</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { ChevronLeftIcon } from '@heroicons/vue/solid';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import AmalanTiktokAds from '@/components/promotion/AmalanTiktokAds.vue'
import CreditScoreBanner from '@/components/promotion/CreditScoreBanner.vue'
import { sentTiktokEvent } from '../../composables/tiktokEventApi'
import { sha256 } from 'js-sha256'
import { mixpanelPushEvent, eventName } from '@/composables/mixpanel'
// import { sentGreetingPresalesPrompt, useCrispInstance } from '@/composables/crisp'

export default defineComponent({
    data() {
        return {}
    },
    components:{
        ChevronLeftIcon,
        AmalanTiktokAds,
        CreditScoreBanner
    },
    computed: {
        ...mapGetters('leadQualification',{
            contactData: 'getContact'
        }),
        ...mapGetters('user',{
            userData: 'getUser'
        })
    },
    methods: {
        goToCreditScore(){
            localStorage.setItem('CreditScoreFromProduct', 'lead_qualification')
            this.$router.push({ name: 'credit checkup - credit score checkout' })
        }
    },
    mounted(){
        this.$store.dispatch('catalog/requestCatalog')
        if(this.userData.email){
            window.dataLayer?.push({
                event: 'Loan_Finished',
                email: this.userData.email,
                phone_number: this.userData.phone_number
            });

            const eventId = 'Finishing_Leads_' + this.userData.phone_number
            window.dataLayer?.push({
                event: 'Finishing_Lead',
                ttk_event_id: eventId,
                sha256_email: sha256(this.userData.email.toLowerCase())
            });

            sentTiktokEvent('SubmitForm', eventId, {
                phone_number: this.userData.phone_number,
                email: this.userData.email
            })

            mixpanelPushEvent(eventName.FINISHED)

            // sentGreetingPresalesPrompt()
        }else{
            this.$router.push({ name: 'lead qualification - informasi tambahan' })
        }
    },
    // setup(){
    //     const crisp = useCrispInstance()

    //     onBeforeUnmount(() => {
    //         crisp.chat.close()
    //         crisp.session.reset()
    //     })
    // }
})
</script>