<template>
    <div>
        <TermsConditions />
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="backButton" class="flex items-center mt-0" :class="{ 'invisible' : termAndConditionStatus }">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold mt-0 text-amalan-black">{{ $route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="pt-4 relative bg-amalan-white" :class="[ termAndConditionStatus ? 'overflow-hidden h-screen' : 'min-h-screen h-auto' ]">
            <div class="mt-2 pb-12 w-full px-4">
                <div class="flex items-center">
                    <div class="flex items-center text-amalan-blue-1 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/credit-checkup/booking-date-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-amalan-blue-1"><p>Booking<br>Konsultasi</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-amalan-gray-3"></div>
                    <div class="flex items-center text-amalan-gray-2 relative">
                        <div class="rounded-full h-12 w-12 border-2 bg-amalan-gray-5 border-amalan-gray-5 flex justify-center items-center">
                            <img src="@/assets/img/credit-checkup/pembayaran-layanan.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-amalan-gray-2"><p>Pembayaran<br>Layanan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-amalan-gray-3"></div>
                    <div class="flex items-center text-amalan-gray-2 relative">
                        <div class="rounded-full h-12 w-12 border-2 bg-amalan-gray-5 border-amalan-gray-5 flex justify-center items-center">
                            <img src="@/assets/img/verified.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-amalan-gray-2"><p>Konfirmasi<br>Layanan</p></div>
                    </div>
                </div>
            </div>
            <div v-if="dataAvailableDate">
                <div v-if="currentStep === 1" class="w-full mt-6 px-4">
                    <p class="text-base font-bold text-amalan-blue-1 text-center">Tanggal Konsultasi</p>
                    <p class="text-xs text-center mt-1 text-amalan-black">Anda dapat memilih tanggal konsultasi yang tersedia</p>
                    <div class="h-px w-full bg-amalan-gray-4 mt-4"></div>
                    <div class="w-full mt-4">
                        <template v-for="(data, index) in dataCalendar" :key="index">
                            <template v-if="index + 1 == stepCalendar">
                                <div class="w-full flex items-center justify-between px-12">
                                    <button class="w-8 h-8 flex items-center justify-center bg-amalan-white hover:bg-amalan-gray-5 rounded-lg" :class="[ stepCalendar == 1 ? 'cursor-not-allowed text-amalan-gray-3' : 'cursor-pointer text-amalan-blue-1']" @click="() => { stepCalendar-- }" :disabled="stepCalendar == 1">
                                        <ArrowSmLeftIcon class="w-5/6" />
                                    </button>
                                    <div class="w-32 h-8 text-amalan-blue-1 text-sm font-bold flex items-center justify-center uppercase">{{ data.month }}</div>
                                    <button class="w-8 h-8 flex items-center justify-center bg-amalan-white hover:bg-amalan-gray-5 rounded-lg" :class="[ stepCalendar == dataCalendar.length ? 'cursor-not-allowed text-amalan-gray-3' : 'cursor-pointer text-amalan-blue-1']" @click="() => { stepCalendar++ }" :disabled="stepCalendar == dataCalendar.length">
                                        <ArrowSmRightIcon class="w-5/6" />
                                    </button>
                                </div>
                                <div class="w-full mt-2 grid grid-cols-7 gap-x-1 gap-y-2">
                                    <div class="w-full rounded-md py-0.5 text-xs font-bold bg-amalan-gray-5 flex justify-center items-center text-amalan-red">Min</div>
                                    <div class="w-full rounded-md py-0.5 text-xs font-bold bg-amalan-gray-5 flex justify-center items-center text-amalan-black">Sen</div>
                                    <div class="w-full rounded-md py-0.5 text-xs font-bold bg-amalan-gray-5 flex justify-center items-center text-amalan-black">Sel</div>
                                    <div class="w-full rounded-md py-0.5 text-xs font-bold bg-amalan-gray-5 flex justify-center items-center text-amalan-black">Rab</div>
                                    <div class="w-full rounded-md py-0.5 text-xs font-bold bg-amalan-gray-5 flex justify-center items-center text-amalan-black">Kam</div>
                                    <div class="w-full rounded-md py-0.5 text-xs font-bold bg-amalan-gray-5 flex justify-center items-center text-amalan-black">Jum</div>
                                    <div class="w-full rounded-md py-0.5 text-xs font-bold bg-amalan-gray-5 flex justify-center items-center text-amalan-red">Sab</div>
                                    <template v-for="n in calculatePrevDay(data.date)" :key="n">
                                        <div class="w-full h-10 rounded-md text-xs font-bold flex justify-center items-center text-amalan-gray-3 cursor-not-allowed bg-amalan-white hover:bg-amalan-gray-5">{{ createPrevDay(n, calculatePrevDay(data.date) + 1, this.$filters.getDaysInMonth(this.$filters.reduceMonthFromSomeDay(data.date, 1)) + 1) }}</div>
                                    </template>
                                    <template v-for="n in data.numberOfDays" :key="n">
                                        <button class="w-full h-10 rounded-md text-xs font-bold flex justify-center items-center" :class="[ checkAvailableOrNot(n, data.dateYearAndMonth) ? formData.selectedIndex && n === formData.selectedIndex ? 'bg-amalan-yellow-100 text-amalan-black cursor-pointer' : 'text-amalan-black cursor-pointer bg-amalan-white hover:bg-amalan-gray-5' : 'text-amalan-gray-3 cursor-not-allowed bg-amalan-white hover:bg-amalan-gray-5' ]" :disabled="!checkAvailableOrNot(n, data.dateYearAndMonth)" @click="selectDate(getTheDate(n, data.dateYearAndMonth), n)">{{ n }}</button>
                                    </template>
                                    <template v-for="n in calculateNextDay(data.dateEnd)" :key="n">
                                        <div class="w-full h-10 rounded-md text-xs font-bold flex justify-center items-center text-amalan-gray-3 cursor-not-allowed bg-amalan-white hover:bg-amalan-gray-5">{{ n }}</div>
                                    </template>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
                <div v-if="currentStep === 2" class="w-full mt-6 px-4">
                    <p class="text-base font-bold text-amalan-blue-1 text-center">Pilih Konsultan</p>
                    <p class="text-xs text-center mt-1 text-amalan-black">Anda dapat memilih konsultan dan jadwal yang tersedia untuk melakukan konsultasi terkait Credit Checkup Anda</p>
                    <div class="h-px w-full bg-amalan-gray-4 mt-4"></div>
                    <div class="w-full" v-if="dataAvailableProviders">
                        <div class="py-4 px-5 rounded-lg shadow-amalan-md flex items-center mt-4 cursor-pointer" v-for="(provider, index) in dataAvailableProviders" :key="index" @click="selectProvider(provider.providerId, index)" :class="[ formData.selectedProvidersIndex === index ? 'bg-amalan-yellow-100' : 'bg-amalan-white']">
                            <div class="w-16 h-16 rounded-full bg-red-100 border-2 border-amalan-white overflow-hidden">
                                <img :src="[provider.providerName == 'Deni Sujarwo' ? require('@/assets/img/credit-checkup/konsultan/deni.png') : require('@/assets/img/credit-checkup/konsultan/dimas.png') ]" class="w-full">
                            </div>
                            <div class="ml-4 text-amalan-black">
                                <p class="text-sm font-bold">{{ provider.providerName }}</p>
                                <p class="text-xs">Credit Specialist amalan</p>
                            </div>
                        </div>
                    </div>
                    <Transition name='dropdown'>
                        <div class="w-full" v-show="showSelectTime">
                            <template v-if="formData.providerId">
                                <div class="w-full mt-8 grid grid-cols-2 gap-4" v-if="dataAvailableProviders[formData.selectedDataProvidersIndex].availability.length > 0">
                                    <div class="flex justify-center items-center h-8 rounded-lg shadow-amalan-md cursor-pointer" v-for="(slot, index) in dataAvailableProviders[formData.selectedDataProvidersIndex].availability" :key="index" @click="selectTime(slot.id, index)" :class="[ formData.startTimeIndex === index ? 'bg-amalan-yellow-100' : 'bg-amalan-white']">{{ $filters.formatHourMinute(slot.start_time) }} - {{ $filters.formatHourMinute(slot.end_time)}}</div>
                                </div>
                                <div v-else class="w-full mt-8 bg-amalan-gray-5 py-8 rounded-md">
                                    <p class="px-4 text-xs text-amalan-black text-center">
                                        Maaf, tidak ada waktu yang tersedia. <br> Silahkan pilih konsultan lain atau tanggal lain.
                                    </p>
                                </div>
                            </template>
                        </div>
                    </Transition>
                </div>
                <div v-if="currentStep === 3" class="w-full mt-6 px-4">
                    <p class="text-base font-bold text-amalan-blue-1 text-center">Jadwal Konsultasi</p>
                    <p class="text-xs text-center mt-1 text-amalan-black">Silahkan cek kembali Jadwal Konsultasi dan Konsultan yang sudah Anda pilih!</p>
                    <div class="h-px w-full bg-amalan-gray-4 mt-4"></div>
                    <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                        <div class="font-bold col-span-4">Hari / Tanggal</div>
                        <div class="col-span-8 flex">
                            <span class="mr-1">:</span> 
                            <span>{{ $filters.formatDay(formData.startTime) }} / {{ $filters.formatDateOnlyDate(formData.startTime) }}</span>
                        </div>
                    </div>
                    <div class="h-px w-full bg-amalan-gray-4"></div>
                    <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                        <div class="font-bold col-span-4">Jam</div>
                        <div class="col-span-8 flex">
                            <span class="mr-1">:</span> 
                            <span>{{ $filters.formatHourMinute($filters.formatHour(formData.startTime)) }} - {{ $filters.formatHourMinuteAddOneHour($filters.formatHour(formData.startTime))}} WIB</span>
                        </div>
                    </div>
                    <div class="h-px w-full bg-amalan-gray-4"></div>
                    <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                        <div class="font-bold col-span-4">Konsultan</div>
                        <div class="col-span-8 flex">
                            <span class="mr-1">:</span> 
                            <span>{{ formData.providerName }}</span>
                        </div>
                    </div>
                    <div class="h-px w-full bg-amalan-gray-4"></div>
                    <div class="px-4 pt-6 flex justify-between items-center">
                        <label class="font-medium text-xs inline-flex" for="terms">
                            <label class="h-4 w-4 border-2 border-gray-400 rounded mr-2 p-0 cursor-pointer" :class="[ formData.terms ? 'bg-amalan-yellow-400 border-amalan-yellow-400' : 'border-amalan-red' ]" @click="termsChecked">
                                <CheckIcon v-if="formData.terms" class="w-3 h-3 mx-auto text-amalan-blue-1" />
                                <div v-else class="w-3 h-3 mx-auto bg-transparent"></div>
                            </label>
                            <input name="terms" v-model="formData.terms" type="checkbox" class="hidden"/>
                        </label>
                        <span class="self-center text-xs">Saya menyetujui seluruh <span class="underline cursor-pointer text-blue-700" @click="showTermsAndCondition">syarat dan ketentuan</span> yang berlaku di <b>amalan international.</b></span>
                    </div>
                </div>
                <div class="w-full mt-24 px-4 pb-24">
                    <button v-if="currentStep !== 1" type="button" class="h-10 rounded-lg font-medium border border-solid cursor-pointer text-center text-base py-1 px-1 text-amalan-blue-1 border-amalan-blue-1 hover:bg-amalan-blue-1 hover:text-amalan-white float-left" @click="backButton"><ChevronLeftIcon class="h-full" /></button>
                    <button v-if="currentStep == 1" type="button" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-1 float-right" :class="[ formData.startDate ? 'text-amalan-white bg-amalan-blue-1 border-amalan-blue-1 cursor-pointer hover:bg-blue-900 hover:border-blue-900' : 'text-amalan-gray-3 bg-amalan-gray-5 border-amalan-gray-5 cursor-not-allowed' ]" :disabled="!formData.startDate" @click="nextStep"><ChevronRightIcon class="h-full" /></button>
                    <button v-if="currentStep == 2" type="button" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-1 float-right" :class="[ formData.providerId && formData.startTime ? 'text-amalan-white bg-amalan-blue-1 border-amalan-blue-1 cursor-pointer hover:bg-blue-900 hover:border-blue-900' : 'text-amalan-gray-3 bg-amalan-gray-5 border-amalan-gray-5 cursor-not-allowed' ]" :disabled="!formData.providerId || !formData.startTime" @click="nextStep"><ChevronRightIcon class="h-full" /></button>
                    <button v-if="currentStep == 3" type="button" class="h-10 rounded-lg font-medium border border-solid text-center text-sm px-8 float-right" :class="[ formData.terms ? 'text-amalan-white bg-amalan-blue-1 border-amalan-blue-1 cursor-pointer hover:bg-blue-900 hover:border-blue-900' : 'text-amalan-gray-3 bg-amalan-gray-5 border-amalan-gray-5 cursor-not-allowed' ]" :disabled="!formData.terms" @click="nextStep">Kirim</button>
                </div>
            </div>
            <loading-overlay-with-message v-else/>
            <loading-overlay v-if="isLoading" />
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, inject, reactive, computed, watch, onMounted } from 'vue'
import { ChevronLeftIcon, ChevronRightIcon, ArrowSmLeftIcon, ArrowSmRightIcon, CheckIcon } from '@heroicons/vue/solid'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import TermsConditions from '@/components/TermsConditions.vue'
import axios from "../../axios"

export default defineComponent({
    components: {
        ChevronLeftIcon,
        ChevronRightIcon,
        ArrowSmLeftIcon,
        ArrowSmRightIcon,
        TermsConditions,
        CheckIcon
    },
    setup(){
        const router = useRouter()
        const store = useStore()
        const swal = inject("$swal")
        const dataAvailableDate = computed(() => store.getters['bookingData/getAvailableDate'])
        const dataAvailableProviders = computed(() => store.getters['bookingData/getAvailableProviders'])
        const dataProviders = computed(() => store.getters['bookingData/getDataProviders'])
        const bookedData = computed(() => store.getters['bookingData/getBookedData'])
        const termAndConditionStatus = computed(() => store.getters['globalState/getTermAndConditionStatus'])
        const catalogs = computed(() => store.getters['catalog/getCatalog'])
        const dataUser = computed(() => store.getters['user/getUser'])
        const idCardUser = computed(() => store.getters['user/getIdCard'])
        const filters = inject("Filters")
        const addDays = ref(70)
        let dataCalendar = reactive([])
        const isLoading = ref(false)
        const showSelectTime = ref(false)
        const stepCalendar = ref(1)
        const daysOfWeek = ref(['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'])
        let formData = reactive({
            serviceId: null,
            startDate: null,
            startTime: null,
            startTimeIndex: null,
            selectedIndex: null,
            selectedProvidersIndex: null,
            selectedDataProvidersIndex: null,
            providerId: null,
            providerName: null,
            providerEmail: null,
            terms: false
        })
        let currentStep = ref(1)

        const swalError = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600',
                cancelButton: 'absolute -top-3 -right-3'
            },
            buttonsStyling: false,
            title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        const swalConfirmIcon = swal.mixin({
            customClass: {
                confirmButton: 'bg-amalan-blue-1 text-amalan-white mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-sm font-semibold hover:bg-blue-900 hover:border-blue-900',
                cancelButton: 'text-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-sm font-semibold hover:bg-amalan-red hover:border-amalan-red hover:text-amalan-white'
            },
            buttonsStyling: false,
            title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        function getDataToCreateCalendar(){
            const today = filters.dateNow()
            const endDay = filters.addDaysFromSomeDay(today, addDays.value)
            const monthData = filters.formatMonthAndYearBetweenTwoDate(today, endDay)

            for(let i in monthData){
                let data = {
                    'date': monthData[i].date,
                    'dateStart': monthData[i].dateStart,
                    'dateEnd': monthData[i].dateEnd,
                    'month': monthData[i].dateFormated,
                    'numberOfDays': filters.getDaysInMonth(monthData[i].date),
                    'dateYearAndMonth': monthData[i].dateYearAndMonth
                }

                dataCalendar.push(data)
            }

            formData.serviceId = bookedData.value.serviceId
            formData.startDate = bookedData.value.startDate ?? null
            formData.selectedIndex = bookedData.value.selectedIndex ?? null
        }

        function calculatePrevDay(date){
            const theDay = filters.formatDay(date)
            return daysOfWeek.value.indexOf(theDay)
        }

        function calculateNextDay(date){
            const theDay = filters.formatDay(date)
            const findIndex = daysOfWeek.value.indexOf(theDay) + 1
            return 7 - findIndex
        }

        function createPrevDay(iteration, lastIteration, totalDays){
            return totalDays - (lastIteration - iteration)
        }

        function checkAvailableOrNot(day, monthYear){
            for(let i in dataAvailableDate.value){
                if(filters.dateFormatDayWithoutZero(dataAvailableDate.value[i]) === monthYear + '-' + day){
                    return true
                }
            }
            
            return false
        }

        function getTheDate(day, monthYear){
            for(let i in dataAvailableDate.value){
                if(filters.dateFormatDayWithoutZero(dataAvailableDate.value[i]) === monthYear + '-' + day){
                    return dataAvailableDate.value[i]
                }
            }
        }

        function selectDate(date, index){
            formData.selectedIndex = index
            formData.startDate = date
            store.dispatch('bookingData/setStartDate', formData)
        }

        function selectProvider(Id, index){
            showSelectTime.value = false
            store.dispatch('bookingData/removeBookedTimeAndProviders')
            formData.selectedProvidersIndex = null
            formData.selectedDataProvidersIndex = null
            formData.providerId = null
            formData.providerName = null
            formData.providerEmail = null
            formData.startTime = null
            formData.startTimeIndex = null

            const data = dataProviders.value.filter(elem => elem.user.id === Id)
            const dataIndex = dataAvailableProviders.value.findIndex(elem => elem.providerId=== Id)
            if(data && data.length > 0){
                formData.providerId = data[0].user.id
                formData.providerName = data[0].user.first_name + ' ' + data[0].user.last_name
                formData.providerEmail = data[0].user.email
                formData.selectedProvidersIndex = index
                formData.selectedDataProvidersIndex = dataIndex
                store.dispatch('bookingData/setSelectedProviders', formData)
            }

            showSelectTime.value = true
        }

        function selectTime(id, index){
            formData.startTime = id
            formData.startTimeIndex = index
            store.dispatch('bookingData/setStartTime', formData)
        }

        function nextStep(){
            if(currentStep.value == 1){
                isLoading.value = true
                axios.get(`/booking/slots/by-date?date=${formData.startDate}&service_id=${formData.serviceId}`)
                .then((resp) => {
                    store.dispatch('bookingData/requestAvailableProviders', resp.data.data)
                    isLoading.value = false
                    currentStep.value++
                })
                .catch(() => {
                    swalError.fire({
                        html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                        showCancelButton: true,
                        confirmButtonText: 'Bantuan',
                        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                    }).then((result) => {
                        if (result.isConfirmed){
                            window.location = 'https://wa.me/6285888236207?text=' + 
                            'Mohon bantuan sistem gagal dalam meminta list jam konsultasi pada proses Book Konsultasi Credit Checkup di web.amalan.com';
                            return;
                        }
                    });
                })
            }else if(currentStep.value == 2){
                currentStep.value++
            }else{
                swalConfirmIcon.fire({
                    html: '<p class="text-center text-sm mt-2">Pastikan konsultan dan jadwal yang Anda pilih sudah sesuai! <br> Jadwal tidak dapat diganti jika proses pembayaran telah dilakukan</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Konfirmasi',
                    cancelButtonText: 'Batalkan',
                }).then((result) => {
                    if (result.isConfirmed){
                        isLoading.value = true;
                        const catalog = catalogs.value.filter(elem => elem.serviceName == 'Credit Checkup')
                        let data = {
                            promoType: localStorage.getItem('promoType') ?? '',
                            promoCode: localStorage.getItem('promoCode') ?? '',
                            serviceCatalogId : catalog[0].serviceCatalogId,
                            email: dataUser.value.email,
                            fullName: dataUser.value.first_name + ' ' + dataUser.value.last_name,
                            providerId: formData.providerId,
                            providerName: formData.providerName,
                            providerEmail: formData.providerEmail,
                            serviceId: formData.serviceId,
                            idNumber: idCardUser.value ? idCardUser.value.number ? idCardUser.value.number : null : null,
                            gender: dataUser.value.gender,
                            birthDate: dataUser.value.birthdate,
                            startDate: formData.startTime,
                            phone: dataUser.value.phone_number
                        }

                        axios.post('/services/store', data)
                        .then((resp) => {
                            isLoading.value = false
                            if(localStorage.getItem('promoType')){
                                localStorage.removeItem('promoType')
                            }
                            if(localStorage.getItem('promoCode')){
                                localStorage.removeItem('promoCode')
                            }
                            router.push('/credit-checkup/checkout-summary/' + resp.data.data.serviceId)
                        })
                        .catch((err) => {
                            isLoading.value = false
                            if(err.response.status == 400 || err.response.status == 422 ){
                                if(err.response.data.message.includes('Selected time not available') || err.response.data.message.includes('selected slot is not available')){
                                    store.dispatch('bookingData/removeBookedTimeAndProviders')
                                    formData.selectedProvidersIndex = null
                                    formData.selectedDataProvidersIndex = null
                                    formData.providerId = null
                                    formData.providerName = null
                                    formData.providerEmail = null
                                    formData.startTime = null
                                    formData.startTimeIndex = null
                                    showSelectTime.value = false
                                    currentStep.value = 1
                                    swalError.fire({
                                        html: '<p class="text-center text-sm mt-2">Mohon Maaf, jadwal yang anda pilih tidak tersedia, silakan pilih kembali.</p>',
                                        showCancelButton: true,
                                        confirmButtonText: 'Tutup',
                                        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                                    }).then((result) => {
                                        if (result.isConfirmed){
                                            return;
                                        }
                                    });
                                }else{
                                    swalError.fire({
                                        html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                                        showCancelButton: true,
                                        confirmButtonText: 'Bantuan',
                                        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                                    }).then((result) => {
                                        if (result.isConfirmed){
                                            window.location = 'https://wa.me/6285888236207?text=' + 
                                            'Mohon bantuan sistem gagal memproses Booking Konsultasi Credit Checkup di web.amalan.com';
                                            return;
                                        }
                                    });
                                }
                            }else{
                                swalError.fire({
                                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                                    showCancelButton: true,
                                    confirmButtonText: 'Bantuan',
                                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                                }).then((result) => {
                                    if (result.isConfirmed){
                                        window.location = 'https://wa.me/6285888236207?text=' + 
                                        'Mohon bantuan sistem gagal memproses Booking Konsultasi Credit Checkup di web.amalan.com';
                                        return;
                                    }
                                });
                            }
                        })
                    }
                });
            }
        }

        function backButton(){
            if(currentStep.value == 1){
                router.push({ name : 'Home' })
            }else if(currentStep.value == 2){
                store.dispatch('bookingData/removeBookedTimeAndProviders')
                formData.selectedProvidersIndex = null
                formData.selectedDataProvidersIndex = null
                formData.providerId = null
                formData.providerName = null
                formData.providerEmail = null
                formData.startTime = null
                formData.startTimeIndex = null
                showSelectTime.value = false
                currentStep.value--
            }else{
                currentStep.value--
            }
        }

        function termsChecked(){
            formData.terms = !formData.terms;
        }

        function showTermsAndCondition(){
            store.dispatch('globalState/changeTermAndConditionStatus')
        }

        watch(() => dataAvailableDate.value, function() {
            getDataToCreateCalendar()
        });

        if(dataAvailableDate.value){
            getDataToCreateCalendar()
        }

        store.dispatch('user/requestIdCard')

        onMounted(() => {
            if(localStorage.getItem('ccu-product-destination')) localStorage.removeItem('ccu-product-destination')
        })
        
        return {
            filters,
            addDays,
            dataAvailableDate,
            dataAvailableProviders,
            dataCalendar,
            isLoading,
            stepCalendar,
            daysOfWeek,
            calculatePrevDay,
            createPrevDay,
            calculateNextDay,
            checkAvailableOrNot,
            getTheDate,
            selectDate,
            formData,
            currentStep,
            nextStep,
            selectProvider,
            backButton,
            selectTime,
            showSelectTime,
            termsChecked,
            showTermsAndCondition,
            termAndConditionStatus
        }
    }
})
</script>

<style scoped>
.dropdown-enter-active,
.dropdown-leave-active {
    transition: all 0.8s ease;
    max-height: 1000px;
    opacity: 1;
}

.dropdown-enter-from,
.dropdown-leave-to {
    opacity: 0;
    max-height: 0px;
    transition: all 0.2s ease;
}
</style>