<script setup>
import { computed, reactive, onMounted, ref, inject } from 'vue'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { Form, Field } from "vee-validate"
import * as yup from "yup"
import axios from "@/axios"
import CurrencyInput from "@/components/CurrencyInput2.vue"

const swal = inject("$swal")
const store = useStore()
const router = useRouter()
const route = useRoute()
const dataUser = computed(() => store.getters['user/getUser'])
const dataDocumentPoa = computed(() => store.getters['user/getDocumentPoa'])
const isLoading = ref(false)
const currentStep = ref(0)
const formValues = reactive({
    npwp: null,
    monthly_income: 0,
    mother_maiden_name: null
})
const defaultValues = {
    kewarganegaraan: 'WNI',
    negara: 'Indonesia',
    domisili_ktp: '+62',
    kantor_cabang_bank: 'Jakarta, Indonesia'
}

const schemas = [
    yup.object({
        npwp: yup.string().nullable().matches(/^[0-9]+$/, "Harus dalam bentuk angka").length(15, "Nomor NPWP harus 15 digit").transform((value) => value === '' ? null : value),
        mother_maiden_name: yup.string().nullable().required('Nama Ibu kandung tidak boleh kosong').max(50, "Maksimal 50 karakter").trim('Mohon isi'),
    }),
    yup.object({
        monthly_income: yup.number('Masukan dalam bentuk angka').nullable().required('Pendapatan perbulan tidak boleh kosong')
        .test('maxLength', 'Maksimal 10 digit', (value) => value && value.toString().length <= 10)
        .test('minLength', 'Minimal 5 digit', (value) => value && value.toString().length >= 5)
        .transform((value) => value && value === Number(value) ? value : 0)
    }),
    yup.object({})
]

const updateForm = computed(() => {
    return schemas[currentStep.value]
})

const swalError = swal.mixin({
    customClass: {
        confirmButton: 'bg-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-amalan-white text-sm font-semibold hover:bg-blue-900 hover:border-blue-900',
        cancelButton: 'absolute -top-3 -right-3'
    },
    buttonsStyling: false,
    title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
    width: '340px',
    allowOutsideClick: false,
    reverseButtons: true,
})

const swalConfirm = swal.mixin({
    customClass: {
        confirmButton: 'bg-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-amalan-white text-sm font-semibold hover:bg-blue-900 hover:border-blue-900',
        cancelButton: 'bg-amalan-white mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-amalan-blue-1 text-sm font-semibold hover:bg-amalan-red hover:border-amalan-red hover:text-amalan-white'
    },
    buttonsStyling: false,
    width: '340px',
    allowOutsideClick: false,
    reverseButtons: true,
})

const nextStep = (values) => {
    if (currentStep.value === 2) {
        Object.assign(formValues, values)
        const data = {
            npwp: formValues.npwp ? formValues.npwp === '' ? null : formValues.npwp : null,
            monthly_income: formValues.monthly_income ?? null,
            mother_maiden_name: formValues.mother_maiden_name ?? null
        }

        swalConfirm.fire({
            html: '<p class="text-center text-sm mt-2">Anda yakin data yang diinput sudah benar?<br>Jika sudah, silahkan untuk dikonfirmasi agar<br>data Anda segera diproses</p>',
            showCancelButton: true,
            confirmButtonText: 'Konfirmasi',
            cancelButtonText: 'Batalkan',
        }).then((result) => {
            if (result.isConfirmed){
                isLoading.value = true
                axios.put('/bni/rdp-preform', data)
                .then((resp) => {
                    isLoading.value = false
                    if(resp.status == 200){
                        router.push({ name : 'rdp opening - email verification' })
                    }else{
                        swalError.fire({
                            html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                            showCancelButton: true,
                            confirmButtonText: 'Bantuan',
                            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                        }).then((result) => {
                            if (result.isConfirmed){
                                window.location = 'https://wa.me/6285888236207?text=' + 
                                'Mohon bantuan saya gagal terus dalam pengisian data pengguna pada proses pembukaan RDP di web.amalan.com'
                                return
                            }
                        })
                    }
                }).catch((err) => {
                    isLoading.value = false
                    if(err.response.status == 400 || err.response.status == 422 ){
                        if(err.response.data.message.includes('Duplicate entry')){
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Maaf, Nomor NPWP Anda terdeteksi sudah terdaftar sebelumnya. Silahkan tekan tombol bantuan dibawah ini atau hubungi konsultan Anda.</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Mohon bantuan saya tidak dapat melanjutkan proses karena Nomor NPWP saya sudah terdaftar di akun lain.'
                                    return
                                }
                            })
                        } else {
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Mohon bantuan saya gagal terus dalam pengisian data pengguna pada proses pembukaan RDP di web.amalan.com'
                                    return
                                }
                            })
                        }
                    }else{
                        swalError.fire({
                            html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                            showCancelButton: true,
                            confirmButtonText: 'Bantuan',
                            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                        }).then((result) => {
                            if (result.isConfirmed){
                                window.location = 'https://wa.me/6285888236207?text=' + 
                                'Mohon bantuan saya gagal terus dalam pengisian data pengguna pada proses pembukaan RDP di web.amalan.com'
                                return
                            }
                        })
                    }
                })
            }
        })

        return
    }

    Object.assign(formValues, values)
    currentStep.value++
}

const prevStep = () => {
    if (currentStep.value <= 0) {
        return
    }

    currentStep.value--
}

const getNpwpData = () => {
    isLoading.value = true
    axios.get('/customer/id-card', {
        params: {
            id_type: 'npwp'
        }
    })
    .then((resp) => {
        formValues.npwp = resp.data.data.number
        isLoading.value = false
    })
    .catch(() => {
        isLoading.value = false
    })
}

const getIncomeData = () => {
    axios.get('/customer/income')
    .then((resp) => {
        formValues.monthly_income = resp.data.data.monthly_amount ? parseInt(resp.data.data.monthly_amount) : 0
    })
    .catch(() => {
        return
    })
}

onMounted(() => {
    if(dataDocumentPoa.value){
        getNpwpData()
        getIncomeData()

        const requestInfo = JSON.parse(dataDocumentPoa.value.request_info)
        formValues.mother_maiden_name = requestInfo.bni_mother_maiden_name ?? null
    }else{
        router.push({ name : 'rdp opening - document poa' })
    }
})

</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="router.push({ name : 'Home' })" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold mt-0 text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full max-w-screen-md px-6">
            <div class="pb-10 pt-6 w-full mx-auto">
                <div class="flex items-center">
                    <div class="flex items-center text-amalan-blue-1 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/signup/data-pinjaman-active.svg" class="w-5 h-5">
                        </div>
                        <div class="absolute -top-2 -ml-10 text-center mt-16 w-32 text-xs font-bold text-amalan-blue-1"><p>Data<br>Pengguna</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-amalan-gray-3"></div>
                    <div class="flex items-center text-amalan-gray-3 relative">
                        <div class="rounded-full h-12 w-12 border-2 bg-amalan-gray-5 border-amalan-gray-5 flex justify-center items-center">
                            <img src="@/assets/img/signup/tanda-tangan.svg" class="w-5 h-5">
                        </div>
                        <div class="absolute -top-2 -ml-10 text-center mt-16 w-32 text-xs font-bold text-amalan-gray-2"><p>Tanda<br>Tangan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-amalan-gray-3"></div>
                    <div class="flex items-center text-amalan-gray-3 relative">
                        <div class="rounded-full h-12 w-12 border-2 flex justify-center items-center bg-amalan-gray-5 border-amalan-gray-5">
                            <img src="@/assets/img/verified.svg" class="w-5 h-5">
                        </div>
                        <div class="absolute -top-2 -ml-4 text-center mt-16 w-20 text-xs text-amalan-gray-2"><p>Registrasi<br>Selesai</p></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full px-6 py-6 text-amalan-black">
            <Form
                @submit="nextStep"
                :validation-schema="updateForm"
                v-slot="{ handleSubmit, errors, meta }"
                >
                <template v-if="currentStep === 0">
                    <div class="my-3">
                        <label class="text-amalan-black font-bold text-sm">Nomor NPWP</label>
                        <Field name="npwp" v-model="formValues.npwp" :validateOnInput="true" :validateOnMount="true" type="text" placeholder="Nomor NPWP" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.npwp ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                        <div v-if="errors.npwp" class="mt-1 text-2xs text-amalan-red">*{{errors.npwp}}</div>
                        <div v-else class="mt-1 text-2xs text-amalan-blue-3">Silahkan isi jika Anda memilikinya</div>
                    </div>
                    <div class="my-3">
                        <label class="text-amalan-black font-bold text-sm">Kewarganegaraan</label>
                        <div class="flex relative w-full">
                            <Field name="kewarganegaraan" as="select" v-model="defaultValues.kewarganegaraan" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer border-amalan-gray-4">
                                <option value="WNI">WNI</option>
                            </Field>
                            <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-3 text-amalan-black">
                                <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="my-3">
                        <label class="text-amalan-black font-bold text-sm">Negara</label>
                        <div class="flex relative w-full">
                            <Field name="negara" as="select" v-model="defaultValues.negara" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer border-amalan-gray-4">
                                <option value="Indonesia">Indonesia</option>
                            </Field>
                            <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-3 text-amalan-black">
                                <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="my-3">
                        <label class="text-amalan-black font-bold text-sm">Nama Ibu Kandung</label>
                        <Field name="mother_maiden_name" v-model="formValues.mother_maiden_name" :validateOnInput="true" :validateOnMount="true" type="text" placeholder="Nama Ibu Kandung" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.mother_maiden_name ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                        <div v-if="errors.mother_maiden_name" class="mt-1 text-2xs text-amalan-red">*{{errors.mother_maiden_name}}</div>
                    </div>
                </template>

                <template v-if="currentStep === 1">
                    <div class="my-3 grid grid-cols-2 gap-x-4">
                        <div>
                            <label class="text-amalan-black font-bold text-sm">Domisili KTP</label>
                            <div class="flex relative w-full">
                                <Field name="domisili_ktp" as="select" v-model="defaultValues.domisili_ktp" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer border-amalan-gray-4">
                                    <option value="+62">+62</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-3 text-amalan-black">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label class="text-amalan-black font-bold text-sm">Masa Berlaku KTP</label>
                            <div class="w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none border-amalan-gray-4 flex justify-between items-center">
                                <span>99/99/999</span>
                                <img src="@/assets/img/date-picker-icon.svg" class="w-3 h-3">
                            </div>
                        </div>
                    </div>
                    <div class="my-3">
                        <label class="text-amalan-black font-bold text-sm">No Handphone</label>
                        <div class="flex">
                            <div class="w-14 px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-l-2 border-t-2 border-b-2 rounded-l-xl">{{ dataUser.phone_number.substring(0, 3) }}</div>
                            <div class="w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-r-xl">{{ dataUser.phone_number.substring(3) }}</div>
                        </div>
                    </div>
                    <div class="my-3">
                        <label class="text-amalan-black font-bold text-sm">Kantor Cabang Bank</label>
                        <div class="flex relative w-full">
                            <Field name="kantor_cabang_bank" as="select" v-model="defaultValues.kantor_cabang_bank" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer border-amalan-gray-4">
                                <option value="Jakarta, Indonesia">Jakarta, Indonesia</option>
                            </Field>
                            <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-3 text-amalan-black">
                                <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="my-3">
                        <label class="text-amalan-black font-bold text-sm">Pendapatan Perbulan</label>
                        <div class="flex flex-col relative w-full">
                            <CurrencyInput 
                            v-model="formValues.monthly_income" 
                            :propsClass="errors.monthly_income ? 'border-amalan-red' : 'border-amalan-gray-4'"
                            :options="{
                                currency: 'IDR',
                                currencyDisplay: 'narrowSymbol',
                                hideCurrencySymbolOnFocus: false,
                                hideGroupingSeparatorOnFocus: false,
                                hideNegligibleDecimalDigitsOnFocus: false,
                                autoDecimalDigits: false,
                                autoSign: false,
                                useGrouping: true,
                                accountingSign: true
                            }" />
                            <Field name="monthly_income" type="text" :validateOnMount="true" :validateOnInput="true" class="hidden" v-model="formValues.monthly_income" />
                            <div v-if="errors.monthly_income" class="mt-1 text-2xs text-amalan-red">*{{errors.monthly_income}}</div>
                        </div>
                    </div>
                </template>

                <template v-if="currentStep === 2">
                    <div class="text-amalan-black">
                        <div class="px-4 pt-3 pb-6">
                            <p class="text-lg text-center font-bold text-amalan-blue-1">
                                Konfirmasi Data
                            </p>
                            <p class="mt-4 text-xs text-center">
                                Cek kembali data Anda dan pastikan semua data yang diinputkan sesuai dengan data yang ada ya!
                            </p>
                        </div>
                        <div class="h-px w-full bg-amalan-gray-4"></div>
                        <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                            <div class="font-bold col-span-6 sm:col-span-4">Nomor NPWP</div>
                            <div class="col-span-6 sm:col-span-8 flex">
                                <span class="mr-1">:</span> 
                                <span>{{ formValues.npwp ? formValues.npwp === '' ? '-' : formValues.npwp : '-' }}</span>
                            </div>
                        </div>
                        <div class="h-px w-full bg-amalan-gray-4"></div>
                        <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                            <div class="font-bold col-span-6 sm:col-span-4">Kewarganegaraan</div>
                            <div class="col-span-6 sm:col-span-8 flex">
                                <span class="mr-1">:</span> 
                                <span>WNI</span>
                            </div>
                        </div>
                        <div class="h-px w-full bg-amalan-gray-4"></div>
                        <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                            <div class="font-bold col-span-6 sm:col-span-4">Negara</div>
                            <div class="col-span-6 sm:col-span-8 flex">
                                <span class="mr-1">:</span> 
                                <span>Indonesia</span>
                            </div>
                        </div>
                        <div class="h-px w-full bg-amalan-gray-4"></div>
                        <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                            <div class="font-bold col-span-6 sm:col-span-4">Nama Ibu Kandung</div>
                            <div class="col-span-6 sm:col-span-8 flex">
                                <span class="mr-1">:</span> 
                                <span>{{ formValues.mother_maiden_name ?? '-' }}</span>
                            </div>
                        </div>
                        <div class="h-px w-full bg-amalan-gray-4"></div>
                        <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                            <div class="font-bold col-span-6 sm:col-span-4">Domisili KTP</div>
                            <div class="col-span-6 sm:col-span-8 flex">
                                <span class="mr-1">:</span> 
                                <span>+62</span>
                            </div>
                        </div>
                        <div class="h-px w-full bg-amalan-gray-4"></div>
                        <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                            <div class="font-bold col-span-6 sm:col-span-4">Masa Berlaku KTP</div>
                            <div class="col-span-6 sm:col-span-8 flex">
                                <span class="mr-1">:</span> 
                                <span>99/99/9999</span>
                            </div>
                        </div>
                        <div class="h-px w-full bg-amalan-gray-4"></div>
                        <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                            <div class="font-bold col-span-6 sm:col-span-4">No Handphone</div>
                            <div class="col-span-6 sm:col-span-8 flex">
                                <span class="mr-1">:</span> 
                                <span>{{ dataUser.phone_number }}</span>
                            </div>
                        </div>
                        <div class="h-px w-full bg-amalan-gray-4"></div>
                        <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                            <div class="font-bold col-span-6 sm:col-span-4">Kantor Cabang Bank</div>
                            <div class="col-span-6 sm:col-span-8 flex">
                                <span class="mr-1">:</span> 
                                <span>Jakarta, Indonesia</span>
                            </div>
                        </div>
                        <div class="h-px w-full bg-amalan-gray-4"></div>
                        <div class="my-2 py-1 px-4 grid grid-cols-12 text-xs">
                            <div class="font-bold col-span-6 sm:col-span-4">Pendapatan Perbulan</div>
                            <div class="col-span-6 sm:col-span-8 flex">
                                <span class="mr-1">:</span> 
                                <span>{{ formValues.monthly_income ? $filters.format_number(formValues.monthly_income) : '-' }}</span>
                            </div>
                        </div>
                        <div class="h-px w-full bg-amalan-gray-4"></div>
                    </div>
                </template>

                <div class="w-full mt-24 pb-20">
                    <button v-if="currentStep !== 0" type="button" class="h-10 rounded-lg font-medium border border-solid cursor-pointer text-center text-base py-1 px-1 text-amalan-blue-1 border-amalan-blue-1 hover:bg-amalan-blue-1 hover:text-amalan-white float-left" @click="prevStep"><ChevronLeftIcon class="h-full" /></button>
                    <button v-if="currentStep !== 2" type="submit" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-1 float-right" :class="[ meta.valid ? 'text-amalan-white bg-amalan-blue-1 border-amalan-blue-1 hover:bg-blue-900 hover:border-blue-900 cursor-pointer' : 'text-amalan-gray-3 bg-amalan-gray-5 border-amalan-gray-5 cursor-not-allowed' ]" @submit="handleSubmit"><ChevronRightIcon class="h-full" /></button>
                    <button v-if="currentStep === 2" type="submit" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-6 text-amalan-white float-right cursor-pointer bg-amalan-blue-1 border-amalan-blue-1 hover:bg-blue-900 hover:border-blue-900">Kirim</button>
                </div>
            </Form>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>