<template>
    <div class="pt-4 min-h-screen h-auto relative">
        <div class="mt-2 pb-12 w-full px-4">
            <div class="flex items-center">
                <div class="flex items-center text-blue-900 relative">
                    <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                        <img src="@/assets/img/signup/data-pinjaman-active.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Data<br>Pinjaman</p></div>
                </div>
                <div class="flex-auto border-t-2 border-blue-900"></div>
                <div class="flex items-center text-blue-900 relative">
                    <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                        <img src="@/assets/img/protect/data-pelanggaran-active.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Data<br>Pelanggaran</p></div>
                </div>
                <div class="flex-auto border-t-2 border-gray-300"></div>
                <div class="flex items-center text-gray-500 relative">
                    <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                        <img src="@/assets/img/signup/tanda-tangan.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Tanda<br>Tangan</p></div>
                </div>
                <div class="flex-auto border-t-2 border-gray-300"></div>
                <div class="flex items-center text-gray-500 relative">
                    <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                        <img src="@/assets/img/signup/pembayaran.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Pembayaran<br>Layanan</p></div>
                </div>
                <div class="flex-auto border-t-2 border-gray-300"></div>
                <div class="flex items-center text-gray-500 relative">
                    <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                        <img src="@/assets/img/verified.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Konfirmasi<br>Layanan</p></div>
                </div>
            </div>
        </div>
        <template v-if="formStep == 'data-pelanggaran'">
            <Form
                @submit="formTermsSubmit"
                :validation-schema="formTermsSchema"
                v-slot="{ errors }"
                >
                <div class="mt-6 pb-40 w-full px-4">
                    <div class="my-6" v-if="docPendukung">
                        <p class="text-center text-blue-900 font-bold text-base">Konfirmasi Data</p>
                        <p class="text-center text-sm mt-2">Cek kembali data Anda dan pastikan semua data yang diinputkan sesuai dengan data yang ada ya!</p>
                    </div>
                    <div class="my-6" v-if="dataPinjaman">
                        <div class="w-full rounded-lg px-4 py-3 mt-6 border border-solid bg-white">
                            <div class="flex items-center">
                                <div class="w-16 h-16 rounded-full flex justify-center items-center border-2 border-amalan-yellow-400 bg-white">
                                    <img :src="require('@/assets/img/signup/icon-jenis-pinjaman/' + getIcon(dataPinjaman.loanType))">
                                </div>
                                <div class="ml-4">
                                    <p class="text-sm font-bold my-0">{{ splitBankName(dataPinjaman.ref_bank_name) }}</p>
                                    <p class="text-xs font-semibold my-0">{{ dataPinjaman.loanType }}</p>
                                    <p class="text-xs font-semibold my-0">{{ dataPinjaman.accountNumber }}</p>
                                </div>
                                <div class="ml-auto" v-if="!docPendukung">
                                    <router-link to="/amalan-protect/data-pinjaman" class="w-8 h-8 flex justify-center items-center rounded-full border border-blue-900 bg-white cursor-pointer hover:bg-gray-300"><img src="@/assets/img/signup/edit-icon.svg"></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my-6" v-if="dataPelanggaran">
                        <div class="w-full rounded-lg px-4 py-3 border border-solid bg-white">
                            <div class="flex justify-between items-center mb-4">
                                <span class="text-base font-bold text-blue-900">Informasi Debt Collector</span>
                                <button type="button" class="w-8 h-8 flex justify-center items-center rounded-full border border-blue-900 bg-white cursor-pointer hover:bg-gray-300" @click="() => { formStep = 'tambah-pelanggaran' }" v-if="!docPendukung"><img src="@/assets/img/signup/edit-icon.svg"></button>
                            </div>
                            <div class="my-2 border-b-2 border-solid pb-2">
                                <h1 class="font-bold text-sm">Nama</h1>
                                <h1 class="text-sm">{{ dataPelanggaran.debtCollectorName }}</h1>
                                <h1 class="text-sm" v-if="!dataPelanggaran.debtCollectorName">-</h1>
                            </div>
                            <div class="my-2 border-b-2 border-solid pb-2">
                                <h1 class="font-bold text-sm">Nomor HP</h1>
                                <h1 class="text-sm">{{ dataPelanggaran.debtCollectorPhoneNumber }}</h1>
                                <h1 class="text-sm" v-if="!dataPelanggaran.debtCollectorPhoneNumber">-</h1>
                            </div>
                            <div class="my-2 border-b-2 border-solid pb-2">
                                <h1 class="font-bold text-sm">Nomor Identitas</h1>
                                <h1 class="text-sm">{{ dataPelanggaran.debtCollectorIdCard }}</h1>
                                <h1 class="text-sm" v-if="!dataPelanggaran.debtCollectorIdCard">-</h1>
                            </div>
                            <div class="my-2 border-b-2 border-solid pb-2">
                                <h1 class="font-bold text-sm">Tanggal Kunjungan</h1>
                                <h1 class="text-sm">{{ dataPelanggaran.billingDate }}</h1>
                            </div>
                            <div class="my-2 border-b-2 border-solid pb-2">
                                <h1 class="font-bold text-sm">Alamat Kunjungan</h1>
                                <h1 class="text-sm">{{ dataPelanggaran.interuptionLocation }}</h1>
                            </div>
                            <div class="my-2 border-b-2 border-solid pb-2">
                                <h1 class="font-bold text-sm">Bentuk Pelanggaran</h1>
                                <h1 class="text-sm">{{ dataPelanggaran.disturbance }}</h1>
                            </div>
                            <div class="my-2">
                                <h1 class="font-bold text-sm">Kronologi Pelanggaran</h1>
                                <h1 class="text-sm">{{ dataPelanggaran.interuptionChronology }}</h1>
                            </div>
                        </div>
                        <template v-if="docPendukung">
                            <div class="w-full rounded-lg px-4 py-3 mt-4 border border-solid bg-white flex justify-between items-center">
                                <span class="text-base text-blue-900 font-bold">Dokumen Pendukung <span class="text-sm text-blue-900 font-normal">(<template v-for="(value, index, key) in docPendukung" :key="key">{{ key !== Object.keys(docPendukung).length - 1 ? (key + 1) + ', ' : key + 1 }}</template>)</span></span>
                                <div class="w-16">
                                    <EyeIcon class="w-6 h-6 text-blue-900 cursor-pointer hover:text-blue-700 inline-block mx-1" @click="$router.push('/amalan-protect/dokumen-pelanggaran')" />
                                    <TrashIcon class="h-6 w-6 text-red-700 cursor-pointer hover:text-red-500 inline-block mx-1" @click="removeDokumenStored" />
                                </div>
                            </div>
                            <div class="mt-6 mb-10 hidden">
                                <label class="text-gray-700 font-medium text-xs inline-flex" for="terms">
                                    <label class="h-4 w-4 border-2 border-gray-400 rounded mr-2 p-0 cursor-pointer" :class="{ 'border-red-600': errors.terms, 'bg-amalan-yellow-400 border-amalan-yellow-400' : formTerms.terms }" @click="termsChecked">
                                        <CheckIcon v-if="formTerms.terms" class="w-3 h-3 mx-auto text-blue-900" />
                                        <div v-else class="w-3 h-3 mx-auto bg-transparent"></div>
                                    </label>
                                    <Field name="terms" v-model="formTerms.terms" type="checkbox" class="hidden" :validateOnMount="true" :validateOnInput="true" />
                                    <span @click="termsChecked" class="self-center cursor-pointer text-sm" :class="{ 'text-red-600': errors.terms }">Saya menyetujui seluruh <span class="underline" :class="[ errors.terms ? 'text-red-600' : 'text-blue-900' ]">syarat dan ketentuan</span> yang berlaku di <b>amalan international.</b></span>
                                </label>
                                <div v-if="errors.terms" class="text-sm text-red-600">*{{errors.terms}}</div>
                            </div>
                        </template>
                    </div>
                    <button v-else type="button" class="w-full py-3 text-sm font-bold bg-blue-900 border border-blue-900 text-white rounded-lg hover:bg-blue-700 hover:border-blue-700" @click="() => { formStep = 'tambah-pelanggaran' }">
                        + Tambahkan Informasi Debt Collector
                    </button>
                </div>
                <div class="absolute bottom-0 mb-28 w-full px-4">
                    <router-link to="/amalan-protect/data-pinjaman" class="h-10 rounded-lg font-medium border border-solid cursor-pointer text-center text-base py-1 px-1 text-blue-900 border-blue-900 hover:bg-blue-900 hover:text-white float-left" v-if="docPendukung"><ChevronLeftIcon class="h-full" /></router-link>
                    <button class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-1 text-gray-300 border-gray-300 float-left cursor-not-allowed" v-if="!docPendukung"><ChevronLeftIcon class="h-full" /></button>
                    <button v-if="docPendukung" type="submit" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-6 text-white float-right" :class="[ formTerms.terms ? 'cursor-pointer bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700' : 'bg-gray-300 border-gray-300 cursor-not-allowed' ]" :disabled="!formTerms.terms">Kirim</button>
                    <button v-else type="button" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-1 text-white float-right" :class="[ dataPelanggaran ? 'cursor-pointer bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700' : 'bg-gray-300 border-gray-300 cursor-not-allowed' ]" :disabled="!dataPelanggaran" @click="() => { formStep = 'tambah-dokumen' }"><ChevronRightIcon class="h-full" /></button>
                </div>
            </Form>
        </template>
        <template v-if="formStep == 'tambah-pelanggaran'">
            <Form
                @submit="formDebtCollector"
                :validation-schema="formDataPelanggaranSchema"
                v-slot="{ errors, meta }"
                >
                <div class="mt-6 pb-48 w-full px-4">
                    <!-- <div class="my-2">
                        <label class="text-gray-700 font-bold text-sm">Nama</label>
                        <Field name="debtCollectorName" :validateOnMount="true" :validateOnInput="true" type="text" class="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none pl-4 pr-8 hover:border-gray-400" :class="{ 'border-red-600': errors.debtCollectorName }" v-model="formDataPelanggaran.debtCollectorName" />
                        <div v-if="errors.debtCollectorName" class="mt-1 text-sm text-red-600">*{{errors.debtCollectorName}}</div>
                    </div> -->
                    <div class="my-2">
                        <label class="text-gray-700 font-bold text-sm">Nama</label>
                        <Field name="debtCollectorName" type="text" :validateOnInput="true" class="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none pl-4 pr-8 hover:border-gray-400" v-model="formDataPelanggaran.debtCollectorName" :class="{ 'border-red-600': errors.debtCollectorName }" />
                        <div v-if="errors.debtCollectorName" class="mt-1 text-sm text-red-600">*{{errors.debtCollectorName}}</div>
                    </div>
                    <!-- <div class="my-2">
                        <label class="text-gray-700 font-bold text-sm">Nomor HP</label>
                        <Field name="debtCollectorPhoneNumber" :validateOnMount="true" :validateOnInput="true" type="text" class="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none pl-4 pr-8 hover:border-gray-400" :class="{ 'border-red-600': errors.debtCollectorPhoneNumber }" v-model="formDataPelanggaran.debtCollectorPhoneNumber" />
                        <div v-if="errors.debtCollectorPhoneNumber" class="mt-1 text-sm text-red-600">*{{errors.debtCollectorPhoneNumber}}</div>
                    </div> -->
                    <div class="my-2">
                        <label class="text-gray-700 font-bold text-sm">Nomor HP</label>
                        <Field name="debtCollectorPhoneNumber" type="text" :validateOnInput="true" class="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none pl-4 pr-8 hover:border-gray-400" v-model="formDataPelanggaran.debtCollectorPhoneNumber" :class="{ 'border-red-600': errors.debtCollectorPhoneNumber }" />
                        <div v-if="errors.debtCollectorPhoneNumber" class="mt-1 text-sm text-red-600">*{{errors.debtCollectorPhoneNumber}}</div>
                    </div>
                    <!-- <div class="my-2">
                        <label class="text-gray-700 font-bold text-sm">Nomor Identitas</label>
                        <Field name="debtCollectorIdCard" :validateOnMount="true" :validateOnInput="true" type="text" class="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none pl-4 pr-8 hover:border-gray-400" :class="{ 'border-red-600': errors.debtCollectorIdCard }" v-model="formDataPelanggaran.debtCollectorIdCard" />
                        <div v-if="errors.debtCollectorIdCard" class="mt-1 text-sm text-red-600">*{{errors.debtCollectorIdCard}}</div>
                    </div> -->
                    <div class="my-2">
                        <label class="text-gray-700 font-bold text-sm">Nomor Identitas</label>
                        <Field name="debtCollectorIdCard" type="text" :validateOnInput="true" class="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none pl-4 pr-8 hover:border-gray-400" v-model="formDataPelanggaran.debtCollectorIdCard" :class="{ 'border-red-600': errors.debtCollectorIdCard }" />
                        <div v-if="errors.debtCollectorIdCard" class="mt-1 text-sm text-red-600">*{{errors.debtCollectorIdCard}}</div>
                    </div>
                    <div class="my-2">
                        <label class="text-gray-700 font-bold text-sm">Tanggal Kunjungan</label>
                        <Field name="billingDate" v-model="formDataPelanggaran.billingDate" :validateOnInput="true" :validateOnMount="true" type="date" class="block w-full py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none px-4 hover:border-gray-400" :class="{ 'border-red-600': errors.billingDate }" />
                        <div v-if="errors.billingDate" class="mt-1 text-sm text-red-600">*{{errors.billingDate}}</div>
                    </div>
                    <div class="my-2">
                        <label class="text-gray-700 font-bold text-sm">Alamat Kunjungan</label>
                        <Field name="interuptionLocation" :validateOnMount="true" :validateOnInput="true" type="text" class="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none pl-4 pr-8 hover:border-gray-400" :class="{ 'border-red-600': errors.interuptionLocation }" v-model="formDataPelanggaran.interuptionLocation" />
                        <div v-if="errors.interuptionLocation" class="mt-1 text-sm text-red-600">*{{errors.interuptionLocation}}</div>
                    </div>

                    <!-- <div class="my-2">
                        <label class="text-gray-700 font-bold text-sm">Bentuk Pelanggaran</label>
                        <Field name="disturbance" :validateOnMount="true" :validateOnInput="true" type="text" class="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none pl-4 pr-8 hover:border-gray-400" :class="{ 'border-red-600': errors.disturbance }" v-model="formDataPelanggaran.disturbance" />
                        <div v-if="errors.disturbance" class="mt-1 text-sm text-red-600">*{{errors.disturbance}}</div>
                    </div> -->
                    
                    <div class="my-2">
                        <label class="text-gray-700 font-bold text-sm">Bentuk Pelanggaran</label>
                        <div class="flex relative w-full">
                            <div class="w-full box-border border rounded-md" :class="[ errors.disturbance ? 'border-red-600' : 'border-gray-200']">
                                <Multiselect
                                v-model="formDataPelanggaran.disturbance"
                                ref="multiselect"
                                :noResultsText="'Data tidak ditemukan.'"
                                :noOptionsText="'Data tidak ada.'"
                                :searchable="true"
                                :options="dataDisturbances"
                                :classes="multiselectClass"
                                />
                            </div>
                            <Field name="disturbance" type="text" class="hidden" :validateOnMount="true" :validateOnInput="true" v-model="formDataPelanggaran.disturbance" />
                        </div>
                        <div v-if="errors.disturbance" class="mt-1 text-sm text-red-600">*{{errors.disturbance}}</div>
                    </div>

                    <div class="my-2">
                        <label class="text-gray-700 font-bold text-sm">Kronologi Pelanggaran</label>
                        <Field name="interuptionChronology" :validateOnMount="true" :validateOnInput="true" type="text" class="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none pl-4 pr-8 hover:border-gray-400" :class="{ 'border-red-600': errors.interuptionChronology }" v-model="formDataPelanggaran.interuptionChronology" />
                        <div v-if="errors.interuptionChronology" class="mt-1 text-sm text-red-600">*{{errors.interuptionChronology}}</div>
                    </div>
                </div>
                <div class="absolute bottom-0 mb-28 w-full px-4">
                    <button type="submit" class="w-full py-3 text-sm font-bold text-white rounded-lg" :class="[ meta.valid ? 'cursor-pointer bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700' : 'bg-gray-300 border-gray-300 cursor-not-allowed' ]" :disabled="!meta.valid">Tambahkan</button>
                </div>
            </Form>
        </template>
        <template v-if="formStep == 'tambah-dokumen'">
            <Form
                @submit="formDokumenSubmit"
                :validation-schema="formDokumenSchema"
                v-slot="{ errors }"
                >
                <div class="mt-6 pb-48 w-full px-4">
                    <p class="text-center text-sm">Tambahkan dokumen pendukung terhadap pelanggaran prilaku yang Anda alami</p>
                    <Field type="file" id="fileInput" name="file_input" class="hidden" v-model="tempFile.file_input" @input="previewFile"/>
                    <div v-if="formDokumen.file.length == 0" class="h-40 w-full rounded-lg border-2 border-solid flex justify-center items-center mt-4">
                        <span class="text-sm">Tidak ditemukan data dokumen pendukung</span>
                    </div>
                    <template v-else>
                        <div v-for="(value, index) in formDokumen.file" :key="index" class="w-full rounded-lg border border-gray-300 mt-4 mb-2">
                            <div class="w-full h-1/6 px-4 py-2 flex justify-between items-center border-b border-gray-300">
                                <span class="text-sm">Dokumen Pendukung {{ index + 1 }}</span>
                                <button type="button" class="h-7 w-7 border border-blue-900 text-blue-900 rounded-full flex justify-center items-center cursor-pointer hover:bg-blue-900 hover:text-white" @click="removeDokumen(index)">
                                    <TrashIcon class="h-5 w-5" />
                                </button>
                            </div>
                            <div class="w-full h-5/6 flex justify-center items-center px-2 py-2">
                                <img v-if="formDokumenPreview.file[index].nameFileTypePratinjau == 'gambar' && formDokumenPreview.file[index].urlPratinjau" :src="formDokumenPreview.file[index].urlPratinjau" class="w-4/6">
                                <video v-else-if="formDokumenPreview.file[index].nameFileTypePratinjau == 'video' && formDokumenPreview.file[index].urlPratinjau" :src="formDokumenPreview.file[index].urlPratinjau" class="w-full" controls></video>
                                <audio v-else-if="formDokumenPreview.file[index].nameFileTypePratinjau == 'audio' && formDokumenPreview.file[index].urlPratinjau" :src="formDokumenPreview.file[index].urlPratinjau" class="w-full" controls></audio>
                                <template v-else-if="formDokumenPreview.file[index].nameFileTypePratinjau == 'pdf'">
                                    <div class="w-40">
                                        <img src="@/assets/img/protect/thumbnail-pdf.png" class="w-full">
                                        <p class="text-center text-sm px-2 overflow-scroll">{{ formDokumenPreview.file[index].fileName }}</p>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="w-40">
                                        <img src="@/assets/img/protect/thumbnail-pdf.png" class="w-full">
                                        <p class="text-center text-sm px-2 overflow-scroll">{{ formDokumenPreview.file[index].fileName }}</p>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>
                    <template v-if="formDokumen.file.length < 3">
                        <button type="button" class="mt-4 w-full py-3 text-sm font-bold bg-white border border-blue-900 text-blue-900 rounded-lg hover:border-8" @click="clickFileInput">
                            + Tambahkan Dokumen Pendukung
                        </button>
                        <div v-if="errors.file_input" class="mt-1 text-sm text-red-600">*{{errors.file_input}}</div>
                        <p class="text-center text-xs mt-4">Anda dapat menambahkan hingga 3 dokumen dengan format PNG, jpg, jpeg, PDF, doc video, audio dan maksimal ukuran 50 MB.</p>
                    </template>
                </div>
                <div class="absolute bottom-0 mb-28 w-full px-4">
                    <button type="button" class="h-10 rounded-lg font-medium border border-solid cursor-pointer text-center text-base py-1 px-1 text-blue-900 border-blue-900 hover:bg-blue-900 hover:text-white float-left" @click="() => { formStep = 'data-pelanggaran' }"><ChevronLeftIcon class="h-full" /></button>
                    <button type="submit" id="formDokumenButton" class="hidden"></button>
                    <button type="button" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-6 text-white float-right" :class="[ formDokumen.file.length !== 0 ? 'cursor-pointer bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700' : 'bg-gray-300 border-gray-300 cursor-not-allowed' ]" :disabled="formDokumen.file.length == 0" @click="sendDokumen">Kirim</button>
                </div>
            </Form>
        </template>
        <template v-if="showFilePreview">
            <div class="fixed inset-0 z-50 bg-gray-900 bg-opacity-75 grid grid-cols-1 p-3 sm:p-10 overflow-y-auto">
                <div class="bg-white rounded-lg w-full sm:w-8/12 place-self-center">
                    <div class="bg-blue-100 rounded-t-lg py-2"> 
                        <p class="text-center text-base font-bold">Pratinjau</p>
                    </div>
                    <div v-if="!errorMessage" class="w-full p-4">
                        <img v-if="nameFileTypePratinjau == 'gambar' && urlPratinjau" :src="urlPratinjau" class="w-full sm:w-8/12 sm:mx-auto">
                        <video v-else-if="nameFileTypePratinjau == 'video' && urlPratinjau" :src="urlPratinjau" class="w-full" controls></video>
                        <audio v-else-if="nameFileTypePratinjau == 'audio' && urlPratinjau" :src="urlPratinjau" :type="fileTypePratinjau" class="w-full" controls></audio>
                        <template v-else-if="nameFileTypePratinjau == 'pdf'">
                            <div class="w-40 mx-auto">
                                <img src="@/assets/img/protect/thumbnail-pdf.png" class="w-full">
                                <p class="text-center text-sm px-2 overflow-scroll">{{ fileName }}</p>
                            </div>
                        </template>
                        <template v-else>
                            <div class="w-40 mx-auto">
                                <img src="@/assets/img/protect/thumbnail-doc.png" class="w-full">
                                <p class="text-center text-sm px-2 overflow-scroll">{{ fileName }}</p>
                            </div>
                        </template>
                    </div>
                    <p v-if="errorMessage" class="text-center font-semibold my-8 text-sm px-4">Pastikan file yang Anda upload dalam format PNG, jpg, jpeg, dan PDF.</p>
                    <div class="flex justify-between">
                        <button type="button" class="w-6/12 rounded-bl-lg py-2 text-sm font-bold text-blue-900 bg-white border border-blue-900 hover:text-white hover:bg-blue-700" @click="clickFileInput">Pilih Kembali</button>
                        <button type="button" class="w-6/12 rounded-br-lg py-2 text-sm font-bold text-white" @click="unggahFile" :class="[ !errorMessage ? 'cursor-pointer bg-blue-900 hover:bg-blue-700' : 'bg-gray-300 cursor-not-allowed' ]" :disabled="errorMessage">Unggah</button>
                    </div>
                </div>
            </div>
        </template>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<script>
import { ChevronRightIcon, ChevronLeftIcon, CheckIcon, EyeIcon } from '@heroicons/vue/solid';
import { TrashIcon } from '@heroicons/vue/outline';
import { useStore } from 'vuex';
import { defineComponent, ref, computed, reactive, inject } from 'vue';
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import amalansignupApi from "@/axios/amalansignup";
import router from "../../router";
import Multiselect from '@vueform/multiselect'
import { listLoanType as listIcon } from '@/constants/LoanConstant'


export default defineComponent({
    components: {
        ChevronRightIcon,
        ChevronLeftIcon,
        CheckIcon,
        EyeIcon,
        TrashIcon,
        Form,
        Field,
        Multiselect
    },
    data(){
        return {
            showFilePreview: false,
            errorMessage: false,
            urlPratinjau: null,
            fileTypePratinjau: null,
            fileName: null,
            nameFileTypePratinjau: null,
            multiselectClass: {
                container: 'relative mx-auto w-full flex items-center justify-end cursor-pointer rounded-md bg-white text-base leading-snug outline-none',
                containerOpen: 'rounded-b-none',
                containerOpenTop: 'rounded-t-none',
                singleLabel: 'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border',
                singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
                search: 'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5',
                caret: 'bg-multiselect-caret bg-center bg-no-repeat w-6 h-6 py-px box-content mr-2 relative z-10 opacity-80 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none text-blue-900',
                caretOpen: 'rotate-180 pointer-events-auto',
                clear: 'pr-2 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80',
                clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
                dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-md shadow-lg',
                dropdownTop: '-translate-y-full top-px bottom-auto flex-col-reverse rounded-md shadow-lg',
                dropdownHidden: 'hidden',
                options: 'flex flex-col p-0 m-0 list-none',
                optionsTop: 'flex-col-reverse',
                option: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-1 px-3',
                optionPointed: 'text-white bg-blue-500',
                noOptions: 'py-2 px-3 text-gray-600 bg-white text-center',
                noResults: 'py-2 px-3 text-gray-600 bg-white text-center',
                spacer: 'h-10 py-px box-content',
            },
        }
    },
    methods: {
        getIcon(loanType){
            let loanIcon = listIcon.filter(elem => elem.title == loanType);
            if (loanIcon[0]) return loanIcon[0].icon
        },
        splitBankName(value){
            const newVal = value.split(';');
            return newVal[1];
        },
        clickFileInput(){
            document.getElementById("fileInput").click()
        },
        unggahFile(){
            if(this.urlPratinjau){
                this.showFilePreview = false;
                this.urlPratinjau = null;
                this.fileTypePratinjau = null;
                this.nameFileTypePratinjau = null;
                this.fileName = null;
                document.getElementById("formDokumenButton").click()
            }
        },
        previewFile() {
            this.urlPratinjau = null;
            this.fileTypePratinjau = null;
            this.fileName = null;
            this.nameFileTypePratinjau = null;
            if (this.tempFile.file_input.length > 0 && this.SUPPORTED_FORMATS.includes(this.tempFile.file_input[0].type) && this.tempFile.file_input[0].size <= this.FILE_SIZE){
                const reader = new window.FileReader();
                reader.onload = (e) => {
                    this.fileTypePratinjau = this.tempFile.file_input[0].type;
                    this.nameFileTypePratinjau = this.fileTypePratinjau.includes("image") ? "gambar" : this.fileTypePratinjau.includes("video") ? "video" : this.fileTypePratinjau.includes("audio") ? "audio" : this.fileTypePratinjau.includes("pdf") ?"pdf" : "dokumen";
                    this.fileName = this.tempFile.file_input[0].name;
                    this.errorMessage = false;
                    this.showFilePreview = true;
                    this.urlPratinjau = e.target.result;
                }
                reader.readAsDataURL(this.tempFile.file_input[0]);
            } else {
                this.errorMessage = true;
                this.urlPratinjau = null;
                this.fileTypePratinjau = null;
                this.fileName = null;
                this.nameFileTypePratinjau = null;
                this.tempFile = {};
            }
        }
    },
    created(){
        if(this.dataPinjaman == null){
            this.$router.push('/amalan-protect/data-pinjaman')
        }

        this.formTerms.terms = true;
    },
    setup(){
        const FILE_SIZE = 49152 * 1024;
        const SUPPORTED_FORMATS = [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "application/pdf",
            "video/x-msvideo",
            "video/mp4",
            "video/x-m4v",
            "video/quicktime",
            "audio/ogg",
            "audio/mp4",
            "audio/mpeg",
            "audio/m4a",
            "video/3gpp",
            "audio/x-m4a"
        ];
        const store = useStore();
        const formStep = ref('data-pelanggaran');
        const dataPinjaman = computed(() => store.getters['formAmalanProtect/getDataPinjaman']);
        const dataPelanggaran = computed(() => store.getters['formAmalanProtect/getDataPelanggaran']);
        const docPendukung = computed(() => store.getters['formAmalanProtect/getDocPendukung']);

        let dataDisturbances = reactive([]);
        const swal = inject("$swal");

        const swalErrorReload = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
            },
            buttonsStyling: false,
            title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
            html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
            showCancelButton: false,
            confirmButtonText: 'Perbaharui',
            width: '340px',
            allowOutsideClick: false,
        })
        
        amalansignupApi.get('salesforce/amalan-protects/disturbances').then(resp => {
            for (let i in resp.data.data.disturbances){
                dataDisturbances.push({ value: resp.data.data.disturbances[i], label: resp.data.data.disturbances[i]});
            }
        }).catch(() => {
            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        });

        const formTerms = reactive({});
        const tempFile = reactive({});
        const formDokumen = reactive({
            file: [],
        });
        const formDokumenPreview = reactive({
            file: [],
        });
        let isLoading = ref(false);

        const swalConfirm = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600',
                cancelButton: 'bg-white mx-2 py-2 px-5 rounded-lg border border-blue-900 text-blue-900 text-sm font-semibold hover:bg-red-400 hover:border-red-400 hover:text-white'
            },
            buttonsStyling: false,
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        const swalConfirmDelete = swal.mixin({
            customClass: {
                confirmButton: 'bg-red-600 text-white mx-2 py-2 px-5 rounded-lg border border-red-600 text-sm font-semibold hover:bg-red-500 hover:border-red-500',
                cancelButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-700 hover:border-blue-700'
            },
            buttonsStyling: false,
            title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        const swalError = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600',
                cancelButton: 'absolute -top-3 -right-3'
            },
            buttonsStyling: false,
            title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        const formTermsSchema = yup.object().shape({
            terms: yup.bool().required('Silahkan dicentang').equals([true], 'Silahkan dicentang'),
        });

        const formDokumenSchema = yup.object({
            file_input: yup.mixed().required("Pilih file.")
            .test("fileSize", "Ukuran file maksimal 48 MB.", (value) => { if (value && value.length > 0) return value[0].size <= FILE_SIZE }).test("fileFormat", "Format file tidak di dukung.", (value) => { if (value && value.length > 0) return SUPPORTED_FORMATS.includes(value[0].type) }),
        });

        const formDataPelanggaran = reactive({
            debtCollectorName: dataPelanggaran.value ? dataPelanggaran.value.debtCollectorName : null,
            debtCollectorPhoneNumber: dataPelanggaran.value ? dataPelanggaran.value.debtCollectorPhoneNumber : null,
            debtCollectorIdCard: dataPelanggaran.value ? dataPelanggaran.value.debtCollectorIdCard : null,
            billingDate: dataPelanggaran.value ? dataPelanggaran.value.billingDate : null,
            interuptionLocation: dataPelanggaran.value ? dataPelanggaran.value.interuptionLocation : null,
            disturbance: dataPelanggaran.value ? dataPelanggaran.value.disturbance : null,
            interuptionChronology: dataPelanggaran.value ? dataPelanggaran.value.interuptionChronology : null,
        });

        const formDataPelanggaranSchema = yup.object().shape({
            debtCollectorName: yup.string().nullable(),
            debtCollectorPhoneNumber: yup.string().nullable().transform((o, c) => o === "" ? null : c).matches(/^[0-9]+$/, "Nomor Hp harus berupa angka").max(15, "Maksimal 15 Karakter"),
            debtCollectorIdCard: yup.string().nullable().transform((o, c) => o === "" ? null : c).matches(/^[0-9]+$/, "Nomor Identitas harus berupa angka"),
            billingDate: yup.string().nullable().required('Tanggal kunjungan tidak boleh kosong').matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Tanggal kunjungan harus berformat YYYY-MM-DD'),
            interuptionLocation: yup.string().nullable().required('Alamat kunjungan tidak boleh kosong'),
            disturbance: yup.string().nullable().required('Bentuk pelanggaran tidak boleh kosong'),
            interuptionChronology: yup.string().nullable().required('Kronologi pelanggaran tidak boleh kosong'),
        });

        function formDebtCollector(values) {
            store.dispatch('formAmalanProtect/storeDataPelanggaran', values).then(() => {
                localStorage.setItem('dataPelanggaran', JSON.stringify(values));
                formStep.value = 'data-pelanggaran';
            });
        }

        function formTermsSubmit(values) {
            const dataBank = dataPinjaman.value.ref_bank_name.split(';')
            let data = {
                bankId : dataBank[0],
                loanType : dataPinjaman.value.loanType,
                accountNumber : dataPinjaman.value.accountNumber,
                disturbance : dataPelanggaran.value.disturbance,
                billingDate : dataPelanggaran.value.billingDate,
                debtCollectorName : dataPelanggaran.value.debtCollectorName,
                debtCollectorPhoneNumber : dataPelanggaran.value.debtCollectorPhoneNumber,
                debtCollectorIdCard : dataPelanggaran.value.debtCollectorIdCard,
                interuptionLocation : dataPelanggaran.value.interuptionLocation,
                interuptionChronology : dataPelanggaran.value.interuptionChronology,
                proof1 : docPendukung.value.Proof_Document_1__c ? docPendukung.value.Proof_Document_1__c : null,
                proof2 : docPendukung.value.Proof_Document_2__c ? docPendukung.value.Proof_Document_2__c : null,
                proof3 : docPendukung.value.Proof_Document_3__c ? docPendukung.value.Proof_Document_3__c : null,
                terms: values.terms
            }
            swalConfirm.fire({
                html: '<p class="text-center text-sm mt-2">Anda Yakin datanya sudah benar?<br>Jika sudah, silahkan untuk dikonfirmasi agar<br>data Anda segera diproses</p>',
                showCancelButton: true,
                confirmButtonText: 'Konfirmasi',
                cancelButtonText: 'Batalkan',
            }).then((result) => {
                if (result.isConfirmed){
                    isLoading.value = true;
                    amalansignupApi.post('salesforce/amalan-protects/bank-complaints', data)
                    .then((resp) => {
                        let dataBank = {
                            bankComplaintId: resp.data.data.bankComplaintId
                        }
                        store.dispatch('formAmalanProtect/removeAll').then(() => {
                            localStorage.removeItem('dataPinjaman');
                            localStorage.removeItem('dataPelanggaran');
                            localStorage.removeItem('docPendukung');
                            localStorage.removeItem('tempDocPendukung');
                        });

                        amalansignupApi.post('salesforce/amalan-protects/hellosign', dataBank)
                        .then(() => {
                            isLoading.value = false;
                            router.push('/amalan-protect/tanda-tangan/' + dataBank.bankComplaintId);
                        })
                        .catch(() => {
                            isLoading.value = false;
                            swalError.fire({
                                html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    window.location = 'https://wa.me/6285888236207?text=' + 
                                    'Mohon bantuan saya gagal terus pada proses amalanProtect - pengiriman data pelanggaran di web.amalan.com';
                                    return;
                                }
                            });
                        })
                    })
                    .catch(() => {
                        isLoading.value = false;
                        swalError.fire({
                            html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                            showCancelButton: true,
                            confirmButtonText: 'Bantuan',
                            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                        }).then((result) => {
                            if (result.isConfirmed){
                                window.location = 'https://wa.me/6285888236207?text=' + 
                                'Mohon bantuan saya gagal terus pada proses amalanProtect - pengiriman data pelanggaran di web.amalan.com';
                                return;
                            }
                        });
                    })
                }
            });
        }

        function termsChecked(){
            formTerms.terms = !formTerms.terms;
        }

        function formDokumenSubmit(values, { resetForm }) {
            const reader = new window.FileReader();
            reader.onload = (e) => {
                let nameFileTypePratinjau = values.file_input[0].type.includes("image") ? "gambar" : values.file_input[0].type.includes("video") ? "video" : values.file_input[0].type.includes("audio") ? "audio" : values.file_input[0].type.includes("pdf") ?"pdf" : "dokumen";
                let fileName = values.file_input[0].name;
                let urlPratinjau = e.target.result;
                let data = {
                    nameFileTypePratinjau : nameFileTypePratinjau,
                    urlPratinjau: urlPratinjau,
                    fileName: fileName
                }
                formDokumen.file.push(values.file_input[0])
                formDokumenPreview.file.push(data)
                resetForm();
                delete tempFile['file_input'];
            }
            reader.readAsDataURL(values.file_input[0]);
        }

        function removeDokumen(index){
            swalConfirmDelete.fire({
                html: '<p class="text-center text-sm mt-2">Dokumen pendukung ini tidak dapat dipulihkan kembali <br><br>Apakah Anda yakin ingin menghapusnya?</p>',
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batalkan',
            }).then((result) => {
                if (result.isConfirmed){
                    formDokumen.file.splice(index, 1)
                    formDokumenPreview.file.splice(index, 1)
                }
            });
        }

        function removeDokumenStored(){
            swalConfirmDelete.fire({
                html: '<p class="text-center text-sm mt-2">Semua Dokumen pendukung yang telah Anda kirim akan dihapus dan tidak dapat dipulihkan kembali <br><br>Apakah Anda yakin ingin menghapusnya?</p>',
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batalkan',
            }).then((result) => {
                if (result.isConfirmed){
                    store.dispatch('formAmalanProtect/removeDocPendukung').then(() => {
                        localStorage.removeItem('docPendukung');
                        localStorage.removeItem('tempDocPendukung');
                    });
                }
            });
        }

        function sendDokumen(){
            isLoading.value = true;
            if(formDokumen.file.length > 0){
                let formData = new FormData();
                for(let i in formDokumen.file){
                    formData.append('file[]', formDokumen.file[i]);
                }

                amalansignupApi.post('salesforce/amalan-protects/bank-complaints/proof-upload', formData,{
                    headers: {
                        'Accept': 'multipart/form-data',
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((resp) => {
                    store.dispatch('formAmalanProtect/storeDocPendukung', resp.data.data).then(() => {
                        isLoading.value = false;
                        localStorage.setItem('docPendukung', JSON.stringify(resp.data.data));
                        store.dispatch('formAmalanProtect/storeTempDocPendukung', formDokumenPreview.file).then(() => {
                            localStorage.setItem('tempDocPendukung', JSON.stringify(formDokumenPreview.file));
                        });
                        formStep.value = 'data-pelanggaran';
                    });
                })
                .catch(() => {
                    isLoading.value = false;
                    swalError.fire({
                        html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                        showCancelButton: true,
                        confirmButtonText: 'Bantuan',
                        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                    }).then((result) => {
                        if (result.isConfirmed){
                            window.location = 'https://wa.me/6285888236207?text=' + 
                            'Mohon bantuan saya gagal terus dalam proses amalanProtect - unggah unggah dokumen pendukung pelanggaran di web.amalan.com';
                            return;
                        }
                    });
                })
            }
        }

        return {
            formStep,
            formDataPelanggaranSchema,
            formDataPelanggaran,
            formDebtCollector,
            dataPinjaman,
            dataPelanggaran,
            docPendukung,
            formTermsSubmit,
            formTerms,
            formTermsSchema,
            termsChecked,
            formDokumenSchema,
            formDokumenSubmit,
            formDokumen,
            tempFile,
            SUPPORTED_FORMATS,
            FILE_SIZE,
            formDokumenPreview,
            removeDokumen,
            sendDokumen,
            isLoading,
            removeDokumenStored,
            dataDisturbances
        }
    }
})
</script>