<script setup>
import { crispChatBoxClosed, crispInstanceLoaded, useCrispInstance } from '@/composables/crisp'
import { onBeforeUnmount, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';

const crisp = useCrispInstance()
const router = useRouter()

watch(() => crispInstanceLoaded.value, () => {
    if(!crispInstanceLoaded.value) return
    return crisp.chat.open()
})

watch(() => crispChatBoxClosed.value, () => {
    if(!crispChatBoxClosed.value) return
    return router.push({ name: 'Home' })
})

onMounted(() => {
    if(!crispInstanceLoaded.value) return
    return crisp.chat.open()
})

onBeforeUnmount(() => {
    return crisp.chat.close()
})
</script>

<template>
    <div class="min-h-screen bg-amalan-white pt-24">
        <loading-data-without-margin />
    </div>
</template>