<script setup>
import { ref, reactive, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import axios from "@/axios"
import { useRouter, useRoute} from "vue-router"
import { Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { PlusIcon } from '@heroicons/vue/solid'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import SampleKtp from '@/views/Akun/SampleImageKtpV2.vue'
import SampleSelfie from '@/views/Akun/SampleImageSelfieV2.vue'
import useModalPopUp from '@/composables/modalPopUp'
import Camera from 'simple-vue-camera'
import SelfieGuideMask from '@/components/icon/SelfieGuideMask.vue'
import KtpGuideMask from '@/components/icon/KtpGuideMask.vue'
import getBrowser from '@/composables/getBrowserInfo'
import { eventName, mixpanelPushEvent } from '@/composables/mixpanel'

const router = useRouter()
const route = useRoute()
const store = useStore();
const modalPopUp = useModalPopUp()

const FILE_SIZE = 49152 * 1024;
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png"
];

const showKtpPreview = ref(false)
const showSelfiePreview = ref(false)
const sampleKtp = ref(false)
const sampleSelfie = ref(false)
const formValues = reactive({
    img_ktp: null,
    img_selfie: null,
});
const currentStep = ref(0);
const isLoading = ref(false);
const isMobile = ref(false)
const cameraForKtp = ref()
const cameraForSelfie = ref()
const cameraErrorMessage = ref(null)
const cameraLoading = ref(false)
const takePictureKTP = ref(false)
const takePictureSelfie = ref(false)
const browserName = ref(null)
const GuideMasking = ref({
    selfie: {
        width: 318,
        height: 435,
        multiplyWith: 1.368
    },
    ktp: {
        width: 318,
        height: 260,
        multiplyWith: 0.818
    }
})
const constraintsSelfieCamera = () => {
    return {
        video: {
            width: isMobile.value ? 1472 : 1080,
            height: isMobile.value ? 1080 : 1430
        }
    }
}
const resolutionKtpCamera = () => {
    return { 
        width: isMobile.value ? 1080 : 1080,
        height: isMobile.value ? 1472 : 1430
    }
}
const pictureTaken = ref({
    fileKTP: {
        blob: null,
        objectURL: null
    },
    fileSelfie: {
        blob: null,
        objectURL: null
    }
})

const schemas = [
    yup.object({
        ktp: yup.mixed().required("Pilih file gambar.")
        .test(
            "fileSize",
            "Ukuran file maksimal 48 MB.",
            (value) => {
                if (value) return value.size <= FILE_SIZE
            }
        )
        .test(
            "fileFormat",
            "Mohon pilih file gambar.",
            (value) => {
                if (value) return SUPPORTED_FORMATS.includes(value.type)
            }
        ),
    }),
    yup.object({
        selfie: yup.mixed().required("Pilih file gambar.")
        .test(
            "fileSize",
            "Ukuran file maksimal 48 MB.",
            (value) => {
                if (value) return value.size <= FILE_SIZE
            }
        )
        .test(
            "fileFormat",
            "Mohon pilih file gambar.",
            (value) => {
                if (value) return SUPPORTED_FORMATS.includes(value.type)
            }
        )
    }),
];

const updateForm = computed(() => {
    return schemas[currentStep.value];
});

const nextStep = (values) => {
    if (currentStep.value === 1) {
        isLoading.value = true;
        Object.assign(formValues, values);
        let data = new FormData();
        data.append('img_ktp', formValues.img_ktp);
        data.append('img_selfie', formValues.img_selfie);
        data.append('type', 'creditcheckup');
        axios.post('/customer/store', data,{
            headers: {
                'Accept': 'multipart/form-data',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(() => {
            store.dispatch('user/storeUser').then(() => {
                isLoading.value = false;
                if(route.meta.creditCheckupProductKYC) return router.push({ name: 'credit checkup product - onboarding 2' });
                return router.push({ name: 'credit checkup - onboarding 2' });
            });
        })
        .catch((err) => {
            formValues.img_ktp = null;
            formValues.img_selfie = null;
            pictureTaken.value.fileKTP.objectURL = null;
            pictureTaken.value.fileSelfie.objectURL = null;
            currentStep.value = 0;
            isLoading.value = false;
            if(err.response.status == 400 || err.response.status == 422 ){
                if(err.response.data.message.includes('Face not detected')){
                    modalPopUp.swalError.fire({
                        html: '<p class="text-center text-sm mt-2">Maaf, Pastikan anda mengunggah foto yang mengandung wajah.</p>',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                    });
                } else if(err.response.data.message.includes('KTP not detected')){
                    modalPopUp.swalError.fire({
                        html: '<p class="text-center text-sm mt-2">Maaf, Pastikan anda mengunggah foto e-KTP pada tempat yang sudah disediakan.</p>',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                    });
                } else if(err.response.data.message.includes('Your selfie and ktp image similarity')){
                    modalPopUp.swalError.fire({
                        html: '<p class="text-center text-sm mt-2">Maaf, Sepertinya skor kecocokan pada foto e-KTP dan Swafoto anda tidak memenuhi persyaratan.</p>',
                        showCancelButton: true,
                        confirmButtonText: 'Bantuan',
                        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                    })
                } else if(err.response.data.message.includes('Duplicate entry')){
                    modalPopUp.swalError.fire({
                        html: '<p class="text-center text-sm mt-2">Maaf, Data KTP Anda terdeteksi sudah terdaftar sebelumnya. Silahkan tekan tombol bantuan dibawah ini atau hubungi konsultan Anda.</p>',
                        showCancelButton: true,
                        confirmButtonText: 'Bantuan',
                        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                    }).then((result) => {
                        if (result.isConfirmed){
                            return window.location = 'https://wa.me/6285888236207?text=Mohon bantuan saya tidak dapat melanjutkan proses karena KTP saya sudah terdaftar di akun lain.';
                        }
                    });
                } else {
                    modalPopUp.swalError.fire({
                        html: '<p class="text-center text-sm mt-2">Maaf, Pastikan anda mengunggah foto e-KTP dan Swafoto sesuai contoh dan pastikan tidak mengunggah di tempat yang tertukar.</p>',
                        showCancelButton: true,
                        confirmButtonText: 'Bantuan',
                        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                    }).then((result) => {
                        if (result.isConfirmed){
                            return window.location = 'https://wa.me/6285888236207?text=Mohon bantuan saya gagal terus dalam proses KYC - unggah e-KTP dan Swafoto di web.amalan.com';
                        }
                    });
                }
            }else{
                modalPopUp.swalError.fire({
                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                }).then((result) => {
                    if (result.isConfirmed){
                        return window.location = 'https://wa.me/6285888236207?text=Mohon bantuan saya gagal terus dalam proses KYC - unggah e-KTP dan Swafoto di web.amalan.com';
                    }
                });
            }
        })
        return;
    }

    Object.assign(formValues, values);
    currentStep.value++;
}

const unggahKtp = () => {
    takePictureKTP.value = false
    showKtpPreview.value = false
    formValues.img_ktp = blobToFIle(pictureTaken.value.fileKTP.blob, 'ktp.png')
}

const unggahSelfie = () => {
    takePictureSelfie.value = false
    showSelfiePreview.value = false
    formValues.img_selfie = blobToFIle(pictureTaken.value.fileSelfie.blob, 'selfie.png')
}

const ktpInputClick = (e) => { 
    if(formValues.img_ktp) return e.preventDefault()
    if(sampleKtp.value == false) return sampleKtp.value = true
    setTimeout(() => sampleKtp.value = true, 500)
    return sampleKtp.value = false
}

const selfieInputClick = (e) => { 
    if(formValues.img_selfie) return e.preventDefault()
    if(sampleSelfie.value == false) return sampleSelfie.value = true
    setTimeout(() => sampleSelfie.value = true, 500)
    return sampleSelfie.value = false
}

const sampleKtpBack = () => sampleKtp.value = false

const sampleSelfieBack = () => sampleSelfie.value = false

const backNavigation = () => {
    if(currentStep.value === 1) return currentStep.value--

    localStorage.removeItem('from-onboarding-go-to')
    return router.push({ name: 'Home'})
}

const snapshotKTP = async () => {
    const cameraContainer = document.getElementById('camera-container')
    const customWidth = cameraContainer.offsetWidth
    const customHeight = cameraContainer.offsetHeight

    const blob = await cameraForKtp.value.snapshot(
        { 
            width: isMobile.value ? browserName.value == 'firefox' ? customWidth * 2 : 1472 : 1430,
            height: isMobile.value ? browserName.value == 'firefox' ? customHeight * 2 : 1080 : 1080
        },
        "image/png",
        0.5
    )

    pictureTaken.value.fileKTP.blob = blob
    pictureTaken.value.fileKTP.objectURL = URL.createObjectURL(blob)
    showKtpPreview.value = true
}

const snapshotSelfie = async () => {
    const cameraContainer = document.getElementById('camera-container')
    const customWidth = cameraContainer.offsetWidth
    const customHeight = cameraContainer.offsetHeight

    const blob = await cameraForSelfie.value.snapshot(
        { 
            width: isMobile.value ? browserName.value == 'firefox' ? customWidth * 2 : 1080 : 1430, 
            height: isMobile.value ? browserName.value == 'firefox' ? customHeight * 2 : 1472 : 1080
        },
        "image/png",
        0.5
    )

    pictureTaken.value.fileSelfie.blob = blob
    pictureTaken.value.fileSelfie.objectURL = URL.createObjectURL(blob)
    showSelfiePreview.value = true
}

const errorCallbackCamera = (error) => {
    cameraLoading.value = false
    callErrorPopUp(error)
}

const loadingCamera = () => cameraLoading.value = true

const startedCamera = () => {
    const cameraContainer = document.getElementById('camera-container')
    GuideMasking.value.selfie.width = cameraContainer.offsetWidth
    GuideMasking.value.selfie.height = Math.round(cameraContainer.offsetWidth * GuideMasking.value.selfie.multiplyWith)
    GuideMasking.value.ktp.width = cameraContainer.offsetWidth
    GuideMasking.value.ktp.height = Math.round(cameraContainer.offsetWidth * GuideMasking.value.ktp.multiplyWith)
    cameraLoading.value = false
}

const showKTPCamera = () => {
    sampleKtp.value = false
    takePictureKTP.value = true
    cameraLoading.value = true
    setTimeout(() => cameraForKtp.value.start(), 1000);
}

const showSelfieCamera = () => {
    sampleSelfie.value = false
    takePictureSelfie.value = true
    cameraLoading.value = true
    setTimeout(() => cameraForSelfie.value.start(), 1000);
}

const blobToFIle = (blobFile, fileName) => {
    blobFile.lastModifiedDate = new Date();
    blobFile.name = fileName
    return blobFile
}

const callErrorPopUp = (msg) => {
    cameraErrorMessage.value = msg
    if(msg == 'camera-not-supported'){
        modalPopUp.swalCameraError.fire({
            html: '<p class="text-center text-sm mt-2"><span class="font-bold text-base text-amalan-blue-1">Tidak bisa menggunakan kamera</span><br><br>Browser Anda mungkin tidak memiliki akses ke kamera. Untuk mengatasi masalah ini, buka Pengaturan sistem</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: 'Muat Ulang',
        }).then((result) => {
            if (!result.isConfirmed) return window.location.reload()
            return window.location = 'https://wa.me/6285888236207?text=Mohon bantuan Browser Saya mungkin tidak memiliki akses ke kamera'
        })
    }else{
        modalPopUp.swalCameraError.fire({
            html: '<p class="text-center text-sm mt-2"><span class="font-bold text-base text-amalan-blue-1">Izinkan aplikasi menggunakan kamera</span><br><br>Aplikasi memerlukan akses ke kamera Anda agar dapat mengambil foto</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: 'Muat Ulang',
        }).then((result) => {
            if (!result.isConfirmed) return window.location.reload()
            return window.location = 'https://wa.me/6285888236207?text=Mohon bantuan Aplikasi mungkin tidak memiliki akses ke kamera'
        })
    }
}

onMounted(() => {
    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
        isMobile.value = window.matchMedia("only screen and (max-width: 480px)").matches
        if('userAgent' in navigator){
            browserName.value = getBrowser()
        }
    }else{
        callErrorPopUp('camera-not-supported')
    }

    if(localStorage.getItem('ccu-product-destination')){
        mixpanelPushEvent(eventName.CC_KYC, {
            applied_program: localStorage.getItem('ccu-product-destination')
        })
    }
})
</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="backNavigation" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="relative bg-amalan-white min-h-screen">
            <SampleKtp :changeVisibility="sampleKtp" @on-close="showKTPCamera" @on-back="sampleKtpBack" />
            <SampleSelfie :changeVisibility="sampleSelfie" @on-close="showSelfieCamera" @on-back="sampleSelfieBack" />
            <div class="mx-5 bg-amalan-white">
                <div v-if="route.meta.creditCheckupProductKYC" class="w-full py-8 flex justify-between items-center">
                    <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                        <span class="text-sm text-amalan-blue-1 font-bold">1</span>
                    </div>
                    <p v-if="currentStep === 0" class="flex-none text-amalan-blue-1 text-sm font-bold mx-1">Foto e-KTP</p>
                    <div class="bg-amalan-blue-1 h-px" :class="[currentStep === 0 ? 'flex-grow' : 'flex-none w-3 mx-1']"></div>
                    <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none" :class="[currentStep === 0 ? 'bg-amalan-gray-4 mx-1' : 'bg-amalan-yellow-400']">
                        <span class="text-sm font-bold" :class="[currentStep === 0 ? 'text-amalan-gray-2' : 'text-amalan-blue-1']">2</span>
                    </div>
                    <p v-if="currentStep === 1" class="flex-none text-amalan-blue-1 text-sm font-bold mx-1">Foto Swafoto</p>
                    <div class="bg-amalan-blue-1 h-px" :class="[currentStep === 1 ? 'flex-grow' : 'flex-none w-3']"></div>
                    <div class="w-6 h-6 rounded-full bg-amalan-gray-4 flex justify-center items-center flex-none ml-1">
                        <span class="text-sm text-amalan-gray-2 font-bold">3</span>
                    </div>
                </div>
                <div v-else class="pt-8 pb-10 w-full max-w-screen-md mx-auto">
                    <div class="flex items-center">
                        <div class="flex items-center text-amalan-blue-1 relative">
                            <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                                <img src="@/assets/img/ktp-active.svg" class="w-7 h-7">
                            </div>
                            <div class="absolute -top-2 -ml-10 text-center mt-16 w-32 text-xs font-bold text-amalan-blue-1"><p>Verifikasi<br>Data</p></div>
                        </div>
                        <div class="flex-auto border-t-2 border-amalan-gray-3"></div>
                        <div class="flex items-center text-amalan-gray-2 relative">
                            <div class="rounded-full h-12 w-12 border-2 bg-amalan-gray-5 border-amalan-gray-5 flex justify-center items-center">
                                <img src="@/assets/img/credit-checkup/pembayaran-layanan.svg" class="w-6 h-6">
                            </div>
                            <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-amalan-gray-2"><p>Pembayaran<br>Layanan</p></div>
                        </div>
                        <div class="flex-auto border-t-2 border-amalan-gray-3"></div>
                        <div class="flex items-center text-amalan-gray-2 relative">
                            <div class="rounded-full h-12 w-12 border-2 bg-amalan-gray-5 border-amalan-gray-5 flex justify-center items-center">
                                <img src="@/assets/img/credit-checkup/credit-score.svg" class="w-6 h-6">
                            </div>
                            <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-amalan-gray-2"><p>Credit<br>Score</p></div>
                        </div>
                    </div>
                </div>
                <div class="w-full pb-12">
                    <Form @submit="nextStep" :validation-schema="updateForm" v-slot="{ handleSubmit, errors }">
                        <template v-if="currentStep === 0">
                            <div class="w-full text-amalan-black">
                                <p v-if="formValues.img_ktp" class="mt-1 text-xs sm:text-sm text-center">Foto <b>e-KTP</b> Anda sudah berhasil di unggah!</p>
                                <p v-else class="mt-1 text-xs sm:text-sm text-center">
                                    Ambil foto <b>e-KTP</b> Anda untuk verifikasi data
                                </p>
                            </div>
                            <div v-if="takePictureKTP && !cameraErrorMessage" class="my-4 overflow-hidden rounded-lg isolate">
                                <camera
                                    :autoplay="false"
                                    :resolution="resolutionKtpCamera()"
                                    ref="cameraForKtp"
                                    @error="errorCallbackCamera"
                                    @loading="loadingCamera"
                                    @started="startedCamera"
                                >
                                    <loading-data-without-margin v-if="cameraLoading" class="mt-20" />
                                    <template v-else>
                                        <KtpGuideMask v-if="isMobile" :width="GuideMasking.ktp.width" :height="GuideMasking.ktp.height" />
                                        <div class="bg-amalan-black bg-opacity-70 w-full h-16 flex justify-center items-center bottom-0 absolute">
                                            <div class="w-14 h-14 bg-amalan-gray-3 border-4 border-amalan-white rounded-full flex justify-center items-center cursor-pointer" @click="snapshotKTP"></div>
                                        </div>
                                    </template>
                                </camera>
                            </div>
                            <div v-else class="w-full mt-3.5">
                                <label class="block rounded-xl h-56 py-16 border-2 border-dashed border-amalan-gray-3 hover:bg-amalan-gray-5 relative" :class="[ formValues.img_ktp ? 'cursor-default' : 'cursor-pointer' ]" @click="ktpInputClick">
                                    <div v-if="pictureTaken.fileKTP.objectURL" class="absolute bg-amalan-white w-full h-full top-0 rounded-xl p-2 flex justify-center items-center">
                                        <img :src="pictureTaken.fileKTP.objectURL" class="max-h-full">
                                    </div>
                                    <div class="h-14 w-14 rounded-full bg-amalan-blue-1 flex justify-center items-center mx-auto">
                                        <PlusIcon class="text-amalan-white h-10 w-10"/>
                                    </div>
                                    <p class="text-center mt-4 text-sm text-amalan-black">Ambil foto <b>e-KTP</b> Anda disini!</p>
                                </label>
                                <Field type="file" id="ktpInput" name="ktp" class="hidden" v-model="formValues.img_ktp" />
                                <div v-if="errors.ktp" class="mt-1 mb-4 text-sm text-red-600">*{{errors.ktp}}</div>
                            </div>
                        </template>

                        <template v-if="currentStep === 1">
                            <div class="w-full text-amalan-black">
                                <p v-if="formValues.img_selfie" class="mt-1 text-xs sm:text-sm text-center">Foto <b>Swafoto</b> Anda sudah berhasil di unggah!</p>
                                <p v-else class="mt-1 text-xs sm:text-sm text-center">
                                    Ambil foto <b>Swafoto</b> Anda untuk verifikasi data
                                </p>
                            </div>
                            <div v-if="takePictureSelfie && !cameraErrorMessage" class="my-4 overflow-hidden rounded-lg isolate">
                                <camera
                                    :autoplay="false"
                                    :constraints="constraintsSelfieCamera()"
                                    :facingMode="'user'"
                                    ref="cameraForSelfie"
                                    @error="errorCallbackCamera"
                                    @loading="loadingCamera"
                                    @started="startedCamera"
                                >
                                    <loading-data-without-margin v-if="cameraLoading" class="mt-20" />
                                    <template v-else>
                                        <SelfieGuideMask v-if="isMobile" :width="GuideMasking.selfie.width" :height="GuideMasking.selfie.height" />
                                        <div class="bg-amalan-black bg-opacity-70 w-full h-16 flex justify-center items-center bottom-0 absolute">
                                            <div class="w-14 h-14 bg-amalan-gray-3 border-4 border-amalan-white rounded-full flex justify-center items-center cursor-pointer" @click="snapshotSelfie"></div>
                                        </div>
                                    </template>
                                </camera>
                            </div>
                            <div v-else class="w-full mt-3.5">
                                <label class="block rounded-xl h-56 py-16 border-2 border-dashed border-amalan-gray-3 hover:bg-amalan-gray-5 relative" :class="[ formValues.img_selfie ? 'cursor-default' : 'cursor-pointer' ]" @click="selfieInputClick">
                                    <div v-if="pictureTaken.fileSelfie.objectURL" class="absolute bg-amalan-white w-full h-full top-0 rounded-xl p-2 flex justify-center items-center">
                                        <img :src="pictureTaken.fileSelfie.objectURL" class="max-h-full">
                                    </div>
                                    <div class="h-14 w-14 rounded-full bg-amalan-blue-1 flex justify-center items-center mx-auto">
                                        <PlusIcon class="text-amalan-white h-10 w-10"/>
                                    </div>
                                    <p class="text-center mt-4 text-sm text-amalan-black">Ambil <b>Swafoto</b> Anda disini!</p>
                                </label>
                                <Field type="file" id="selfieInput" name="selfie" class="hidden" v-model="formValues.img_selfie" />
                                <div v-if="errors.selfie" class="mt-1 mb-4 text-sm text-red-600">*{{errors.selfie}}</div>
                            </div>
                        </template>

                        <div class="mt-16">
                            <button v-if="currentStep === 0" @submit="handleSubmit" type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ formValues.img_ktp ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!formValues.img_ktp">Selanjutnya</button>
                            <button v-if="currentStep === 1" type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ formValues.img_selfie ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!formValues.img_selfie">Selanjutnya</button>
                        </div>
                    </Form>
                </div>
            </div>
            <loading-overlay v-if="isLoading" />
            <template v-if="showKtpPreview">
                <div class="fixed inset-0 z-50 bg-black bg-opacity-60 grid grid-cols-1 p-5 sm:p-10 overflow-y-auto">
                    <div class="bg-amalan-white rounded-lg w-full sm:w-8/12 max-w-lg place-self-center">
                        <div class="bg-amalan-blue-7 rounded-t-lg py-3"> 
                            <p class="text-center text-base font-bold text-amalan-black">Pratinjau</p>
                        </div>
                        <div class="w-full p-4">
                            <img :src="pictureTaken.fileKTP.objectURL" class="w-full sm:w-8/12 sm:mx-auto">
                        </div>
                        <div class="w-full grid grid-cols-2 gap-x-4 px-4 pb-4">
                            <button @click="showKtpPreview = !showKtpPreview" type="button" class="w-full shadow-amalan-md rounded-amalan-lg font-bold text-center text-sm py-3 text-amalan-blue-1 border border-amalan-blue-1 cursor-pointer bg-amalan-white hover:bg-amalan-gray-5">Pilih Kembali</button>
                            <button @click="unggahKtp" type="button" class="w-full shadow-amalan-md rounded-amalan-lg font-bold text-center text-sm py-3 text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2">Unggah</button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="showSelfiePreview">
                <div class="fixed inset-0 z-50 bg-black bg-opacity-60 grid grid-cols-1 p-5 sm:p-10 overflow-y-auto">
                    <div class="bg-amalan-white rounded-lg w-full sm:w-8/12 max-w-lg place-self-center">
                        <div class="bg-amalan-blue-7 rounded-t-lg py-3"> 
                            <p class="text-center text-base font-bold text-amalan-black">Pratinjau</p>
                        </div>
                        <div class="w-full p-4">
                            <img :src="pictureTaken.fileSelfie.objectURL" class="w-10/12 sm:w-8/12 mx-auto">
                        </div>
                        <div class="w-full grid grid-cols-2 gap-x-4 px-4 pb-4">
                            <button @click="showSelfiePreview = !showSelfiePreview" type="button" class="w-full shadow-amalan-md rounded-amalan-lg font-bold text-center text-sm py-3 text-amalan-blue-1 border border-amalan-blue-1 cursor-pointer bg-amalan-white hover:bg-amalan-gray-5">Pilih Kembali</button>
                            <button @click="unggahSelfie" type="button" class="w-full shadow-amalan-md rounded-amalan-lg font-bold text-center text-sm py-3 text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2">Unggah</button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
