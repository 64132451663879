<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import DoneBanner from '@/components/icon/DoneBanner'
import { sentGreetingMessage, useCrispInstance } from '@/composables/crisp'

const route = useRoute()
const router = useRouter()
const store = useStore()
const crisp = useCrispInstance()

const lastLoanConsolidation = computed(() => store.getters['loanConsolidation/getLastLoanConsolidation'])

const consolidationInitialResult = ref(null)
const timeOutInstance = ref(null)

const goTo = (routeName) => {
    if(timeOutInstance.value) clearTimeout(timeOutInstance.value)
    return router.push({name: routeName})
}

const eligibleWithStatusDone = () => {
    if(route.name === 'Loan Consolidation - Registrasi - Finish Page') {
        return sentGreetingMessage()
    }

    return router.push({name: 'Loan Consolidation - Registrasi - Finish Page'})
}

const eligibleWithStatusPending = () => {
    if(route.name === 'Loan Consolidation - Registrasi - Finish Page') return router.push({name: 'Loan Consolidation - Registrasi - Qualification'})
    timeOutInstance.value = setTimeout(() => router.push({ name: 'Loan Consolidation - Registrasi - Daftar Pinjaman'}), 5000)
}

onMounted(() => {
    consolidationInitialResult.value = lastLoanConsolidation.value.data?.data?.Loan_Consolidation_Initial_Results__c?.toUpperCase()

    if(lastLoanConsolidation.value.status === 404) return router.push({ name: 'Loan Consolidation - Registrasi - Informasi Umum'})

    if(lastLoanConsolidation.value.status !== 200 && lastLoanConsolidation.value.status !== 201 && lastLoanConsolidation.value.status !== 202) return router.push({ name: 'Home'})

    if(lastLoanConsolidation.value.status === 200) return eligibleWithStatusDone()

    return eligibleWithStatusPending()
})
const goToCreditScore = () => {
    localStorage.setItem('CreditScoreFromProduct', 'KBK')
    return router.push({ name: 'credit checkup - credit score checkout' })
}

onBeforeUnmount(() => {
    crisp.chat.close()
})
</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <div class="flex items-center justify-center w-6 h-8"></div>
                    <span class="text-sm font-bold text-amalan-black text-center">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full min-h-screen px-6 -mt-14 flex flex-col justify-center items-center">
            <div class="mt-4 pb-7 w-full px-4 text-amalan-black">
                <div class="mt-12 sm:px-6 text-center flex flex-col">
            <template v-if="route.name === 'Loan Consolidation - Registrasi - Finish Page'">
                
                <div  class="mx-auto"><DoneBanner /></div>
                <p class="text-center text-xs text-amalan-black mt-8">Registrasi Kredit Bijak Karyawan Anda<br>telah selesai dilakukan.<br><br>Selanjutnya konsultan kami akan segera<br>menghubungi Anda untuk proses berikutnya.</p>
                <router-link :to="{ name: 'Home'}" class="w-full flex items-center justify-center py-3 px-2 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-8">
                    <span class="text-amalan-white text-sm font-bold">
                        Kembali ke Beranda
                    </span>
                </router-link>
            </template>
            <template v-else>
                <template v-if="consolidationInitialResult === '1. NOT ELIGIBLE'">
                    <div  class="mx-auto"><DoneBanner :is-fail="true" /></div>
                    <p class="text-center text-xs text-amalan-black mt-8">Terima kasih atas ketertarikan Anda.<br><br>Mohon maaf saat ini <b>Kredit Bijak Karyawan</b><br>hanya tersedia untuk karyawan tetap.<br><br>Namun jangan khawatir, <b>amalan</b> memiliki<br>solusi lain berupa Program Keringanan Utang. Program ini dapat meringankan beban utang Anda hingga 70%.<br><br>Silakan lanjutkan pengisian data pinjaman<br>untuk terhubung dengan konsultan kami.</p>
                    <button @click="goTo('Loan Consolidation - Registrasi - Daftar Pinjaman')" class="w-full flex items-center justify-center py-3 px-2 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-8">
                        <span class="text-amalan-white text-sm font-bold">
                            Lanjutkan Proses Registrasi
                        </span>
                    </button>
                </template>
                <template v-else-if="consolidationInitialResult === '2. ELIGIBLE FOR KBK'">
                    <div  class="mx-auto"><DoneBanner /></div>
                    <p class="text-center text-xs text-amalan-black mt-8">Selamat! Anda memenuhi syarat<br>untuk mengajukan untuk Kredit Bijak Karyawan dengan sistem potongan gaji.<br><br>Silahkan lanjutkan proses<br>pengisian data pinjaman Anda</p>
                    <button @click="goTo('Loan Consolidation - Registrasi - Daftar Pinjaman')" class="w-full flex items-center justify-center py-3 px-2 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-8">
                        <span class="text-amalan-white text-sm font-bold">
                            Lanjutkan Proses Registrasi
                        </span>
                    </button>
                </template>
                <template v-else-if="consolidationInitialResult === '3. ELIGIBLE - NEED CONFIRMATION'">
                    <div  class="mx-auto"><DoneBanner /></div>
                    <p class="text-center text-xs text-amalan-black mt-8">Terima kasih atas ketertarikan Anda.<br><br>Kami perlu memastikan ketersediaan<br>program Kredit Bijak Karyawan<br>untuk perusahaan Anda terlebih dahulu.<br><br>Silahkan lanjutkan proses<br>pengisian data pinjaman Anda.</p>
                    <button @click="goTo('Loan Consolidation - Registrasi - Daftar Pinjaman')" class="w-full flex items-center justify-center py-3 px-2 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-8">
                        <span class="text-amalan-white text-sm font-bold">
                            Lanjutkan Proses Registrasi
                        </span>
                    </button>
                </template>
                <template v-else-if="consolidationInitialResult === '4. ELIGIBLE FOR KPR+'">
                    <div  class="mx-auto"><DoneBanner /></div>
                    <p class="text-center text-xs text-amalan-black mt-8">Terima kasih atas ketertarikan Anda.<br><br>Anda memenuhi syarat untuk mengajukan<br>program KPR+ yang serupa dengan Kredit Bijak Karyawan<br><br>Silahkan lanjutkan proses<br>pengisian data pinjaman Anda.</p>
                    <button @click="goTo('Loan Consolidation - Registrasi - Daftar Pinjaman')" class="w-full flex items-center justify-center py-3 px-2 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-8">
                        <span class="text-amalan-white text-sm font-bold">
                            Lanjutkan Proses Registrasi
                        </span>
                    </button>
                </template>
                <template v-else>
                    <div  class="mx-auto"><DoneBanner :is-fail="true" /></div>
                    <p class="text-center text-xs text-amalan-black mt-8">Terima kasih atas ketertarikan Anda.<br><br>Saat ini kami terus berusaha untuk<br>mengembangkan program Kredit Bijak<br>Karyawan untuk karyawan swasta pada 2024.<br><br>Selain itu, amalan juga memiliki<br>solusi lain berupa Program Keringanan<br>Utang. Program ini dapat meringankan<br>beban utang Anda hingga 70%.<br><br>Silakan lanjutkan pengisian data pinjaman<br>untuk terhubung dengan konsultan kami.</p>
                    <button @click="goTo('Loan Consolidation - Registrasi - Daftar Pinjaman')" class="w-full flex items-center justify-center py-3 px-2 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-8">
                        <span class="text-amalan-white text-sm font-bold">
                            Lanjutkan Proses Registrasi
                        </span>
                    </button>
                </template>
            </template>
            </div>
            </div>
            <div class="w-full flex justify-center items-center text-sm bg-amalan-gray-3 text-amalan-gray-3 my-1" style="height: 1px">
                <div class="bg-amalan-white px-2">ATAU</div>
            </div>
            <div class="w-full">
                <p class="text-center text-amalan-blue-1 font-bold text-base sm:text-lg my-2">Apakah Anda ingin mengetahui Credit Score yang Anda miliki?</p>
                <p class="text-center text-xs sm:text-sm my-2">Credit score adalah profil ekonomi berdasarkan kelayakan kredit untuk memudahkan Anda melihat analisis finansial Anda dan peluang mendapatkan pinjaman baru.</p>
                <button @click="goToCreditScore" type="button" class="mx-auto rounded-lg font-bold border border-solid cursor-pointer text-center text-sm mt-8 mb-6 py-3 w-52 block text-amalan-white bg-amalan-blue-1 border-amalan-blue-1 hover:bg-blue-900 hover:border-blue-900">Dapatkan Credit Score</button>
                <p class="text-center text-amalan-blue-1 font-bold text-base sm:text-lg">Kenapa Perlu Credit Score?</p>
                <div class="w-full mt-4 px-6">
                    <div class="w-full flex items-center my-4">
                        <img class="w-12 mr-3" src="@/assets/img/lead-qualification/self-service-4.svg">
                        <div class="w-full py-2 px-2 border-2 border-solid rounded-lg">
                            <p class="text-xs">Sebagai bahan pertimbangan besar kecilnya suku bunga pinjaman</p>
                        </div>
                    </div>
                    <div class="w-full flex items-center my-4">
                        <img class="w-12 mr-3" src="@/assets/img/lead-qualification/self-service-2.svg">
                        <div class="w-full py-2 px-2 border-2 border-solid rounded-lg">
                            <p class="text-xs">Sebagai gambaran kemampuan membayar tagihan Anda kedepannya</p>
                        </div>
                    </div>
                    <div class="w-full flex items-center my-4">
                        <img class="w-12 mr-3" src="@/assets/img/lead-qualification/self-service-5.svg">
                        <div class="w-full py-2 px-2 border-2 border-solid rounded-lg">
                            <p class="text-xs">Sebagai cerminan perilaku kredit Anda sebagai acuan persetujuan pinjaman</p>
                        </div>
                    </div>
                    <div class="w-full flex items-center my-4">
                        <img class="w-12 mr-3" src="@/assets/img/lead-qualification/self-service-6.svg">
                        <div class="w-full py-2 px-2 border-2 border-solid rounded-lg">
                            <p class="text-xs">Penentu besarnya pinjaman yang akan diperoleh & jangka waktu yang didapat</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>