<script setup>
import { ref, onMounted, computed, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { mixpanelFlushData } from '@/composables/mixpanel'
import { useCrispInstance } from '@/composables/crisp';

const router = useRouter()
const store = useStore()
const crisp = useCrispInstance()
const swal = inject('$swal')
const timeToDocCollection = ref(null)
const showMenuDocCollection = ref(false)
const isLoading = ref(false)
const getterUser = computed(() => store.getters['user/getUser'])
const profilePicture = computed(() => store.getters['user/getProfilePicture'])
const billCompleted = computed(() => store.getters['docCollection/getbillStatus'])
const docCompleted = computed(() => store.getters['docCollection/getDocStatus'])
const signUpStatus = computed(() => store.getters['contact/getSignUpStatus'])
const notificationRDP = computed(() => store.getters['RDPManagement/paymentDataNotification'])
const rdpDataIsExist = computed(() => store.getters['RDPManagement/getRdpExisting'])
const negotiationResultApprovalFlag = computed(() => store.getters['negotiationResult/getNegotiationResultApprovalFlag'])
const companyWebsiteBaseURL = process.env.VUE_APP_AMALAN_WEBSITE_BASE_URL

const swalConfirm = swal.mixin({
    customClass: {
        confirmButton: 'mx-2 py-3 w-34 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-blue-1 hover:bg-amalan-blue-2 text-xs font-bold text-amalan-white',
        cancelButton: 'mx-2 py-3 w-34 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-white hover:bg-amalan-gray-5 text-xs font-bold text-amalan-blue-1'
    },
    title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
    buttonsStyling: false,
    width: '340px',
    allowOutsideClick: false,
    reverseButtons: true,
})

const logout = () => {
    swalConfirm.fire({
        html: '<p class="text-center text-xs mt-2 text-amalan-black">Anda ingin keluar dari <b>amalan Web App</b>?</p>',
        showCancelButton: true,
        confirmButtonText: 'Keluar',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if (result.isConfirmed){
            store.dispatch('auth/logout').then(() => {
                crisp.session.reset()
                mixpanelFlushData()
                window.location.href = '/login'
                return
            })
        }
        return
    })
}

const getName = () => {
    const firstName = getterUser.value.first_name ? getterUser.value.first_name.trim() : ""
    const lastName = getterUser.value.last_name ? getterUser.value.last_name.trim() : ""
    return (firstName + " " + lastName).trim()
}

const checkDocCollection = () => {
    timeToDocCollection.value = true

    if(signUpStatus.value) return setDocCollection()

    isLoading.value = true
    store.dispatch('contact/requestSignUpStatus')
    .then(() => {
        isLoading.value = false
        setDocCollection()
    })

    return
}

const setDocCollection = () => showMenuDocCollection.value = signUpStatus.value == 200 && timeToDocCollection.value == true ? true : false

onMounted(() => {
    checkDocCollection()
})

</script>

<template>
    <div class="min-h-screen bg-amalan-white relative pt-72">
        <div class="absolute w-full top-0 h-72 akun-gradient-color rounded-b-amalan-base py-12">
            <!-- <div class="w-28 h-28 rounded-full bg-amalan-white opacity-30 absolute top-12 -left-16"></div>
            <div class="w-32 h-32 rounded-full bg-amalan-white opacity-30 absolute bottom-0 -right-16"></div> -->
            <div @click="router.push({ name: 'profile picture - edit' })" class="w-30 h-30 rounded-full border-4 border-amalan-white bg-gray-100 mx-auto my-4 cursor-pointer overflow-hidden">
                <img :src="profilePicture ? profilePicture : require('@/assets/img/logo.png')" :class="[ profilePicture ? 'w-full h-full' : 'w-full h-full px-5 py-5']">
            </div>
            <div class="flex justify-center items-center">
                <span class="font-bold text-amalan-blue-1 text-lg">{{ getName() }}</span> 
                <img v-if="getterUser.ocr_app" src="@/assets/img/checklist-verified.svg" class="w-4 h-4 ml-1">
            </div>
            <p class="text-center text-sm mt-1">{{ getterUser.phone_number.replace('+62', '0') }}</p>
        </div>
        <div class="w-full pt-10 pb-32 px-6 bg-amalan-white">
            <div class="w-full grid grid-cols-3 gap-4 justify-items-center">
                <router-link to="/akun/profil" class="w-24 h-25 grid grid-cols-1 justify-items-center bg-amalan-white hover:bg-amalan-gray-5 rounded-xl shadow-amalan-xs py-3">
                    <img src="@/assets/img/akun/profil-pengguna.svg" class="w-9">
                    <p class="text-xs font-bold text-amalan-blue-1 text-center mt-2">Profil<br>Pengguna</p>
                </router-link>
                <router-link v-if="showMenuDocCollection" to="/akun/dokumen-list" class="w-24 h-25 grid grid-cols-1 justify-items-center bg-amalan-white hover:bg-amalan-gray-5 rounded-xl shadow-amalan-xs py-3 relative">
                    <img src="@/assets/img/akun/daftar-dokumen-active.svg" class="w-9">
                    <div v-if="!docCompleted" class="w-4 h-4 bg-amalan-red rounded-full absolute -top-1 -right-1"></div>
                    <p class="text-xs font-bold text-amalan-blue-1 text-center mt-2">Daftar<br>Dokumen</p>
                </router-link>
                <div v-else class="w-24 h-25 grid grid-cols-1 justify-items-center bg-amalan-gray-5 rounded-xl py-3 cursor-not-allowed">
                    <img src="@/assets/img/akun/daftar-dokumen.svg" class="w-9">
                    <p class="text-xs font-bold text-amalan-gray-2 text-center mt-2">Daftar<br>Dokumen</p>
                </div>
                <!-- <router-link to="/akun/loan-list" class="w-24 h-25 grid grid-cols-1 justify-items-center bg-amalan-white hover:bg-amalan-gray-5 rounded-xl shadow-amalan-xs py-3 relative"> -->
                <router-link to="/loan-management/home" class="w-24 h-25 grid grid-cols-1 justify-items-center bg-amalan-white hover:bg-amalan-gray-5 rounded-xl shadow-amalan-xs py-3 relative">
                    <img src="@/assets/img/akun/manajemen-pinjaman.svg" class="w-12">
                    <div v-if="showMenuDocCollection && !billCompleted" class="w-4 h-4 bg-amalan-red rounded-full absolute -top-1 -right-1"></div>
                    <p class="text-xs font-bold text-amalan-blue-1 text-center mt-2">Manajemen<br>Pinjaman</p>
                </router-link>
                <router-link v-if="rdpDataIsExist === 'FOUND'" to="/rdp/home" class="w-24 h-25 grid grid-cols-1 justify-items-center bg-amalan-white hover:bg-amalan-gray-5 rounded-xl shadow-amalan-xs py-3 relative">
                    <div v-if="notificationRDP" class="w-4 h-4 bg-amalan-red rounded-full absolute -top-1 -right-1"></div>
                    <img src="@/assets/img/akun/informasi-rdp-active.svg" class="w-9">
                    <p class="text-xs font-bold text-amalan-blue-1 text-center mt-2">Informasi<br>RDP</p>
                </router-link>
                <div v-else class="w-24 h-25 grid grid-cols-1 justify-items-center bg-amalan-gray-5 rounded-xl py-3 cursor-not-allowed">
                    <img src="@/assets/img/akun/informasi-rdp.svg" class="w-9">
                    <p class="text-xs font-bold text-amalan-gray-2 text-center mt-2">Informasi<br>RDP</p>
                </div>
                <router-link to="/negotiation-process/home" class="w-24 h-25 grid grid-cols-1 justify-items-center bg-amalan-white hover:bg-amalan-gray-5 rounded-xl shadow-amalan-xs py-3 relative">
                    <div v-if="negotiationResultApprovalFlag" class="w-4 h-4 bg-amalan-red rounded-full absolute -top-1 -right-1"></div>
                    <img src="@/assets/img/akun/proses-negosiasi-active.svg" class="w-9">
                    <p class="text-xs font-bold text-amalan-blue-1 text-center mt-2">Proses<br>Negosiasi</p>
                </router-link>
                <div class="w-24 h-25 grid grid-cols-1 justify-items-center bg-amalan-gray-5 rounded-xl py-3 cursor-not-allowed">
                    <img src="@/assets/img/akun/proses-pelunasan.svg" class="w-9">
                    <p class="text-xs font-bold text-amalan-gray-2 text-center mt-2">Proses<br>Pelunasan</p>
                </div>
                <router-link 
                    :to="{
                        name: 'Web View Frame',
                        query: {
                            to: encodeURIComponent(`${companyWebsiteBaseURL}id/tentang-kami`),
                            title: 'Tentang amalan'
                        }
                    }"
                    class="w-24 h-25 grid grid-cols-1 justify-items-center bg-amalan-white hover:bg-amalan-gray-5 rounded-xl shadow-amalan-xs py-3"
                >
                    <img src="@/assets/img/akun/tentang-amalan.svg" class="w-7">
                    <p class="text-xs font-bold text-amalan-blue-1 text-center mt-2">Tentang<br>amalan</p>
                </router-link>
                <router-link 
                    :to="{
                        name: 'Web View Frame',
                        query: {
                            to: encodeURIComponent(`${companyWebsiteBaseURL}id/faq`),
                            title: 'Pertanyaan & Jawaban'
                        }
                    }" 
                    class="w-24 h-25 grid grid-cols-1 justify-items-center bg-amalan-white hover:bg-amalan-gray-5 rounded-xl shadow-amalan-xs py-3"
                >
                    <img src="@/assets/img/akun/faq.svg" class="w-11">
                    <p class="text-xs font-bold text-amalan-blue-1 text-center mt-2">Pertanyaan<br>& Jawaban</p>
                </router-link>
                <router-link 
                    :to="{
                        name: 'Web View Frame',
                        query: {
                            to: encodeURIComponent(`${companyWebsiteBaseURL}id/ketentuan-layanan`),
                            title: 'Syarat dan Ketentuan'
                        }
                    }" 
                    class="w-24 h-25 grid grid-cols-1 justify-items-center bg-amalan-white hover:bg-amalan-gray-5 rounded-xl shadow-amalan-xs py-3"
                >
                    <img src="@/assets/img/akun/syarat-dan-ketentuan.svg" class="w-9">
                    <p class="text-xs font-bold text-amalan-blue-1 text-center mt-2">Syarat dan<br>Ketentuan</p>
                </router-link>
            </div>
            <div @click="logout" class="flex items-center justify-center py-3 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-14 cursor-pointer">
                <span class="text-amalan-white text-sm font-bold">
                    Keluar
                </span>
            </div>
            <p class="text-center text-2xs text-amalan-gray-1 mt-3">©{{ new Date().getFullYear() }} PT. amalan international Indonesia</p>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>