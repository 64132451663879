<template>
    <div class="pt-4 min-h-screen h-auto relative">
        <div class="mt-2 pb-12 w-full px-4">
            <div class="flex items-center">
                <div class="flex items-center text-blue-900 relative">
                    <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                        <img src="@/assets/img/docCollection/lembar-tagihan-active.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Lembar<br>Tagihan</p></div>
                </div>
                <div class="flex-auto border-t-2 border-blue-900"></div>
                <div class="flex items-center text-blue-900 relative">
                    <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                        <img src="@/assets/img/docCollection/dokumen-wajib-active.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Dokumen<br>Wajib</p></div>
                </div>
                <div class="flex-auto border-t-2 border-gray-300"></div>
                <div class="flex items-center text-gray-500 relative">
                    <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                        <img src="@/assets/img/verified.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Unggah<br>Selesai</p></div>
                </div>
            </div>
        </div>
        <div class="mt-6 pb-48 w-full px-4">
            <p class="w-full text-center text-xs">Anda dapat mengunggah dokumen dengan format  PNG, jpg, jpeg, PDF, DOC, DOCX, XLS, atau XLSX</p>
            <template v-if="docCompleted && docCompletedFile">
                <div class="w-full rounded-lg border border-solid mt-4">
                    <div class="w-full border-b border-solid py-2 flex justify-between items-center px-4">
                        <span class="text-sm">Kartu Keluarga</span>
                        <button type="button" class="h-7 w-7 border border-blue-900 text-blue-900 rounded-full flex justify-center items-center cursor-pointer hover:bg-blue-900 hover:text-white" @click="removeDokumen(docCompletedFile.Id)">
                            <TrashIcon class="h-5 w-5" />
                        </button>
                    </div>
                    <div class="w-full flex items-center px-4 py-4 cursor-pointer" @click="downloadFile(docCompletedFile.URL__c + '&appkey=' + $amazyncKey)">
                        <img v-if="checkFileExtension(docCompletedFile.Name) == 'image'" :src="docCompletedFile.URL__c + '&appkey=' + $amazyncKey" class="w-12">
                        <img v-else-if="checkFileExtension(docCompletedFile.Name) == 'pdf'" src="@/assets/img/protect/thumbnail-pdf.png" class="w-12">
                        <img v-else src="@/assets/img/protect/thumbnail-doc.png" class="w-12">
                        <span class="text-sm ml-4">{{ docCompletedFile.Name }}</span>
                    </div>
                </div>
            </template>
            <template v-else>
                <Form
                @submit="formKartuKeluarga"
                :validation-schema="formDokumenWajibSchema"
                >
                    <div class="mt-4">
                        <label class="block rounded-xl h-28 py-4 cursor-pointer border border-solid relative" :class="[ docCompleted ? 'bg-white border-gray-300 hover:bg-gray-100' : 'bg-red-50 border-red-600 hover:bg-white' ]">
                            <div class="h-10 w-10 rounded-full bg-blue-900 flex justify-center items-center mx-auto">
                                <PlusIcon class="text-white h-8 w-8"/>
                            </div>
                            <p class="text-center mt-4 text-sm">Tambahkan <b>Kartu Keluarga</b> disini!</p>
                            <Field type="file" id="fileInputKartuKeluarga" name="file_input" class="hidden" v-model="tempFile.file_input" @input="previewFile('fileInputKartuKeluarga', 'formKartuKeluargaButton')" />
                        </label>
                        <button type="submit" id="formKartuKeluargaButton" class="hidden"></button>
                    </div>
                    <div v-if="!docCompleted" class="text-xs text-red-600">*Dokumen ini wajib untuk diunggah</div>
                </Form>
            </template>
            <template v-if="allDocStatus.slipGaji && allDocStatus.slipGajiFile">
                <div class="w-full rounded-lg border border-solid mt-4">
                    <div class="w-full border-b border-solid py-2 flex justify-between items-center px-4">
                        <span class="text-sm">Slip Gaji</span>
                        <button type="button" class="h-7 w-7 border border-blue-900 text-blue-900 rounded-full flex justify-center items-center cursor-pointer hover:bg-blue-900 hover:text-white" @click="removeDokumen(allDocStatus.slipGajiFile.Id)">
                            <TrashIcon class="h-5 w-5" />
                        </button>
                    </div>
                    <div class="w-full flex items-center px-4 py-4 cursor-pointer" @click="downloadFile(allDocStatus.slipGajiFile.URL__c + '&appkey=' + $amazyncKey)">
                        <img v-if="checkFileExtension(allDocStatus.slipGajiFile.Name) == 'image'" :src="allDocStatus.slipGajiFile.URL__c + '&appkey=' + $amazyncKey" class="w-12">
                        <img v-else-if="checkFileExtension(allDocStatus.slipGajiFile.Name) == 'pdf'" src="@/assets/img/protect/thumbnail-pdf.png" class="w-12">
                        <img v-else src="@/assets/img/protect/thumbnail-doc.png" class="w-12">
                        <span class="text-sm ml-4">{{ allDocStatus.slipGajiFile.Name }}</span>
                    </div>
                </div>
            </template>
            <template v-else>
                <Form
                @submit="formSlipGaji"
                :validation-schema="formDokumenSchema"
                v-slot="{ errors }"
                >
                    <div class="mt-4">
                        <label class="block rounded-xl h-28 py-4 cursor-pointer border border-solid relative bg-white border-gray-300 hover:bg-gray-100">
                            <div class="h-10 w-10 rounded-full bg-blue-900 flex justify-center items-center mx-auto">
                                <PlusIcon class="text-white h-8 w-8"/>
                            </div>
                            <p class="text-center mt-4 text-sm">Tambahkan <b>Slip Gaji</b> disini!</p>
                            <Field type="file" id="fileInputSlipGaji" name="file_input" class="hidden" v-model="tempFile.file_input" @input="previewFile('fileInputSlipGaji', 'formSlipGajiButton')" />
                        </label>
                        <button type="submit" id="formSlipGajiButton" class="hidden"></button>
                    </div>
                    <div v-if="errors.file_input" class="text-xs text-red-600">*{{errors.file_input}}</div>
                </Form>
            </template>
            <template v-if="allDocStatus.inability && allDocStatus.inabilityFile">
                <div class="w-full rounded-lg border border-solid mt-4">
                    <div class="w-full border-b border-solid py-2 flex justify-between items-center px-4">
                        <span class="text-sm">Surat Keterangan Tidak Mampu</span>
                        <button type="button" class="h-7 w-7 border border-blue-900 text-blue-900 rounded-full flex justify-center items-center cursor-pointer hover:bg-blue-900 hover:text-white" @click="removeDokumen(allDocStatus.inabilityFile.Id)">
                            <TrashIcon class="h-5 w-5" />
                        </button>
                    </div>
                    <div class="w-full flex items-center px-4 py-4 cursor-pointer" @click="downloadFile(allDocStatus.inabilityFile.URL__c + '&appkey=' + $amazyncKey)">
                        <img v-if="checkFileExtension(allDocStatus.inabilityFile.Name) == 'image'" :src="allDocStatus.inabilityFile.URL__c + '&appkey=' + $amazyncKey" class="w-12">
                        <img v-else-if="checkFileExtension(allDocStatus.inabilityFile.Name) == 'pdf'" src="@/assets/img/protect/thumbnail-pdf.png" class="w-12">
                        <img v-else src="@/assets/img/protect/thumbnail-doc.png" class="w-12">
                        <span class="text-sm ml-4">{{ allDocStatus.inabilityFile.Name }}</span>
                    </div>
                </div>
            </template>
            <template v-else>
                <Form
                @submit="formInability"
                :validation-schema="formDokumenSchema"
                v-slot="{ errors }"
                >
                    <div class="mt-4">
                        <label class="block rounded-xl h-28 py-4 cursor-pointer border border-solid relative bg-white border-gray-300 hover:bg-gray-100">
                            <div class="h-10 w-10 rounded-full bg-blue-900 flex justify-center items-center mx-auto">
                                <PlusIcon class="text-white h-8 w-8"/>
                            </div>
                            <p class="text-center mt-4 text-sm">Tambahkan <b>Surat Keterangan Tidak Mampu</b> disini!</p>
                            <Field type="file" id="fileInputInability" name="file_input" class="hidden" v-model="tempFile.file_input" @input="previewFile('fileInputInability', 'formInabilityButton')" />
                        </label>
                        <button type="submit" id="formInabilityButton" class="hidden"></button>
                    </div>
                    <div v-if="errors.file_input" class="text-xs text-red-600">*{{errors.file_input}}</div>
                </Form>
            </template>
            <template v-if="allDocStatus.resign && allDocStatus.resignFile">
                <div class="w-full rounded-lg border border-solid mt-4">
                    <div class="w-full border-b border-solid py-2 flex justify-between items-center px-4">
                        <span class="text-sm">Surat Pemutusan Hubungan Kerja</span>
                        <button type="button" class="h-7 w-7 border border-blue-900 text-blue-900 rounded-full flex justify-center items-center cursor-pointer hover:bg-blue-900 hover:text-white" @click="removeDokumen(allDocStatus.resignFile.Id)">
                            <TrashIcon class="h-5 w-5" />
                        </button>
                    </div>
                    <div class="w-full flex items-center px-4 py-4 cursor-pointer" @click="downloadFile(allDocStatus.resignFile.URL__c + '&appkey=' + $amazyncKey)">
                        <img v-if="checkFileExtension(allDocStatus.resignFile.Name) == 'image'" :src="allDocStatus.resignFile.URL__c + '&appkey=' + $amazyncKey" class="w-12">
                        <img v-else-if="checkFileExtension(allDocStatus.resignFile.Name) == 'pdf'" src="@/assets/img/protect/thumbnail-pdf.png" class="w-12">
                        <img v-else src="@/assets/img/protect/thumbnail-doc.png" class="w-12">
                        <span class="text-sm ml-4">{{ allDocStatus.resignFile.Name }}</span>
                    </div>
                </div>
            </template>
            <template v-else>
                <Form
                @submit="formResign"
                :validation-schema="formDokumenSchema"
                v-slot="{ errors }"
                >
                    <div class="mt-4">
                        <label class="block rounded-xl h-28 py-4 cursor-pointer border border-solid relative bg-white border-gray-300 hover:bg-gray-100">
                            <div class="h-10 w-10 rounded-full bg-blue-900 flex justify-center items-center mx-auto">
                                <PlusIcon class="text-white h-8 w-8"/>
                            </div>
                            <p class="text-center mt-4 text-sm">Tambahkan <b>Surat Pemutusan Hubungan Kerja</b> disini!</p>
                            <Field type="file" id="fileInputResign" name="file_input" class="hidden" v-model="tempFile.file_input" @input="previewFile('fileInputResign', 'formResignButton')" />
                        </label>
                        <button type="submit" id="formResignButton" class="hidden"></button>
                    </div>
                    <div v-if="errors.file_input" class="text-xs text-red-600">*{{errors.file_input}}</div>
                </Form>
            </template>
        </div>
        <div class="absolute bottom-0 mb-28 w-full px-4">
            <button type="button" @click="nextStep" class="rounded-lg block w-full font-medium border border-solid text-center text-base py-2 text-white" :class="[ docCompleted ? 'cursor-pointer bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700' : 'bg-gray-300 border-gray-300 cursor-not-allowed' ]" :disabled="!docCompleted">Kirim</button>
        </div>
    </div>
    <template v-if="showFilePreview">
        <div class="fixed inset-0 z-50 bg-gray-900 bg-opacity-75 grid grid-cols-1 p-3 sm:p-10 overflow-y-auto">
            <div class="bg-white rounded-lg w-full sm:w-8/12 place-self-center">
                <div class="bg-blue-100 rounded-t-lg py-2"> 
                    <p class="text-center text-base font-bold">Pratinjau</p>
                </div>
                <div v-if="!errorMessage" class="w-full p-4">
                    <img v-if="nameFileTypePratinjau == 'gambar' && urlPratinjau" :src="urlPratinjau" class="w-full sm:w-8/12 sm:mx-auto">
                    <template v-else-if="nameFileTypePratinjau == 'pdf'">
                        <div class="w-40 mx-auto">
                            <img src="@/assets/img/protect/thumbnail-pdf.png" class="w-full">
                            <p class="text-center text-sm px-2 overflow-scroll">{{ fileName }}</p>
                        </div>
                    </template>
                    <template v-else>
                        <div class="w-40 mx-auto">
                            <img src="@/assets/img/protect/thumbnail-doc.png" class="w-full">
                            <p class="text-center text-sm px-2 overflow-scroll">{{ fileName }}</p>
                        </div>
                    </template>
                </div>
                <p v-if="errorMessage" class="text-center font-semibold my-8 text-sm px-4">Pastikan file yang Anda upload dalam format JPG, JPEG, PNG, PDF, DOC, DOCX, XLS, atau XLSX.</p>
                <div class="flex justify-between">
                    <button type="button" class="w-6/12 rounded-bl-lg py-2 text-sm font-bold text-blue-900 bg-white border border-blue-900 hover:text-white hover:bg-blue-700" @click="clickFileInput">Pilih Kembali</button>
                    <button type="button" class="w-6/12 rounded-br-lg py-2 text-sm font-bold text-white" @click="unggahFile" :class="[ !errorMessage ? 'cursor-pointer bg-blue-900 hover:bg-blue-700' : 'bg-gray-300 cursor-not-allowed' ]" :disabled="errorMessage">Unggah</button>
                </div>
            </div>
        </div>
    </template>
    <loading-overlay v-if="isLoading" />
</template>

<script>
import { defineComponent, ref, inject, computed, reactive } from 'vue';
import { useStore } from 'vuex';
import router from "../../router";
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import { PlusIcon } from '@heroicons/vue/solid';
import { TrashIcon } from '@heroicons/vue/outline';
import documentApi from "@/axios/docCollection";

export default defineComponent({
    components: {
        Form,
        Field,
        PlusIcon,
        TrashIcon
    },
    data() {
        return {
            errorMessage: false,
            urlPratinjau: null,
            fileName: null,
            nameFileTypePratinjau: null,
            fileInputId: null,
            formButtonId: null,
        }
    },
    methods: {
        clickFileInput(){
            if(this.fileInputId){
                document.getElementById(this.fileInputId).click()
            }
        },
        unggahFile(){
            if(this.urlPratinjau){
                if(this.formButtonId){
                    document.getElementById(this.formButtonId).click()
                }
            }
        },
        previewFile(fileInputId, formButtonId) {
            this.urlPratinjau = null;
            this.fileName = null;
            this.nameFileTypePratinjau = null;
            if (this.tempFile.file_input.length > 0 && this.SUPPORTED_FORMATS.includes(this.tempFile.file_input[0].type) && this.tempFile.file_input[0].size <= this.FILE_SIZE){
                const reader = new window.FileReader();
                reader.onload = (e) => {
                    let fileType = this.tempFile.file_input[0].type;
                    this.nameFileTypePratinjau = fileType.includes("image") ? "gambar" : fileType.includes("pdf") ? "pdf" : "dokumen";
                    this.fileName = this.tempFile.file_input[0].name;
                    this.errorMessage = false;
                    this.fileInputId = fileInputId;
                    this.formButtonId = formButtonId;
                    this.showFilePreview = true;
                    this.urlPratinjau = e.target.result;
                }
                reader.readAsDataURL(this.tempFile.file_input[0]);
            } else {
                this.errorMessage = true;
                this.urlPratinjau = null;
                this.fileName = null;
                this.nameFileTypePratinjau = null;
                this.tempFile = {};
            }
        },
        checkFileExtension(fileName){
            let fileNameSplit = fileName.split('.')
            if(fileNameSplit.length > 0){
                let a = fileNameSplit.length - 1;
                if(fileNameSplit[a] == 'doc' || fileNameSplit[a] == 'docx'){
                    return 'doc';
                }else if(fileNameSplit[a] == 'xls' || fileNameSplit[a] == 'xlsx'){
                    return 'xls';
                }else if(fileNameSplit[a] == 'pdf'){
                    return 'pdf';
                }else{
                    return 'image';
                }
            }else{
                return 'default';
            }
        }
    },
    setup(){
        const FILE_SIZE = 49152 * 1024;
        const SUPPORTED_FORMATS = [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ];
        const store = useStore();
        const swal = inject("$swal");
        const docWajib = computed(() => store.getters['docCollection/getDocWajib']);
        const docCompleted = computed(() => store.getters['docCollection/getDocStatus']);
        const docCompletedFile = computed(() => store.getters['docCollection/getDocStatusFile']);
        const allDocStatus = computed(() => store.getters['docCollection/getAllDocStatus']);
        let isLoading = ref(false);
        let showFilePreview = ref(false);
        const tempFile = reactive({
            file_input: null,
        });

        const formDokumenWajibSchema = yup.object({
            file_input: yup.mixed().required("Pilih file.")
            .test("fileSize", "Ukuran file maksimal 48 MB.", (value) => { if (value && value.length > 0) return value[0].size <= FILE_SIZE }).test("fileFormat", "Mohon pilih file sesuai format yang diperbolehkan.", (value) => { if (value && value.length > 0) return SUPPORTED_FORMATS.includes(value[0].type) }),
        });

        const formDokumenSchema = yup.object({
            file_input: yup.mixed().nullable()
            .test("fileSize", "Ukuran file maksimal 48 MB.", (value) => { if (value && value.length > 0) return value[0].size <= FILE_SIZE }).test("fileFormat", "Mohon pilih file sesuai format yang diperbolehkan.", (value) => { if (value && value.length > 0) return SUPPORTED_FORMATS.includes(value[0].type) }),
        });

        const swalConfirm = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600',
                cancelButton: 'bg-white mx-2 py-2 px-5 rounded-lg border border-blue-900 text-blue-900 text-sm font-semibold hover:bg-red-400 hover:border-red-400 hover:text-white'
            },
            buttonsStyling: false,
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        const swalConfirmDelete = swal.mixin({
            customClass: {
                confirmButton: 'bg-red-600 text-white mx-2 py-2 px-5 rounded-lg border border-red-600 text-sm font-semibold hover:bg-red-500 hover:border-red-500',
                cancelButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-700 hover:border-blue-700'
            },
            buttonsStyling: false,
            title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        const swalError = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600',
                cancelButton: 'absolute -top-3 -right-3'
            },
            buttonsStyling: false,
            title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        function nextStep(){
            swalConfirm.fire({
                html: '<p class="text-center text-sm mt-2">Anda yakin dokumen sudah benar?<br>Jika sudah, silahkan untuk dikonfirmasi agar data Anda segera diproses!</p>',
                showCancelButton: true,
                confirmButtonText: 'Konfirmasi',
                cancelButtonText: 'Batalkan',
            }).then((result) => {
                if (result.isConfirmed){
                    router.push({ name: 'doc collection - unggah selesai' });
                }
            });
        }

        function formKartuKeluarga(values, { resetForm }) {
            showFilePreview.value = false;
            isLoading.value = true;
            let formData = new FormData();
            formData.append('file', values.file_input[0]);
            formData.append('field', 'Family Card');

            documentApi.post('salesforce/contacts/upload-document', formData,{
                headers: {
                    'Accept': 'multipart/form-data',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(() => {
                resetForm();
                store.dispatch('docCollection/requestDocWajib').then(() => {
                    isLoading.value = false;
                });
            })
            .catch(() => {
                isLoading.value = false;
                swalError.fire({
                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                }).then((result) => {
                    if (result.isConfirmed){
                        window.location = 'https://wa.me/6285888236207?text=' + 
                        'Mohon bantuan saya gagal terus dalam proses document collection - unggah dokumen wajib di web.amalan.com';
                        return;
                    }else{
                        showFilePreview.value = true;
                    }
                });
            })
        }

        function formSlipGaji(values, { resetForm }) {
            showFilePreview.value = false;
            isLoading.value = true;
            let formData = new FormData();
            formData.append('file', values.file_input[0]);
            formData.append('field', 'Pay Slip');

            documentApi.post('salesforce/contacts/upload-document', formData,{
                headers: {
                    'Accept': 'multipart/form-data',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(() => {
                resetForm();
                store.dispatch('docCollection/requestDocWajib').then(() => {
                    isLoading.value = false;
                });
            })
            .catch(() => {
                isLoading.value = false;
                swalError.fire({
                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                }).then((result) => {
                    if (result.isConfirmed){
                        window.location = 'https://wa.me/6285888236207?text=' + 
                        'Mohon bantuan saya gagal terus dalam proses document collection - unggah dokumen wajib di web.amalan.com';
                        return;
                    }else{
                        showFilePreview.value = true;
                    }
                });
            })
        }

        function formInability(values, { resetForm }) {
            showFilePreview.value = false;
            isLoading.value = true;
            let formData = new FormData();
            formData.append('file', values.file_input[0]);
            formData.append('field', 'Certificate Inability');

            documentApi.post('salesforce/contacts/upload-document', formData,{
                headers: {
                    'Accept': 'multipart/form-data',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(() => {
                resetForm();
                store.dispatch('docCollection/requestDocWajib').then(() => {
                    isLoading.value = false;
                });
            })
            .catch(() => {
                isLoading.value = false;
                swalError.fire({
                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                }).then((result) => {
                    if (result.isConfirmed){
                        window.location = 'https://wa.me/6285888236207?text=' + 
                        'Mohon bantuan saya gagal terus dalam proses document collection - unggah dokumen wajib di web.amalan.com';
                        return;
                    }else{
                        showFilePreview.value = true;
                    }
                });
            })
        }

        function formResign(values, { resetForm }) {
            showFilePreview.value = false;
            isLoading.value = true;
            let formData = new FormData();
            formData.append('file', values.file_input[0]);
            formData.append('field', 'Resign Letter');

            documentApi.post('salesforce/contacts/upload-document', formData,{
                headers: {
                    'Accept': 'multipart/form-data',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(() => {
                resetForm();
                store.dispatch('docCollection/requestDocWajib').then(() => {
                    isLoading.value = false;
                });
            })
            .catch(() => {
                isLoading.value = false;
                swalError.fire({
                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                }).then((result) => {
                    if (result.isConfirmed){
                        window.location = 'https://wa.me/6285888236207?text=' + 
                        'Mohon bantuan saya gagal terus dalam proses document collection - unggah dokumen wajib di web.amalan.com';
                        return;
                    }else{
                        showFilePreview.value = true;
                    }
                });
            })
        }

        function removeDokumen(docId){
            swalConfirmDelete.fire({
                html: '<p class="text-center text-sm mt-2">Dokumen ini tidak dapat dipulihkan kembali. <br><br>Apakah Anda yakin ingin menghapusnya?</p>',
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batalkan',
            }).then((result) => {
                if (result.isConfirmed){
                    isLoading.value = true;
                    documentApi.delete('salesforce/contacts/documents/' + docId)
                    .then(() => {
                        store.dispatch('docCollection/requestDocWajib').then(() => {
                            isLoading.value = false;
                        });
                    })
                    .catch(() => {
                        isLoading.value = false;
                        swalError.fire({
                            html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                            showCancelButton: true,
                            confirmButtonText: 'Bantuan',
                            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                        }).then((result) => {
                            if (result.isConfirmed){
                                window.location = 'https://wa.me/6285888236207?text=' + 
                                'Mohon bantuan saya gagal terus untuk menghapus dokumen wajib - document collection di web.amalan.com';
                                return;
                            }
                        });
                    })
                }
            });
        }

        function downloadFile(url){
            window.open(url, '_blank');
        }

        return {
            docWajib,
            docCompleted,
            nextStep,
            FILE_SIZE,
            SUPPORTED_FORMATS,
            tempFile,
            formDokumenWajibSchema,
            formDokumenSchema,
            isLoading,
            formKartuKeluarga,
            showFilePreview,
            formSlipGaji,
            formInability,
            formResign,
            allDocStatus,
            docCompletedFile,
            removeDokumen,
            downloadFile
        };
    }
})
</script>