<script setup>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { crispChatBoxClosed, setCrispLoaded, useCrispInstance } from '@/composables/crisp'
import { ref } from 'vue';

const store = useStore()
const crisp = useCrispInstance()

const notificationData = computed(() => store.getters['pendingUserActivity/getPendingActivityData'])

const isChatBoxOpened = ref(false)

const hideCrispBtn = () => {
	const chatboxBtn = document.querySelectorAll('.cc-1m2mf')
	chatboxBtn[0]?.setAttribute('style', 'display: none !important;')
}

onMounted(() => {
	crisp.setZIndex(19)
	crisp.load()

	hideCrispBtn()

	crisp.session.onLoaded(() => {
		crisp.chat.close()
		hideCrispBtn()
		setCrispLoaded()
	})

	crisp.chat.onChatOpened(() => {
		isChatBoxOpened.value = true
		crispChatBoxClosed.value = false

		setTimeout(() => {
			hideCrispBtn()

			const chatbox = document.querySelectorAll('.cc-1no03')
			chatbox[0].setAttribute('style', 'max-width: 512px !important; margin-left: auto !important; margin-right: auto !important; left: 0 !important; right: 0 !important; top: 0 !important; bottom: 38px !important;')
		}, 100);
	})

	crisp.chat.onChatClosed(() => {
		isChatBoxOpened.value = false
		crispChatBoxClosed.value = true

		setTimeout(() => hideCrispBtn(), 100);
	})
})
</script>

<template>
    <section class="block fixed inset-x-0 mx-auto w-full max-w-lg bottom-0 z-10 sssm:z-20 bg-amalan-white shadow-amalan-md">
		<div class="flex justify-between">
			<router-link to="/home" class="w-full hover:text-amalan-blue-1 hover:bg-amalan-gray-5 justify-center inline-block text-center pt-3 pb-2" :class="[ $route.name == 'Home' ? 'text-amalan-blue-1 bg-amalan-gray-5' : 'text-amalan-gray-3']">
				<img v-if="$route.name == 'Home'" src="../assets/img/home-active.svg" class="h-6 w-6 inline-block mb-1">
				<img v-else src="../assets/img/home.svg" class="h-6 w-6 inline-block mb-1">
				<span class="block text-xs" :class="[ $route.name == 'Home' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]">Beranda</span>
			</router-link>
			<!-- <router-link to="/pembelian" class="w-full hover:text-amalan-blue-1 hover:bg-amalan-gray-5 justify-center inline-block text-center pt-3 pb-2" :class="[ $route.name == 'Pembelian' ? 'text-amalan-blue-1 bg-amalan-gray-5' : 'text-amalan-gray-3']">
				<img v-if="$route.name == 'Pembelian'" src="../assets/img/riwayat-active.svg" class="h-6 w-6 inline-block mb-1">
				<img v-else src="../assets/img/riwayat.svg" class="h-6 w-6 inline-block mb-1">
				<span class="block text-xs" :class="[ $route.name == 'Pembelian' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]">Riwayat</span>
			</router-link> -->
			<router-link to="/aktivitas" class="w-full hover:text-amalan-blue-1 hover:bg-amalan-gray-5 justify-center inline-block text-center pt-3 pb-2" :class="[ $route.name == 'Aktivitas' ? 'text-amalan-blue-1 bg-amalan-gray-5' : 'text-amalan-gray-3']">
				<div class="relative inline-block">
					<div v-if="($route.name !== 'Aktivitas' && notificationData.length > 0)" class="absolute h-2 w-2 bg-amalan-red rounded-full top-0 right-0"></div>
					<img v-if="$route.name == 'Aktivitas'" src="../assets/img/riwayat-active.svg" class="h-6 w-6 inline-block mb-1">
					<img v-else src="../assets/img/riwayat.svg" class="h-6 w-6 inline-block mb-1">
				</div>
				<span class="block text-xs" :class="[ $route.name == 'Aktivitas' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]">Aktivitas</span>
			</router-link>
			<router-link to="/score" class="w-full hover:text-amalan-blue-1 hover:bg-amalan-gray-5 justify-center inline-block text-center pt-3 pb-2" :class="[ $route.name == 'Score' ? 'text-amalan-blue-1 bg-amalan-gray-5' : 'text-amalan-gray-3']">
				<img v-if="$route.name == 'Score'" src="../assets/img/score-active.svg" class="h-6 w-6 inline-block mb-1">
				<img v-else src="../assets/img/score.svg" class="h-6 w-6 inline-block mb-1">
				<span class="block text-xs" :class="[ $route.name == 'Score' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]">Score</span>
			</router-link>
			<router-link to="/pesan" class="w-full hover:text-amalan-blue-1 hover:bg-amalan-gray-5 justify-center inline-block text-center pt-3 pb-2" :class="[ $route.name == 'Pesan' ? 'text-amalan-blue-1 bg-amalan-gray-5' : 'text-amalan-gray-3']">
				<img v-if="$route.name == 'Pesan'" src="../assets/img/pesan-active.svg" class="h-6 w-6 inline-block mb-1">
				<img v-else src="../assets/img/pesan.svg" class="h-6 w-6 inline-block mb-1">
				<span class="block text-xs" :class="[ $route.name == 'Pesan' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]">Pesan</span>
			</router-link>
			<router-link to="/akun" class="w-full hover:text-amalan-blue-1 hover:bg-amalan-gray-5 justify-center inline-block text-center pt-3 pb-2" :class="[ $route.path.includes('akun') ? 'text-amalan-blue-1 bg-amalan-gray-5' : 'text-amalan-gray-3']">
				<img v-if="$route.path.includes('akun')" src="../assets/img/akun-active.svg" class="h-6 w-6 inline-block mb-1">
				<img v-else src="../assets/img/akun.svg" class="h-6 w-6 inline-block mb-1">
				<span class="block text-xs" :class="[ $route.path.includes('akun') ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]">Akun</span>
			</router-link>
		</div>
	</section>
</template>