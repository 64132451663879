<script setup>
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
const store = useStore()
const getterUser = computed(() => store.getters['user/getUser'])
const profilePicture = computed(() => store.getters['user/getProfilePicture'])
const TITLE_LIST = {
    'Bpk': 'Bapak',
    'Ibu': 'Ibu',
    'Sdr': 'Sodara',
    'Sdri': 'Sodari'
}

const getName = () => {
    const firstName = getterUser.value.first_name ? getterUser.value.first_name.trim() : ''
    const lastName = getterUser.value.last_name ? getterUser.value.last_name.trim() : ''
    return (firstName + ' ' + lastName).trim()
}

const getNameWithTitle = () => {
    const name = getName()
    const title = !getterUser.value.title || getterUser.value.title == 'Blank' ? '' : TITLE_LIST[getterUser.value.title] + ' '
    return title + name
}

const goToSupport = () => {
    window.location = 'https://wa.me/6285888236207?text=' + 
    'Halo, mohon bantuan untuk memperbaiki data diri saya yang keliru pada my amalan.'
    return
}

</script>

<template>
    <div class="min-h-screen bg-amalan-white">
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="router.go(-1)" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div v-if="getterUser.ocr_app" class="w-full px-6 py-7 pb-28">
            <div class="w-full px-4 py-4 border border-amalan-gray-4 box-border rounded-lg flex">
                <div @click="router.push({ name: 'profile picture - edit' })" class="w-22 h-22 rounded-full relative cursor-pointer">
                    <div class="w-22 h-22 rounded-full border-4 border-amalan-white shadow-amalan-md overflow-hidden">
                        <img :src="profilePicture ? profilePicture : require('@/assets/img/logo.png')" :class="[ profilePicture ? 'w-full h-full' : 'w-full h-full px-5 py-5']">
                    </div>
                    <div class="w-6 h-6 bg-amalan-white rounded-full border border-amalan-blue-1 flex justify-center items-center absolute right-0.5 bottom-0.5 shadow-amalan-md">
                        <img src="@/assets/img/pencil-icon.svg">
                    </div>
                </div>
                <div class="flex justify-center items-center ml-4">
                    <div class="w-full">
                        <p class="font-bold text-amalan-blue-1 text-lg leading-tight">
                            {{ getName() }} 
                            <span class="inline-block"><img v-if="getterUser.ocr_app" src="@/assets/img/checklist-verified.svg" class="h-3.5"></span>
                        </p>
                        <p class="text-amalan-black text-xs mt-0.5">Profil Terverifikasi</p>
                    </div>
                </div>
            </div>
            <p class="text-center text-xs text-amalan-black my-4">Jika terdapat data Anda yang keliru,<br>silahkan hubungi kami <span @click="goToSupport" class="text-amalan-blue-3 cursor-pointer underline">DISINI</span></p>
            <div class="w-full px-4 py-4 border border-amalan-gray-4 box-border rounded-lg">
                <p class="text-amalan-blue-1 text-base font-bold">Data Diri di e-KTP</p>
                <div class="border-t border-amalan-blue-1 mt-3 pt-3 grid grid-cols-10 gap-y-2 text-amalan-black text-xs">
                    <div class="font-bold col-span-4">Nama</div>
                    <div class="col-span-6">: {{ getNameWithTitle() }}</div>
                    <div class="font-bold col-span-4">Jenis Kelamin</div>
                    <div class="col-span-6">: {{ getterUser.gender == 'M' ? 'Laki-laki' : 'Perempuan' }}</div>
                    <div class="font-bold col-span-4">Tempat/Tgl Lahir</div>
                    <div class="col-span-6">: {{ getterUser.birthplace }}/{{ $filters.dateFormatDayFirst(getterUser.birthdate) }}</div>
                    <div class="font-bold col-span-4">Agama</div>
                    <div class="col-span-6">: {{ getterUser.religion.name }}</div>
                    <div class="font-bold col-span-4">Pendidikan</div>
                    <div class="col-span-6">: {{ getterUser.education.name }}</div>
                    <div class="font-bold col-span-4">Status</div>
                    <div class="col-span-6">: {{ getterUser.marital.name }}</div>
                </div>
            </div>
            <div class="w-full my-4 px-4 py-4 border border-amalan-gray-4 box-border rounded-lg">
                <p class="text-amalan-blue-1 text-base font-bold">Alamat di e-KTP</p>
                <div class="border-t border-amalan-blue-1 mt-3 pt-3 grid grid-cols-10 gap-y-2 text-amalan-black text-xs">
                    <div class="font-bold col-span-4">Alamat</div>
                    <div class="col-span-6">: {{ getterUser.address.street }}</div>
                    <div class="font-bold col-span-4">RT/RW</div>
                    <div class="col-span-6">: {{ getterUser.address.rt }}/{{ getterUser.address.rw }}</div>
                    <div class="font-bold col-span-4">Kelurahan</div>
                    <div class="col-span-6">: {{ getterUser.address.village.name }}</div>
                    <div class="font-bold col-span-4">Kecamatan</div>
                    <div class="col-span-6">: {{ getterUser.address.district.name }}</div>
                    <div class="font-bold col-span-4">Kota</div>
                    <div class="col-span-6">: {{ getterUser.address.city.name }}</div>
                    <div class="font-bold col-span-4">Provinsi</div>
                    <div class="col-span-6">: {{ getterUser.address.province.name }}</div>
                    <div class="font-bold col-span-4">Kode Pos</div>
                    <div class="col-span-6">: {{ getterUser.address.postal_code }}</div>
                </div>
            </div>
            <div class="w-full px-4 py-4 border border-amalan-gray-4 box-border rounded-lg">
                <p class="text-amalan-blue-1 text-base font-bold">Kontak</p>
                <div class="border-t border-amalan-blue-1 mt-3 pt-3 grid grid-cols-10 gap-y-2 text-amalan-black text-xs">
                    <div class="font-bold col-span-4">No HP</div>
                    <div class="col-span-6">: {{ getterUser.phone_number.replace('+62', '0') }}</div>
                    <div class="font-bold col-span-4">No Whatsapp</div>
                    <div class="col-span-6">: {{ getterUser.is_whatsapp_phone_number ? getterUser.phone_number.replace('+62', '0') : '-' }}</div>
                    <div class="font-bold col-span-4">Email</div>
                    <div class="col-span-6">: {{ getterUser.email }}</div>
                </div>
            </div>
        </div>
        <div v-else class="w-full min-h-screen px-6 flex flex-col justify-center items-center">
            <img src="@/assets/img/clipboard-v2.svg" class="w-2/6">
            <p class="text-center text-xs text-amalan-black mt-6">Akun Anda belum terverifikasi<br>Silahkan lakukan Verifikasi Data agar Anda dapat<br>menggunakan layanan amalan international.</p>
            <router-link to="/onboarding-1" class="flex items-center justify-center py-3 px-10 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-14">
                <span class="text-amalan-white text-sm font-bold">
                    Lakukan Verifikasi Data Sekarang
                </span>
            </router-link>
        </div>
    </div>
</template>