<script setup>
import { ChevronRightIcon, CheckIcon } from '@heroicons/vue/solid'
import { onMounted, ref } from 'vue'
import setCookies from '@/composables/setCookies'
import { mixpanelPushEvent, eventName } from '@/composables/mixpanel'
import { useRoute, useRouter } from 'vue-router';

const router = useRouter()
const route = useRoute()
const showPage = ref(false)
const hideLewati = ref(false)
const firstStep = ref(true)
const secondStep = ref(false)
const thirdStep = ref(false)
const fourStep = ref(false)
const currentPosition = ref(1)
const isLoading = ref(false)

const nextStep = () => {
    if(currentPosition.value == 1){
        firstStep.value = false
        hideLewati.value = true
        secondStep.value = true
        thirdStep.value = false
        fourStep.value = false
        currentPosition.value = 2
    }else if(currentPosition.value == 2){
        firstStep.value = false
        hideLewati.value = true
        secondStep.value = false
        thirdStep.value = true
        fourStep.value = false
        currentPosition.value = 3
    }else if(currentPosition.value == 3){
        firstStep.value = false
        hideLewati.value = true
        secondStep.value = false
        thirdStep.value = false
        fourStep.value = true
        currentPosition.value = 4
    }else{
        isLoading.value = true
        router.push({ name : 'Login' })
    }
}

const skip = () => {
    isLoading.value = true
    router.push({ name : 'Login' })
}

onMounted(() => {
    localStorage.setItem('from-onboarding-go-to', 'lead qualification - daftar pinjaman')

    const queryParams = route.query

    if(route.path == '/lead-qualification-onboarding'){
        setCookies.setUtmCookies(queryParams)
    }else{
        if(queryParams.utm_source) setCookies.setUtmCookies(queryParams)
        else setCookies.setDefaultUtmParams(queryParams, route.path.slice(1).replace('-onboarding', ''))
    }

    mixpanelPushEvent(eventName.ONBOARDING)

    setTimeout(() => {
        showPage.value = true
    }, 500)
})
</script>

<template>
    <div class="bg-amalan-white text-amalan-black min-h-screen">
        <Transition name="fade-fast">
            <div v-if="showPage" class="w-full h-800 mb-10">
                <div class="w-full h-5/6 relative">
                    <Transition name="fade-fast">
                        <div v-show="firstStep" class="absolute z-11">
                            <div class="justify-center flex pt-16">
                                <img class="h-16" src="../assets/img/logo-amalan.png">
                            </div>
                            <div class="w-full flex justify-center my-12">
                                <img src="@/assets/img/lead-qualification/first-onboarding-1.png" class="w-52">
                            </div>
                            <div class="px-10">
                                <p class="text-xs text-center">Layanan <b>Program Keringanan Utang</b> dari <b>amalan</b>, dapat membantu Anda mendapatkan diskon pinjaman atau program cicilan yang lebih rendah untuk utang Kartu Kredit, KTA, KPR, KMG, dan Pinjaman Online. </p>
                                <p class="text-xs text-center mt-8">Cukup dengan 3 langkah, Anda dapat segera bergabung dengan <b>amalan</b>!</p>
                            </div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-show="secondStep" class="absolute z-12">
                            <div class="justify-start flex pt-16">
                                <img class="h-12" src="@/assets/img/lead-qualification/onboarding-decoration-1.svg">
                            </div>
                            <div class="w-full flex justify-center mt-6 mb-14">
                                <img src="@/assets/img/lead-qualification/second-onboarding-1.png" class="w-60">
                            </div>
                            <div class="px-10">
                                <p class="text-center text-xs font-bold text-amalan-blue-1">Langkah Pertama:</p>
                                <p class="text-center text-base font-bold text-amalan-blue-1">Menambahkan Data Pinjaman</p>
                                <p class="text-xs text-center mt-1">Anda dapat menambahkan data pinjaman yang ingin Anda ajukan untuk mendapatkan keringanan pinjaman sesuai dengan jenis pinjaman yang tersedia.</p>
                            </div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-show="thirdStep" class="absolute z-13">
                            <div class="absolute right-0 pt-16">
                                <img class="h-28" src="@/assets/img/lead-qualification/onboarding-decoration-2.svg">
                            </div>
                            <div class="w-full grid grid-cols-5 gap-x-3 mt-40 px-6">
                                <img src="@/assets/img/lead-qualification/third-onboarding-1.png" class="col-span-2 order-1 sm:w-4/5 sm:mx-auto">
                                <div class="col-span-3 flex flex-col items-start justify-center order-2">
                                    <p class="text-xs font-bold text-amalan-blue-1">Langkah Kedua:</p>
                                    <p class="text-base font-bold text-amalan-blue-1">Informasi Tambahan</p>
                                    <p class="text-xs mt-1">Anda akan diminta untuk menambahkan dan menjelaskan kendala terkait pelunasan pinjaman yang Anda alami.</p>
                                </div>
                            </div>
                            <div class="w-full grid grid-cols-5 gap-x-3 mt-6 mb-14 px-6">
                                <img src="@/assets/img/lead-qualification/third-onboarding-2.png" class="col-span-2 order-2 sm:w-4/5 sm:mx-auto">
                                <div class="col-span-3 flex flex-col items-start justify-center order-1">
                                    <p class="text-xs font-bold text-amalan-blue-1">Langkah Ketiga:</p>
                                    <p class="text-base font-bold text-amalan-blue-1">Estimasi Keringanan</p>
                                    <p class="text-xs mt-1">Anda akan diberikan saran untuk jumlah menabung, menambahkan perkiraan kemampuan menabung Anda, dan melihat perbandingan keringanan yang didapatkan jika bersama amalan.</p>
                                </div>
                            </div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-show="fourStep" class="absolute z-14">
                            <div class="absolute left-0 pt-16">
                                <img class="h-28" src="@/assets/img/lead-qualification/onboarding-decoration-3.svg">
                            </div>
                            <div class="w-full flex justify-center items-center mt-48 mb-14 relative h-56">
                                <div class="absolute w-48 h-48 rounded-full bg-amalan-yellow-100"></div>
                                <div class="w-28 relative">
                                    <img src="@/assets/img/lead-qualification/four-onboarding-1.png" class="w-full">
                                    <div class="absolute bg-amalan-blue-1 w-11 h-11 rounded-full -right-4 -bottom-2 flex justify-center items-center text-amalan-yellow-400">
                                        <CheckIcon class="w-8" />
                                    </div>
                                </div>
                            </div>
                            <div class="px-10">
                                <p class="text-center text-base font-bold text-amalan-blue-1 mt-1">Registrasi Selesai</p>
                                <p class="text-xs text-center mt-2">Jika data Anda sudah lengkap, Anda dapat melakukan konfirmasi data dan Anda akan segera dihubungi oleh konsultan amalan.</p>
                            </div>
                        </div>
                    </Transition>
                </div>
                <div class="w-full h-1/6 relative">
                    <Transition name="fade-fast">
                        <div v-if="firstStep" class="w-full flex justify-center absolute z-11">
                            <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-if="secondStep" class="w-full flex justify-center absolute z-12">
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-if="thirdStep" class="w-full flex justify-center absolute z-11">
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-if="fourStep" class="w-full flex justify-center absolute z-12">
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                        </div>
                    </Transition>
                    <div class="w-full px-6 flex justify-between items-center flex-row-reverse absolute bottom-2">
                        <div type="button" @click="nextStep" class="text-amalan-white text-sm font-bold bg-amalan-blue-1 h-12 w-40 hover:bg-blue-900 rounded-lg flex items-center justify-center cursor-pointer"><span :class="{ 'ml-3' : currentPosition !== 4}">{{ currentPosition == 4 ? 'Mulai Sekarang' : 'Selanjutnya' }}</span><ChevronRightIcon class="h-8" v-if="currentPosition !== 4" /></div>
                        <Transition name="fade-fast"><span @click="skip" class="text-sm font-bold text-amalan-blue-1 cursor-pointer" v-if="!hideLewati">Lewati</span></Transition>
                    </div>
                </div>
            </div>
        </Transition>
        <loading-overlay v-if="isLoading" />
        <div class="-mt-3 px-6 mb-10" v-if="currentPosition == 4">
            <div class="w-full flex justify-center items-center text-sm bg-amalan-blue-3 text-amalan-blue-3" style="height: 1px">
                <div class="bg-amalan-white px-2">ATAU</div>
            </div>
            <p class="text-xs text-center mt-9 mb-4">Apakah Anda ingin mengetahui Credit Score yang Anda miliki?</p>
            <div class="w-full flex justify-center">
                <router-link :to="{ name: 'Landing Page Credit Checkup' }" class="text-sm font-bold text-amalan-blue-1 hover:text-blue-900 underline">Dapatkan Credit Score</router-link>
            </div>
            <!-- <p class="text-xs text-center mt-6 mb-2">Anda mampu membayar pembayaran pinjaman Anda saat ini dan ingin mendapatkan pinjaman baru?</p>
            <a href="https://lp.amalan.com/id-credit-checkup/" target="_blank" class="w-full block text-center cursor-pointer rounded-lg text-sm py-3 border border-solid font-bold bg-amalan-white text-amalan-black border-amalan-blue-1 hover:text-amalan-white hover:bg-amalan-blue-1">Dapatkan Pinjaman Baru</a> -->
        </div>
    </div>
</template>

<style scoped>
.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.8s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.8s ease
}
</style>